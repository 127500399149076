import type { WirelessReceiverLink } from "kubb";
import {
  type AssetPosition,
  type GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse,
  useGetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet,
} from "kubb";
import type React from "react";
import { ErrorBanner } from "../../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { useGetDataNew } from "../../../../../hooks/getData";

interface Props {
  assetPosition: AssetPosition;
  children: (props: {
    history: Array<WirelessReceiverLink>;
  }) => React.ReactNode;
}

function ReceiverHistoryGetter(props: Props) {
  const { assetPosition } = props;
  const { asset_position_id, company_id, scheme_id } = assetPosition;

  const { data, loading, error } =
    useGetDataNew<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>(
      () =>
        useGetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet(
          asset_position_id,
          { company_id },
        ),
    );

  return (
    <BlockSpinner loading={loading}>
      {data && props.children({ history: data })}
      {error && <ErrorBanner error={error} />}
    </BlockSpinner>
  );
}

export default ReceiverHistoryGetter;
