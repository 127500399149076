import type { TariffInstanceOut } from "kubb";
import type { TariffInstanceRatesInput } from "kubb";

type TariffServiceDescription = Omit<
  TariffInstanceRatesInput,
  | "unitConsumptionRate"
  | "unitConsumptionVatRate"
  | "standingChargeRate"
  | "standingChargeVatRate"
>;
type TariffServiceDescriptionMap = Record<string, TariffServiceDescription>;

function getTariffServices(
  tariffInstances: Array<TariffInstanceOut>,
): TariffServiceDescriptionMap {
  let serviceMap = {};

  for (const instance of tariffInstances) {
    for (const rate of instance.rates) {
      if (!(rate.service_name in serviceMap)) {
        serviceMap = { ...serviceMap, [rate.service_name]: rate };
      }
    }
  }

  return serviceMap;
}

export type { TariffServiceDescription, TariffServiceDescriptionMap };
export { getTariffServices };
