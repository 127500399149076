import type React from "react";

import { useGetDataNew } from "../../../hooks/getData";
import {
  type AssetTypeEnum,
  type IssueStats,
  useGetIssuesStatsIssueStatsGet,
} from "../../../kubb";

export type IssueStatsComponent = (props: {
  data?: IssueStats;
  loading?: boolean;
  error?: Error | null;
}) => React.ReactElement;

interface IssueStatsGetterProps {
  company_id: string;
  scheme_id?: string;
  asset_type?: AssetTypeEnum[];
  render: IssueStatsComponent;
}

export default function IssueStatsGetter(
  props: IssueStatsGetterProps,
): React.ReactElement {
  const { company_id, scheme_id, asset_type, render } = props;

  const { data, loading, error } = useGetDataNew<IssueStats>(() =>
    useGetIssuesStatsIssueStatsGet({
      company_id,
      scheme_id,
      asset_type,
    }),
  );

  return <>{render({ data, loading, error })}</>;
}
