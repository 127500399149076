import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { useContext, useEffect } from "react";
import ErrorTriangle from "../../../components/Error/ErrorTriangle";
import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData.tsx";
import { useUpdateActiveWirelessReceiverIdList } from "../../../hooks/updateEntity";
import useUserHasRoles from "../../../hooks/user/getCurrentUserRole";
import {
  type AssetPosition,
  useGetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet,
} from "../../../kubb";
import {
  AssetPositionCacheContext,
  useGetCachedAssetPosition,
} from "../../../reducers/assetPositionCachingReducer";
import { useRequiredSelectedCompanyId } from "../../../reducers/company.tsx";

export default function ReceiverAssignmentButton(props: {
  assetPosition: AssetPosition | AssetRegisterDataItem;
  receiverPositionId: string;
  scheme_id: string;
  initLoad?: boolean;
}): React.ReactElement {
  const company_id = useRequiredSelectedCompanyId();
  const { receiverPositionId, assetPosition } = props;
  const { dispatch } = useContext(AssetPositionCacheContext);
  const assetPositionState = useGetCachedAssetPosition(
    assetPosition.asset_position_id,
  );

  const isUserAdmin = useUserHasRoles("admin");

  const request =
    useGetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet(
      assetPosition.asset_position_id,
      { company_id: company_id },
      { query: { enabled: false } },
    );

  async function refresh() {
    dispatch({
      type: "FETCHING_ITEM",
      asset_position_id: assetPosition.asset_position_id,
    });
    const result = await request.refetch();
    dispatch({
      type: "FETCHED_ITEM",
      asset_position_id: assetPosition.asset_position_id,
      payload: { data: result.data?.data, error: result.error },
    });
  }

  useEffect(() => {
    if (assetPositionState === undefined && props.initLoad) {
      refresh();
    }
  });
  const { update, error } = useUpdateActiveWirelessReceiverIdList(
    assetPosition,
    company_id,
  );

  const assigned =
    assetPositionState?.data &&
    (assetPositionState.data || []).includes(receiverPositionId);

  function removeReceiverFromPosition(
    receivers: string[],
    receiverPositionId: string,
  ): string[] {
    const index = receivers.indexOf(receiverPositionId, 0);
    if (index > -1) {
      receivers.splice(index, 1);
    }
    return receivers;
  }

  function toggleAssignment() {
    if (assetPositionState?.data && isUserAdmin) {
      const updatedReceivers = !assigned
        ? [...assetPositionState.data, receiverPositionId]
        : removeReceiverFromPosition(
            assetPositionState.data,
            receiverPositionId,
          );
      if (
        assetPositionState.data &&
        (assetPositionState.updating === false ||
          assetPositionState.updating === undefined)
      ) {
        dispatch({
          type: "UPDATING_ITEM",
          asset_position_id: assetPosition.asset_position_id,
        });
        update(updatedReceivers).then(() => {
          dispatch({
            type: "UPDATED_ITEM",
            asset_position_id: assetPosition.asset_position_id,
            payload: { data: updatedReceivers },
          });
        });
      }
    }
  }

  return (
    <div style={{ width: "20px" }} className={"align-middle"}>
      {(assetPositionState?.loading || assetPositionState?.updating) && (
        <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
      )}
      {!assetPositionState?.loading &&
        !assetPositionState?.updating &&
        assetPositionState?.data && (
          <FontAwesomeIcon
            icon={assigned ? faCheckSquare : faSquare}
            onClick={toggleAssignment}
            role={isUserAdmin ? "button" : undefined}
            fixedWidth
          />
        )}
      {!assetPositionState?.loading && (
        <ErrorTriangle error={assetPositionState?.error} />
      )}
      {!assetPositionState?.updating && <ErrorTriangle error={error} />}
    </div>
  );
}
