import type { AssetPosition } from "kubb";
import type React from "react";
import CompanyNavLink from "../navigation/CompanyNavLink";

const AssetPositionIdLink = (props: {
  assetPosition?: AssetPosition;
  asset_position_id?: string;
  full?: boolean;
  text?: string;
}): React.ReactElement => {
  const { assetPosition, asset_position_id } = props;
  const actualasset_position_id = assetPosition
    ? assetPosition.asset_position_id
    : asset_position_id;

  if (actualasset_position_id) {
    if (actualasset_position_id !== "NONE") {
      const displayasset_position_id = props.full
        ? actualasset_position_id
        : `...${actualasset_position_id.slice(24)}`;
      return (
        <CompanyNavLink
          to={{ pathname: `/admin/asset_position/${actualasset_position_id}` }}
        >
          {props.text || displayasset_position_id}
        </CompanyNavLink>
      );
    } else {
      return <>-</>;
    }
  }
  throw new Error("One of assetPosition or asset_position_id must be set");
};

export default AssetPositionIdLink;
