import { ErrorBanner } from "components/Error/ErrorBanner";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { Form, Formik, type FormikHelpers, type FormikProps } from "formik";
import type { AssetType, AssetTypeEnum, AssetUseCaseEnum } from "kubb";
import { assetTypeEnum } from "kubb";
import { assetUseCaseEnum } from "kubb";
import type { Location } from "kubb";
import { isNil } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import AssetUseCaseSelect from "../../../components/Asset/AssetUseCaseSelect";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import { useCreateAssetPosition } from "../../../hooks/createEntity";
import { assetPositionSchema } from "../../../model/assetPosition";
import AssetTypeSelect from "../assets/AssetTypeSelect";

interface Props {
  location: Location;
  postCreateActions?: () => any;
}

interface FormValues {
  asset_position_type?: AssetTypeEnum;
  use_case: AssetUseCaseEnum;
  customer_reference?: string;
  parent_asset_position_id?: string;
}

function InnerAssetPositionCreateForm(
  props: FormikProps<FormValues> & { error?: Error; creating: boolean },
): React.ReactElement {
  const { values, setFieldValue, isValid, isSubmitting, creating, error } =
    props;
  React.useEffect(() => {
    if (
      (
        [
          assetTypeEnum.heat_meter,
          assetTypeEnum.cooling_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ] as AssetTypeEnum[]
      ).indexOf(values.asset_position_type as AssetType) < 0
    ) {
      setFieldValue("use_case", assetUseCaseEnum.NONE);
    }
  }, [values.asset_position_type, setFieldValue]);

  return (
    <BlockSpinner loading={creating}>
      <Form data-cy={"asset-position-create"}>
        <h3 className="text-white mb-0">Add Asset Position</h3>
        <AssetTypeSelect
          fieldName={"asset_position_type"}
          includeSelect={true}
        />
        <AssetUseCaseSelect
          fieldName={"use_case"}
          includeNone={true}
          disabled={
            isNil(values.asset_position_type) ||
            (
              [
                assetTypeEnum.heat_meter,
                assetTypeEnum.cooling_meter,
                assetTypeEnum.heating_and_cooling_meter,
              ] as AssetTypeEnum[]
            ).indexOf(values.asset_position_type) === -1
          }
        />
        <NewTextFormComponent
          fieldName={"customer_reference"}
          label={{ label: "Customer Reference" }}
        />
        <NewTextFormComponent
          fieldName={"parent_asset_position_id"}
          label={{ label: "Parent Asset Position Id" }}
        />
        <Button
          type={"submit"}
          color={"brandLight"}
          disabled={isSubmitting || !isValid}
        >
          Add Position
        </Button>
        <ErrorBanner error={error} />
      </Form>
    </BlockSpinner>
  );
}

export default function AssetPositionCreateForm(
  props: Props,
): React.ReactElement {
  const { update, error, loading } = useCreateAssetPosition(
    props.location.company_id,
  );

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    update({
      ...values,
      parent_asset_position_id:
        values.parent_asset_position_id &&
        values.parent_asset_position_id !== ""
          ? values.parent_asset_position_id
          : undefined,
      asset_position_type: values.asset_position_type as AssetTypeEnum,
      location_id: props.location.location_id,
      scheme_id: props.location.scheme_id,
      company_id: props.location.company_id,
    })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    asset_position_type: undefined,
    use_case: assetUseCaseEnum.NONE,
    customer_reference: "",
    parent_asset_position_id: "",
  };
  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={Yup.object().shape(assetPositionSchema)}
    >
      {(formikProps): React.ReactElement => (
        <InnerAssetPositionCreateForm
          {...formikProps}
          error={error}
          creating={loading}
        />
      )}
    </Formik>
  );
}
