import type {
  Company,
  GroupBy,
  GroupByEnum,
  LocationGroup,
  Scheme,
} from "kubb";
import { groupByEnum } from "kubb";
import { isNil } from "lodash-es";
import type React from "react";
import type { AggregatedStatsResponse } from "../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import { useSelectedCompany } from "../../reducers/company";
import { usePrivacyMode } from "../../reducers/privacy";
import {
  useLocationGroups,
  useSchemes,
  useSelectedScheme,
} from "../../reducers/scheme";
import type { GetDataWrapperResult } from "../MutateWrapper.tsx";
import BlockSpinner from "../Spinners/BlockSpinner";
import { Heading } from "../Theme/heading";
import {
  aggregatedStatsCountToPercentage,
  aggregatedStatsSelector,
  filterGroupList,
  mapLocationGroupData,
  mapSchemesData,
} from "../TimeSeries/model";
import { getUrlHash } from "../navigation/URL";
import SimpleLineChart, { type DataPoint } from "./SimpleLineChart";

interface AggregatedStatsChartDefinitionProps {
  groupBy?: GroupBy;
  clickUrl?: string;
  showCountAsPercentage?: boolean;
  seriesName: string;
  units: string;
  groupFilter?: string;
}

function getData(
  groupBy: GroupByEnum,
  company_id: string,
  scheme_id?: string,
  data?: any,
  schemes?: Scheme[],
  locationGroups?: LocationGroup[],
  statsCount = false,
  groupFilter?: string,
): any {
  if (isNil(data)) {
    return [[], undefined];
  }
  switch (groupBy) {
    case "GROUP_ALL":
      return scheme_id
        ? [
            undefined,
            !isNil(locationGroups)
              ? mapLocationGroupData(
                  data,
                  filterGroupList(
                    locationGroups as LocationGroup[],
                    groupFilter,
                  ),
                  (stats, groupId) =>
                    statsCount
                      ? (aggregatedStatsCountToPercentage(stats)(
                          groupId,
                        ) as DataPoint[])
                      : aggregatedStatsSelector("mean")(
                          stats,
                          groupId as string,
                        ),
                )
              : [],
          ]
        : [
            aggregatedStatsSelector("mean")(data, scheme_id as string),
            undefined,
          ];
    case "SCHEME":
      return scheme_id || isNil(schemes)
        ? [[], undefined]
        : [
            undefined,
            mapSchemesData(data, schemes, (data, scheme_id) =>
              statsCount
                ? aggregatedStatsCountToPercentage(data)(scheme_id)
                : aggregatedStatsSelector("mean")(data, scheme_id),
            ),
          ];

    case "NONE":
      return [
        statsCount
          ? aggregatedStatsCountToPercentage(data)(
              scheme_id ? scheme_id : company_id,
            )
          : aggregatedStatsSelector("mean")(
              data,
              scheme_id ? scheme_id : company_id,
            ),
        undefined,
      ];
  }
}

export default function AggregatedStatsChart(
  props: GetDataWrapperResult<AggregatedStatsResponse> &
    AggregatedStatsChartDefinitionProps,
): React.ReactElement {
  const allSchemes = useSchemes() || [];
  const privacyMode = usePrivacyMode();
  const selectedCompany = useSelectedCompany() as Company;
  const locationGroups = useLocationGroups();
  const selectedScheme = useSelectedScheme();

  const graphClickHandler = (e: any, company_id: string): void => {
    const scheme_id: string = e.point.series.userOptions.id;
    const url = `${props.clickUrl}${getUrlHash(
      company_id,
      scheme_id,
      privacyMode,
    )}`;
    window.open(url, "_blank");
  };

  const {
    data,
    loading,
    groupBy = groupByEnum.NONE,
    showCountAsPercentage = false,
  } = props;

  const [singleData, multipleData] = getData(
    groupBy as GroupByEnum,
    selectedCompany.company_id,
    selectedScheme?.scheme_id,
    data,
    allSchemes,
    locationGroups,
    showCountAsPercentage,
    props.groupFilter,
  );

  return (
    <BlockSpinner loading={loading}>
      {loading && <Heading>LOADING</Heading>}
      <SimpleLineChart
        data={singleData}
        multipleData={multipleData}
        name={props.seriesName}
        unit={props.units}
        displayExport={false}
        yMin={showCountAsPercentage ? 0 : undefined}
        yMax={showCountAsPercentage ? 100 : undefined}
        rounding={1}
        loading={loading}
        privacyModeEnabled={true}
        clickEvent={(e) =>
          graphClickHandler(e, selectedCompany.company_id as string)
        }
      />
    </BlockSpinner>
  );
}
