import client from 'client'
import type {
  UpdateAssetAssetAssetIdPutMutationRequest,
  UpdateAssetAssetAssetIdPutMutationResponse,
  UpdateAssetAssetAssetIdPutPathParams,
  UpdateAssetAssetAssetIdPutQueryParams,
  UpdateAssetAssetAssetIdPut422,
} from '../../types/UpdateAssetAssetAssetIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateAssetAssetAssetIdPutMutationKey = () => [{ url: '/asset/{asset_id}' }] as const

export type UpdateAssetAssetAssetIdPutMutationKey = ReturnType<typeof updateAssetAssetAssetIdPutMutationKey>

/**
 * @summary Update Asset
 * {@link /asset/:asset_id}
 */
export async function updateAssetAssetAssetIdPut(
  asset_id: UpdateAssetAssetAssetIdPutPathParams['asset_id'],
  data?: UpdateAssetAssetAssetIdPutMutationRequest,
  params?: UpdateAssetAssetAssetIdPutQueryParams,
  config: Partial<RequestConfig<UpdateAssetAssetAssetIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateAssetAssetAssetIdPutMutationResponse,
    ResponseErrorConfig<UpdateAssetAssetAssetIdPut422>,
    UpdateAssetAssetAssetIdPutMutationRequest
  >({ method: 'PUT', url: `/asset/${asset_id}`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Update Asset
 * {@link /asset/:asset_id}
 */
export function useUpdateAssetAssetAssetIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateAssetAssetAssetIdPutMutationResponse>,
      ResponseErrorConfig<UpdateAssetAssetAssetIdPut422>,
      {
        asset_id: UpdateAssetAssetAssetIdPutPathParams['asset_id']
        data?: UpdateAssetAssetAssetIdPutMutationRequest
        params?: UpdateAssetAssetAssetIdPutQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateAssetAssetAssetIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateAssetAssetAssetIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateAssetAssetAssetIdPutMutationResponse>,
    ResponseErrorConfig<UpdateAssetAssetAssetIdPut422>,
    {
      asset_id: UpdateAssetAssetAssetIdPutPathParams['asset_id']
      data?: UpdateAssetAssetAssetIdPutMutationRequest
      params?: UpdateAssetAssetAssetIdPutQueryParams
    }
  >({
    mutationFn: async ({ asset_id, data, params }) => {
      return updateAssetAssetAssetIdPut(asset_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}