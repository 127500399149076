import client from 'client'
import type {
  ExecuteBulkUpdateBulkUpdateIdPostMutationResponse,
  ExecuteBulkUpdateBulkUpdateIdPostPathParams,
  ExecuteBulkUpdateBulkUpdateIdPostQueryParams,
  ExecuteBulkUpdateBulkUpdateIdPost422,
} from '../../types/ExecuteBulkUpdateBulkUpdateIdPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const executeBulkUpdateBulkUpdateIdPostMutationKey = () => [{ url: '/bulk/{update_id}' }] as const

export type ExecuteBulkUpdateBulkUpdateIdPostMutationKey = ReturnType<typeof executeBulkUpdateBulkUpdateIdPostMutationKey>

/**
 * @summary Execute Bulk Update
 * {@link /bulk/:update_id}
 */
export async function executeBulkUpdateBulkUpdateIdPost(
  update_id: ExecuteBulkUpdateBulkUpdateIdPostPathParams['update_id'],
  params?: ExecuteBulkUpdateBulkUpdateIdPostQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ExecuteBulkUpdateBulkUpdateIdPostMutationResponse, ResponseErrorConfig<ExecuteBulkUpdateBulkUpdateIdPost422>, unknown>({
    method: 'POST',
    url: `/bulk/${update_id}`,
    params,
    ...requestConfig,
  })
  return res
}

/**
 * @summary Execute Bulk Update
 * {@link /bulk/:update_id}
 */
export function useExecuteBulkUpdateBulkUpdateIdPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<ExecuteBulkUpdateBulkUpdateIdPostMutationResponse>,
      ResponseErrorConfig<ExecuteBulkUpdateBulkUpdateIdPost422>,
      { update_id: ExecuteBulkUpdateBulkUpdateIdPostPathParams['update_id']; params?: ExecuteBulkUpdateBulkUpdateIdPostQueryParams }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? executeBulkUpdateBulkUpdateIdPostMutationKey()

  return useMutation<
    ResponseConfig<ExecuteBulkUpdateBulkUpdateIdPostMutationResponse>,
    ResponseErrorConfig<ExecuteBulkUpdateBulkUpdateIdPost422>,
    { update_id: ExecuteBulkUpdateBulkUpdateIdPostPathParams['update_id']; params?: ExecuteBulkUpdateBulkUpdateIdPostQueryParams }
  >({
    mutationFn: async ({ update_id, params }) => {
      return executeBulkUpdateBulkUpdateIdPost(update_id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}