import { createSearchString } from "components/navigation/SearchString";
import type { Dayjs } from "dayjs";
import type React from "react";
import type { Path } from "react-router-dom";
import CompanyNavLink from "../navigation/CompanyNavLink";

const LocationRowLink = (props: {
  location_id?: string;
  children: React.ReactNode;
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}): React.ReactElement => {
  const { location_id, startDatetime, endDatetime } = props;

  const link: Partial<Path> = {
    pathname: `/admin/location/${location_id}`,
    search:
      (startDatetime || endDatetime) &&
      `?${createSearchString({
        startDatetime: startDatetime?.format(),
        endDatetime: endDatetime?.format(),
      })}`,
  };

  return (
    <>
      {location_id && (
        <CompanyNavLink to={link} className={"px-0"} external={true} inline>
          {props.children}
        </CompanyNavLink>
      )}
    </>
  );
};

export default LocationRowLink;
