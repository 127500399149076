import clsx from "clsx";
import dayjs from "dayjs";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";
import { Badge } from "../../../../../components/Theme/badge";
import { TableCell, TableRow } from "../../../../../components/Theme/table";
import CopyToClipboard from "../../../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../../../components/utils/HighlightNullAndOldDates";

function StreamingGatewayDataTableRow(props: {
  dataCollectorId?: string;
  dataCollector?: WirelessSurveyDataCollectorOut;
  date: string;
  data: string;
  secondaryAddress: string;
  manufacturer: string;
  medium: string;
  version: string;
  rssi: string;
  newOnTimeOnly?: boolean;
}) {
  const { newOnTimeOnly = false } = props;
  const [isNew, setIsNew] = useState<boolean>(
    newOnTimeOnly ? dayjs().diff(dayjs(props.date), "seconds") <= 60 : true,
  );
  useTimeout(() => {
    setIsNew(false);
  }, 10000);
  const { date, data } = props;
  const hex = b64ToHex(data)
    .toUpperCase()
    .match(/.{1,2}/g)
    ?.join(" ");
  return (
    <TableRow className={clsx(isNew ? "bg-gray-200" : "table-light")}>
      {props.dataCollectorId && (
        <TableCell>
          {props.dataCollector?.data_collector_name || props.dataCollectorId}
        </TableCell>
      )}
      <TableCell>
        <span>{formatDate(date, undefined, true)}</span>
        <span className={"w-12 inline-block"}>
          {isNew && (
            <Badge color={"brandLight"} className={"px-2 mx-2"}>
              New
            </Badge>
          )}
        </span>
      </TableCell>
      <TableCell>{props.manufacturer}</TableCell>
      <TableCell>{props.secondaryAddress}</TableCell>
      <TableCell>{props.medium}</TableCell>
      <TableCell>{props.version}</TableCell>
      <TableCell>{props.rssi}</TableCell>
      <TableCell>
        <CopyToClipboard
          value={hex}
          slice={10}
          className={"text-monospace small"}
        />
      </TableCell>
    </TableRow>
  );
}

function toHexString(byteArray: Uint8Array) {
  return Array.from(byteArray, (byte) =>
    `0${(byte & 0xff).toString(16)}`.slice(-2),
  ).join("");
}

function base64ToArrayBuffer(base64: string): Uint8Array {
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
}

export function b64ToHex(value: string): string {
  return toHexString(base64ToArrayBuffer(value));
}

export default StreamingGatewayDataTableRow;
