import type { WirelessSurveyOut } from "kubb";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";

export default function WirelessSurveyHeading(props: {
  survey: WirelessSurveyOut;
}) {
  const { survey } = props;
  return (
    <ObjectDetailHeading
      leftColumn={{
        content: (
          <ObjectPropertyItem title={"Survey Name"} type={"heading"}>
            <PrivacyMode>{survey.survey_name}</PrivacyMode>
          </ObjectPropertyItem>
        ),
      }}
      rightColumn={{
        content: (
          <>
            <ObjectPropertyItem title={"Survey ID"}>
              <CopyToClipboard value={survey.survey_id} slice={8} />
            </ObjectPropertyItem>
            <ObjectPropertyItem title={"Created"}>
              {formatDate(survey.created_at, undefined, false)}
            </ObjectPropertyItem>
            <ObjectPropertyItem title={"Updated"}>
              {formatDate(survey.last_updated, undefined, false)}
            </ObjectPropertyItem>
          </>
        ),
      }}
    />
  );
}
