import PieChart, {
  type ColouredPieChartData,
} from "../../../components/charts/PieChart.tsx";

import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner.tsx";
import SelectedScheme from "../../../components/Scheme/SelectedScheme.tsx";
import BlockSpinner from "../../../components/Spinners/BlockSpinner.tsx";
import Card, { FixHeightCardBody } from "../../../components/Theme/card.tsx";
import { pieChartColors } from "../../../components/charts/ChartColors.tsx";
import type { IssueStats } from "../../../kubb";
import {
  BillableAssets,
  NonBillableAssetTypes,
} from "../../../model/assetPosition.tsx";
import IssueStatsGetter from "./IssueStatsGetter.tsx";

type Props = { title: string };

const preprocessData = (data: IssueStats): ColouredPieChartData => {
  /* removes 0 data returned by API and adds a color */
  return [
    {
      label: "OK",
      count: data.total - data.error - data.warning,
      color: pieChartColors.great,
    },
    { label: "Warning", count: data.warning, color: pieChartColors.bad },
    {
      label: "Error",
      count: data.error,
      color: pieChartColors.reallyReallyBad,
    },
  ];
};

export default function AssetPositionIssueStatsWidget(props: Props) {
  return (
    <SelectedScheme>
      {(schemeProps) => {
        return (
          <IssueStatsGetter
            {...schemeProps}
            asset_type={[...BillableAssets, ...NonBillableAssetTypes]}
            render={({ data, error, loading }) => {
              return (
                <Card title={props.title}>
                  <FixHeightCardBody height={36} overflowScroll={false}>
                    <BlockSpinner loading={loading || false}>
                      <ErrorOverlappingBanner error={error || undefined}>
                        {
                          <PieChart
                            data={data ? preprocessData(data) : []}
                            half={true}
                            donut={true}
                          />
                        }
                      </ErrorOverlappingBanner>
                    </BlockSpinner>
                  </FixHeightCardBody>
                </Card>
              );
            }}
          />
        );
      }}
    </SelectedScheme>
  );
}
