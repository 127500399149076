import dayjs from "dayjs";
import { Form, Formik } from "formik";
import type { TariffInstanceIn } from "kubb";
import type { TariffInstanceOut } from "kubb";
import type { TariffInstanceRatesInput } from "kubb";
import { Fragment } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import NewTariffInstanceRatesForm from "./NewTariffInstanceRatesForm";
import NewTariffServiceButton from "./NewTariffServiceButton";
import type {
  TariffServiceDescription,
  TariffServiceDescriptionMap,
} from "./model";

type FormRate = Pick<
  TariffInstanceRatesInput,
  | "unit_consumption_rate"
  | "standing_charge_rate"
  | "standing_charge_vat_rate"
  | "unit_consumption_vat_rate"
>;

function TariffInstanceListDisplay(props: {
  tariffInstances: Array<TariffInstanceOut>;
  addNewService: (newService: TariffServiceDescription) => Promise<any>;
  services: TariffServiceDescriptionMap;
  addNewInstance: (newInstance: TariffInstanceIn) => Promise<any>;
}) {
  const { services, tariffInstances } = props;

  const serviceOrder = Object.keys(props.services).sort();
  const instances = Object.fromEntries(
    tariffInstances.map((v) => [v.start_date, v]),
  );

  const instanceOrder = Object.keys(instances).sort();

  const addServiceDatesToRates = (
    formValues: FormRate[],
  ): TariffInstanceRatesInput[] => {
    return formValues.map((v, i): TariffInstanceRatesInput => {
      return {
        ...props.services[serviceOrder[i]],
        ...v,
      };
    });
  };

  return (
    <div>
      <Formik
        onSubmit={(v) =>
          props.addNewInstance({
            start_date: v.startDate,
            rates: addServiceDatesToRates(v.rates),
          })
        }
        initialValues={{
          startDate: dayjs().format().slice(0, 10),
          rates: [],
        }}
      >
        <Form>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader />
                {serviceOrder.map((value) => (
                  <TableHeader colSpan={4} key={value}>
                    {services[value].service_name}
                  </TableHeader>
                ))}

                {tariffInstances.length === 0 ? (
                  <TableHeader>
                    <NewTariffServiceButton
                      create={(value) => props.addNewService(value)}
                    />
                  </TableHeader>
                ) : (
                  <DisplayIfUserType userTypes={"superadmin"}>
                    <TableHeader> </TableHeader>
                  </DisplayIfUserType>
                )}
              </TableRow>
              <TableRow>
                <TableHeader>Start Date</TableHeader>
                {serviceOrder.map((value) => (
                  <Fragment key={value}>
                    <TableHeader>Net Unit Cost</TableHeader>
                    <TableHeader>Unit VAT Rate (%)</TableHeader>
                    <TableHeader>Daily Cost</TableHeader>
                    <TableHeader>Daily VAT Rate (%)</TableHeader>
                  </Fragment>
                ))}
                <DisplayIfUserType userTypes={"superadmin"}>
                  <TableHeader> </TableHeader>
                </DisplayIfUserType>
              </TableRow>
            </TableHead>
            <>
              {instanceOrder.map((value) => (
                <TableRow key={value}>
                  <TableCell>{value}</TableCell>
                  {serviceOrder.map((serviceName) => {
                    const instance = instances[value];
                    const service = instance.rates.filter(
                      (v) => v.service_name === serviceName,
                    );
                    if (service.length === 1) {
                      const theService = service[0];
                      return (
                        <>
                          <TableCell width={200} className={"text-right"}>
                            {theService.unit_consumption_rate}
                          </TableCell>
                          <TableCell width={200} className={"text-right"}>
                            {theService.unit_consumption_vat_rate}
                          </TableCell>
                          <TableCell width={200} className={"text-right"}>
                            {theService.standing_charge_rate}
                          </TableCell>
                          <TableCell width={200} className={"text-right"}>
                            {theService.standing_charge_vat_rate}
                          </TableCell>
                          <DisplayIfUserType userTypes={"superadmin"}>
                            <TableCell width={200} className={"text-right"}>
                              {" "}
                            </TableCell>
                          </DisplayIfUserType>
                        </>
                      );
                    } else {
                      throw new Error(
                        "The instance has more than one service with the same name or it doesnt have a service it should.",
                      );
                    }
                  })}
                </TableRow>
              ))}
              <DisplayIfUserType userTypes={"superadmin"}>
                <NewTariffInstanceRatesForm
                  services={serviceOrder.map((v) => services[v])}
                />
              </DisplayIfUserType>
            </>
          </Table>
        </Form>
      </Formik>
    </div>
  );
}

export default TariffInstanceListDisplay;
