export const assetTypeEnum = {
  heat_meter: 'heat_meter',
  cooling_meter: 'cooling_meter',
  heating_and_cooling_meter: 'heating_and_cooling_meter',
  electricity_meter: 'electricity_meter',
  gas_meter: 'gas_meter',
  water_meter: 'water_meter',
  room_sensor: 'room_sensor',
  co2_room_sensor: 'co2_room_sensor',
  input_output_module: 'input_output_module',
  gateway: 'gateway',
  wireless_receiver: 'wireless_receiver',
  wireless_cluster: 'wireless_cluster',
  api_integration: 'api_integration',
  sim_card: 'sim_card',
  unknown: 'unknown',
  wireless_survey: 'wireless_survey',
} as const

export type AssetTypeEnum = (typeof assetTypeEnum)[keyof typeof assetTypeEnum]

export type AssetType = AssetTypeEnum