import {
  type AssetAlarmEvent,
  type GetAlarmListEventAlarmGetQueryParams,
  alarmTypesEnum,
} from "kubb";
import { useGetAlarmListEventAlarmGet } from "kubb";
import type React from "react";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import {
  StackedListHeadingContent,
  StackedListTwoColumnItem,
} from "../../components/Theme/stacked-list";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import { useRequiredSelectedCompanyId } from "../../reducers/company.tsx";
import { useSelectedSchemeId } from "../../reducers/scheme.tsx";
import AlarmWidgetRow from "./AlarmWidgetRow";

interface Props {
  params: GetAlarmListEventAlarmGetQueryParams;
  list?: boolean;
  lastUpdated?: string;
  row?: (value: AssetAlarmEvent, index: number) => React.ReactElement;
  showPagination?: boolean;
  maxDisplay?: number;
  noDataRow?: React.ReactElement;
  showHeader?: boolean;
  dense?: boolean;
}

export default function AlarmList(props: Props): React.ReactElement {
  const { showHeader = true } = props;
  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();

  const filterAlarms = !useUserHasRoles("superadmin", [], false);

  const row = props.row
    ? props.row
    : (value: AssetAlarmEvent, index: number): React.ReactElement =>
        AlarmWidgetRow(value, index, props.dense);
  return (
    <GetListPaginatedList
      row={row}
      useQuery={useGetAlarmListEventAlarmGet}
      params={{
        company_id: company_id,
        scheme_id: scheme_id,
        ...props.params,
        alarm_types: filterAlarms
          ? [alarmTypesEnum.GATEWAY_OFFLINE]
          : undefined,
      }}
      header={
        showHeader ? (
          <StackedListTwoColumnItem
            column1={<StackedListHeadingContent title={""} />}
            column2={
              <StackedListHeadingContent
                title={"Age"}
                className={"text-right"}
              />
            }
            column1Size={"w-5/6"}
            column2Size={"w-1/6"}
          />
        ) : (
          <></>
        )
      }
      cursor={{ max_items: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={props.showPagination}
      noDataRow={props.noDataRow}
    />
  );
}
