import type { GatewayState, InputOutputModuleStateOutput } from "kubb";
import type React from "react";
import type { Asset } from "utils/object";
import GatewayStateLabel from "./gateway/GatewayStateLabel";
import RelayStateLabel from "./input_output_module/RelayStateLabel";

const AssetStateLabel = (
  props: {
    asset?: Asset;
    size?: "sm" | "md" | "lg";
  } & React.HTMLAttributes<HTMLSpanElement>,
): React.ReactElement => {
  const { asset, ...rest } = props;
  const relay = asset?.asset_type === "input_output_module";
  const gateway = asset?.asset_type === "gateway";
  const receiver = asset?.asset_type === "wireless_receiver";

  if (asset?.state) {
    if (relay) {
      return (
        <RelayStateLabel
          state={(asset.state as InputOutputModuleStateOutput).relay_state}
          {...rest}
        />
      );
    } else if (gateway || receiver) {
      return (
        <GatewayStateLabel
          state={(asset.state as GatewayState).configuration_state}
          {...rest}
        />
      );
    }
  }
  return <></>;
};

export default AssetStateLabel;
