import type { AssetPositionAssetLinkOut } from "kubb";
import type React from "react";
import { useUpdateAssetPositionAssetLink } from "../../../../hooks/updateEntity";
import type { AssetPositionAssetRowInnerProps } from "./AssetPositionAssetRowInner";

function AssetPositionAssetRowContainer(props: {
  link: AssetPositionAssetLinkOut;
  refreshList?: () => void;
  children: (props: AssetPositionAssetRowInnerProps) => React.ReactNode;
}): React.ReactElement {
  const { link, refreshList } = props;

  const update = useUpdateAssetPositionAssetLink(link);

  return (
    <>
      {props.children({
        link,
        refreshList,
        updateEntity: update,
      })}
    </>
  );
}

export default AssetPositionAssetRowContainer;
