import client from 'client'
import type { GetEventListEventGetQueryResponse, GetEventListEventGetQueryParams, GetEventListEventGet422 } from '../../types/GetEventListEventGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getEventListEventGetQueryKey = (params?: GetEventListEventGetQueryParams) => [{ url: '/event' }, ...(params ? [params] : [])] as const

export type GetEventListEventGetQueryKey = ReturnType<typeof getEventListEventGetQueryKey>

/**
 * @summary Get Event List
 * {@link /event}
 */
export async function getEventListEventGet(params?: GetEventListEventGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetEventListEventGetQueryResponse, ResponseErrorConfig<GetEventListEventGet422>, unknown>({
    method: 'GET',
    url: `/event`,
    params,
    ...requestConfig,
  })
  return res
}

export function getEventListEventGetQueryOptions(params?: GetEventListEventGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getEventListEventGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetEventListEventGetQueryResponse>,
    ResponseErrorConfig<GetEventListEventGet422>,
    ResponseConfig<GetEventListEventGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getEventListEventGet(params, config)
    },
  })
}

/**
 * @summary Get Event List
 * {@link /event}
 */
export function useGetEventListEventGet<
  TData = ResponseConfig<GetEventListEventGetQueryResponse>,
  TQueryData = ResponseConfig<GetEventListEventGetQueryResponse>,
  TQueryKey extends QueryKey = GetEventListEventGetQueryKey,
>(
  params?: GetEventListEventGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetEventListEventGetQueryResponse>, ResponseErrorConfig<GetEventListEventGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getEventListEventGetQueryKey(params)

  const query = useQuery({
    ...(getEventListEventGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetEventListEventGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}