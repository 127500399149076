import { Badge, type colors } from "components/Theme/badge";
import { type RelayStateEnum, relayStateEnum } from "kubb";
import type React from "react";

const getColor = (state?: RelayStateEnum): keyof typeof colors => {
  switch (state) {
    case relayStateEnum.ON:
      return "success";
    case relayStateEnum.OFF:
      return "danger";
    case relayStateEnum.SWITCHING_ON:
      return "warning";
    case relayStateEnum.SWITCHING_OFF:
      return "warning";
    case relayStateEnum.SWITCHING_ERROR:
      return "danger";
    default:
      return "warning";
  }
};
const relayStateEnumLabel = (
  props: {
    state?: RelayStateEnum;
    size?: "sm" | "md" | "lg";
  } & Omit<React.HTMLAttributes<HTMLSpanElement>, "color">,
): React.ReactElement => {
  const { size = "lg", state, ...rest } = props;
  return (
    <span className={`text-${size}`}>
      <Badge color={getColor(state)} {...rest}>
        {state || "UNKNOWN"}
      </Badge>
    </span>
  );
};

export default relayStateEnumLabel;
