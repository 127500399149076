import type { BulkUpdateSummary } from "kubb";
import {
  type ObjectTypes,
  bulkUpdateStatusEnum,
  useGetBulkUpdateListBulkGet,
} from "kubb";
import { snakeCase, startCase } from "lodash-es";
import type React from "react";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import { useSelectedSchemeId } from "../../reducers/scheme";
import NewGetListPaginatedTable from "../Pagination/GetListPaginatedTable.tsx";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { formatDate } from "../utils/HighlightNullAndOldDates";
import { OneCardPage } from "../utils/OneCardPage";

interface Props {
  objectType: ObjectTypes;
}

const BulkUpdateList = (props: Props): React.ReactElement => {
  const type_url = snakeCase(props.objectType);

  const header = (
    <tr>
      <th scope="col" className="text-left">
        Filename
      </th>
      <th scope="col" className="text-left">
        Status
      </th>
      <th scope="col" className="text-left">
        Created At
      </th>
      <th scope="col" className="text-left">
        Executed At
      </th>
      <th scope="col" className="text-left">
        New
      </th>
      <th scope="col" className="text-left">
        Updates
      </th>
      <th scope="col" className="text-left">
        Unchanged
      </th>
      <th scope="col" className="text-left">
        Invalid
      </th>
      <th scope={"col"}>&nbsp;</th>
    </tr>
  );

  const row = (value: BulkUpdateSummary): React.ReactElement => {
    const invalid =
      (value.preview_stats.new_invalid ?? 0) +
      (value.preview_stats.update_invalid ?? 0) +
      (value.preview_stats.not_found ?? 0);

    return (
      <tr data-cy="updates-list-item">
        <td data-cy="update-filename">{value.filename}</td>
        <td data-cy="update-status">{value.status}</td>
        <td data-cy={"update-created"}>{formatDate(value.created_at)}</td>
        <td data-cy={"update-executed"}>
          {value.executed_at ? formatDate(value.executed_at) : "N/A"}
        </td>
        {value.status === bulkUpdateStatusEnum.PREVIEW_CREATED ? (
          <>
            <td data-cy={"update-new"}>{value.preview_stats.new_valid}</td>
            <td data-cy={"update-updated"}>
              {value.preview_stats.update_valid}
            </td>
            <td data-cy={"update-unchanged"}>
              {value.preview_stats.unchanged}
            </td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        ) : (
          <>
            <td data-cy={"update-new"}>
              {value.execute_stats?.created}/{value.preview_stats.new_valid}
            </td>
            <td data-cy={"update-updated"}>
              {value.execute_stats?.updated}/{value.preview_stats.update_valid}
            </td>
            <td data-cy={"update-unchanged"}>
              {value.preview_stats.unchanged}
            </td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        )}
        <td data-cy={"update-actions"}>
          <CompanyNavLink
            to={{ pathname: `/admin/${type_url}/updates/${value.update_id}` }}
            button={{
              color: "brandLight",
            }}
          >
            View
          </CompanyNavLink>
        </td>
      </tr>
    );
  };

  const title = startCase(`${props.objectType}s`);

  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();

  return (
    <OneCardPage
      headerTitle={`${title} Updates`}
      breadcrumbs={[
        { url: `/admin/${type_url}`, name: title },
        { url: `/admin/${type_url}/update`, name: "Bulk Update" },
      ]}
      setPageTitle={schemeObjectPageTitle(`Bulk Updates (${props.objectType})`)}
    >
      <NewGetListPaginatedTable
        header={[header, 9]}
        row={row}
        params={{
          company_id: company_id,
          scheme_id: scheme_id,
          objectType: props.objectType,
        }}
        useQuery={useGetBulkUpdateListBulkGet}
      />
    </OneCardPage>
  );
};

export default BulkUpdateList;
