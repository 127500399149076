import {
  type AssetRegisterAggregatedTimeSeriesResponse,
  assetTypeEnum,
  periodEnum,
} from "kubb";
import type { AggregatedReportRequest } from "kubb";
import type React from "react";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";
import type { HeatNetworkReportParams } from "./model";

interface Props {
  company_id?: string;
  scheme_id?: string;
  query: HeatNetworkReportParams;
}

export const SYNTHETIC_VOLUME_REPORT_AGGREGATIONS = [
  "sum_estimated_volume",
  "sum_estimated_forward_volume",
  "sum_estimated_reverse_volume",
];

const VOLUME_REPORT_COLS = [
  {
    parameter: "Synthetic Volume",
    aggregation: "sum_estimated_volume",
    name: "Estimated Volume Change",
  },
  {
    parameter: "Synthetic Volume",
    aggregation: "sum_estimated_forward_volume",
    name: "Estimated Forward Volume Change",
  },
  {
    parameter: "Synthetic Volume",
    aggregation: "sum_estimated_negative_volume",
    name: "Estimated Negative Volume Change",
  },
  {
    parameter: "Volume",
    aggregation: "difference",
    name: "Recorded Volume Change",
  },
  {
    parameter: "Synthetic Volume",
    aggregation: "difference",
    name: "Difference",
  },
  {
    parameter: "Synthetic Volume",
    aggregation: "difference_percentage",
    name: "Difference (%)",
  },
];

const addDifferenceValues = (
  data?: AssetRegisterAggregatedTimeSeriesResponse,
): AssetRegisterAggregatedTimeSeriesResponse | undefined => {
  if (data) {
    return {
      times: data.times,
      data: data.data.map((value) => {
        const { aggregations, ...asset } = value;

        const synthetic_total =
          (aggregations["Synthetic Volume"]
            .sum_estimated_volume[0] as number) || 0;
        const recorded_total =
          (aggregations.Volume.difference[0] as number) || 0;
        const difference = recorded_total - synthetic_total;
        const percentage =
          recorded_total !== 0
            ? Math.abs(difference / recorded_total) * 100
            : undefined;

        return {
          ...asset,
          aggregations: {
            "Synthetic Volume": {
              ...aggregations["Synthetic Volume"],
              difference: [difference],
              differencePercentage: [percentage as number],
            },
            Volume: aggregations.Volume,
          },
        };
      }),
    };
  }
};

const SyntheticVolumeReportPage = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const params = (): AggregatedReportRequest => {
    if (props.company_id && props.scheme_id) {
      return {
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        aggregations: [
          ...SYNTHETIC_VOLUME_REPORT_AGGREGATIONS.map((value) => {
            return {
              parameter: "Synthetic Volume",
              aggregation: value,
            };
          }),
          {
            parameter: "Volume",
            aggregation: "difference",
          },
        ],
        asset_types: [
          assetTypeEnum.heat_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ],
        start_time: startDatetime.toISOString(),
        end_time: endDatetime.toISOString(),
        period: periodEnum.ALL,
        use_case: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  return (
    <>
      {props.company_id && props.scheme_id && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => (
            <AllPeriodReportTable
              data={addDifferenceValues(data)}
              columnDefinitions={VOLUME_REPORT_COLS}
              downloadFilename={`${props.scheme_id}_synthetic_volume_report`}
              startDatetime={startDatetime}
              endDatetime={endDatetime}
            />
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default SyntheticVolumeReportPage;
