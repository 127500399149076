import { Form, Formik, type FormikHelpers } from "formik";
import type { WirelessSurveyIn } from "kubb";
import type { WirelessSurveyOut } from "kubb";
import type React from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import type { MutateEntityWrapperResult } from "../../../hooks/updateEntity.tsx";
import { useSelectedCompany } from "../../../reducers/company";

interface FormValues {
  survey_name: string;
}
function WirelessSurveyCreateForm(props: {
  createWirelessSurvey: MutateEntityWrapperResult<
    WirelessSurveyOut,
    WirelessSurveyIn
  >;
  onCreate?: (surveyId: string) => void;
}) {
  const company = useSelectedCompany();

  const { update, error } = props.createWirelessSurvey;
  async function submit(
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ) {
    if (company === undefined) {
      throw new Error("Company must be selected.");
    }
    setSubmitting(true);
    update({
      company_id: company.company_id,
      ...values,
    })
      .then(([d]) => {
        resetForm();
        props.onCreate?.(d.survey_id);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Formik
      initialValues={{
        survey_name: "",
      }}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        survey_name: Yup.string().required().min(5),
      })}
      validateOnMount={true}
    >
      {({ touched, isValid, isSubmitting }): React.ReactElement => (
        <>
          <Form>
            <NewTextFormComponent
              fieldName={"survey_name"}
              label={{ label: "Name" }}
            />
            <Button
              type="submit"
              color="brandLight"
              disabled={isSubmitting || !isValid || !touched}
            >
              Create
            </Button>
          </Form>
          <ErrorBanner error={error} />
        </>
      )}
    </Formik>
  );
}

export default WirelessSurveyCreateForm;
