import type React from "react";
import type { ColumnDefinitionWithData } from "../../../../model/deviceTimeSeries";
import type { Asset } from "../../../../utils/object.tsx";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import AssetTimeSeriesDataGetter from "./AssetTimeSeriesDataGetter";
import type { DeviceTimeSeriesDisplayComponent } from "./model";

interface Props<
  T = string | undefined,
  D extends ColumnDefinitionWithData<T> = ColumnDefinitionWithData<T>,
> {
  device: Asset;
  render: DeviceTimeSeriesDisplayComponent<T, D>;
}

const DeviceTimeSeriesContainer = (props: Props): React.ReactElement => {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesDataGetter
            device={props.device}
            searchParams={searchParams}
            render={props.render}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
};

export default DeviceTimeSeriesContainer;
