import { Form, Formik, type FormikHelpers } from "formik";
import type { AssetPosition, AssetTypeEnum } from "kubb";
import { assetClassEnum } from "kubb";
import type React from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner.tsx";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../../components/Forms/SelectCombo/SelectCombo";
import { Button } from "../../../components/Theme/button";
import { useCreateAsset } from "../../../hooks/createEntity";
import AssetTypeSelect from "../assets/AssetTypeSelect";
import AssetPositionAssetLinkDateSelect from "./assetPositionAssetLinks/AssetPositionAssetLinkDateSelect";

interface FormValues {
  serial_number: string;
  manufacturer: string;
  asset_type: AssetTypeEnum;
  install_date?: string;
  uninstall_date?: string;
}

interface Props {
  assetPosition: AssetPosition;
  refresh?: () => void;
}

export default function CreateAssetForm(props: Props): React.ReactElement {
  const create = useCreateAsset(props.assetPosition.company_id);
  const isGateway = props.assetPosition.asset_position_type === "gateway";

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);

    create
      .update({
        serial_number: values.serial_number,
        manufacturer: isGateway ? values.manufacturer : undefined,
        asset_class: isGateway
          ? values.manufacturer === "evinox"
            ? assetClassEnum.evinox
            : assetClassEnum.mbus
          : assetClassEnum.mbus,
        company_id: props.assetPosition.company_id,
        asset_type: props.assetPosition.asset_position_type as unknown as
          | "heat_meter"
          | "cooling_meter"
          | "heating_and_cooling_meter"
          | "electricity_meter"
          | "gas_meter"
          | "water_meter"
          | "room_sensor"
          | "co2_room_sensor"
          | "input_output_module"
          | "gateway"
          | "wireless_receiver"
          | "sim_card"
          | "unknown",
        current_link: {
          asset_position_id: props.assetPosition.asset_position_id,
          install_date: values.install_date,
          uninstall_date: values.uninstall_date,
          cancelled: false,
        },
      })
      .then(() => {
        props.refresh?.();
        resetForm();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    serial_number: "",
    manufacturer: isGateway ? "elvaco" : "",
    asset_type: props.assetPosition.asset_position_type,
  };

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        serial_number: Yup.string().required("Required"),
        manufacturer: isGateway
          ? Yup.string().required()
          : Yup.string().notRequired().max(0),
      })}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid }) => (
        <Form data-cy={"create-asset-form"}>
          <>
            <NewTextFormComponent
              fieldName={"serial_number"}
              label={{ label: "Serial Number" }}
            />
            <AssetTypeSelect
              fieldName={"asset_type"}
              label={{ label: "AssetType" }}
              disabled={true}
            />
            {isGateway && (
              <SelectComboField
                fieldName={"manufacturer"}
                options={[
                  { label: "Elvaco", value: "elvaco" },
                  {
                    label: "Evinox",
                    value: "evinox",
                  },
                  { label: "Lansen", value: "lansen" },
                ]}
                label={{ label: "Manufacturer" }}
              />
            )}
            <h4>Dates</h4>
            <AssetPositionAssetLinkDateSelect newLink={true} />

            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Asset
            </Button>
            {create.error && <ErrorBanner error={create.error} />}
          </>
        </Form>
      )}
    </Formik>
  );
}
