import type { AssetPositionNumbers } from "kubb";
import type React from "react";
import { useContext } from "react";
import {
  AssetPositionNumbersToTable,
  computeTotalNumberOfAssetPositionsForListOfSchemes,
} from "../../../../components/AssetPosition/AssetPositionNumberSummaryList";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import Card, { FixHeightCardBody } from "../../../../components/Theme/card";
import { Heading } from "../../../../components/Theme/heading";
import { SchemeContext } from "../../../../reducers/scheme";

export default function AssetPositionCountWidget(): React.ReactElement {
  const { state } = useContext(SchemeContext);

  const numberOfAssets: AssetPositionNumbers | undefined = state.selected
    ? state.selected.number_of_asset_positions
    : computeTotalNumberOfAssetPositionsForListOfSchemes(
        state.schemes ? state.schemes : [],
      );

  return (
    <Card title={"Asset Positions"}>
      <FixHeightCardBody height={96}>
        <BlockSpinner loading={state.loading || false}>
          {numberOfAssets ? (
            <AssetPositionNumbersToTable numbers={numberOfAssets} />
          ) : (
            <Heading>No assets yet.</Heading>
          )}
        </BlockSpinner>
      </FixHeightCardBody>
    </Card>
  );
}
