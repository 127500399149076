import { volumeUnitsEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

const VolumeUnitSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "m3", value: volumeUnitsEnum.m3 },
    { label: "Litres", value: volumeUnitsEnum.litres },
  ];

  return <SelectComboField options={options} {...props} />;
};

export default VolumeUnitSelect;
