export const actionsEnum = {
  UPDATE: 'UPDATE',
  NEW: 'NEW',
  NOT_FOUND: 'NOT_FOUND',
  DELETED: 'DELETED',
  UNCHANGED: 'UNCHANGED',
} as const

export type ActionsEnum = (typeof actionsEnum)[keyof typeof actionsEnum]

export type Actions = ActionsEnum