import client from 'client'
import type {
  GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse,
  GetSchemeGroupsSchemeSchemeIdGroupsGetPathParams,
  GetSchemeGroupsSchemeSchemeIdGroupsGetQueryParams,
  GetSchemeGroupsSchemeSchemeIdGroupsGet422,
} from '../../types/GetSchemeGroupsSchemeSchemeIdGroupsGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSchemeGroupsSchemeSchemeIdGroupsGetQueryKey = (
  scheme_id: GetSchemeGroupsSchemeSchemeIdGroupsGetPathParams['scheme_id'],
  params?: GetSchemeGroupsSchemeSchemeIdGroupsGetQueryParams,
) => [{ url: '/scheme/:scheme_id/groups', params: { scheme_id: scheme_id } }, ...(params ? [params] : [])] as const

export type GetSchemeGroupsSchemeSchemeIdGroupsGetQueryKey = ReturnType<typeof getSchemeGroupsSchemeSchemeIdGroupsGetQueryKey>

/**
 * @summary Get Scheme Groups
 * {@link /scheme/:scheme_id/groups}
 */
export async function getSchemeGroupsSchemeSchemeIdGroupsGet(
  scheme_id: GetSchemeGroupsSchemeSchemeIdGroupsGetPathParams['scheme_id'],
  params?: GetSchemeGroupsSchemeSchemeIdGroupsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse, ResponseErrorConfig<GetSchemeGroupsSchemeSchemeIdGroupsGet422>, unknown>({
    method: 'GET',
    url: `/scheme/${scheme_id}/groups`,
    params,
    ...requestConfig,
  })
  return res
}

export function getSchemeGroupsSchemeSchemeIdGroupsGetQueryOptions(
  scheme_id: GetSchemeGroupsSchemeSchemeIdGroupsGetPathParams['scheme_id'],
  params?: GetSchemeGroupsSchemeSchemeIdGroupsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getSchemeGroupsSchemeSchemeIdGroupsGetQueryKey(scheme_id, params)
  return queryOptions<
    ResponseConfig<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse>,
    ResponseErrorConfig<GetSchemeGroupsSchemeSchemeIdGroupsGet422>,
    ResponseConfig<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!scheme_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSchemeGroupsSchemeSchemeIdGroupsGet(scheme_id, params, config)
    },
  })
}

/**
 * @summary Get Scheme Groups
 * {@link /scheme/:scheme_id/groups}
 */
export function useGetSchemeGroupsSchemeSchemeIdGroupsGet<
  TData = ResponseConfig<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse>,
  TQueryData = ResponseConfig<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse>,
  TQueryKey extends QueryKey = GetSchemeGroupsSchemeSchemeIdGroupsGetQueryKey,
>(
  scheme_id: GetSchemeGroupsSchemeSchemeIdGroupsGetPathParams['scheme_id'],
  params?: GetSchemeGroupsSchemeSchemeIdGroupsGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetSchemeGroupsSchemeSchemeIdGroupsGetQueryResponse>,
        ResponseErrorConfig<GetSchemeGroupsSchemeSchemeIdGroupsGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSchemeGroupsSchemeSchemeIdGroupsGetQueryKey(scheme_id, params)

  const query = useQuery({
    ...(getSchemeGroupsSchemeSchemeIdGroupsGetQueryOptions(scheme_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetSchemeGroupsSchemeSchemeIdGroupsGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}