import {
  Pagination,
  PaginationFirst,
  PaginationNext,
  PaginationPrevious,
} from "components/Theme/pagination";
import type { Cursor } from "kubb";
import { isNil } from "lodash-es";

interface Props {
  cursor: Cursor;
  prevCursor?: Cursor;
  newCursor: (cursor?: Cursor) => any;
  all?: () => any;
  refresh?: () => any;
}

export default function ApiPagination(props: Props) {
  function next(): void {
    console.log(props.cursor);
    props.newCursor({
      after: props.cursor.after,
      max_items: props.cursor.max_items,
      direction: props.cursor.direction,
    });
  }

  function back(): void {
    console.log(props.cursor);
    props.newCursor({
      before: props.cursor.before,
      max_items: props.cursor.max_items,
      direction: props.cursor.direction,
    });
  }

  function first(): void {
    props.newCursor({
      max_items: props.cursor.max_items,
      direction: props.cursor.direction,
    });
  }

  // function refresh(): void {
  //   props.refresh && props.refresh();
  // }
  //

  return (
    <>
      {props.cursor && (
        <Pagination>
          <PaginationFirst
            onClick={first}
            disabled={isNil(props.cursor.before)}
          />
          <PaginationPrevious
            onClick={back}
            disabled={isNil(props.cursor.before)}
          />
          <PaginationNext onClick={next} disabled={isNil(props.cursor.after)} />
        </Pagination>
      )}
    </>
  );
}
