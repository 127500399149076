import type { BulkUpdatePreviewStats } from "kubb";
import type React from "react";
import AlertBanner from "../Banner/AlertBanner";

interface Props {
  previewStats: BulkUpdatePreviewStats;
}

const GenericUpdateErrorsBanner = (props: Props): React.ReactElement => {
  const previewErrors =
    (props.previewStats.new_invalid ?? 0) +
    (props.previewStats.update_invalid ?? 0);

  if (previewErrors > 0) {
    return (
      <AlertBanner>
        <p>
          This update has {previewErrors} error
          {previewErrors > 1 ? "s. These are" : ". It is"} highlighted below.
        </p>
        <p className={"mb-0"}>
          The update can still be executed but{" "}
          {previewErrors > 1 ? "these lines" : "this line"} will be ignored.
        </p>
      </AlertBanner>
    );
  } else {
    return <></>;
  }
};

export default GenericUpdateErrorsBanner;
