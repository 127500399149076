import dayjs from "dayjs";
import type { JobDefinitionOut } from "kubb";
import type { JobStatus } from "kubb";
import type { OutputType } from "kubb";
import { useGetExportJobListJobGet } from "kubb";
import { capitalize, startCase } from "lodash-es";
import type React from "react";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { Heading } from "../../components/Theme/heading";
import { StackedListThreeColumnItem } from "../../components/Theme/stacked-list";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates from "../../components/utils/HighlightNullAndOldDates";
import { getNumberWithOrdinal } from "../../utils/number";

interface Props {
  company_id: string;
  scheme_id?: string;
  asset_position_id?: string;
}

export default function JobList(props: Props): React.ReactElement {
  const { company_id, scheme_id, asset_position_id } = props;

  const niceFormatName = (format?: OutputType): string => {
    switch (format) {
      case "ELVACO_X109":
        return "Elvaco X109";
      case "ELVACO_X110":
        return "Elvaco X110";
      default:
        return capitalize(startCase(format));
    }
  };
  const row = (value: JobDefinitionOut): React.ReactElement => (
    <StackedListThreeColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          <div>
            <CompanyNavLink
              to={{ pathname: `/admin/job/${value.job_id}` }}
              className={"px-0"}
            >
              <PrivacyMode>{value.name}</PrivacyMode>{" "}
              <small> ({value.job_id.slice(24, 32)})</small>
            </CompanyNavLink>
          </div>
          <div>
            <small>
              {niceFormatName(
                value.report_format.format as unknown as OutputType,
              )}
              {value.object_type && (
                <>
                  {" "}
                  for {value.object_type}/{value.object_id}
                </>
              )}
              | Destination {value.export_configuration?.export_type || "None"}
            </small>
          </div>
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          <div>
            {capitalize(value.schedule?.frequency) || "Daily"}
            <span className={"px-2"}>
              <AssetRegisterStatusLabel
                status={value.status as JobStatus}
                displayActive={true}
              />
            </span>
          </div>
          <div className={"text-xs pt-2"}>
            {value.schedule?.frequency === "WEEKLY" && (
              <> (runs on {capitalize(value.schedule.day_of_week)})</>
            )}
            {value.schedule?.frequency === "MONTHLY" && (
              <>
                {" "}
                (runs on{" "}
                {getNumberWithOrdinal(value.schedule.day_of_month || 1)})
              </>
            )}
          </div>
        </div>
      }
      column3={
        <HighlightNullAndOldDates ageHighlight={dayjs.duration(24, "hours")}>
          {value.last_run}
        </HighlightNullAndOldDates>
      }
      column1Size={"sm:w-6/12"}
      column2Size={"sm:w-2/12"}
      column3Size={"sm:w-4/12"}
    />
  );

  const header = (
    <StackedListThreeColumnItem
      column1={<Heading>Job</Heading>}
      column2={<Heading>Schedule</Heading>}
      column3={<Heading>Last run</Heading>}
      column1Size={"sm:w-6/12"}
      column2Size={"sm:w-2/12"}
      column3Size={"sm:w-4/12"}
    />
  );

  return (
    <GetListPaginatedList
      params={{ company_id, scheme_id, asset_position_id }}
      useQuery={useGetExportJobListJobGet}
      cursor={{ max_items: 10, direction: "desc" }}
      row={row}
      header={header}
    />
  );
}
