import { startCase } from "lodash-es";
import type React from "react";
import BetaAlert from "../../../components/Beta/BetaAlert.tsx";
import GetListPaginatedList from "../../../components/Pagination/GetListPaginatedList.tsx";
import { Badge } from "../../../components/Theme/badge.tsx";
import { Heading } from "../../../components/Theme/heading.tsx";
import {
  StackedListHeadingContent,
  StackedListThreeColumnItem,
} from "../../../components/Theme/stacked-list.tsx";
import CopyToClipboard from "../../../components/utils/CopyToClipboard.tsx";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates.tsx";
import { type Issue, useGetIssuesIssueGet } from "../../../kubb";
import { useRequiredSelectedCompanyId } from "../../../reducers/company.tsx";
import { useSelectedSchemeId } from "../../../reducers/scheme.tsx";
import {
  AssetPositionColumn,
  LocationColumn,
} from "../../assetRegister/AssetRegisterListPage/AssetRegisterListItem.tsx";

function IssueCancelledBadge(props: { issue: Issue }): React.ReactNode {
  const { issue } = props;
  if (issue.cancelled) return <Badge color={"zinc"}>Cancelled</Badge>;
  return null;
}

function issueCategoryFormatter(issue: Issue): React.ReactNode {
  const [group, specific] = issue.category.split(".");
  return `${startCase(group.toLowerCase())}: ${startCase(specific.toLowerCase())}`;
}

function IssueSeverityBadge(props: { issue: Issue }): React.ReactNode {
  return (
    <>
      <Badge color={"red"} className={"uppercase"}>
        Error
      </Badge>
    </>
  );
}

function IssueListRow(issue: Issue): React.ReactElement {
  return (
    <StackedListThreeColumnItem
      key={issue.issue_id}
      column1={
        <LocationColumn
          location_id={issue.location_id}
          address={issue.location_address || ""}
          group={issue.group}
          customer_reference={issue.location_customer_reference}
        />
      }
      column2={
        <AssetPositionColumn
          asset_position_id={issue.asset_position_id}
          use_case={issue.use_case}
          customer_reference={issue.asset_position_reference}
        />
      }
      column3={
        <div className={"min-w-0 flex-auto"}>
          <div>
            {issueCategoryFormatter(issue)} from{" "}
            {formatDate(issue.start_date_time)}
            {issue.end_date_time && ` to ${formatDate(issue.end_date_time)}`}
          </div>
          <div className={"flex flex-auto gap-2 small"}>
            <span className={"text-sm my-auto"}>
              Id:{" "}
              <CopyToClipboard
                value={issue.issue_id}
                slice={8}
                iconSize={"sm"}
              />
            </span>
            {" | "}
            <IssueSeverityBadge issue={issue} />
            <IssueCancelledBadge issue={issue} />
            {" | "}
            <span className={"text-sm my-auto"}>
              Created: {formatDate(issue.raised_date_time)}
            </span>
          </div>
        </div>
      }
    />
  );
}

export default function IssueListPage(): React.ReactElement {
  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();

  return (
    <>
      <Heading>Asset Issues List</Heading>
      <BetaAlert title={"Issues List"} />
      <GetListPaginatedList
        params={{ company_id, scheme_id, include_cancelled: false }}
        cursor={{ max_items: 25 }}
        header={
          <StackedListThreeColumnItem
            column1={<StackedListHeadingContent title={"Location"} />}
            column2={<StackedListHeadingContent title={"Asset Position"} />}
            column3={<StackedListHeadingContent title={"Issue"} />}
          />
        }
        row={IssueListRow}
        tableClassName={"list-group-flush"}
        data-cy={"issue-list"}
        useQuery={useGetIssuesIssueGet}
      />
    </>
  );
}
