import type React from "react";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import WirelessClusterCreateForm from "./WirelessClusterCreateForm";

export default function WirelessClusterCreatePage(): React.ReactElement {
  return (
    <SelectedScheme>
      {(schemeProps) => (
        <OneCardPage
          headerTitle={
            schemeProps.scheme_id
              ? `Add a wireless Cluster to ${schemeProps.scheme_id}`
              : "Add a Wireless Cluster"
          }
          breadcrumbs={[
            {
              url: "/admin/wireless_cluster",
              name: "Wireless Clusters",
            },
            {
              url: "/admin/wireless_cluster/create",
              name: "Create",
            },
          ]}
          setPageTitle={schemeObjectPageTitle("Create Wireless Cluster")}
        >
          <RequireScheme>
            <WirelessClusterCreateForm
              scheme_id={schemeProps.scheme_id as string}
              company_id={schemeProps.company_id}
            />
          </RequireScheme>
        </OneCardPage>
      )}
    </SelectedScheme>
  );
}
