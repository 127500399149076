import {
  type BulkUpdateDetailed,
  type BulkUpdateExecutionStats,
  bulkUpdateStatusEnum,
} from "kubb";
import type React from "react";
import { useEffect, useMemo } from "react";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import type { GetDataWrapperResult } from "../MutateWrapper.tsx";
import BlockSpinner from "../Spinners/BlockSpinner";
import StateTabGroupInterface from "../TabGroup/StateTabGroupInterface";
import type { Tab } from "../TabGroup/model";
import { Heading } from "../Theme/heading";
import { OneCardPage } from "../utils/OneCardPage";
import ExecuteBulkUpdateButton from "./ExecuteBulkUpdateButton";
import GenericUpdateErrorsBanner from "./GenericUpdateErrorsBanner";
import GenericUpdateList, {
  type TypedBulkInstruction,
} from "./GenericUpdateList";
import UnusedColumnsBanner from "./UnusedColumnsBanner";
import UpdateProgressBanner from "./UpdateProgressBanner";

export interface GenericUpdateProps<T> {
  title: string;
  header: string;
  tableFieldHeaders: string[];
  tableFields: string[];
  objectKey: keyof T;
}

export interface TypedBulkUpdateDetailed<T>
  extends Omit<BulkUpdateDetailed, "preview"> {
  preview: TypedBulkInstruction<T>[];
}

interface Props<T>
  extends GenericUpdateProps<T>,
    GetDataWrapperResult<TypedBulkUpdateDetailed<T>> {}

function filterPayloadList<T>(
  instructions: TypedBulkInstruction<T>[],
  filter: string,
  errors = false,
): TypedBulkInstruction<T>[] {
  return instructions.filter((instruction: any) => {
    const hasErrors =
      instruction.validationErrors.length > 0 ||
      (instruction.immutableChangeErrors &&
        instruction.immutableChangeErrors.length > 0);
    return (
      instruction.type === filter &&
      ((hasErrors && errors) || (!hasErrors && !errors))
    );
  });
}

const arrayToObject = (array: any[], key: string): any =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

const idKeyProperty = (objectType: string): string => {
  const idKeys: { [key: string]: string } = {
    asset_position: "asset_position_id",
    location: "location_id",
    asset: "asset_id",
  };
  return idKeys[objectType];
};

function GenericUpdatesDisplay<T>(props: Props<T>): React.ReactElement {
  const { data, loading, refresh } = props;

  const selected = useMemo(
    () =>
      data && {
        ...data,
        existingMap: arrayToObject(
          data.existing,
          idKeyProperty(data.object_type),
        ),
      },
    [data],
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const setTimer = (): void => {
      if (selected) {
        const status = selected.status;

        if (
          selected &&
          (status === bulkUpdateStatusEnum.EXECUTION_STARTED ||
            status === bulkUpdateStatusEnum.PREVIEW_GENERATING)
        ) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            refresh();
          }, 5000);
        }
      }
    };
    setTimer();
    return () => timer && clearTimeout(timer);
  }, [selected, refresh]);

  const tabContent = (
    title: string,
    list: TypedBulkInstruction<T>[],
    index: string,
    actioned: boolean,
  ): Tab => {
    return {
      tabName: title,
      tabContent: GenericUpdateList<T>({
        title,
        list,
        listPrefix: index,
        tableFieldHeaders: props.tableFieldHeaders,
        tableFields: props.tableFields,
        existingMap: selected?.existingMap,
        actioned: actioned,
        objectKey: props.objectKey,
      }),
      tabPath: title,
    };
  };

  const executed = selected?.status !== bulkUpdateStatusEnum.PREVIEW_CREATED;

  const lists: {
    title: string;
    list: TypedBulkInstruction<T>[];
    render?: () => React.ReactElement;
  }[] = selected
    ? [
        { title: "All", list: selected.preview },
        {
          title: "New",
          list: filterPayloadList(selected.preview, "NEW"),
        },
        {
          title: "Invalid New ",
          list: filterPayloadList(selected.preview, "NEW", true),
        },
        {
          title: "Valid Changes",
          list: filterPayloadList(selected.preview, "UPDATE"),
        },
        {
          title: "Invalid Changes",
          list: filterPayloadList(selected.preview, "UPDATE", true),
        },
        {
          title: "Unchanged",
          list: filterPayloadList(selected.preview, "UNCHANGED"),
        },
        {
          title: "Rows Not Found on Server",
          list: filterPayloadList(selected.preview, "NOT_FOUND"),
        },
        {
          title: "Rows Found on Server Deleted",
          list: filterPayloadList(selected.preview, "DELETED"),
        },
      ].filter((l) => l.list.length > 0)
    : [];

  const actioned = selected?.status !== bulkUpdateStatusEnum.PREVIEW_CREATED;
  const header = props.header + (actioned ? " Status" : " Preview");

  return (
    <OneCardPage
      headerTitle={header}
      setPageTitle={schemeObjectPageTitle(
        `Bulk Update (${selected?.update_id})`,
        true,
      )}
    >
      <BlockSpinner loading={loading}>
        <div style={{ minHeight: "300px" }}>
          <Heading>Update: {selected?.update_id}</Heading>
          {selected ? (
            <>
              {executed && (
                <UpdateProgressBanner
                  status={selected.status}
                  previewStats={selected.preview_stats}
                  executeStats={
                    selected.execute_stats as BulkUpdateExecutionStats
                  }
                />
              )}

              {!executed && (
                <GenericUpdateErrorsBanner
                  previewStats={selected.preview_stats}
                />
              )}
              <UnusedColumnsBanner unusedColumns={selected.ignored_fields} />
              {selected.preview.length > 0 ? (
                <StateTabGroupInterface
                  tabs={lists.map((value, index) => {
                    return tabContent(
                      value.title,
                      value.list,
                      index.toString(),
                      actioned,
                    );
                  })}
                />
              ) : (
                <Heading>No content in this update.</Heading>
              )}
              {/*<GenericUpdateToCsvExportButton instructions={this.selected.preview} idKey={this.props.objectKey}*/}
              {/*                                existingMap={this.selected.existingMap}/>*/}
              {selected.status === bulkUpdateStatusEnum.PREVIEW_CREATED && (
                <ExecuteBulkUpdateButton
                  company_id={selected.company_id}
                  updateId={selected.update_id}
                  refresh={refresh}
                />
              )}
            </>
          ) : (
            <p>Update loading</p>
          )}
        </div>
      </BlockSpinner>
    </OneCardPage>
  );
}

export default GenericUpdatesDisplay;
