import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { AssetFile } from "kubb";
import type { IntegrationFile } from "kubb";
import { Badge, type colors } from "../../../../../components/Theme/badge";
import UncontrolledTooltip from "../../../../../components/Theme/tooltip";
import { isGatewayFile } from "../model";

function GatewayFileStatusBadge(props: { file: AssetFile | IntegrationFile }) {
  const { file } = props;

  const [color, text, tooltip_title]: [
    keyof typeof colors,
    string,
    string | undefined,
  ] = {
    PROCESSED: ["success", "Processed", undefined],
    PROCESSED_WITH_ERRORS: [
      "warning",
      "Processed (with errors)",
      "File could not be fully processed because: ",
    ],
    QUARANTINED: [
      "danger",
      "Quarantined",
      "File has been quarantined because: ",
    ],
    QUEUED: ["zinc", "Queued", undefined],
    UNKNOWN: ["teal", "Unknown", undefined],
  }[file.status || "QUEUED"] as [
    keyof typeof colors,
    string,
    string | undefined,
  ];

  return (
    <>
      <Badge color={color}>{text}</Badge>
      {tooltip_title && (
        <UncontrolledTooltip
          message={
            <>
              {tooltip_title}
              <ul>
                {file.status === "PROCESSED_WITH_ERRORS" &&
                  (file.processing_errors || ["Unknown errors."]).map(
                    (error) => <li key={error}>{error}</li>,
                  )}
                {file.status === "QUARANTINED" && (
                  <li>
                    {isGatewayFile(file)
                      ? file.quarantine_reason || "Unknown"
                      : "Unknown"}
                  </li>
                )}
              </ul>
            </>
          }
          placement={"left"}
        >
          <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
        </UncontrolledTooltip>
      )}
    </>
  );
}

export default GatewayFileStatusBadge;
