import { meterOptions } from "components/Asset/AssetTypeSelectCombo";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { FormikHelpers } from "formik/dist/types";
import { type AssetTypeEnum, periodEnum, reportViewEnum } from "kubb";
import { isEqual } from "lodash-es";
import type React from "react";
import * as Yup from "yup";
import DateRangePicker from "../../Forms/DateRangePicker/DateRangePicker";
import { Button } from "../../Theme/button.tsx";
import { Col } from "../../Theme/grid";
import type { NewTimeSeriesRequestParams } from "../model";
import AggregatedTimeSeriesQuerySubForm, {
  type AggregatedTimeSeriesQuerySubFormProps,
  AggregatedTimeSeriesQuerySubFormSchema,
  type AggregatedTimeSeriesQuerySubFormValues,
} from "./AggregatedTimeSeriesQuerySubForm";

export interface AggregatedTimeSeriesQueryFormValues {
  query: AggregatedTimeSeriesQuerySubFormValues;
  start_date: Dayjs;
  end_date: Dayjs;
}

export function mapFormValuesToTimeSeriesParams(
  values: AggregatedTimeSeriesQueryFormValues,
  includeGroupBy = false,
  includeViewBy = true,
  includeassetUseCaseEnum = false,
): NewTimeSeriesRequestParams {
  const _q: NewTimeSeriesRequestParams = {
    ...values.query,
    parameter: values.query.parameter as string,
    aggregation: values.query.aggregation as string,
    asset_type: values.query.asset_type as unknown as AssetTypeEnum[],
    start_date: values.start_date,
    end_date: values.end_date,
  };
  if (!includeGroupBy) {
    _q.group_by = undefined;
  }
  if (!includeViewBy) {
    _q.view_by = undefined;
  }
  if (!includeassetUseCaseEnum) {
    _q.use_case = undefined;
  }
  return _q;
}

export function mapTimeSeriesRequestParamsToFormValues(
  params: NewTimeSeriesRequestParams,
): AggregatedTimeSeriesQueryFormValues {
  const { start_date, end_date, ...query } = params;
  return {
    start_date: dayjs(start_date),
    end_date: dayjs(end_date),
    query: {
      ...query,
      view_by: query.view_by || reportViewEnum.ASSET_POSITION,
    },
  };
}

export interface AggregatedTimeSeriesQueryFormProps
  extends Omit<AggregatedTimeSeriesQuerySubFormProps, "namespace"> {
  onSubmit: (values: AggregatedTimeSeriesQueryFormValues) => Promise<any>;
  initialValues?: AggregatedTimeSeriesQueryFormValues;
}

export default function AggregatedTimeSeriesQueryForm(
  props: AggregatedTimeSeriesQueryFormProps,
) {
  const {
    initialValues = {
      query: {
        period: periodEnum.DAILY,
        asset_type: meterOptions,
        aggregation: "last",
        parameter: "Energy (Heating)",
        view_by: reportViewEnum.ASSET_POSITION,
      },
    } as AggregatedTimeSeriesQueryFormValues,
    onSubmit,
    ...rest
  } = props;
  const { wide } = rest;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        query: AggregatedTimeSeriesQuerySubFormSchema,
      })}
      onSubmit={(
        values: AggregatedTimeSeriesQueryFormValues,
        helpers: FormikHelpers<AggregatedTimeSeriesQueryFormValues>,
      ): Promise<any> => {
        helpers.setSubmitting(true);
        return onSubmit(values).finally(() => helpers.setSubmitting(false));
      }}
    >
      {({ values, errors }): React.ReactElement => {
        const isChanged = !isEqual(values, initialValues);

        return (
          <Form className={"mb-2"}>
            <AggregatedTimeSeriesQuerySubForm
              namespace={"query"}
              submitButton={
                <>
                  <Col sm={12} md={wide ? 9 : 12}>
                    <DateRangePicker
                      startDateName={"start_date"}
                      endDateName={"end_date"}
                      label={{ label: "Date Range", size: "sm" }}
                      maxDate={dayjs().startOf("day")}
                    />
                  </Col>
                  <Col sm={12} md={wide ? 3 : 12} className={"mt-auto"}>
                    <Button
                      type="submit"
                      disabled={!isChanged}
                      className={"w-full h-[38px]"}
                    >
                      Search
                    </Button>
                  </Col>
                </>
              }
              {...rest}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
