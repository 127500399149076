import type React from "react";
import type { ParsedAssetDefinition } from "./UploadPage";
import UploadPreviewTable from "./UploadPreviewTable";

const requiredHeaders = ["serial_number", "asset_type", "address"];

const optionalHeaders = [
  "asset_position_customer_reference",
  "group",
  "location_reference",
  "configuration",
];

function SimpleAssetRegisterUploadPreview(props: {
  definitions: ParsedAssetDefinition[];
}): React.ReactElement {
  const { definitions } = props;
  return (
    <UploadPreviewTable
      definitions={definitions}
      requiredHeaders={requiredHeaders}
      optionalHeaders={optionalHeaders}
      uploadedEntityName={"asset"}
    />
  );
}

export default SimpleAssetRegisterUploadPreview;
