import PrivacyMode from "components/Text/PrivacyMode";
import CopyToClipboard from "components/utils/CopyToClipboard";
import dayjs from "dayjs";
import type {
  AssetPosition,
  DeviceAssetUpdateIn,
  DeviceConfigurationOutput,
  GatewayAssetOut,
  GatewayAssetUpdateIn,
  GatewayState,
  Location,
  WirelessReceiverStateOutput,
} from "kubb";
import type React from "react";
import type { Asset } from "utils/object";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates, {
  formatDate,
} from "../../../components/utils/HighlightNullAndOldDates";
import { useUpdateAsset } from "../../../hooks/updateEntity";
import AssetCompanyAssignForm from "./AssetCompanyAssignForm";
import AssetTypeChangeForm from "./AssetTypeChangeForm";
import GatewayLink from "./gateway/GatewayLink";
import GatewayStateLabel from "./gateway/GatewayStateLabel";

export default function InnerAssetPreview(props: {
  asset: Asset;
  assetPosition?: AssetPosition;
  location?: Location;
  refresh?: () => any;
}): React.ReactElement {
  const { asset, assetPosition, location } = props;

  const update = useUpdateAsset(
    asset as DeviceAssetUpdateIn | GatewayAssetUpdateIn,
  );

  const [openModal, modal] = AssetTypeChangeForm({
    values: asset as GatewayAssetUpdateIn | DeviceAssetUpdateIn,
    title: "Asset ",
    update,
    postUpdate: props.refresh,
  });

  const assigned =
    asset?.asset_position_id !== undefined && asset?.asset_position_id !== null;
  const isGateway = asset?.asset_type === "gateway" || false;
  const isReceiver = asset?.asset_type === "wireless_receiver" || false;

  return (
    <>
      {modal}
      <ObjectDetailHeading
        leftColumn={{
          content: (
            <>
              {assigned ? (
                <>
                  <ObjectPropertyItem
                    title={"Location Address"}
                    type={"heading"}
                  >
                    <CompanyNavLink
                      className={"p-0 d-inline"}
                      to={{
                        pathname: `/admin/location/${location?.location_id}`,
                      }}
                    >
                      <PrivacyMode>{location?.address}</PrivacyMode>
                    </CompanyNavLink>
                  </ObjectPropertyItem>
                  <ObjectPropertyItem
                    title={"Position Reference"}
                    type={"heading"}
                  >
                    <CompanyNavLink
                      className={"p-0 d-inline"}
                      to={{
                        pathname: `/admin/asset_position/${assetPosition?.asset_position_id}`,
                      }}
                    >
                      <PrivacyMode>
                        {assetPosition?.customer_reference ||
                          "No Position Reference"}
                      </PrivacyMode>
                    </CompanyNavLink>
                  </ObjectPropertyItem>{" "}
                </>
              ) : (
                <ObjectPropertyItem
                  title={"Location - Position"}
                  type={"heading"}
                >
                  <h2 className={"text-warning"}>Unassigned</h2>
                </ObjectPropertyItem>
              )}
              <ObjectPropertyItem title={"Serial Number"} type={"heading"}>
                {asset && (
                  <CopyToClipboard
                    value={asset.serial_number}
                    privacyMode={true}
                  />
                )}
              </ObjectPropertyItem>
            </>
          ),
        }}
        rightColumn={{
          content: (
            <>
              <ObjectPropertyItem
                title={"Type"}
                editActionUserType={"superadmin"}
                editAction={
                  asset?.asset_position_id === undefined ? openModal : undefined
                }
              >
                {asset && (
                  <span>
                    <AssetTypeHumanLabel asset={asset} />
                  </span>
                )}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Manufacturer"} type={"detail"}>
                {asset?.manufacturer || "-"}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Model"} type={"detail"}>
                <span>{!isGateway ? asset?.version : asset?.model || "-"}</span>
              </ObjectPropertyItem>
              {!isGateway && (
                <GatewayLink deviceAssetPosition={assetPosition} />
              )}
              {isReceiver && (
                <ObjectPropertyItem title={"Status"} type={"detail"}>
                  {asset && (
                    <GatewayStateLabel
                      state={
                        (asset.state as WirelessReceiverStateOutput)
                          .configuration_state
                      }
                      size={"sm"}
                      className={"px-1 py-1"}
                    />
                  )}
                </ObjectPropertyItem>
              )}
              {isGateway && (
                <>
                  <ObjectPropertyItem title={"Last contact"} type={"detail"}>
                    {asset && (
                      <HighlightNullAndOldDates
                        ageHighlight={dayjs.duration(1, "days")}
                      >
                        {(asset as GatewayAssetOut).last_contact}
                      </HighlightNullAndOldDates>
                    )}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Status"} type={"detail"}>
                    {asset && (
                      <GatewayStateLabel
                        state={
                          (asset.state as GatewayState).configuration_state
                        }
                        size={"sm"}
                        className={"px-1 py-1"}
                      />
                    )}
                  </ObjectPropertyItem>

                  <ObjectPropertyItem title={"Firmware"} type={"detail"}>
                    {(asset && (asset as GatewayAssetOut).firmware_version) ||
                      "-"}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"IP Address"} type={"detail"}>
                    <CopyToClipboard
                      value={(asset as GatewayAssetOut).ip_address || "-"}
                      privacyMode={true}
                    />
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Sim card"} type={"detail"}>
                    <CopyToClipboard
                      value={(asset as GatewayAssetOut).sim_card_iccid || "-"}
                      privacyMode={true}
                    />
                  </ObjectPropertyItem>
                </>
              )}
              {asset?.configuration &&
                "mbus" &&
                (asset.configuration as DeviceConfigurationOutput).mbus
                  ?.addressing_mode === "primary" && (
                  <ObjectPropertyItem title={"Addressing mode"} type={"detail"}>
                    Primary (address:{" "}
                    {
                      (asset.configuration as DeviceConfigurationOutput).mbus
                        ?.primary_address
                    }
                    )
                  </ObjectPropertyItem>
                )}{" "}
              <ObjectPropertyItem title={"Asset Id"} type={"detail"}>
                <CopyToClipboard value={asset?.asset_id} slice={8} />
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Created"} type={"detail"}>
                {formatDate(asset?.created_at, undefined, false)}
              </ObjectPropertyItem>
            </>
          ),
        }}
      />
      {asset?.company_id === "NONE" && asset?.asset_type === "gateway" && (
        <AssetCompanyAssignForm asset={asset} />
      )}
    </>
  );
}
