import { faLinkSlash } from "@fortawesome/free-solid-svg-icons";
import type { AssetPosition } from "kubb";
import type React from "react";
import UpdateButton, {
  type UpdateButtonProps,
} from "../../../components/Buttons/UpdateButton";
import { useUpdateAssetPosition } from "../../../hooks/updateEntity.tsx";

export default function WirelessClusterGatewayLeaveButton(
  props: {
    gatewayPosition: AssetPosition;
  } & Omit<UpdateButtonProps<AssetPosition>, "update" | "updatedEntity">,
): React.ReactElement {
  const { gatewayPosition } = props;

  return (
    <UpdateButton
      updatedEntity={{
        ...gatewayPosition,
        parent_asset_position_id: undefined,
      }}
      update={useUpdateAssetPosition(props.gatewayPosition)}
      text={"Leave"}
      icon={faLinkSlash}
      color={"brandLight"}
      /* TODO: small button */
      postUpdate={props.postUpdate}
    />
  );
}
