import { isDayjs } from "dayjs";
import { isDate } from "lodash";
import { isArray, isEmpty, isObject } from "lodash-es";
import type { DeviceAssetOut, GatewayAssetOut } from "../kubb";

export type Asset = GatewayAssetOut | DeviceAssetOut;

export function removeBlankStringsFromObject(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export function objectBlankStringsToUndefined(obj: any): any {
  if (isObject(obj)) {
    if (isArray(obj)) {
      return obj.map(objectBlankStringsToUndefined);
    } else {
      return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => {
          return [k, objectBlankStringsToUndefined(v)];
        }),
      );
    }
  }
  return obj !== "" ? obj : undefined;
}

export function removeMapBlanks(map: { [key: string]: any }): {
  [key: string]: any;
} {
  return Object.entries(map).reduce((accumulator, [k, v]) => {
    if (isObject(v) && !isArray(v) && !isDayjs(v) && !isDate(v)) {
      return { ...accumulator, [k]: removeMapBlanks(v) };
    }
    if (v === "" || v === null || v === undefined) {
      return accumulator;
    }
    return { ...accumulator, [k]: v };
  }, {});
}

export function removeBlanksFromArray(arr: any): any {
  if (isArray(arr)) {
    return arr.filter((v) => {
      return v !== undefined && v !== "";
    });
  }
  return arr;
}
export function removeMapBlankObjects(obj: any): any {
  if (isObject(obj)) {
    if (isArray(obj)) {
      return obj.map(removeMapBlankObjects);
    }
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => {
        if (isObject(v)) {
          if (isEmpty(v)) {
            return [k, undefined];
          }
          if (isArray(v)) {
            return [k, removeBlanksFromArray(v)];
          }
          return [k, removeMapBlankObjects(removeMapBlanks(v))];
        }
        return [k, v];
      }),
    );
  }
  return obj;
}
