import {
  type AggregatedReportRequest,
  type GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest,
  type GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse,
  type GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams,
  useGetSummarisedTimeSeriesReportReportTimeSeriesPost,
} from "kubb";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "kubb";
import type React from "react";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import type { GetDataWrapperResult } from "../../../components/MutateWrapper.tsx";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { useMutateGetData } from "../../../hooks/getData";

export default function AggregatedTimeSeriesApiWrapper(props: {
  request: AggregatedReportRequest;
  children: (
    props: GetDataWrapperResult<AssetRegisterAggregatedTimeSeriesResponse>,
  ) => React.ReactNode;
}): React.ReactElement {
  const { data, error, loading, refresh } = useMutateGetData<
    GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse,
    GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest,
    GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams
  >(useGetSummarisedTimeSeriesReportReportTimeSeriesPost({}), props.request);

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({
            error: error || undefined,
            data,
            loading,
            refresh,
          })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
}
