import { roundingEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

const RoundingMethodSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "None", value: roundingEnum.NONE },
    { label: "0 decimal places", value: roundingEnum.WHOLE },
    { label: "1 decimal place", value: roundingEnum.ONE_DP },
    { label: "2 decimal places", value: roundingEnum.TWO_DP },
  ];

  return <SelectComboField {...props} options={options} />;
};

export default RoundingMethodSelect;
