import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import type { AssetPositionAssetLinkOut } from "kubb";
import type React from "react";
import UpdateButton, {
  type UpdateButtonProps,
} from "../../../components/Buttons/UpdateButton";
import { useUpdateAssetPositionAssetLink } from "../../../hooks/updateEntity.tsx";

type Props = {
  assetPositionAssetLink: AssetPositionAssetLinkOut;
} & Omit<
  UpdateButtonProps<AssetPositionAssetLinkOut>,
  "update" | "updatedEntity"
>;

export default function UnlinkAssetPositionFromAssetButton(
  props: Props,
): React.ReactElement {
  return (
    <UpdateButton
      update={useUpdateAssetPositionAssetLink(props.assetPositionAssetLink)}
      updatedEntity={{ ...props.assetPositionAssetLink, cancelled: true }}
      postUpdate={props.postUpdate}
      icon={faTrashAlt}
      text={"Cancel Link"}
      color={"red"}
      size={"sm"}
      paddingY={1}
    />
  );
}
