import client from 'client'
import type {
  CreateExportJobJobPostMutationRequest,
  CreateExportJobJobPostMutationResponse,
  CreateExportJobJobPostQueryParams,
  CreateExportJobJobPost422,
} from '../../types/CreateExportJobJobPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createExportJobJobPostMutationKey = () => [{ url: '/job' }] as const

export type CreateExportJobJobPostMutationKey = ReturnType<typeof createExportJobJobPostMutationKey>

/**
 * @summary Create Export Job
 * {@link /job}
 */
export async function createExportJobJobPost(
  data: CreateExportJobJobPostMutationRequest,
  params?: CreateExportJobJobPostQueryParams,
  config: Partial<RequestConfig<CreateExportJobJobPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CreateExportJobJobPostMutationResponse, ResponseErrorConfig<CreateExportJobJobPost422>, CreateExportJobJobPostMutationRequest>({
    method: 'POST',
    url: `/job`,
    params,
    data,
    ...requestConfig,
  })
  return res
}

/**
 * @summary Create Export Job
 * {@link /job}
 */
export function useCreateExportJobJobPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateExportJobJobPostMutationResponse>,
      ResponseErrorConfig<CreateExportJobJobPost422>,
      { data: CreateExportJobJobPostMutationRequest; params?: CreateExportJobJobPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateExportJobJobPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createExportJobJobPostMutationKey()

  return useMutation<
    ResponseConfig<CreateExportJobJobPostMutationResponse>,
    ResponseErrorConfig<CreateExportJobJobPost422>,
    { data: CreateExportJobJobPostMutationRequest; params?: CreateExportJobJobPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createExportJobJobPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}