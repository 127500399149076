import type { ResponseConfig } from "@kubb/plugin-client/clients/axios.ts";
import type { AxiosError } from "axios";
import { isNil } from "lodash-es";
import { useState } from "react";
import type { Cursor } from "../kubb";

interface WholeListResult<TItem> {
  data?: Array<TItem>;
  error?: AxiosError | Error;
  loading: boolean;
  execute: () => Promise<Array<TItem>>;
}

export default function newGetWholeList<TItem, TQuery>(
  params: TQuery,
  useQuery: (variables: TQuery) => Promise<ResponseConfig<TItem[]>> | undefined,
): WholeListResult<TItem> {
  const [state, setState] = useState<Omit<WholeListResult<TItem>, "execute">>({
    loading: false,
  });

  async function execute(): Promise<Array<TItem>> {
    let pages = 0;
    let more = true;
    let cursor: Cursor = { max_items: 100 };
    let data: TItem[] = [];

    const maxPages = 100;
    setState({ loading: true });

    while (pages <= maxPages && more) {
      try {
        const nextPage = await useQuery({ ...params, ...cursor });
        if (nextPage && nextPage.status === 200) {
          cursor = nextPage?.headers["x-cursor"];
          data = [...data, ...nextPage.data];
          more = !isNil(cursor.after);
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("Downloading failed");
        }
        pages++;
        if (pages >= maxPages && more) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("Downloading failed. Too much data.");
        }
      } catch (error) {
        setState({
          loading: false,
          error: error as unknown as Error,
        });
        more = false;
        throw error;
      }
    }
    setState({
      loading: false,
      data,
    });
    return data;
  }

  return { ...state, execute };
}
