import { isArray, isNil } from "lodash-es";
import React from "react";
import { useEffect } from "react";
import type { RequireExactlyOne } from "type-fest";
import {
  useGetAsset,
  useGetAssetRegisterItem,
} from "../../hooks/getEntity.tsx";
import type { AssetPosition, Location } from "../../kubb";
import type { Asset } from "../../utils/object.tsx";

export type GetAssetRegisterEntitiesProps = RequireExactlyOne<
  {
    company_id: string;
    scheme_id?: string;
    asset_id?: string;
    asset_position_id?: string;
  },
  "asset_position_id" | "asset_id"
>;

export interface AssetRegisterEntityResult {
  loading: boolean;
  assetPosition?: AssetPosition;
  location?: Location;
  asset?: Asset;
  error?: Error | Error[] | null;
}

export interface AssetRegisterEntityProps extends AssetRegisterEntityResult {
  refresh: () => void;
}

export type AssetRegisterEntitySuccessProps = Omit<
  AssetRegisterEntityProps,
  "error"
>;

interface Props {
  children: (props: AssetRegisterEntitySuccessProps) => React.ReactElement;
  error: (props: { error: Error }) => React.ReactElement;
  placeholder: () => React.ReactElement;
}

const GetAssetRegisterEntitiesCombination = (
  props: Props & GetAssetRegisterEntitiesProps,
): React.ReactElement => {
  const { children, ...params } = props;

  const assetLoader = props.asset_id
    ? useGetAsset(props.asset_id, props.company_id)
    : undefined;

  const [init, setInit] = React.useState(false);

  const assetPositionLoader = useGetAssetRegisterItem(
    props.company_id,
    undefined,
    props.asset_position_id || assetLoader?.data?.asset_position_id,
    undefined,
    !isNil(props.asset_position_id),
  );

  const assetPositionError = assetPositionLoader.error
    ? isArray(assetPositionLoader.error)
      ? assetPositionLoader.error[0]
      : assetPositionLoader.error
    : undefined;
  const assetError = assetLoader?.error
    ? isArray(assetLoader.error)
      ? assetLoader.error[0]
      : assetLoader.error
    : undefined;

  const loading = assetPositionLoader.loading || assetLoader?.loading || !init;

  useEffect(() => {
    if (assetLoader?.data?.asset_position_id) {
      assetPositionLoader.refresh();
    } else {
      setInit(!assetLoader?.loading || false);
    }
  }, [
    assetLoader?.data?.asset_position_id,
    assetPositionLoader.refresh,
    assetLoader?.loading,
  ]);

  useEffect(() => {
    if (!assetPositionLoader?.loading && !assetLoader?.loading) {
      setInit(true);
    }
  }, [assetPositionLoader?.loading, assetLoader?.loading]);

  const actualError = assetPositionError ? assetPositionError : assetError;

  const refresh = () => {
    assetLoader?.refresh();
    assetPositionLoader.refresh();
  };

  return (
    <>
      {loading
        ? props.placeholder()
        : actualError
          ? props.error({ error: actualError })
          : children({
              loading: loading,
              asset: assetPositionLoader?.data
                ? assetPositionLoader.data.asset
                : assetLoader?.data,
              assetPosition: assetPositionLoader?.data?.asset_position,
              location: assetPositionLoader?.data?.location,
              refresh,
            })}
    </>
  );
};

export default GetAssetRegisterEntitiesCombination;
