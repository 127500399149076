import type { ResponseConfig } from "@kubb/plugin-client/clients/axios.ts";
import newGetWholeList from "hooks/getWholeList";
import CSVExportButton, { type CSVExportButtonProps } from "./CSVExportButton";

export interface ExportFileProps<TItem, TQuery> {
  mapFunction?: (object: TItem) => any;
  params: TQuery;
  useQuery: (variables: TQuery) => Promise<ResponseConfig<TItem[]>> | undefined;
}

type PaginatedApiToCSVExportButtonProps<TItem, TQuery> = ExportFileProps<
  TItem,
  TQuery
> &
  Omit<CSVExportButtonProps, "download">;

export default function PaginatedApiToCSVExportButton<TItem, TQuery>(
  props: PaginatedApiToCSVExportButtonProps<TItem, TQuery>,
) {
  const { params, useQuery, mapFunction, ...rest } = props;
  const { execute } = newGetWholeList<TItem, TQuery>(
    props.params,
    props.useQuery,
  );
  const download = (): Promise<Array<any>> => {
    return execute().then((res) => {
      if (props.mapFunction) {
        return res.map(props.mapFunction);
      } else {
        return res;
      }
    });
  };
  return <CSVExportButton download={download} {...rest} />;
}
