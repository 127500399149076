import type {
  AssetPosition,
  AssetRegisterItem,
  DeviceConfigurationOutput,
} from "kubb";
import { useGetAssetRegisterListAssetRegisterGet } from "kubb";
import type React from "react";
import AssetTypeHumanLabel from "../../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import NewGetListPaginatedTable from "../../../../components/Pagination/GetListPaginatedTable";

interface Props {
  parentAssetPosition: AssetPosition;
}

const VirtualAssetSummaryList = (props: Props): React.ReactElement => {
  const header = (
    <tr>
      <th className={"text-left"}>Position Id</th>
      <th className={"text-left"}>Reference</th>
      <th className="text-left">Type</th>
      <th className={"text-left"}>Asset Serial Number</th>
      <th className={"text-left"}>Data Field</th>
    </tr>
  );
  const colCount = 5;
  const row = (value: AssetRegisterItem): React.ReactElement => (
    <tr>
      <td className="text-left" data-cy={"asset_position_id"}>
        <AssetPositionIdLink assetPosition={value.asset_position} />
      </td>
      <td className={"text-left"}>
        {value.asset_position.customer_reference
          ? value.asset_position.customer_reference
          : "-"}
      </td>
      <td className="text-left">
        <AssetTypeHumanLabel asset={value.asset_position} />
      </td>
      <td className={"text-left"}>
        {value.asset ? value.asset.serial_number : "-"}
      </td>
      <td className={"text-left"}>
        {value.asset
          ? (value.asset.configuration as DeviceConfigurationOutput).virtual
              ?.field || "-"
          : "-"}
      </td>
    </tr>
  );

  return (
    <NewGetListPaginatedTable
      params={{
        company_id: props.parentAssetPosition.company_id,
        scheme_id: props.parentAssetPosition.scheme_id,
        parent_asset_position_id: props.parentAssetPosition.asset_position_id,
      }}
      useQuery={useGetAssetRegisterListAssetRegisterGet}
      cursor={{ max_items: 25 }}
      header={[header, colCount]}
      row={row}
    />
  );
};

export default VirtualAssetSummaryList;
