import {
  faCog,
  faExclamationCircle,
  faSlash,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import type {
  AssetAlarmEvent,
  GatewayConfigurationFailedData,
  GatewayOfflineMetaData,
} from "kubb";
import type { Event } from "kubb";
import type { GatewayAssetOut } from "kubb";
import { isNil, lowerCase, startCase } from "lodash-es";
import type React from "react";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { StackedListTwoColumnItem } from "../../components/Theme/stacked-list";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import {
  formatDate,
  formatDuration,
} from "../../components/utils/HighlightNullAndOldDates";

const AlarmIcon = (props: { alarm: AssetAlarmEvent }) => {
  const closed = !isNil(props.alarm.closed_date_time);
  switch (props.alarm.alarm_type) {
    case "GATEWAY_OFFLINE":
      return (
        <span
          className={clsx(
            "fa-layers",
            " ml-0",
            " mr-2",
            closed ? "text-success" : "text-danger",
          )}
        >
          <>
            {!closed && <FontAwesomeIcon icon={faSlash} fixedWidth />}
            <FontAwesomeIcon icon={faWifi} fixedWidth />
          </>
        </span>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <span
          className={clsx(
            "fa-layers",
            " ml-0",
            " mr-2",
            closed ? "text-success" : "text-danger",
          )}
        >
          <FontAwesomeIcon icon={faCog} fixedWidth />
        </span>
      );
    default:
      return <FontAwesomeIcon icon={faExclamationCircle} />;
  }
};

const getAlarmHeading = (alarm: AssetAlarmEvent): React.ReactElement => {
  const closed = !isNil(alarm.closed_date_time);
  switch (alarm.alarm_type) {
    case "GATEWAY_OFFLINE":
      return (
        <CompanyNavLink
          to={{
            pathname: `/admin/asset/${alarm.affected_objects.asset?.asset_id}`,
          }}
          colorClassName={closed ? "text-success" : "text-danger"}
        >
          Gateway{" "}
          <PrivacyMode>
            {alarm.affected_objects.asset?.serial_number}
          </PrivacyMode>{" "}
          offline
          {closed && (
            <>
              {" "}
              (closed{" "}
              {formatDuration(
                dayjs.duration(dayjs().diff(alarm.closed_date_time)),
              )}
              )
            </>
          )}
        </CompanyNavLink>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <CompanyNavLink
          to={{
            pathname: `/admin/asset/${alarm.affected_objects.asset?.asset_id}`,
          }}
          colorClassName={closed ? "text-success" : "text-danger"}
        >
          Gateway{" "}
          <PrivacyMode>
            {alarm.affected_objects.asset?.serial_number}
          </PrivacyMode>{" "}
          configuration error
          {closed && (
            <>
              {" "}
              (closed{" "}
              {formatDuration(
                dayjs.duration(dayjs().diff(alarm.closed_date_time)),
              )}
              )
            </>
          )}
        </CompanyNavLink>
      );

    default:
      return <></>;
  }
};

const AlarmMessage = (props: {
  alarm: AssetAlarmEvent;
}): React.ReactElement => {
  const { alarm } = props;
  const closed = !(props.alarm.closed_date_time === undefined);
  const asset = alarm.affected_objects.asset as GatewayAssetOut;
  switch (alarm.alarm_type) {
    case "GATEWAY_OFFLINE":
      return (
        <>
          <span className={"d-inline-block"}>
            {(alarm.event_data as GatewayOfflineMetaData)?.last_contact ? (
              <>
                Data was last received at&nbsp;
                {formatDate(
                  (alarm.event_data as GatewayOfflineMetaData).last_contact,
                  "LT",
                )}{" "}
                on{" "}
                {formatDate(
                  (alarm.event_data as GatewayOfflineMetaData).last_contact,
                  "L",
                )}
                .
              </>
            ) : closed ? (
              <>Data was not received within the expected period.</>
            ) : (
              <>
                Data was last received at&nbsp;
                {formatDate(asset.last_contact, "LT")} on{" "}
                {formatDate(asset?.last_contact, "L")}.
              </>
            )}
          </span>
          {alarm.affected_objects.location && (
            <span className={"d-inline-block px-1"}>
              Address:{" "}
              <CompanyNavLink
                to={{
                  pathname: `/admin/location/${alarm.affected_objects.location.location_id}`,
                }}
              >
                <PrivacyMode>
                  {alarm.affected_objects.location.address}.
                </PrivacyMode>
              </CompanyNavLink>
              {alarm.affected_objects.asset_position?.customer_reference && (
                <>
                  &nbsp;Reference:&nbsp;
                  <CompanyNavLink
                    to={{
                      pathname: `/admin/asset_position/${alarm.affected_objects.asset_position.asset_position_id}`,
                    }}
                  >
                    <PrivacyMode>
                      {alarm.affected_objects.asset_position.customer_reference}
                    </PrivacyMode>
                  </CompanyNavLink>
                </>
              )}
            </span>
          )}
        </>
      );
    case "GATEWAY_CONFIGURATION_ERROR_UPDATING":
      return (
        <>
          <span className={"d-inline-block"}>
            Updating device (
            {lowerCase(
              startCase(
                (alarm.event_data as GatewayConfigurationFailedData)
                  .action_type,
              ),
            )}
            ) failed at{" "}
            {formatDate(
              (alarm.event_data as GatewayConfigurationFailedData).last_update,
              "LT",
            )}{" "}
            on{" "}
            {formatDate(
              (alarm.event_data as GatewayConfigurationFailedData).last_update,
              "L",
            )}
            .
          </span>
          {alarm.affected_objects.location && (
            <span className={"d-inline-block px-1"}>
              Address:{" "}
              <CompanyNavLink
                to={{
                  pathname: `/admin/location/${alarm.affected_objects.location.location_id}`,
                }}
              >
                <PrivacyMode>
                  {alarm.affected_objects.location.address}.
                </PrivacyMode>
              </CompanyNavLink>
              {alarm.affected_objects.asset_position?.customer_reference && (
                <>
                  &nbsp;Reference:&nbsp;
                  <CompanyNavLink
                    to={{
                      pathname: `/admin/asset_position/${alarm.affected_objects.asset_position.asset_position_id}`,
                    }}
                  >
                    <PrivacyMode>
                      {alarm.affected_objects.asset_position.customer_reference}
                    </PrivacyMode>
                  </CompanyNavLink>
                </>
              )}
            </span>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const AlarmListItemGroup = (props: {
  icon: React.ReactElement;
  heading: React.ReactElement;
  age: string | React.ReactNode;
  description: React.ReactElement;
  dense?: boolean;
}): React.ReactElement => {
  const { icon, heading, age, description } = props;
  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          {icon}
          <span className={"mb-0"}>{heading}</span>
          <p className="text-sm mb-0 mt-1">{description}</p>
        </div>
      }
      column2={
        <div className={"min-w-0 flex-auto text-right"}>
          <small>{age}</small>
        </div>
      }
      column1Size={"w-5/6"}
      column2Size={"w-1/6"}
      dense={props.dense}
    />
  );
};

const AlarmWidgetRow = (
  value: Event | AssetAlarmEvent,
  index: number,
  dense?: boolean,
): React.ReactElement => {
  if (Object.hasOwnProperty.call(value, "alarm_type")) {
    const alarm = value as AssetAlarmEvent;
    return (
      <AlarmListItemGroup
        icon={<AlarmIcon alarm={alarm} />}
        heading={getAlarmHeading(alarm)}
        age={formatDuration(
          dayjs.duration(dayjs().diff(alarm.event_date_time)),
        )}
        description={<AlarmMessage alarm={alarm} />}
        key={index}
        dense={dense}
      />
    );
  } else {
    return <></>;
  }
};

export default AlarmWidgetRow;
