import { ErrorBanner } from "components/Error/ErrorBanner";
import NewTextFormComponent from "components/Forms/NewTextFormComponent";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { Button } from "components/Theme/button";
import { OneCardPage } from "components/utils/OneCardPage";
import { Form, Formik, type FormikHelpers } from "formik";
import { useCreateScheme } from "hooks/createEntity";
import type React from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelectedCompany } from "reducers/company";
import { SchemeContext } from "reducers/scheme";
import * as Yup from "yup";

interface FormValues {
  scheme_id: string;
  scheme_name: string;
  location: string;
}

const NewSchemePage = (): React.ReactElement => {
  const { update, error } = useCreateScheme();

  const selectedCompany = useSelectedCompany();
  const { dispatch } = useContext(SchemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    update({ ...values, company_id: selectedCompany?.company_id })
      .then(() => {
        resetForm();
        dispatch({ type: "SELECT_SCHEME", payload: values });
        setSubmitting(false);
        navigate({ ...location, pathname: "/admin/location" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    scheme_id: "",
    scheme_name: "",
    location: "",
  };
  return (
    <OneCardPage
      headerTitle={"Create Scheme"}
      breadcrumbs={[
        { url: "/admin/schemes", name: "Schemes" },
        { url: "/admin/schemes/create", name: "Create Scheme" },
      ]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          scheme_name: Yup.string().required("Required."),
          scheme_id: Yup.string()
            .required()
            .min(5, "Id is too short -  should be 5 chars minimum")
            .max(50, "Id is too long - should be be 50 chars maximum")
            .matches(
              /[a-z0-9-]/,
              "Id can only contain letters a-z, numbers 0-9 or a `-`",
            ),
          location: Yup.string(),
        })}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <NewTextFormComponent
                fieldName={"scheme_id"}
                label={{ label: "Scheme Id" }}
              />
              <NewTextFormComponent
                fieldName={"scheme_name"}
                label={{ label: "Scheme Name" }}
              />
              <NewTextFormComponent
                fieldName={"location"}
                label={{ label: "Location" }}
              />
              <Button
                type="submit"
                color="brandLight"
                disabled={isSubmitting || !isValid}
              >
                Create
              </Button>
            </Form>
            <ErrorBanner error={error} />
          </BlockSpinner>
        )}
      </Formik>
    </OneCardPage>
  );
};

export default NewSchemePage;
