import clsx from "clsx";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { CumulativeDataConfigurationInput } from "kubb";
import type { Period } from "kubb";
import type React from "react";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import { Button } from "../../../components/Theme/button";
import { Col, Container } from "../../../components/Theme/grid";
import BillingConfigurationForm from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import PeriodSelector from "../selectors/PeriodSelector";

export interface BillingReportRequestParams {
  start_time: Dayjs;
  end_time?: Dayjs;
  period?: Period;
  configuration?: CumulativeDataConfigurationInput;
}

interface Options {
  action: (request: BillingReportRequestParams) => void;
  displayWide?: boolean;
  actionLabel?: string;
}

const BillingReportSelectorForm = (
  props: BillingReportRequestParams & Options,
): React.ReactElement => {
  const { action, displayWide = false, actionLabel = "Search" } = props;

  const initialValues = (): BillingReportRequestParams => {
    return props;
  };

  const selectData = (values: BillingReportRequestParams): void => {
    action(values);
  };

  const _adjustForDisplayWide = (
    valueIfWide: any,
    valueIfNotWide: any = undefined,
  ): any => {
    return displayWide ? valueIfWide : valueIfNotWide;
  };

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={selectData}
      enableReinitialize={true}
    >
      {(): React.ReactElement => {
        return (
          <Form>
            <Container>
              <Col sm={12} md={_adjustForDisplayWide(12, 6)}>
                <DateRangePicker
                  startDateName={"start_time"}
                  endDateName={"end_time"}
                  maxDate={dayjs().startOf("day").subtract(1, "second")}
                  label={{ label: "Date Range", size: "sm" }}
                />
              </Col>
              <Col sm={12} md={_adjustForDisplayWide(12, 4)}>
                <PeriodSelector
                  fieldName={"period"}
                  label={{ label: "Period", size: "sm" }}
                />
              </Col>
              <Col
                sm={12}
                md={_adjustForDisplayWide(12, 2)}
                className={clsx("mt-auto")}
              >
                <Button
                  type={"submit"}
                  color={"brandLight"}
                  className={"w-full h-[38px]"}
                >
                  {actionLabel}
                </Button>
              </Col>
              <Col>
                <BillingConfigurationForm
                  namespace={"configuration"}
                  includedFieldsEditable={false}
                />
              </Col>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BillingReportSelectorForm;
