import client from 'client'
import type {
  GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse,
  GetDetailedBulkUpdateBulkUpdateIdGetPathParams,
  GetDetailedBulkUpdateBulkUpdateIdGetQueryParams,
  GetDetailedBulkUpdateBulkUpdateIdGet422,
} from '../../types/GetDetailedBulkUpdateBulkUpdateIdGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDetailedBulkUpdateBulkUpdateIdGetQueryKey = (
  update_id: GetDetailedBulkUpdateBulkUpdateIdGetPathParams['update_id'],
  params?: GetDetailedBulkUpdateBulkUpdateIdGetQueryParams,
) => [{ url: '/bulk/:update_id', params: { update_id: update_id } }, ...(params ? [params] : [])] as const

export type GetDetailedBulkUpdateBulkUpdateIdGetQueryKey = ReturnType<typeof getDetailedBulkUpdateBulkUpdateIdGetQueryKey>

/**
 * @summary Get Detailed Bulk Update
 * {@link /bulk/:update_id}
 */
export async function getDetailedBulkUpdateBulkUpdateIdGet(
  update_id: GetDetailedBulkUpdateBulkUpdateIdGetPathParams['update_id'],
  params?: GetDetailedBulkUpdateBulkUpdateIdGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse, ResponseErrorConfig<GetDetailedBulkUpdateBulkUpdateIdGet422>, unknown>({
    method: 'GET',
    url: `/bulk/${update_id}`,
    params,
    ...requestConfig,
  })
  return res
}

export function getDetailedBulkUpdateBulkUpdateIdGetQueryOptions(
  update_id: GetDetailedBulkUpdateBulkUpdateIdGetPathParams['update_id'],
  params?: GetDetailedBulkUpdateBulkUpdateIdGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getDetailedBulkUpdateBulkUpdateIdGetQueryKey(update_id, params)
  return queryOptions<
    ResponseConfig<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse>,
    ResponseErrorConfig<GetDetailedBulkUpdateBulkUpdateIdGet422>,
    ResponseConfig<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!update_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDetailedBulkUpdateBulkUpdateIdGet(update_id, params, config)
    },
  })
}

/**
 * @summary Get Detailed Bulk Update
 * {@link /bulk/:update_id}
 */
export function useGetDetailedBulkUpdateBulkUpdateIdGet<
  TData = ResponseConfig<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse>,
  TQueryData = ResponseConfig<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse>,
  TQueryKey extends QueryKey = GetDetailedBulkUpdateBulkUpdateIdGetQueryKey,
>(
  update_id: GetDetailedBulkUpdateBulkUpdateIdGetPathParams['update_id'],
  params?: GetDetailedBulkUpdateBulkUpdateIdGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetDetailedBulkUpdateBulkUpdateIdGetQueryResponse>,
        ResponseErrorConfig<GetDetailedBulkUpdateBulkUpdateIdGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDetailedBulkUpdateBulkUpdateIdGetQueryKey(update_id, params)

  const query = useQuery({
    ...(getDetailedBulkUpdateBulkUpdateIdGetQueryOptions(update_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetDetailedBulkUpdateBulkUpdateIdGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}