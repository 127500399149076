import NewAssetUseCaseSelect from "components/Asset/NewAssetUseCaseSelect";
import NewButton from "components/Forms/NewButton/NewButton";
import NewLocationGroupSelect from "components/LocationGroup/NewLocationGroupSelect";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { FormikHelpers } from "formik/dist/types";
import type { AssetUseCaseEnum } from "kubb";
import { isEqual } from "lodash-es";
import type React from "react";
import * as Yup from "yup";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import { Col, Container } from "../../../components/Theme/grid";

export interface HeatNetworkReportQueryFormValues {
  startDate: Dayjs;
  endDate: Dayjs;
  useCases: AssetUseCaseEnum[];
  group?: string;
}

export interface HeatNetworkReportQueryFormProps {
  onSubmit: (values: HeatNetworkReportQueryFormValues) => Promise<any>;
  initialValues?: HeatNetworkReportQueryFormValues;
  dateOnly?: boolean;
}

export default function HeatNetworkReportQueryForm(
  props: HeatNetworkReportQueryFormProps,
) {
  const { initialValues = {} as HeatNetworkReportQueryFormValues, onSubmit } =
    props;
  const wide = true;
  const showErrors = true;
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(
        values: HeatNetworkReportQueryFormValues,
        helpers: FormikHelpers<HeatNetworkReportQueryFormValues>,
      ): Promise<any> => {
        helpers.setSubmitting(true);
        return onSubmit(values).finally(() => helpers.setSubmitting(false));
      }}
    >
      {({ values, submitForm }): React.ReactElement => {
        const isChanged = !isEqual(values, initialValues);

        return (
          <Form>
            <Container>
              <Col sm={12} md={wide ? 3 : 12}>
                <DateRangePicker
                  startDateName={"startDate"}
                  endDateName={"endDate"}
                  label={{ label: "Date Range", size: "sm" }}
                  maxDate={dayjs().startOf("day")}
                />
              </Col>
              {!props.dateOnly && (
                <>
                  <Col sm={12} md={wide ? 3 : 12}>
                    <NewAssetUseCaseSelect
                      label={{
                        label: "Asset Use Cases",
                        size: "sm",
                        showErrors,
                      }}
                      isMulti={true}
                      fieldName={"useCases"}
                    />
                  </Col>
                  <Col sm={12} md={wide ? 3 : 12}>
                    <NewLocationGroupSelect
                      label={{ label: "Group", size: "sm", showErrors }}
                      fieldName={"group"}
                    />
                  </Col>
                </>
              )}
              <Col sm={12} md={wide ? 3 : 12} className={"mt-auto"}>
                <NewButton
                  color={"brandLight"}
                  className={"w-full"}
                  onClick={submitForm}
                  disabled={!isChanged}
                >
                  Search
                </NewButton>
              </Col>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
