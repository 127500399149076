import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
} from "@headlessui/react";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logoImage from "assets/img/brand/meterpoint.png";
import {
  type CollapseRoute,
  type PageRoute,
  allRoutes,
  filterRoutes,
  isPageRoute,
} from "components/Sidebar/model";
import type React from "react";
import type { Dispatch, SetStateAction } from "react";
import { NavLink, useLocation } from "react-router";
import { useSelectedCompany } from "reducers/company";
import { useProfile } from "../../reducers/profile";
import { Badge } from "../Theme/badge";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";

const selectedColours = "bg-brand-dark-800 text-brand-light-100";
const unSelectedColours =
  "hover:bg-brand-dark-800 hover:text-brand-light-100 text-brand-light-500";

function isCurrent(currentPath: string, item: PageRoute): boolean {
  const livePath = item.layout + item.path;
  return currentPath.startsWith(`${livePath}/`) || currentPath === livePath;
}

function PageRouteLink(
  item: PageRoute,
  currentPath: string,
  currentHash: string,
): React.ReactElement {
  return (
    <DisplayIfUserType
      userTypes={item.userGroup ? [item.userGroup] : "viewer"}
      key={item.name}
    >
      <li>
        <NavLink
          to={{
            pathname: item.layout + item.path,
            hash: currentHash,
          }}
          className={classNames(
            isCurrent(currentPath, item) ? selectedColours : unSelectedColours,
            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
          )}
        >
          {item.actualIcon && (
            <item.actualIcon
              aria-hidden="true"
              className="h-6 w-6 shrink-0 text-brand-light-400"
            />
          )}
          {item.name}
        </NavLink>
      </li>
    </DisplayIfUserType>
  );
}

function CollapseRouteLink(
  item: CollapseRoute,
  currentPath: string,
  currentHash: string,
): React.ReactElement {
  const open = item.views
    .map((v) => v.layout + v.path === currentPath)
    .some((v) => v);
  return (
    <DisplayIfUserType
      userTypes={item.userGroup ? [item.userGroup] : "viewer"}
      key={item.name}
    >
      <li>
        <Disclosure as="div" defaultOpen={open}>
          <DisclosureButton
            className={classNames(
              unSelectedColours,
              "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6",
            )}
          >
            {item.actualIcon && (
              <item.actualIcon
                aria-hidden="true"
                className="h-6 w-6 shrink-0 text-brand-light-400"
              />
            )}
            {item.name}
            <ChevronRightIcon
              aria-hidden="true"
              className="ml-auto h-5 w-5 shrink-0 text-brand-light-400 group-data-[open]:rotate-90 group-data-[open]:text-brand-light-600"
            />
          </DisclosureButton>
          <DisclosurePanel as="ul" className="mt-1">
            {item.views.map((subItem) =>
              ChildPageRouteLink(subItem, currentPath, currentHash),
            )}
          </DisclosurePanel>
        </Disclosure>
      </li>
    </DisplayIfUserType>
  );
}

function ChildPageRouteLink(
  item: PageRoute,
  currentPath: string,
  currentHash: string,
): React.ReactElement {
  return (
    <DisplayIfUserType
      userTypes={item.userGroup ? [item.userGroup] : "viewer"}
      key={item.name}
    >
      <li key={item.name}>
        <NavLink
          to={{
            pathname: item.layout + item.path,
            hash: currentHash,
          }}
          className={classNames(
            isCurrent(currentPath, item) ? selectedColours : unSelectedColours,
            "block rounded-md p-2 text-sm leading-6",
            "group flex gap-x-3 ",
          )}
        >
          <span className={"h-6 w-6 text-center text-lg"}>&bull;</span>
          {item.name}
          {item.beta && (
            <Badge color={"menu"} size={"sm"}>
              Preview
            </Badge>
          )}
        </NavLink>
      </li>
    </DisplayIfUserType>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar(props: {
  sidebarState: [boolean, Dispatch<SetStateAction<boolean>>];
}) {
  const [sidebarOpen, setSidebarOpen] = props.sidebarState;
  const company = useSelectedCompany();
  const user = useProfile();
  const navigation = filterRoutes(company, user, allRoutes);
  const location = useLocation();
  const menu = (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-dark px-6 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <img alt="Meterpoint" src={logoImage} className="h-8 w-auto" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation.map((item) =>
                isPageRoute(item)
                  ? PageRouteLink(item, location.pathname, location.hash)
                  : CollapseRouteLink(item, location.pathname, location.hash),
              )}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 xl:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {menu}
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          {menu}
        </div>
      </div>
    </>
  );
}
