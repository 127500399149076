import React from "react";
import type { Asset } from "utils/object";
import { useGetAsset } from "../../../hooks/getEntity";

export interface AssetChildProps {
  asset: Asset;
  refresh: () => void;
  loading: boolean;
}

export interface OptionalAssetChildProps {
  asset?: Asset;
  refresh: () => void;
  loading: boolean;
}

interface AssetProps {
  asset: Asset;
  children: (props: AssetChildProps) => React.ReactElement;
}

interface asset_idProps {
  asset_id: string;
  company_id: string;
  children: (props: OptionalAssetChildProps) => React.ReactElement;
}

type Props = asset_idProps | AssetProps;

function isAssetProps(props: Props): props is AssetProps {
  return (props as AssetProps).asset !== undefined;
}

function AssetContainer(props: Props) {
  const [enabled, setEnabled] = React.useState(!isAssetProps(props));
  const { data, loading, refresh } = useGetAsset(
    isAssetProps(props) ? props.asset.asset_id : props.asset_id,
    isAssetProps(props) ? props.asset.company_id : props.company_id,
    enabled,
  );

  if (isAssetProps(props)) {
    return props.children({
      asset: data || props.asset, // prioritise data incase of a refreshed item
      refresh: enabled ? refresh : () => setEnabled(true),
      loading,
    });
  } else {
    return props.children({
      asset: data,
      refresh,
      loading,
    });
  }
}

export default AssetContainer;
