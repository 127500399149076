import type { Account } from "kubb";
import { useGetAccountListAccountGet } from "kubb";
import NewGetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import PrivacyMode from "../../components/Text/PrivacyMode";
import {
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";

function AccountList(props: {
  company_id: string;
  scheme_id?: string;
  location_id?: string;
}) {
  const showLocationCol = props.location_id === undefined;

  function row(account: Account) {
    return (
      <TableRow>
        <TableCell>{account.account_reference} </TableCell>
        {showLocationCol && (
          <TableCell>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={{
                pathname: `/admin/location/${account.location_id}/accounts`,
              }}
              dataCy={"location_id"}
              inline={true}
            >
              <PrivacyMode>
                {account.location_reference
                  ? `${account.location_address}(${account.location_reference})`
                  : account.location_address}
              </PrivacyMode>
            </CompanyNavLink>
          </TableCell>
        )}
        <TableCell>{account.opening_date}</TableCell>
        <TableCell>{account.closing_date || "-"}</TableCell>
      </TableRow>
    );
  }

  return (
    <NewGetListPaginatedTable
      row={row}
      header={[
        <TableHead key={0}>
          <TableHeader>
            <h5>Account Ref</h5>
          </TableHeader>
          {showLocationCol && (
            <TableHeader>
              <h5>Location</h5>
            </TableHeader>
          )}
          <TableHeader>
            <h5> Opening Date</h5>
          </TableHeader>
          <TableHeader>
            <h5>Closing Date</h5>
          </TableHeader>
        </TableHead>,
        showLocationCol ? 4 : 3,
      ]}
      params={props}
      useQuery={useGetAccountListAccountGet}
      cursor={{ max_items: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={true}
    />
  );
}

export default AccountList;
