import { faPlay } from "@fortawesome/free-solid-svg-icons";
import type React from "react";
import Action from "../../core/action/Action";
import Spinner from "../../core/action/Spinner";
import { useCreateBulkUpdateExecution } from "../../hooks/createEntity";

export default function ExecuteBulkUpdateButton(props: {
  updateId: string;
  company_id: string;
  refresh?: () => any;
}): React.ReactElement {
  const create = useCreateBulkUpdateExecution(props.updateId, props.company_id);

  return (
    <Action
      onClick={() => {
        create.update(null).then(() => {
          props.refresh?.();
        });
      }}
      color={"brandLight"}
    >
      <Spinner alt={faPlay} spin={create.loading} className={"mr-2"} />
      Execute
    </Action>
  );
}
