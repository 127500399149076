import { ChevronDownIcon } from "@heroicons/react/20/solid";
import type { ButtonProps } from "components/Theme/button";
import { saveAs } from "file-saver";
import {
  type ActionTypesEnum,
  type ConfigurationFormat,
  type ConfigurationType,
  type GatewayAssetOut,
  actionTypesEnum,
  configurationFormatEnum,
  configurationTypeEnum,
  getAssetConfigurationAssetAssetIdConfigurationGet,
} from "kubb";
import type React from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
} from "../../../../components/Theme/dropdown";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AssetActionConfirmModal from "../../assetActions/AssetActionConfirmModal";

type Props = Omit<ButtonProps, "children" | "onClick"> & {
  gateway: GatewayAssetOut;
  refresh?: () => any;
};

export const getConfigurationFileForAsset = (
  asset_id: string,
  company_id: string,
  configuration_type: ConfigurationType,
  configuration_format?: ConfigurationFormat,
): Promise<void> => {
  return getAssetConfigurationAssetAssetIdConfigurationGet(asset_id, {
    company_id,
    configuration_format,
    configuration_type,
  }).then((d) => {
    if (d.status === 200) {
      const filename = d.headers["content-disposition"]
        .split(";")[1]
        .split("filename")[1]
        .split("=")[1]
        .trim()
        .replace(/"/g, "");
      saveAs(new Blob([d.data], { type: d.headers["content-type"] }), filename);
    } else {
      throw new Error("Unable to find configuration file.");
    }
  });
};

const GatewayConfigurationDropdown = (props: Props): React.ReactElement => {
  const { gateway, ...rest } = props;

  const downloadDeviceList = (
    type: ConfigurationType,
    format?: ConfigurationFormat,
  ): void => {
    getConfigurationFileForAsset(
      gateway.asset_id,
      props.gateway.company_id,
      type,
      format,
    );
  };

  const [action, setAction] = useState<
    { actionType: ActionTypesEnum; actionName: string } | undefined
  >(undefined);
  const identifiedGatewayModel: boolean = gateway.model !== undefined;

  const isElvaco = props.gateway.manufacturer === "elvaco";

  const dropDownItem = (actionType: ActionTypesEnum, actionName: string) => {
    return (
      <DropdownItem onClick={() => setAction({ actionType, actionName })}>
        {actionName}
      </DropdownItem>
    );
  };
  return (
    <>
      {action && (
        <AssetActionConfirmModal
          show={true}
          asset={gateway}
          action={{ action_type: action.actionType, payload: undefined }}
          close={() => setAction(undefined)}
          description={action.actionName}
          onComplete={props.refresh}
        />
      )}
      <Dropdown>
        <DropdownButton {...rest} disabled={!isElvaco}>
          Configuration
          <ChevronDownIcon />
        </DropdownButton>

        {isElvaco && (
          <DropdownMenu>
            <DisplayIfUserType userTypes={"admin"}>
              {gateway.sim_card_id !== undefined &&
                props.gateway.sim_card_id !== "" && (
                  <>
                    {dropDownItem(
                      actionTypesEnum.FACTORY_RESET_2100,
                      "Factory Reset",
                    )}
                    {dropDownItem(actionTypesEnum.SYNC_CONFIG, "Sync Config")}
                    {dropDownItem(actionTypesEnum.GET_CONFIG, "Refresh Config")}
                    {dropDownItem(
                      actionTypesEnum.SYNC_DEVICE_LIST,
                      "Sync Devices",
                    )}
                    <DropdownDivider />
                    {dropDownItem(
                      actionTypesEnum.SYNC_CONFIG_SMS,
                      "Sync Config (via SMS)",
                    )}
                    {dropDownItem(
                      actionTypesEnum.SYNC_DEVICE_LIST_SMS,
                      "Sync Devices (via SMS)",
                    )}
                    <DropdownDivider />
                    {dropDownItem(
                      actionTypesEnum.FIRMWARE_UPGRADE,
                      "Firmware Upgrade",
                    )}
                    {dropDownItem(
                      actionTypesEnum.SETUP_WIRELESS_RECEIVERS,
                      "Setup Wireless Receivers",
                    )}
                    <DropdownDivider />
                    <DisplayIfUserType userTypes={"superadmin"}>
                      <>
                        {dropDownItem(
                          actionTypesEnum.RELEASE_SIM_CARD,
                          "Return sim card to pool",
                        )}
                        {dropDownItem(
                          actionTypesEnum.ROTATE_CREDENTIALS,
                          "Rotate gateway access credentials",
                        )}
                      </>
                    </DisplayIfUserType>
                  </>
                )}
            </DisplayIfUserType>
            {identifiedGatewayModel ? (
              <>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(configurationTypeEnum.DEVICE_LIST)
                  }
                >
                  Download device list
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(configurationTypeEnum.SETUP)
                  }
                >
                  Download setup configuration
                </DropdownItem>
              </>
            ) : (
              <>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(
                      configurationTypeEnum.DEVICE_LIST,
                      configurationFormatEnum.ELVACO_21XX,
                    )
                  }
                >
                  Download device list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(
                      configurationTypeEnum.DEVICE_LIST,
                      configurationFormatEnum.ELVACO_31XX,
                    )
                  }
                >
                  Download device list (Elvaco 31XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(
                      configurationTypeEnum.SETUP,
                      configurationFormatEnum.ELVACO_21XX,
                    )
                  }
                >
                  Download setup configuration list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList(
                      configurationTypeEnum.SETUP,
                      configurationFormatEnum.ELVACO_31XX,
                    )
                  }
                >
                  Download setup configuration (Elvaco 31XX)
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  );
};

export default GatewayConfigurationDropdown;
