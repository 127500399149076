import type { AssetPositionNumbers } from "kubb";
import type { Scheme } from "kubb";
import type React from "react";
import type { Writable } from "type-fest";

import { startCase } from "lodash-es";
import { Table, TableBody, TableCell, TableRow } from "../Theme/table";

export function computeTotalNumberOfAssetPositionsForListOfSchemes(
  schemes: Scheme[],
): AssetPositionNumbers {
  const newObj: Writable<AssetPositionNumbers> = {};
  for (const scheme of schemes) {
    if (scheme.number_of_asset_positions) {
      for (const [key, value] of Object.entries(
        scheme.number_of_asset_positions,
      )) {
        newObj[key as keyof AssetPositionNumbers] =
          (newObj[key as keyof AssetPositionNumbers] || 0) +
          (value ? value : 0);
      }
    }
  }
  return newObj;
}

export function AssetPositionNumbersToTable(props: {
  numbers?: AssetPositionNumbers;
}): React.ReactElement {
  const { numbers } = props;
  return (
    <Table dense={true}>
      <TableBody>
        {Object.entries(numbers || []).map(([name, count]) => (
          <TableRow key={name}>
            <TableCell>{startCase(name)}</TableCell>
            <TableCell className={"text-right"}>{count as number}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default function AssetPositionNumbersToStringList(props: {
  numbers?: AssetPositionNumbers;
  includeGateways?: boolean;
}): React.ReactElement {
  const { numbers, includeGateways = false } = props;
  const filteredNumbers: AssetPositionNumbers = Object.fromEntries(
    Object.entries(numbers || [])
      .filter(([assetType, number]) => {
        const nonZero = number !== 0;
        return includeGateways ? nonZero : assetType !== "gateway" && nonZero;
      })
      .map(([assetType, number]) => {
        return [assetType, number];
      }),
  );
  return (
    <>
      {Object.entries(filteredNumbers).map(
        ([assetType, number], index, all) => (
          <>
            {startCase(assetType)}s: {number as number}
            {index === all.length - 1 ? "" : ", "}
          </>
        ),
      )}
    </>
  );
}
