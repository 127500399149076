import { unflatten } from "flat";
import type { BulkUpdateDetailed, ObjectTypes } from "kubb";
import type { ParseResult } from "papaparse";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateNewBulkUpdate } from "../../hooks/createEntity";
import {
  objectBlankStringsToUndefined,
  removeMapBlankObjects,
} from "../../utils/object";
import BulkUpdateCSVDropZone from "./BulkUpdateCSVDropZone";

interface Props {
  company_id: string;
  scheme_id?: string;
  objectType: ObjectTypes;
}

export function unFlattenData(data: any[]): any[] {
  return data.map((d) => {
    return unflatten(d, { object: false });
  });
}

export const identifyUniqueArrayValues = (
  arrayOne: any[],
  arrayTwo: any[],
): any[] => {
  return arrayOne.reduce((accumulator, value) => {
    if (!arrayTwo.includes(value)) {
      return [...accumulator, value];
    }
    return accumulator;
  }, []);
};

export default function BulkUpdate(props: Props): React.ReactElement {
  const create = useCreateNewBulkUpdate(props.company_id);

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToUpdate = (update: BulkUpdateDetailed): void => {
    navigate({
      pathname: `/admin/${props.objectType}/updates/${update.update_id}`,
      hash: location.hash,
    });
  };

  const parseComplete = async (
    inputData: Promise<ParseResult<any>>,
    filename: string,
  ): Promise<any> => {
    const data = await inputData;
    const objects = unFlattenData(data.data).map((v) =>
      removeMapBlankObjects(objectBlankStringsToUndefined(v)),
    );
    const [update] = await create.update({
      objects: objects,
      company_id: props.company_id,
      scheme_id: props.scheme_id,
      object_type: props.objectType,
      filename: filename,
    });
    navigateToUpdate(update);
  };

  return <BulkUpdateCSVDropZone parseComplete={parseComplete} />;
}
