import type { Scheme } from "kubb";
import type { TariffOut } from "kubb";
import { useCreateNewTariff } from "../../hooks/createEntity";
import type { PaginatedData } from "../../hooks/getList";
import { useRequiredSelectedCompanyId } from "../../reducers/company.tsx";
import { useSelectedScheme } from "../../reducers/scheme";
import TariffCreateForm from "./TariffCreateForm";
import TariffHomepage from "./TariffHomepage";

function SchemeTariffListDisplay(props: { data: PaginatedData<TariffOut> }) {
  const scheme = useSelectedScheme() as Scheme;

  const create = useCreateNewTariff(useRequiredSelectedCompanyId());
  switch (props.data.data.length) {
    case 0:
      return <TariffCreateForm scheme={scheme} create={create} />;
    case 1:
      return <TariffHomepage tariff={props.data.data[0]} />;
  }
  throw new Error(
    `More than 1 tariff was returned for scheme ${scheme.company_id}/${scheme.scheme_id}.`,
  );
}

export default SchemeTariffListDisplay;
