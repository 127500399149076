import type { LocationGroup } from "kubb";
import React from "react";
import { SchemeContext } from "../../reducers/scheme";
import {
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";
import { locationGroupListToOptions } from "../Location/model";

export default function LocationGroupSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const { disabled, label, ...rest } = props;
  const locationGroups = React.useContext(SchemeContext).state.groups;

  const ready = !locationGroups.loading && !locationGroups.error;
  const haveGroups = locationGroups.groups && locationGroups.groups.length > 0;
  const altText: string = ready
    ? "No Groups"
    : locationGroups.loading
      ? "Loading Groups"
      : locationGroups.error?.message || "Error Loading Groups";
  return (
    <SelectComboField
      label={label || { label: "Group" }}
      options={
        haveGroups
          ? [
              { label: "All", value: "undefined" },
              ...locationGroupListToOptions(
                locationGroups.groups as LocationGroup[],
                0,
              ),
            ]
          : [{ label: altText, value: "undefined" }]
      }
      disabled={!haveGroups || disabled}
      {...rest}
    />
  );
}
