import { faFolderClosed } from "@fortawesome/free-solid-svg-icons";
import type {
  WirelessSurveyDataCollectorOut,
  WirelessSurveyDataCollectorUpdate,
} from "kubb";
import UpdateButton, {
  type UpdateButtonProps,
} from "../../../components/Buttons/UpdateButton";
import { useUpdateWirelessSurveyDataCollector } from "../../../hooks/updateEntity.tsx";

function WirelessSurveyDataCollectorCloseButton(
  props: {
    dataCollector: WirelessSurveyDataCollectorOut;
  } & Omit<
    UpdateButtonProps<WirelessSurveyDataCollectorUpdate>,
    "update" | "updatedEntity"
  >,
) {
  const { dataCollector } = props;
  return (
    <UpdateButton
      update={useUpdateWirelessSurveyDataCollector(props.dataCollector)}
      updatedEntity={{
        ...dataCollector,
        closed: true,
      }}
      color={"amber"}
      text={"Close"}
      size={"sm"}
      icon={faFolderClosed}
      postUpdate={props.postUpdate}
    />
  );
}

export default WirelessSurveyDataCollectorCloseButton;
