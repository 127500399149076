import { useField } from "formik";
import { type ElvacoX109ReportConfiguration, timeFilterEnum } from "kubb";
import type React from "react";
import * as Yup from "yup";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import type { SubFormProps } from "../../../components/Forms/WithSubForm";
import { yupRequiredString } from "../../../components/Forms/yupValidators";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";

export interface X109ConfigurationProps {
  file_name: string;
  configuration: ElvacoX109ReportConfiguration;
}

export const X109ConfigurationSubFormSchema = Yup.object().shape({
  file_name: yupRequiredString,
});

export const X109ConfigurationInitialValues: X109ConfigurationProps = {
  file_name: "{gateway_serial_number}_{meter_serial_number}_{date}_2109.csv",
  configuration: {
    time_filter: timeFilterEnum.ALL,
    non_mbus_asset_options: {
      ignore: true,
      manufacturer: "???",
    },
  },
};

export const X110ConfigurationInitialValues: X109ConfigurationProps = {
  file_name: "{gateway_serial_number}_{date}_2110.csv",
  configuration: {
    time_filter: timeFilterEnum.ALL,
    non_mbus_asset_options: {
      ignore: true,
      manufacturer: "???",
    },
  },
};

const X109ConfigurationForm = ({
  namespace,
  disabled,
}: SubFormProps): React.ReactElement => {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  const [ignoreNonMBus] = useField(
    withNamespace("configuration.non_mbus_asset_options.ignore"),
  );

  return (
    <>
      <h4>X109 Report Configuration</h4>
      <NewTextFormComponent
        fieldName={withNamespace("file_name")}
        label={{ label: "Filename" }}
        placeholder={
          "Keywords for filename are: gateway_serial_number, meter_serial_number and date"
        }
        disabled={disabled}
      />
      <TimeFilterSelect
        fieldName={withNamespace("configuration.time_filter")}
        label={{ label: "Time Filter" }}
        disabled={disabled}
      />
      <h5>Non MBus meters</h5>
      <ButtonGroupField
        fieldName={withNamespace("configuration.non_mbus_asset_options.ignore")}
        label={{ label: "Process Data From Non MBus meters" }}
        options={[
          { label: "Yes", value: false },
          { label: "No", value: true },
        ]}
        fullWidth={true}
      />
      <NewTextFormComponent
        fieldName={withNamespace(
          "configuration.non_mbus_asset_options.manufacturer",
        )}
        label={{ label: "Manufacturer for Non MBus meters" }}
        disabled={disabled || ignoreNonMBus.value === true}
      />
    </>
  );
};

export default X109ConfigurationForm;
