import {
  type AssetPositionNumbers,
  type AssetTypeEnum,
  assetTypeEnum,
} from "kubb";
import { snakeCase } from "lodash-es";
import type React from "react";
import timeSeries from "../../constants/timeSeriesTemplates.json";
import type { Asset } from "../../utils/object.tsx";

interface FieldDescription {
  name: string;
  factor?: number;
}

interface TimeSeriesTemplates {
  [key: string]: {
    data_fields: {
      name: string;
      unit: string;
      field:
        | string
        | string[]
        | {
            elvaco_mbus?: FieldDescription[];
            guru_systems?: FieldDescription[];
          };
      aggregations: string[];
    }[];

    aggregations: {
      data_fields: string[];
      aggregations: string[];
    }[];
  };
}

const timeSeriesTemplates: TimeSeriesTemplates = timeSeries;

export const doesDeviceHaveParsedData = (device: Asset): boolean => {
  return (
    [
      assetTypeEnum.heat_meter,
      assetTypeEnum.heating_and_cooling_meter,
      assetTypeEnum.room_sensor,
      assetTypeEnum.co2_room_sensor,
      assetTypeEnum.electricity_meter,
      assetTypeEnum.heating_and_cooling_meter,
      assetTypeEnum.water_meter,
      assetTypeEnum.gas_meter,
      assetTypeEnum.gateway,
      assetTypeEnum.cooling_meter,
    ] as AssetTypeEnum[]
  ).includes(device.asset_type);
};

export const getFieldsForAssetPositions = (
  assetPositionNumbers: AssetPositionNumbers,
): Array<string> => {
  const assetTypes: string[] = Object.keys(assetPositionNumbers);
  const fields = new Set<string>();

  for (const assetType of assetTypes) {
    const assetTypeSC = snakeCase(assetType);
    if (
      Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetTypeSC)
    ) {
      timeSeriesTemplates[
        assetTypeSC as keyof typeof timeSeriesTemplates
      ].data_fields.reduce((accumulator, currentField: any) => {
        return accumulator.add(currentField.name);
      }, fields);
    }
  }

  return [...fields];
};

export const getFieldsForAssetType = (assetType?: string): Array<string> => {
  if (
    assetType &&
    Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetType)
  ) {
    return timeSeriesTemplates[
      assetType as keyof typeof timeSeriesTemplates
    ].data_fields.map((x) => x.name);
  } else {
    return [];
  }
};

export const doesAssetHaveDataField = (
  assetType?: string,
  field?: string,
): boolean => {
  const assetTypeSC = snakeCase(assetType);
  if (
    Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetTypeSC) &&
    field
  ) {
    const fieldNames = timeSeriesTemplates[
      assetTypeSC as keyof typeof timeSeriesTemplates
    ].data_fields.map((x) => x.name);
    return fieldNames.includes(field);
  } else {
    return false;
  }
};

export const formatDataCollectionFrequency = (
  collectionFrequency?: number,
  abbreviate = false,
): React.ReactNode => {
  if (collectionFrequency) {
    if (collectionFrequency === 1) {
      return abbreviate ? <>1&nbsp;min</> : "1 minute";
    } else if (collectionFrequency < 60) {
      return (
        <>
          {collectionFrequency}&nbsp;{abbreviate ? "mins" : "minutes"}
        </>
      );
    } else if (collectionFrequency === 60) {
      return "Hourly";
    } else if (collectionFrequency < 1440) {
      return (
        <>
          {collectionFrequency / 60}&nbsp;{abbreviate ? "hrs" : "hours"}
        </>
      );
    } else {
      return "Daily";
    }
  } else {
    return "-";
  }
};
