import client from 'client'
import type { GetTariffListTariffGetQueryResponse, GetTariffListTariffGetQueryParams, GetTariffListTariffGet422 } from '../../types/GetTariffListTariffGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getTariffListTariffGetQueryKey = (params?: GetTariffListTariffGetQueryParams) => [{ url: '/tariff' }, ...(params ? [params] : [])] as const

export type GetTariffListTariffGetQueryKey = ReturnType<typeof getTariffListTariffGetQueryKey>

/**
 * @summary Get Tariff List
 * {@link /tariff}
 */
export async function getTariffListTariffGet(params?: GetTariffListTariffGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetTariffListTariffGetQueryResponse, ResponseErrorConfig<GetTariffListTariffGet422>, unknown>({
    method: 'GET',
    url: `/tariff`,
    params,
    ...requestConfig,
  })
  return res
}

export function getTariffListTariffGetQueryOptions(
  params?: GetTariffListTariffGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getTariffListTariffGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetTariffListTariffGetQueryResponse>,
    ResponseErrorConfig<GetTariffListTariffGet422>,
    ResponseConfig<GetTariffListTariffGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getTariffListTariffGet(params, config)
    },
  })
}

/**
 * @summary Get Tariff List
 * {@link /tariff}
 */
export function useGetTariffListTariffGet<
  TData = ResponseConfig<GetTariffListTariffGetQueryResponse>,
  TQueryData = ResponseConfig<GetTariffListTariffGetQueryResponse>,
  TQueryKey extends QueryKey = GetTariffListTariffGetQueryKey,
>(
  params?: GetTariffListTariffGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetTariffListTariffGetQueryResponse>, ResponseErrorConfig<GetTariffListTariffGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getTariffListTariffGetQueryKey(params)

  const query = useQuery({
    ...(getTariffListTariffGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetTariffListTariffGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}