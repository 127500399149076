import { outputTypeEnum } from "kubb";
import BetaAlert from "../../../components/Beta/BetaAlert";
import { PageHeadingBlock } from "../../../components/Theme/heading";
import { useCreateReportExecution } from "../../../hooks/createEntity";
import { useRequiredSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";
import ReportExecutionCreator from "../executions/ReportExecutionCreator";
import ReportExecutionList from "../executions/ReportExecutionList";

function TimeSeriesDataExportPage() {
  const company = useRequiredSelectedCompany();
  const scheme = useSelectedScheme();

  const create = useCreateReportExecution(company.company_id);

  return (
    <>
      <PageHeadingBlock>Export Aggregated Data</PageHeadingBlock>
      <BetaAlert />
      <ReportExecutionCreator
        scheme={scheme}
        company={company}
        create={create.update}
        outputTypeFilter={[
          outputTypeEnum.AGGREGATED_DATA,
          outputTypeEnum.ALL_DATA,
        ]}
      />
      <PageHeadingBlock>Previous Executions</PageHeadingBlock>

      <ReportExecutionList
        company_id={company.company_id}
        scheme_id={scheme?.scheme_id}
        params={{
          format: [outputTypeEnum.AGGREGATED_DATA, outputTypeEnum.ALL_DATA],
        }}
        cursor={{ max_items: 15 }}
      />
    </>
  );
}

export default TimeSeriesDataExportPage;
