import { Heading } from "components/Theme/heading";
import type { AssetPosition, AssetType } from "kubb";
import { assetTypeEnum } from "kubb";
import type { DeviceAssetOut } from "kubb";
import type { GatewayAssetOut } from "kubb";
import type { Location } from "kubb";
import type React from "react";
import type { Asset } from "utils/object";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import {
  assetPositionToBreadcrumb,
  assetToBreadcrumb,
  locationToBreadcrumb,
} from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import type { AssetRegisterEntitySuccessProps } from "../../../core/action/GetAssetRegisterEntitiesCombination";
import AssetPositionAssetList from "../assetPositions/assetPositionAssetLinks/AssetPositionAssetList";
import InnerAssetPreview from "./InnerAssetPreview";
import VirtualAssetTab from "./VirtualAssetTab";
import GatewayHomepage from "./gateway/GatewayHomepage";
import InputOutputModuleRelayStateTab from "./input_output_module/InputOutputModuleRelayStateTab";
import AssetRfTab from "./meters/AssetRFTab";
import AssetTimeSeriesDataTableTab from "./meters/AssetTimeSeriesDataTableTab";
import MeterTimeSeriesCharts from "./meters/MeterTimeSeriesCharts";
import DeviceConfigurationTab from "./meters/configuration/DeviceConfigurationTab";
import WirelessReceiverConfigurationTab from "./meters/configuration/WirelessReceiverConfigurationTab";

interface State {
  init: boolean;
  asset?: Asset;
  assetPosition?: AssetPosition;
  location?: Location;
  loading?: boolean;
}

const renderAssetTabs = (
  asset_id: string,
  state: State,
  assetType: AssetType,
  refresh?: () => void,
): React.ReactElement => {
  return (
    <RoutedTabGroupInterface
      tabs={[
        state.asset && assetType === assetTypeEnum.input_output_module
          ? {
              tabName: "Relay State",
              tabContent: (
                <InputOutputModuleRelayStateTab
                  asset={state.asset as DeviceAssetOut}
                  refresh={refresh}
                />
              ),
              tabPath: "relay_state",
            }
          : undefined,
        {
          tabName: "Charts",
          tabContent: (
            <MeterTimeSeriesCharts device={state.asset as DeviceAssetOut} />
          ),
          tabPath: "charts",
          keepSearchString: true,
        },
        {
          tabName: "Data",
          tabContent: (
            <AssetTimeSeriesDataTableTab
              device={state.asset as DeviceAssetOut}
            />
          ),
          tabPath: "data",
          keepSearchString: true,
        },
        state.asset && assetType !== assetTypeEnum.gateway
          ? {
              tabName: "RF Data",
              tabContent: (
                <>
                  <AssetRfTab
                    device={state.asset}
                    assetPosition={state.assetPosition as AssetPosition}
                  />
                </>
              ),
              tabPath: "rf",
              userTypes: "superadmin",
              keepSearchString: true,
            }
          : undefined,
        state.asset && {
          tabName: "Configuration",
          tabPath: "configuration",
          tabContent: (
            <>
              {state.asset.asset_type === "wireless_receiver" ? (
                <WirelessReceiverConfigurationTab
                  asset={state.asset as DeviceAssetOut}
                />
              ) : (
                <DeviceConfigurationTab asset={state.asset as DeviceAssetOut} />
              )}
            </>
          ),
          keepSearchString: false,
        },
        state.asset &&
        assetType !== assetTypeEnum.input_output_module &&
        state.assetPosition &&
        !state.assetPosition.virtual_asset_position
          ? {
              tabName: "Virtual Assets",
              tabPath: "virtual_assets",
              tabContent: (
                <VirtualAssetTab
                  asset={state.asset as DeviceAssetOut}
                  assetPosition={state.assetPosition}
                />
              ),
              keepSearchString: false,
            }
          : undefined,
        {
          tabName: "Asset Position History",
          tabPath: "link",
          tabContent: (
            <>
              <Heading>Asset Position Asset History</Heading>
              {state.asset && (
                <AssetPositionAssetList
                  company_id={state.asset.company_id}
                  asset_id={state.asset.asset_id}
                  view={"assetPosition"}
                />
              )}
            </>
          ),
          userTypes: "superadmin",
          keepSearchString: false,
        },
        {
          tabName: "Events",
          tabContent: (
            <>
              <Heading>Event List</Heading>
              {state.asset && (
                <EventList
                  asset_id={state.asset.asset_id}
                  company_id={state.asset.company_id}
                />
              )}
            </>
          ),
          tabPath: "events",
          keepSearchString: false,
        },
      ]}
      topLevelPath={`/admin/asset_register/asset/${asset_id}`}
    />
  );
};

export default function AssetDisplay({
  assetPosition,
  location,
  asset,
  loading,
  refresh,
}: AssetRegisterEntitySuccessProps): React.ReactElement {
  const assigned: boolean = asset
    ? asset.asset_position_id !== undefined && asset.asset_position_id !== null
    : false;

  return (
    <>
      <OneCardPage
        headerTitle={"Asset Detail"}
        setPageTitle={() => `Asset ${asset?.serial_number}`}
        breadcrumbs={
          asset
            ? assigned
              ? location &&
                assetPosition && [
                  locationToBreadcrumb(location as Location),
                  assetPositionToBreadcrumb(assetPosition as AssetPosition),
                  assetToBreadcrumb(asset as Asset),
                ]
              : [
                  { name: "Assets", url: "/admin/assets/" },
                  assetToBreadcrumb(asset as Asset),
                ]
            : []
        }
        loading={loading}
      >
        {asset && (
          <>
            <InnerAssetPreview
              asset={asset}
              assetPosition={assetPosition}
              location={location}
              refresh={refresh}
            />
            {asset &&
              (asset.asset_type === "gateway" ? (
                <GatewayHomepage
                  gateway={asset as GatewayAssetOut}
                  refresh={refresh}
                />
              ) : (
                renderAssetTabs(
                  asset.asset_id,
                  { init: true, loading, asset, assetPosition },
                  asset.asset_type,
                  refresh,
                )
              ))}
          </>
        )}
      </OneCardPage>
    </>
  );
}
