import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { type JobDefinitionOut, jobStatusEnum } from "kubb";
import type React from "react";
import { useState } from "react";
import { UpdateButtonSpinner } from "../../components/Buttons/UpdateButton.tsx";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { useUpdateJob } from "../../hooks/updateEntity";

interface Props {
  job: JobDefinitionOut;
  refresh?: () => any;
}

const ChangeJobStateButton = (props: Props): React.ReactElement => {
  const { job } = props;

  const { loading, error, update } = useUpdateJob(job);

  const [submitting, setSubmitting] = useState(false);

  const active = job.status === jobStatusEnum.active;

  const switchState = () => {
    setSubmitting(true);
    update({
      ...job,
      status: active ? jobStatusEnum.disabled : jobStatusEnum.active,
    })
      .then(() => {
        props.refresh?.();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      <span className={"px-2"}>
        <Button
          className={"btn-sm"}
          color={active ? "red" : "green"}
          onClick={switchState}
          disabled={submitting}
        >
          {active ? "Set to disabled" : "Set to active"}
          <UpdateButtonSpinner
            icon={active ? faPause : faPlay}
            updating={loading}
          />
        </Button>
        <ErrorBanner error={error} />
      </span>
    </DisplayIfUserType>
  );
};

export default ChangeJobStateButton;
