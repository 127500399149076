import type { User } from "kubb";

export const isUserSuperAdmin = (user?: User): boolean => {
  return user?.user_type === "superadmin";
};
export const isUserPartnerAdmin = (user?: User): boolean => {
  if (user) {
    return user.companies.some(
      (c) =>
        c.company_type === "PARTNER" &&
        userHasTypeForCompany(user, c.company_id, "admin"),
    );
  }
  return false;
};
export const userHasTypeForCompany = (
  user: User,
  company_id: string,
  userTypes: string | string[],
): boolean => {
  const userCompany = user.companies?.filter((userCompany) => {
    return userCompany.company_id === company_id;
  });

  if (company_id === "NONE") {
    return isUserPartnerAdmin(user) || isUserSuperAdmin(user);
  }

  return (
    (userCompany &&
      userCompany?.length === 1 &&
      userTypes.includes(userCompany[0].user_type)) ||
    false
  );
};
