import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useField } from "formik";
import { isArray, isNull, isUndefined, startCase } from "lodash-es";
import type React from "react";
import Select, {
  type ActionMeta,
  type ClearIndicatorProps,
  components,
  type GroupBase,
  type MultiValueRemoveProps,
  type OnChangeValue,
  type OptionsOrGroups,
  type ValueContainerProps,
} from "react-select";
import type { DownChevronProps } from "react-select/dist/declarations/src/components/indicators";
import type { SetOptional } from "type-fest";
import NewLabelWrapper, { type LabelProps } from "../NewLabelWrapper";
import { inputStyles } from "../styles";
import MultiValueContainer from "./MultiValueContainer";

export type Value = string | number | boolean;
export type ValueList = Value | Value[];

export interface Option {
  label: string | number;
  value: Value;
}

export function flattenNestedLists(arr: Option[][]): Option[] {
  return arr.reduce((r: Option[], i: Option[]) => [...r, ...i], []);
}
export function isOption(option: Option | GroupBase<Option>): option is Option {
  return (option as Option).value !== undefined;
}

export type OptionsList = OptionsOrGroups<Option, GroupBase<Option>>;

export interface DropDownProps {
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export interface SelectComboProps extends DropDownProps {
  size?: "sm" | "md";
  name: string;
  label?: LabelProps;
  onChange: (
    newValue: OnChangeValue<Value, boolean>,
    actionMeta: ActionMeta<Option>,
  ) => void;
  isMulti?: boolean;
  options?: OptionsList;
  value: ValueList;
  error?: string;
  touched?: boolean;
}

/* TODO implement size and otherwise style this component */

export function stringListToOptions(lst: (string | undefined)[]): Option[] {
  return lst
    .filter((v) => v)
    .map((v): Option => {
      return {
        value: v as string,
        label: startCase(v),
      };
    });
}

export function isGroupBase(
  option: Option | GroupBase<Option>,
): option is GroupBase<Option> {
  return (option as GroupBase<Option>).options !== undefined;
}

function flattenGroupedOptions(options: OptionsList): Option[] {
  return options.reduce(
    (accumulator: Option[], value) =>
      accumulator.concat(isGroupBase(value) ? value.options : [value]),
    [],
  );
}

function valueToOptionsList(
  value: ValueList | null | undefined,
  options: Option[],
): Option[] | null | undefined {
  if (isNull(value)) {
    return null;
  }
  if (isUndefined(value)) {
    return undefined;
  }
  if (isArray(value)) {
    const filtered = options.filter((o) => value.indexOf(o.value) !== -1);
    if (filtered.length !== value.length) {
      console.error(
        `not all values are in the option list ${value} ${options}`,
      );
    }
    return filtered;
  } else {
    const filtered = options.filter(
      (o) => !isGroupBase(o) && value === o.value,
    );
    if (filtered.length === 0) {
      console.error(`Select value not found ${value} ${options}`);
    }
    return filtered;
  }
}

const DownChevron = (props: DownChevronProps) => {
  return (
    <components.DownChevron {...props}>
      <ChevronDownIcon />
    </components.DownChevron>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<Option>) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon />
    </components.MultiValueRemove>
  );
};

const ValueContainer = ({ children, ...rest }: ValueContainerProps<Option>) => {
  return (
    <>
      {rest.isMulti ? (
        <MultiValueContainer {...rest}>{children}</MultiValueContainer>
      ) : (
        <components.ValueContainer {...rest}>
          {children}
        </components.ValueContainer>
      )}
    </>
  );
};

const controlStyles = {
  base: "border-0 ring-gray-300 ring-1 rounded-lg",
  focus: "border-0 ring-1 ring-brand-light-600",
  nonFocus: "border-zinc-950/10 hover:border-zinc-950/20",
};
const placeholderStyles = clsx(inputStyles.base, inputStyles.placeholderText);
const selectInputStyles = clsx(inputStyles.base, inputStyles.text);
const valueContainerStyles = "";

const singleValueStyles = clsx(inputStyles.base, inputStyles.text);
const multiValueStyles =
  "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5 text-zinc-700 text-sm/6";
const multiValueLabelStyles = "leading-6 text-sm";
const multiValueRemoveStyles =
  "hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md w-4";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800 w-6";
const indicatorSeparatorStyles = "bg-zinc-950/10";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black w-6";
const menuStyles =
  "p-1 mt-2 border border-zinc-950/10 bg-white rounded-lg z-50";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "after:content-['√'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed bg-zinc-950/10 rounded-sm";

export const SelectCombo = (props: SelectComboProps): React.ReactElement => {
  const {
    label,
    error,
    name,
    value,
    options,
    isMulti,
    onChange,
    disabled,
    ...rest
  } = props;

  return (
    <NewLabelWrapper {...{ ...label, error: error }}>
      <Select
        unstyled={true}
        id={`select-${name}`}
        isMulti={isMulti}
        isDisabled={disabled}
        options={options}
        onChange={(
          v: OnChangeValue<Option, boolean>,
          m: ActionMeta<Option>,
        ) => {
          if (isArray(v)) {
            return onChange(
              v.map((item) => item.value),
              m,
            );
          } else {
            if (isNull(v)) {
              return onChange(v, m);
            } else {
              return onChange((v as Option).value, m);
            }
          }
        }}
        isClearable={isMulti}
        value={valueToOptionsList(
          value,
          options ? flattenGroupedOptions(options) : [],
        )}
        closeMenuOnSelect={!props.isMulti}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
          // On mobile, the label will truncate automatically, so we want to
          // override that behaviour.
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "visible",
            flexWrap: "nowrap",
          }),
          multiValue: (base) => ({
            ...base,
          }),
          control: (base) => ({
            ...base,
            transition: "none",
          }),
          menu: (base) => ({
            ...base,
            zIndex: 50,
          }),
          valueContainer: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "hidden",
            flexWrap: "nowrap",
          }),
        }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base,
              inputStyles.background,
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base,
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        components={{
          DownChevron,
          ClearIndicator,
          MultiValueRemove,
          ValueContainer,
        }}
        {...rest}
      />
    </NewLabelWrapper>
  );
};

export type SelectComboFieldProps = { fieldName: string } & Omit<
  SetOptional<SelectComboProps, "onChange">,
  "value" | "name"
>;

export function SelectComboField(
  props: SelectComboFieldProps,
): React.ReactElement {
  const { fieldName, onChange, ...rest } = props;
  const [field, _meta, helpers] = useField(fieldName);
  return (
    <>
      <SelectCombo
        name={fieldName}
        value={field.value}
        onChange={(v, m) => {
          helpers.setValue(v).then(() => {
            onChange?.(v, m);
          });
        }}
        {...rest}
      />
    </>
  );
}

// export function Example() {
//   const [query, setQuery] = useState('')
//   const [selectedPerson, setSelectedPerson] = useState<People[]>([])
//
//   const filteredPeople =
//     query === ''
//       ? people
//       : people.filter((person) => {
//           return person.name.toLowerCase().includes(query.toLowerCase())
//         })
//
//   return (
//     <Combobox
//       as="div"
//       value={selectedPerson}
//       onChange={(person) => {
//         setQuery('')
//         setSelectedPerson(person)
//       }}
//       multiple={true}
//     >
//       <Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Label>
//       <div className="relative mt-2">
//         <ComboboxInput
//           className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//           onChange={(event) => setQuery(event.target.value)}
//           onBlur={() => setQuery('')}
//           displayValue={(person: People[]) => person?.map((v) => v.name).join(', ')}
//         />
//         <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
//           <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
//         </ComboboxButton>
//
//         {filteredPeople.length > 0 && (
//           <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//             {filteredPeople.map((person) => (
//               <ComboboxOption
//                 key={person.id}
//                 value={person}
//                 className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
//               >
//                 <span className="block truncate group-data-[selected]:font-semibold">{person.name}</span>
//
//                 <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
//                   <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                 </span>
//               </ComboboxOption>
//             ))}
//           </ComboboxOptions>
//         )}
//       </div>
//     </Combobox>
//   )
// }
//
export default SelectCombo;
