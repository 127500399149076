import {
  type Cursor,
  type GetReportJobListReportGetQueryParams,
  type ReportExecutionOut,
  useGetReportJobListReportGet,
} from "kubb";
import GetListPaginatedList from "../../../components/Pagination/GetListPaginatedList.tsx";
import ReportExecutionContainer, {
  type ReportExecutionChildProps,
} from "./ReportExecutionContainer";
import ReportExecutionListRow from "./ReportExecutionListRow";

interface Props {
  company_id: string;
  scheme_id?: string;
  params: Omit<
    GetReportJobListReportGetQueryParams,
    "company_id" | "scheme_id"
  >;
  cursor?: Cursor;
}

export default function ReportExecutionList(props: Props) {
  return (
    <GetListPaginatedList
      useQuery={useGetReportJobListReportGet}
      params={{
        ...props.params,
        company_id: props.company_id,
        scheme_id: props.scheme_id,
      }}
      cursor={props.cursor || { max_items: 25 }}
      row={(v: ReportExecutionOut) => (
        <ReportExecutionContainer key={v.report_id} reportExecution={v}>
          {({ reportExecution, refresh }: ReportExecutionChildProps) => (
            <ReportExecutionListRow
              refresh={refresh}
              execution={reportExecution}
            />
          )}
        </ReportExecutionContainer>
      )}
    />
  );
}
