import type React from "react";

import SelectedScheme from "components/Scheme/SelectedScheme";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import JobList from "./JobList";

export const JobsListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"List All Scheduled Jobs"}
      breadcrumbs={[
        { url: "/admin/job", name: "Jobs" },
        { url: "/admin/job", name: "List" },
      ]}
      setPageTitle={schemeObjectPageTitle("Jobs")}
    >
      <SelectedScheme>{(props) => <JobList {...props} />}</SelectedScheme>
      <DisplayIfUserType userTypes={"admin"}>
        <CompanyNavLink
          to={{ pathname: "/admin/job/create" }}
          button={{ color: "brandDark" }}
        >
          Create New Job
        </CompanyNavLink>
      </DisplayIfUserType>
    </OneCardPage>
  );
};
