import { useFormikContext } from "formik";
import {
  type AssetUseCaseEnum,
  type GroupBy,
  type Period,
  type ReportView,
  groupByEnum,
  periodEnum,
  reportViewEnum,
} from "kubb";
import { get, isNil } from "lodash-es";
import type React from "react";
import type { OnChangeValue } from "react-select";
import * as Yup from "yup";
import AssetTypeSelectCombo, {
  meterOptions,
} from "../../Asset/AssetTypeSelectCombo";
import NewAssetUseCaseSelect from "../../Asset/NewAssetUseCaseSelect";
import PeriodSelectCombo from "../../DateTime/PeriodSelectCombo";
import { ButtonGroupField, type OptionsList } from "../../Forms/ButtonGroup";
import type { LabelProps } from "../../Forms/NewLabelWrapper";
import type { Value } from "../../Forms/SelectCombo/SelectCombo";
import LocationGroupSelectCombo from "../../Location/LocationGroupSelectCombo/LocationGroupSelectCombo";
import { Col, Container } from "../../Theme/grid";
import AssetAggregationSelectCombo from "../AssetAggregationSelectCombo/AssetAggregationSelectCombo";
import AssetParameterSelectCombo from "../AssetParameterSelectCombo/AssetParameterSelectCombo";
import {
  type AssetType,
  type AssetTypeList,
  getAggregations,
  getFields,
} from "../model";

export interface AggregatedTimeSeriesQuerySubFormProps {
  wide?: boolean;
  showErrors?: boolean;
  submitButton?: React.ReactNode;
  namespace: string;
  disabled?: boolean;
  showLocationGroupSelect?: boolean;
  showassetUseCaseEnum?: boolean;
  showGroup?: boolean;
  showGroupByScheme?: boolean;
  showGroupByGroup?: boolean;
}

export interface AggregatedTimeSeriesQuerySubFormValues {
  asset_type?: AssetTypeList;
  parameter: string;
  aggregation: string;
  period: Period;
  group?: string;
  view_by?: ReportView;
  group_by?: GroupBy;
  use_case?: AssetUseCaseEnum[];
}

export const AggregatedTimeSeriesQueryDefaultValues: AggregatedTimeSeriesQuerySubFormValues =
  {
    asset_type: meterOptions,
    parameter: "Energy (Heating)",
    aggregation: "last",
    period: periodEnum.DAILY,
    view_by: reportViewEnum.ASSET_POSITION,
  };

function handleAssetTypeChange(
  setParameter: (v: string | null) => Promise<any>,
  setAggregation: (v: string | null) => Promise<any>,
): (
  values: AggregatedTimeSeriesQuerySubFormValues,
  change: OnChangeValue<Value, boolean>,
) => Promise<any> {
  return (values, change) => {
    const [newParameter, newAggregation] = getNewParameterAndAggregation(
      values.parameter || null,
      values.aggregation || null,
      change as string[] | null,
    );
    return setAggregation(newAggregation).then(() =>
      setParameter(newParameter),
    );
  };
}

function getNewParameterAndAggregation(
  currentParameter: string | null,
  currentAggregation: string | null,
  newAssetTypeList: string[] | null,
): (string | null)[] {
  if (isNil(newAssetTypeList) || (newAssetTypeList as Value[]).length === 0) {
    return [null, null];
  } else {
    const newParameters = getFields(newAssetTypeList as AssetTypeList);
    if (currentParameter) {
      if (newParameters.indexOf(currentParameter) === -1) {
        return [newParameters[0], getAggregations(newParameters[0])[0]];
      } else {
        if (currentAggregation) {
          const newAggregations = getAggregations(currentParameter);
          if (newAggregations.indexOf(currentAggregation) === -1) {
            return [currentParameter, newAggregations[0]];
          }
        }
      }
    }
  }
  return [currentParameter, currentAggregation];
}

function handleParameterChange(
  setAggregation: (v: string | null) => Promise<any>,
): (
  values: AggregatedTimeSeriesQuerySubFormValues,
  change: OnChangeValue<Value, boolean>,
) => Promise<any> {
  return (values, change) => {
    const [_, newAggregation] = getNewParameterAndAggregation(
      change as string | null,
      values.aggregation || null,
      values.asset_type as string[] | null,
    );
    return setAggregation(newAggregation);
  };
}

export const AggregatedTimeSeriesQuerySubFormSchema = Yup.object().shape({
  period: Yup.string().nullable().required("Required"),
  asset_type: Yup.array()
    .of(Yup.string().required())
    .nullable()
    .min(1)
    .required("Required"),
  aggregation: Yup.string().nullable().required("Required"),
  parameter: Yup.string().nullable().required("Required"),
});

function AggregatedTimeSeriesQuerySubForm(
  props: AggregatedTimeSeriesQuerySubFormProps,
) {
  const {
    namespace,
    wide = false,
    showErrors = true,
    disabled = false,
  } = props;

  const labelDefaults: Partial<LabelProps> = { size: "sm", showErrors };

  const context = useFormikContext<{
    namespace: AggregatedTimeSeriesQuerySubFormValues;
  }>();

  const { setFieldValue } = context;

  const values: AggregatedTimeSeriesQuerySubFormValues = get(
    context.values,
    namespace,
  );

  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      {values && (
        <Container>
          <Col md={wide ? 6 : 12} sm={12}>
            <Container gap={3}>
              <Col sm={wide ? (props.showassetUseCaseEnum ? 8 : 12) : 12}>
                <AssetTypeSelectCombo
                  fieldName={withNamespace("asset_type")}
                  onChange={(change) => {
                    return handleAssetTypeChange(
                      (v) => setFieldValue(withNamespace("parameter"), v),
                      (v) => setFieldValue(withNamespace("aggregation"), v),
                    )(values, change);
                  }}
                  includeNonCreateables={false}
                  includeWirelessCluster={false}
                  allowUnknown={false}
                  isMulti={true}
                  disabled={disabled}
                  label={{ label: "Asset Type", ...labelDefaults }}
                />
              </Col>
              {props.showassetUseCaseEnum && (
                <Col sm={wide ? 4 : 12}>
                  <NewAssetUseCaseSelect
                    fieldName={withNamespace("use_case")}
                    label={{ label: "Asset Use Cases", size: "sm", showErrors }}
                    isMulti={true}
                  />
                </Col>
              )}
              <Col sm={wide ? 4 : 12}>
                <AssetParameterSelectCombo
                  fieldName={withNamespace("parameter")}
                  onChange={(change) => {
                    return handleParameterChange((v) =>
                      setFieldValue(withNamespace("aggregation"), v),
                    )(values, change);
                  }}
                  clearable={false}
                  disabled={
                    isNil(values?.asset_type) ||
                    (values.asset_type as AssetType[]).length === 0 ||
                    disabled
                  }
                  assetType={values.asset_type}
                  isMulti={false}
                  label={{ label: "Parameter", ...labelDefaults }}
                />
              </Col>
              <Col sm={wide ? 4 : 12}>
                <AssetAggregationSelectCombo
                  fieldName={withNamespace("aggregation")}
                  clearable={false}
                  isMulti={false}
                  label={{ label: "Aggregation", ...labelDefaults }}
                  assetParameter={values.parameter || ""}
                  disabled={isNil(values.parameter) || disabled}
                />
              </Col>
              <Col sm={wide ? 4 : 12}>
                <PeriodSelectCombo
                  fieldName={withNamespace("period")}
                  isMulti={false}
                  clearable={false}
                  disabled={disabled}
                  label={{ label: "Period", ...labelDefaults }}
                />
              </Col>
            </Container>
          </Col>
          <Col sm={12} md={wide ? 6 : 12}>
            <Container gap={3}>
              {(props.showLocationGroupSelect === undefined ||
                props.showLocationGroupSelect) && (
                <Col sm={wide ? 5 : 12} lg={wide ? 6 : 12}>
                  <LocationGroupSelectCombo
                    fieldName={withNamespace("group")}
                    isMulti={false}
                    clearable={false}
                    disabled={disabled}
                    label={{ label: "Group", ...labelDefaults }}
                  />
                </Col>
              )}
              <Col sm={wide ? 7 : 12} lg={wide ? 6 : 12}>
                {props.showGroup ? (
                  <ButtonGroupField
                    fieldName={withNamespace("group_by")}
                    label={{ label: "Group By", ...labelDefaults }}
                    disabled={disabled}
                    options={
                      [
                        { label: "None", value: groupByEnum.NONE },
                        props.showGroupByScheme
                          ? {
                              label: "Scheme",
                              value: groupByEnum.SCHEME,
                            }
                          : undefined,
                        props.showGroupByGroup
                          ? {
                              label: "Group",
                              value: groupByEnum.GROUP_ALL,
                            }
                          : undefined,
                      ].filter((v) => !isNil(v)) as OptionsList
                    }
                    fullWidth={true}
                  />
                ) : (
                  <ButtonGroupField
                    fieldName={withNamespace("view_by")}
                    label={{ label: "View By", ...labelDefaults }}
                    disabled={disabled}
                    options={[
                      { label: "Asset", value: reportViewEnum.ASSET },
                      {
                        label: "Asset Position",
                        value: reportViewEnum.ASSET_POSITION,
                      },
                    ]}
                    fullWidth={true}
                  />
                )}
              </Col>
              {props.submitButton}
            </Container>
          </Col>
        </Container>
      )}
    </>
  );
}

export default AggregatedTimeSeriesQuerySubForm;
