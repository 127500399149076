import {
  type GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest,
  type GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse,
  type GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams,
  type LatestTimeSeriesItem,
  useGetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost,
} from "kubb";
import type React from "react";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { useMutateGetData } from "../../../hooks/getData";
import type { LatestDataReportParams } from "./model";

export type LatestTimeSeriesResponse = Array<LatestTimeSeriesItem>;

export interface LatestTimeSeriesReportProps {
  error?: Error;
  data?: LatestTimeSeriesResponse;
  loading: boolean;
}

export interface LatestTimeSeriesReportRequest extends LatestDataReportParams {
  company_id: string;
  scheme_id: string;
}

const LatestTimeSeriesApiWrapper = (props: {
  request: LatestTimeSeriesReportRequest;
  children: (props: LatestTimeSeriesReportProps) => React.ReactNode;
}): React.ReactElement => {
  const { company_id, scheme_id, ...rest } = props.request;
  const { data, error, loading } = useMutateGetData<
    GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse,
    GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest,
    GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams
  >(useGetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost(), {
    company_id,
    scheme_id,
    ...rest,
  });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({ error: error || undefined, data, loading })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
};

export default LatestTimeSeriesApiWrapper;
