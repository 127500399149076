import { Form, Formik } from "formik";
import {
  type AssetRegisterAggregatedTimeSeriesResponse,
  assetTypeEnum,
  periodEnum,
} from "kubb";
import type { AggregatedReportRequest } from "kubb";
import type React from "react";
import { useState } from "react";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";
import type { HeatNetworkReportParams } from "./model";

interface Props {
  company_id?: string;
  scheme_id?: string;
  query: HeatNetworkReportParams;
}

export const SYNTHETIC_ENERGY_REPORT_AGGREGATIONS = [
  "sum_estimated_net_energy",
  "sum_estimated_forward_net_energy",
  "sum_estimated_forward_heating_energy",
  "sum_estimated_forward_cooling_energy",
  "sum_estimated_reverse_net_energy",
  "sum_estimated_reverse_heating_energy",
  "sum_estimated_reverse_cooling_energy",
];

const ENERGY_REPORT_COLS = [
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_net_energy",
    name: "Estimated Energy Net Change",
    omitCollapsed: false,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_forward_net_energy",
    name: "Estimated Forward Net Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_forward_heating_energy",
    name: "Estimated Forward Heating Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_forward_cooling_energy",
    name: "Estimated Forward Cooling Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_reverse_net_energy",
    name: "Estimated Reverse Net Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_reverse_heating_energy",
    name: "Estimated Reverse Heating Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "sum_estimated_reverse_cooling_energy",
    name: "Estimated Reverse Cooling Energy Change",
    omitCollapsed: true,
  },
  {
    parameter: "Energy (Heating)",
    aggregation: "difference",
    name: "Recorded Energy Change",
    omitCollapsed: false,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "difference",
    name: "Difference",
    omitCollapsed: false,
  },
  {
    parameter: "Synthetic Energy",
    aggregation: "difference_percentage",
    name: "Difference (%)",
    omitCollapsed: false,
  },
];

const addDifferenceValues = (
  data?: AssetRegisterAggregatedTimeSeriesResponse,
): AssetRegisterAggregatedTimeSeriesResponse | undefined => {
  if (data) {
    return {
      times: data.times,
      data: data.data.map((value) => {
        const { aggregations, ...asset } = value;

        const synthetic_total =
          (aggregations["Synthetic Energy"]
            .sum_estimated_net_energy[0] as number) || 0;
        const recorded_total =
          (aggregations["Energy (Heating)"].difference[0] as number) || 0;
        const difference = recorded_total - synthetic_total;
        const percentage =
          recorded_total !== 0
            ? Math.abs(difference / recorded_total) * 100
            : undefined;

        return {
          ...asset,
          aggregations: {
            "Synthetic Energy": {
              ...aggregations["Synthetic Energy"],
              difference: [difference],
              difference_percentage: [percentage as number],
            },
            "Energy (Heating)": aggregations["Energy (Heating)"],
          },
        };
      }),
    };
  }
};

const SyntheticEnergyReportPage = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const [expandedTable, setExpandedTable] = useState(false);

  const params = (): AggregatedReportRequest => {
    if (props.company_id && props.scheme_id) {
      return {
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        aggregations: [
          ...SYNTHETIC_ENERGY_REPORT_AGGREGATIONS.map((value) => {
            return {
              parameter: "Synthetic Energy",
              aggregation: value,
            };
          }),
          {
            parameter: "Energy (Heating)",
            aggregation: "difference",
          },
        ],
        asset_types: [
          assetTypeEnum.heat_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ],
        start_time: startDatetime.toISOString(),
        end_time: endDatetime.toISOString(),
        period: periodEnum.ALL,
        use_case: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const energy_report_cols = ENERGY_REPORT_COLS.filter((value) => {
    return expandedTable ? true : !value.omitCollapsed;
  });

  return (
    <>
      <Formik
        initialValues={{ expanded: false }}
        onSubmit={(x): void => {
          setExpandedTable(x.expanded);
        }}
      >
        {({ submitForm }): React.ReactElement => (
          <Form>
            <ButtonGroupField
              fieldName={"expanded"}
              label={{ label: "Expanded" }}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={async (): Promise<void> => {
                await submitForm();
              }}
            />
          </Form>
        )}
      </Formik>
      {props.company_id && props.scheme_id && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => {
            return (
              <AllPeriodReportTable
                data={addDifferenceValues(data)}
                columnDefinitions={energy_report_cols}
                startDatetime={startDatetime}
                endDatetime={endDatetime}
                downloadFilename={`${props.scheme_id}_synthetic_energy_report`}
              />
            );
          }}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default SyntheticEnergyReportPage;
