import type { Dayjs } from "dayjs";
import {
  type ParsedTimeSeriesResponse,
  type RawTimeSeriesResponse,
  timeSeriesDataProjectionEnum,
  useGetAssetTimeseriesDataAssetAssetIdTimeSeriesGet,
} from "kubb";
import type React from "react";
import { useGetDataNew } from "../../../../hooks/getData";
import {
  type DeviceTimeSeriesData,
  convertTimesToDayjs,
  extendDataToTimeRange,
  mapColumns,
} from "../../../../model/deviceTimeSeries";
import type { Asset } from "../../../../utils/object.tsx";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./model";

interface Props {
  device: Asset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesDataGetter = (props: Props): React.ReactElement => {
  const { device, searchParams } = props;
  const { asset_id, company_id, scheme_id } = device;
  const { start_datetime, end_datetime, projection } = searchParams;

  const { data, loading, error } = useGetDataNew<
    RawTimeSeriesResponse | ParsedTimeSeriesResponse
  >(() =>
    useGetAssetTimeseriesDataAssetAssetIdTimeSeriesGet(asset_id, {
      start_datetime: start_datetime?.format(),
      end_datetime: end_datetime?.format(),
      projection,
      company_id,
    }),
  );

  const timeseries: DeviceTimeSeriesData | undefined =
    data &&
    extendDataToTimeRange(
      data.columns,
      data.data,
      data.errors,
      "device_errors" in data ? data.device_errors : undefined,
      convertTimesToDayjs(data.times),
      start_datetime,
      end_datetime,
    );

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(
          timeseries,
          projection === timeSeriesDataProjectionEnum.raw,
        ),
        times: timeseries.times as Dayjs[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
};

export default AssetTimeSeriesDataGetter;
