import type { FTPConfiguration } from "kubb";
import type React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { yupRequiredString } from "../../../components/Forms/yupValidators";

export const FTPExportConfigurationSchema = Yup.object().shape({
  host: yupRequiredString,
  username: yupRequiredString,
  password: yupRequiredString,
  port: Yup.number(),
  TLS: Yup.boolean(),
  path: Yup.string(),
});

export const FTPExportInitialValues: FTPConfiguration = {
  host: "",
  port: 21,
  username: "",
  password: "",
  path: "/",
  TLS: true,
};

export default function FTPExportConfigurationForm({
  namespace,
  disabled = false,
}: {
  namespace: string;
  disabled?: boolean;
}): React.ReactElement {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>FTP Configuration</h4>
      <NewTextFormComponent
        fieldName={withNamespace("host")}
        label={{ label: "Host" }}
        disabled={disabled}
      />
      <NewTextFormComponent
        fieldName={withNamespace("port")}
        label={{ label: "Port" }}
        disabled={disabled}
      />
      <NewTextFormComponent
        fieldName={withNamespace("username")}
        label={{ label: "Username" }}
        disabled={disabled}
      />
      <NewTextFormComponent
        fieldName={withNamespace("password")}
        label={{ label: "Password" }}
        disabled={disabled}
      />
      <NewTextFormComponent
        fieldName={withNamespace("path")}
        label={{ label: "Remote path" }}
        disabled={disabled}
      />
    </>
  );
}
