import client from 'client'
import type {
  GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse,
  GetIntegrationFilesAssetPositionAssetPositionIdFilesGetPathParams,
  GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  GetIntegrationFilesAssetPositionAssetPositionIdFilesGet422,
} from '../../types/GetIntegrationFilesAssetPositionAssetPositionIdFilesGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey = (
  asset_position_id: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetPathParams['asset_position_id'],
  params?: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
) => [{ url: '/asset_position/:asset_position_id/files', params: { asset_position_id: asset_position_id } }, ...(params ? [params] : [])] as const

export type GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey = ReturnType<typeof getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey>

/**
 * @summary Get Integration Files
 * {@link /asset_position/:asset_position_id/files}
 */
export async function getIntegrationFilesAssetPositionAssetPositionIdFilesGet(
  asset_position_id: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetPathParams['asset_position_id'],
  params?: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse,
    ResponseErrorConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGet422>,
    unknown
  >({ method: 'GET', url: `/asset_position/${asset_position_id}/files`, params, ...requestConfig })
  return res
}

export function getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryOptions(
  asset_position_id: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetPathParams['asset_position_id'],
  params?: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey(asset_position_id, params)
  return queryOptions<
    ResponseConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse>,
    ResponseErrorConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGet422>,
    ResponseConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_position_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getIntegrationFilesAssetPositionAssetPositionIdFilesGet(asset_position_id, params, config)
    },
  })
}

/**
 * @summary Get Integration Files
 * {@link /asset_position/:asset_position_id/files}
 */
export function useGetIntegrationFilesAssetPositionAssetPositionIdFilesGet<
  TData = ResponseConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse>,
  TQueryData = ResponseConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse>,
  TQueryKey extends QueryKey = GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey,
>(
  asset_position_id: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetPathParams['asset_position_id'],
  params?: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryResponse>,
        ResponseErrorConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryKey(asset_position_id, params)

  const query = useQuery({
    ...(getIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryOptions(asset_position_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetIntegrationFilesAssetPositionAssetPositionIdFilesGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}