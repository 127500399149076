import type { CompanyUser } from "../../containers/users/users";
import DeletedLabel from "../Labels/DeletedLabel";
import { Badge } from "../Theme/badge";
import { Heading } from "../Theme/heading";

const UserTypeLabel = (props: { user: CompanyUser }) => {
  const { user } = props;

  const apiLabel = user.api_user ? <Badge color={"warning"}>API</Badge> : <></>;
  switch (user.user_type) {
    case "admin":
      return (
        <Heading>
          <Badge color={"success"}>Admin</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </Heading>
      );
    case "editor":
      return (
        <Heading>
          <Badge color={"warning"}>Editor</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </Heading>
      );
    case "none":
      return (
        <Heading>
          <Badge color={"danger"}>None</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </Heading>
      );

    default:
      return (
        <Heading>
          <Badge color={"brandLight"}>Viewer</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </Heading>
      );
  }
};

export default UserTypeLabel;
