import dayjs from "dayjs";
import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/admin/Admin";
import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "dayjs/locale/en-gb";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./reducers/auth";
import { ProfileProvider, useProfile } from "./reducers/profile";
import { AppContext, AppProvider } from "./reducers/reactStore";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

dayjs.locale("en-GB");

const App = (): React.ReactElement => {
  const { dispatch } = React.useContext(AppContext);
  const location = useLocation();

  React.useEffect(() => {
    dispatch({ type: "SET_REFERER", payload: location });
  }, [location, dispatch]);

  const userProfile = useProfile();
  return (
    <SentryRoutes>
      {userProfile && <Route path="/admin/*" element={<AdminLayout />} />}
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path={"*"} element={<Navigate replace to={"/auth/login"} />} />
    </SentryRoutes>
  );
};

export const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const RootApp = (element?: HTMLElement): void => {
  const root = createRoot(
    element || (document.getElementById("root") as HTMLElement),
  );
  root.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <AppProvider>
            <AuthProvider>
              <ProfileProvider>
                <App />
              </ProfileProvider>
            </AuthProvider>
          </AppProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>,
  );
};

export default RootApp;
