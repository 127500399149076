export const outputTypeEnum = {
  ELVACO_X109: 'ELVACO_X109',
  ELVACO_X110: 'ELVACO_X110',
  CUMULATIVE_DATA: 'CUMULATIVE_DATA',
  LATEST: 'LATEST',
  ALL_DATA: 'ALL_DATA',
  AGGREGATED_DATA: 'AGGREGATED_DATA',
  BILLING: 'BILLING',
} as const

export type OutputTypeEnum = (typeof outputTypeEnum)[keyof typeof outputTypeEnum]

export type OutputType = OutputTypeEnum