import client from 'client'
import type {
  CreateTariffInstanceTariffTariffIdInstancePostMutationRequest,
  CreateTariffInstanceTariffTariffIdInstancePostMutationResponse,
  CreateTariffInstanceTariffTariffIdInstancePostPathParams,
  CreateTariffInstanceTariffTariffIdInstancePostQueryParams,
  CreateTariffInstanceTariffTariffIdInstancePost422,
} from '../../types/CreateTariffInstanceTariffTariffIdInstancePost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createTariffInstanceTariffTariffIdInstancePostMutationKey = () => [{ url: '/tariff/{tariff_id}/instance' }] as const

export type CreateTariffInstanceTariffTariffIdInstancePostMutationKey = ReturnType<typeof createTariffInstanceTariffTariffIdInstancePostMutationKey>

/**
 * @summary Create Tariff Instance
 * {@link /tariff/:tariff_id/instance}
 */
export async function createTariffInstanceTariffTariffIdInstancePost(
  tariff_id: CreateTariffInstanceTariffTariffIdInstancePostPathParams['tariff_id'],
  data: CreateTariffInstanceTariffTariffIdInstancePostMutationRequest,
  params?: CreateTariffInstanceTariffTariffIdInstancePostQueryParams,
  config: Partial<RequestConfig<CreateTariffInstanceTariffTariffIdInstancePostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateTariffInstanceTariffTariffIdInstancePostMutationResponse,
    ResponseErrorConfig<CreateTariffInstanceTariffTariffIdInstancePost422>,
    CreateTariffInstanceTariffTariffIdInstancePostMutationRequest
  >({ method: 'POST', url: `/tariff/${tariff_id}/instance`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Tariff Instance
 * {@link /tariff/:tariff_id/instance}
 */
export function useCreateTariffInstanceTariffTariffIdInstancePost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateTariffInstanceTariffTariffIdInstancePostMutationResponse>,
      ResponseErrorConfig<CreateTariffInstanceTariffTariffIdInstancePost422>,
      {
        tariff_id: CreateTariffInstanceTariffTariffIdInstancePostPathParams['tariff_id']
        data: CreateTariffInstanceTariffTariffIdInstancePostMutationRequest
        params?: CreateTariffInstanceTariffTariffIdInstancePostQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateTariffInstanceTariffTariffIdInstancePostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createTariffInstanceTariffTariffIdInstancePostMutationKey()

  return useMutation<
    ResponseConfig<CreateTariffInstanceTariffTariffIdInstancePostMutationResponse>,
    ResponseErrorConfig<CreateTariffInstanceTariffTariffIdInstancePost422>,
    {
      tariff_id: CreateTariffInstanceTariffTariffIdInstancePostPathParams['tariff_id']
      data: CreateTariffInstanceTariffTariffIdInstancePostMutationRequest
      params?: CreateTariffInstanceTariffTariffIdInstancePostQueryParams
    }
  >({
    mutationFn: async ({ tariff_id, data, params }) => {
      return createTariffInstanceTariffTariffIdInstancePost(tariff_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}