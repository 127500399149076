import type React from "react";
import { Component } from "react";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { statusEnum } from "../../../kubb";
import AssetListFilterForm from "../assets/list/AssetListFilterForm";
import { AssetPositionListSearchParam } from "../assets/list/AssetListSearchParam.tsx";
import AssetPositionList from "./AssetPositionList";

const InnerAssetPositionListPage = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      <>
        <AssetListFilterForm
          includeNonCreateables={false}
          filterUnassignedField={"asset_id"}
          {...props}
        />
        <AssetPositionList {...props} />
      </>
    </SelectedScheme>
  );
};

class AssetPositionListPage extends Component {
  render(): React.ReactElement {
    return (
      <OneCardPage
        headerTitle={"Asset Position List"}
        breadcrumbs={[
          { url: "/admin/asset_position", name: "Asset Positions" },
          { url: "/admin/asset_position", name: "List" },
        ]}
        setPageTitle={schemeObjectPageTitle("Asset Positions")}
      >
        <AssetPositionListSearchParam
          initialState={{ status: [statusEnum.ACTIVE] }}
        >
          <InnerAssetPositionListPage />
        </AssetPositionListSearchParam>
      </OneCardPage>
    );
  }
}

export default AssetPositionListPage;
