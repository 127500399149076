import { faTag } from "@fortawesome/free-solid-svg-icons";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { getFilenameFromHeaders } from "components/utils/FileDownloads";
import { saveAs } from "file-saver";
import type React from "react";
import UpdateButton from "../../../components/Buttons/UpdateButton.tsx";
import { useCreateAssetTags } from "../../../hooks/createEntity.tsx";

export default function AssetRegisterToAsseTagButton(props: {
  company_id: string;
  scheme_id: string;
}): React.ReactElement {
  const { company_id, scheme_id } = props;
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <UpdateButton
        update={useCreateAssetTags()}
        updatedEntity={{ company_id, scheme_id }}
        text={"Create Asset Tags"}
        size={"md"}
        color={"brandLight"}
        icon={faTag}
        postUpdate={(response, headers) => {
          saveAs(new Blob([response]), getFilenameFromHeaders(headers));
        }}
      />
    </DisplayIfUserType>
  );
}
