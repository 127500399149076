import type { NewCompany, NewUser } from "kubb";
import type React from "react";
import { useContext } from "react";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import {
  useCreateCompany,
  useCreateCompanyAndUser,
} from "../../hooks/createEntity";
import { CompanyContext } from "../../reducers/company";
import CreateCompanyForm from "./CreateCompanyForm";

interface Props {
  onSubmit?: () => any;
}

export default function CreateCompanyContainer(
  props: Props,
): React.ReactElement {
  const { dispatch } = useContext(CompanyContext);
  const registerCompany = useCreateCompanyAndUser();
  const createCompany = useCreateCompany();

  const submit = (
    new_company: NewCompany,
    new_user?: NewUser,
  ): Promise<any> => {
    const p = new_user
      ? registerCompany.update({
          new_company,
          new_user,
        })
      : createCompany.update(new_company);
    return p.then(() => {
      dispatch({ type: "SET_STALE" });
      props.onSubmit?.();
    });
  };

  return (
    <>
      <CreateCompanyForm onSubmit={submit} />
      <ErrorBanner error={registerCompany.error || createCompany.error} />
    </>
  );
}
