import { camelCase, startCase, upperCase } from "lodash-es";

export function convertStringToOpenApiEnum<E, K extends string = string>(
  str: string,
  type: { [key in K]: E },
  uppercase = false,
): E | undefined {
  const val = (uppercase ? upperCase : startCase)(camelCase(str)).replace(
    / /g,
    "",
  );
  if (val in type) {
    return type[val as keyof typeof type];
  }
  return undefined;
}
