import client from 'client'
import type {
  GetIssuesStatsIssueStatsGetQueryResponse,
  GetIssuesStatsIssueStatsGetQueryParams,
  GetIssuesStatsIssueStatsGet422,
} from '../../types/GetIssuesStatsIssueStatsGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getIssuesStatsIssueStatsGetQueryKey = (params?: GetIssuesStatsIssueStatsGetQueryParams) =>
  [{ url: '/issue/stats' }, ...(params ? [params] : [])] as const

export type GetIssuesStatsIssueStatsGetQueryKey = ReturnType<typeof getIssuesStatsIssueStatsGetQueryKey>

/**
 * @summary Get Issues Stats
 * {@link /issue/stats}
 */
export async function getIssuesStatsIssueStatsGet(
  params?: GetIssuesStatsIssueStatsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetIssuesStatsIssueStatsGetQueryResponse, ResponseErrorConfig<GetIssuesStatsIssueStatsGet422>, unknown>({
    method: 'GET',
    url: `/issue/stats`,
    params,
    ...requestConfig,
  })
  return res
}

export function getIssuesStatsIssueStatsGetQueryOptions(
  params?: GetIssuesStatsIssueStatsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getIssuesStatsIssueStatsGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetIssuesStatsIssueStatsGetQueryResponse>,
    ResponseErrorConfig<GetIssuesStatsIssueStatsGet422>,
    ResponseConfig<GetIssuesStatsIssueStatsGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getIssuesStatsIssueStatsGet(params, config)
    },
  })
}

/**
 * @summary Get Issues Stats
 * {@link /issue/stats}
 */
export function useGetIssuesStatsIssueStatsGet<
  TData = ResponseConfig<GetIssuesStatsIssueStatsGetQueryResponse>,
  TQueryData = ResponseConfig<GetIssuesStatsIssueStatsGetQueryResponse>,
  TQueryKey extends QueryKey = GetIssuesStatsIssueStatsGetQueryKey,
>(
  params?: GetIssuesStatsIssueStatsGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetIssuesStatsIssueStatsGetQueryResponse>,
        ResponseErrorConfig<GetIssuesStatsIssueStatsGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getIssuesStatsIssueStatsGetQueryKey(params)

  const query = useQuery({
    ...(getIssuesStatsIssueStatsGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetIssuesStatsIssueStatsGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}