import React from "react";
import { useLocation } from "react-router-dom";
import { selectPropertyFromLocationHash } from "../../components/navigation/URL";
import { CompanyContext } from "../../reducers/company";
import { useProfile } from "../../reducers/profile";

export default function CompanySelectInit(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const location = useLocation();
  const profile = useProfile();

  const [init, setInit] = React.useState(false);
  const { state, dispatch } = React.useContext(CompanyContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies(state.stale): want the trigger to occur when the stale token changes
  React.useEffect(() => {
    const company_id = selectPropertyFromLocationHash("company", location.hash);
    if (company_id) {
      dispatch({ type: "SELECT_COMPANY_BY_ID", payload: company_id });
    } else {
      if (
        state.companies &&
        profile?.user_type !== "superadmin" &&
        state.companies.length >= 1
      ) {
        dispatch({ type: "SELECT_COMPANY", payload: state.companies[0] });
      }
    }
    setInit(true);
    // only want this to run first time it renders - detect inbound company selection
  }, [dispatch, location.hash, state.stale, profile, state.companies]);

  return <>{init && props.children}</>;
}
