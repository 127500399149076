import type React from "react";
import type { Asset } from "../../../../../utils/object.tsx";
import AssetDataSearchStringParser from "../AssetDataSearchStringParser";
import AssetTimeSeriesQualityGetter from "../AssetTimeSeriesQualityGetter";
import AssetDataQualityChart from "./AssetDataQualityChart";

interface Props {
  device: Asset;
}

const AssetDataQualityContainer = (props: Props): React.ReactElement => {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesQualityGetter
            device={props.device}
            searchParams={searchParams}
            render={(props) => <AssetDataQualityChart {...props} />}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
};

export default AssetDataQualityContainer;
