import RoutedTabGroupInterface from "components/TabGroup/RoutedTabGroupInterface";
import {
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY,
} from "components/Table/AssetRegisterData";
import AggregatedTimeSeriesQueryForm, {
  type AggregatedTimeSeriesQueryFormValues,
  mapTimeSeriesRequestParamsToFormValues,
} from "components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import type { NewTimeSeriesRequestParams } from "components/TimeSeries/model";
import CSVExportButton from "components/utils/CSVExport/CSVExportButton";
import TimeSeriesReportGraph from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportGraph";
import TimeSeriesReportTable from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportTable";
import { VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS } from "containers/report/vwartReport/VWARTReportView";
import dayjs from "dayjs";
import {
  type AssetRegisterAggregatedTimeSeriesResponse,
  type AssetRegisterItemAggregatedTimeSeries,
  reportViewEnum,
} from "kubb";
import { camelCase } from "lodash-es";
import type React from "react";
import type { TableRow } from "react-data-table-component";
import BetaAlert from "../../../components/Beta/BetaAlert";
import { ErrorBanner } from "../../Error/ErrorBanner";
import type { GetDataWrapperResult } from "../../MutateWrapper.tsx";
import BlockSpinner from "../../Spinners/BlockSpinner";
import type { BillingData } from "../../Table/model.ts";

interface AggregatedTimeSeriesDataReportViewProps {
  onSubmit: (v: AggregatedTimeSeriesQueryFormValues) => Promise<any>;
  request: NewTimeSeriesRequestParams;
  data: GetDataWrapperResult<AssetRegisterAggregatedTimeSeriesResponse>;
  company_id: string;
  scheme_id: string;
}

type P = TableRow & Omit<AssetRegisterItemAggregatedTimeSeries, "aggregations">;

export interface SingleAggregationTimeAssetData extends P {
  data: { [key: string]: number | undefined | string };
}

const mapReportData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  parameter: string,
  aggregation: string,
  includeAssetDetails = true,
): Array<SingleAggregationTimeAssetData> => {
  return data.data.map((assetData) => {
    const { aggregations, ...asset } = assetData;
    const assetOnlyDetails = { ...asset };
    assetOnlyDetails.asset_id = undefined;
    assetOnlyDetails.serial_number = undefined;
    assetOnlyDetails.install_date = undefined;
    assetOnlyDetails.uninstall_date = undefined;
    return {
      ...(includeAssetDetails ? asset : assetOnlyDetails),
      data: Object.fromEntries(
        data.times.map((e, i) => {
          const aggregationParameter =
            (aggregations[parameter] ?? {
              [aggregation]: [],
            })[aggregation] ?? [];
          return [dayjs(e).format(), aggregationParameter[i] ?? undefined];
        }),
      ),
    };
  });
};

export const aggregatedDataCSVExport = (
  data?: Array<SingleAggregationTimeAssetData | BillingData>,
  filterCols?: string[],
): (() => Promise<any[]>) => {
  return () =>
    new Promise((resolve, reject) => {
      if (data) {
        resolve(
          data.map((value) => {
            const { data, ...asset } = value;
            return {
              ...Object.fromEntries(
                Object.entries(asset)
                  .map(([k, v]) => {
                    return [camelCase(k), v];
                  })
                  .filter(([k, _v]) =>
                    filterCols ? filterCols.includes(k as string) : true,
                  ),
              ),
              ...data,
            };
          }),
        );
      } else {
        reject(new Error("No Data"));
      }
    });
};

export default function AggregatedTimeSeriesDataReportView(
  props: AggregatedTimeSeriesDataReportViewProps,
): React.ReactElement {
  const { aggregation, parameter, start_date, end_date } = props.request;
  const beta = VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS.includes(
    aggregation as string,
  );

  const { data, loading, error } = props.data;
  const showAssets = props.request.view_by !== reportViewEnum.ASSET_POSITION;

  const exportCols = showAssets
    ? DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER
    : DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY;

  return (
    <BlockSpinner loading={loading}>
      <AggregatedTimeSeriesQueryForm
        onSubmit={props.onSubmit}
        initialValues={mapTimeSeriesRequestParamsToFormValues(props.request)}
        wide={true}
        showErrors={false}
        showassetUseCaseEnum={true}
      />
      <div className={"w-full text-right"}>
        <CSVExportButton
          download={aggregatedDataCSVExport(
            data
              ? mapReportData(data, parameter, aggregation, showAssets)
              : undefined,
            exportCols,
          )}
          disabled={data === undefined}
          columnOrder={exportCols}
          camelCaseColumns={false}
          filename={`${props.scheme_id}_aggregated_${parameter}_${aggregation}`}
        />
      </div>
      {beta && <BetaAlert title={"Volume Weighted Aggregations"} />}
      {error && <ErrorBanner error={error} />}
      <RoutedTabGroupInterface
        tabs={[
          {
            tabContent: (
              <>
                {data && (
                  <TimeSeriesReportTable
                    reportData={mapReportData(data, parameter, aggregation)}
                    times={data.times.map((v) => dayjs(v))}
                    start_time={start_date}
                    end_time={end_date}
                    showAsset={showAssets}
                  />
                )}
              </>
            ),
            tabName: "Table",
            tabPath: "table",
          },
          {
            tabContent: (
              <>
                {data && (
                  <TimeSeriesReportGraph
                    times={data.times.map((v) => dayjs(v))}
                    data={mapReportData(data, parameter, aggregation)}
                    start_time={start_date}
                    end_time={end_date}
                  />
                )}
              </>
            ),
            tabName: "Graph",
            tabPath: "graph",
          },
        ]}
        topLevelPath={"/admin/report/time_series/aggregated"}
      />
    </BlockSpinner>
  );
}
