import { Heading } from "components/Theme/heading";
import { Form, Formik } from "formik";
import type {
  BasicAuthCredentials,
  GatewayAssetOut,
  PublicKeyCredentials,
} from "kubb";
import { appConfig } from "../../../../Config";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import { useUpdateAsset } from "../../../../hooks/updateEntity";

enum AuthTypes {
  basic_auth = "basic_auth",
  public_key = "public_key",
}

interface connectivitySettings {
  postUrl: string;
  sftpServer: string;
  asset_user: string;
  password?: string;
  authenticationType: AuthTypes;
  publicKey?: string;
}

function isCredentialsBasic(
  credentials: BasicAuthCredentials | PublicKeyCredentials,
): credentials is BasicAuthCredentials {
  return (credentials as BasicAuthCredentials).type === AuthTypes.basic_auth;
}

function isCredentialsPublicKey(
  credentials: BasicAuthCredentials | PublicKeyCredentials,
): credentials is PublicKeyCredentials {
  return (credentials as PublicKeyCredentials).type === AuthTypes.public_key;
}

function GatewayConnectionDisplay(props: { gateway: GatewayAssetOut }) {
  const { gateway } = props;

  const { update, loading, error } = useUpdateAsset(gateway);

  const isCMe2100 = gateway.model && gateway.model.indexOf("21") !== -1;

  const initialValues: connectivitySettings = {
    postUrl: isCMe2100
      ? `${appConfig().hes.cme2100}${gateway.company_id}`
      : `${appConfig().hes.cme}/${gateway.company_id}`,
    sftpServer: window.runConfig?.sftpServer || "unknown",
    asset_user: gateway.credentials.username || "",
    password: isCredentialsBasic(gateway.credentials)
      ? gateway.credentials.password
      : "",
    publicKey: isCredentialsPublicKey(gateway.credentials)
      ? gateway.credentials.public_key
      : "",
    authenticationType: gateway.credentials.type as AuthTypes,
  };

  const canSwitch: boolean = gateway.manufacturer === "evinox";

  function updatedGateway(
    formValues: connectivitySettings,
  ): BasicAuthCredentials | PublicKeyCredentials {
    switch (formValues.authenticationType) {
      case AuthTypes.basic_auth:
        return {
          username: gateway.credentials.username,
          type: formValues.authenticationType,
          password: formValues.password,
        } as BasicAuthCredentials;

      case AuthTypes.public_key:
        return {
          username: gateway.credentials.username,
          type: formValues.authenticationType,
          public_key: formValues.publicKey,
        } as PublicKeyCredentials;
      default:
        throw new Error("Unsupported authentication type");
    }
  }

  const submit = (formValues: connectivitySettings) => {
    update({
      ...gateway,
      credentials: updatedGateway(formValues),
    });
  };
  return (
    <>
      <Heading>Gateway Connectivity Settings</Heading>

      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ values }) => {
          const basicAuth: boolean = values.authenticationType === "basic_auth";

          return (
            <Form>
              <BlockSpinner loading={loading}>
                {canSwitch && (
                  <ButtonGroupField
                    fieldName={"authenticationType"}
                    label={{ label: "Credentials type" }}
                    options={[
                      {
                        value: "basic_auth",
                        label: "Basic",
                      },
                      {
                        value: "public_key",
                        label: "Key pair",
                      },
                    ]}
                  />
                )}

                {gateway.manufacturer === "elvaco" ? (
                  <NewTextFormComponent
                    fieldName={"postUrl"}
                    label={{ label: "Post URL" }}
                    staticValue
                    disabled
                    clipboard
                  />
                ) : (
                  <NewTextFormComponent
                    fieldName={"sftpServer"}
                    label={{ label: "SFTP Server" }}
                    staticValue
                    disabled
                    clipboard
                  />
                )}
                <NewTextFormComponent
                  fieldName={"asset_user"}
                  label={{ label: "Username" }}
                  staticValue
                  disabled
                  clipboard
                />
                {basicAuth ? (
                  <NewTextFormComponent
                    fieldName={"password"}
                    label={{ label: "Password" }}
                    staticValue
                    disabled
                    clipboard
                  />
                ) : (
                  <NewTextFormComponent
                    fieldName={"publicKey"}
                    label={{ label: "SSH Public Key" }}
                  />
                )}
                {gateway.manufacturer === "evinox" && (
                  <>
                    <span className={"float-right"}>
                      <Button
                        type={"submit"}
                        color={"brandLight"}
                        disabled={loading}
                      >
                        Update SSH Settings
                      </Button>
                    </span>
                    <ErrorBanner error={error} />
                  </>
                )}
              </BlockSpinner>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default GatewayConnectionDisplay;
