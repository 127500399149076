import type { AssetRegisterDataItemBaseWithAsset } from "kubb";
import type React from "react";
import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";
import ReceiverAssignmentButton from "./ReceiverAssignmentButton";

export function getReceiverAssignmentTableValue(
  receiver: AssetRegisterDataItemBaseWithAsset,
) {
  return (d: AssetRegisterDataItem): number => {
    if (receiver.asset_id) {
      const data = d[receiver.asset_id] as unknown as number[] | undefined;
      return data ? data[0] : Number.NEGATIVE_INFINITY;
    }
    return Number.NEGATIVE_INFINITY;
  };
}

export default function ReceiverAssignmentTableCell(
  receiver: AssetRegisterDataItemBaseWithAsset,
  initLoad = false,
) {
  return function Cell(row: AssetRegisterDataItem): React.ReactElement {
    const value = getReceiverAssignmentTableValue(receiver)(row);
    return (
      <>
        <ReceiverAssignmentButton
          assetPosition={row}
          receiverPositionId={receiver.asset_position_id}
          scheme_id={receiver.scheme_id}
          initLoad={initLoad}
        />
        <span
          style={{ width: "50px", textAlign: "right", paddingRight: "2px" }}
        >
          {value === Number.NEGATIVE_INFINITY ? "-" : value.toFixed(1)}
        </span>
      </>
    );
  };
}
