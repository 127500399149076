import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import {
  type AggregatedDataReportFormatInput,
  type AllDataReportConfigurationInput,
  type AllDataReportFormatInput,
  type BillingReportFormatInput,
  type Company,
  type CumulativeDataReportFormatInput,
  type ElvacoX109ReportFormatInput,
  type LatestDataReportFormat,
  type OutputType,
  type OutputTypeEnum,
  type ReportExecutionIn,
  type Scheme,
  outputTypeEnum,
} from "kubb";
import * as Yup from "yup";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import {
  SelectComboField,
  type Value,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "../../../components/Theme/button";
import AggregatedTimeSeriesQuerySubForm, {
  AggregatedTimeSeriesQueryDefaultValues,
} from "../../../components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQuerySubForm";
import AllDataReportConfigurationSubForm, {
  AllDataReportConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/AllDataReportConfigurationForm";
import BillingConfigurationSubForm, {
  BillingConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import X109ConfigurationSubForm, {
  X109ConfigurationInitialValues,
  X110ConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/X109ConfigurationForm";

interface FormValues
  extends Omit<
    ReportExecutionIn,
    "end_date" | "start_date" | "company_id" | "scheme_id"
  > {
  start_date: Dayjs;
  end_date: Dayjs;
}

export interface ReportConfigurationFormProps {
  company: Company;
  scheme?: Scheme;
  outputTypeFilter?: OutputTypeEnum[];
  create: (report: ReportExecutionIn) => Promise<any>;
}

export type ReportFormat =
  | BillingReportFormatInput
  | ElvacoX109ReportFormatInput
  | AllDataReportFormatInput
  | LatestDataReportFormat
  | CumulativeDataReportFormatInput
  | AggregatedDataReportFormatInput;

function ReportConfigurationForm(props: ReportConfigurationFormProps) {
  const locked = false;
  const {
    outputTypeFilter = [
      outputTypeEnum.LATEST,
      outputTypeEnum.ELVACO_X109,
      outputTypeEnum.ELVACO_X110,
      outputTypeEnum.ALL_DATA,
      outputTypeEnum.CUMULATIVE_DATA,
      outputTypeEnum.AGGREGATED_DATA,
    ],
  } = props;

  const defaultValuesForReport = (reportType: OutputTypeEnum): ReportFormat => {
    switch (reportType) {
      case outputTypeEnum.ELVACO_X109:
        return {
          ...X109ConfigurationInitialValues,
          format: outputTypeEnum.ELVACO_X109,
        };
      case outputTypeEnum.ELVACO_X110:
        return {
          ...X110ConfigurationInitialValues,
          format: outputTypeEnum.ELVACO_X110,
        };
      case outputTypeEnum.CUMULATIVE_DATA:
        return {
          ...BillingConfigurationInitialValues(),
          format: outputTypeEnum.CUMULATIVE_DATA,
        };
      case outputTypeEnum.ALL_DATA:
        return {
          ...AllDataReportConfigurationInitialValues,
          format: outputTypeEnum.ALL_DATA,
        };
      case outputTypeEnum.BILLING:
        return {
          format: outputTypeEnum.BILLING,
        } as unknown as BillingReportFormatInput; /* handle that paramater/aggregation ins't correct in the form */
      case outputTypeEnum.AGGREGATED_DATA:
        return {
          configuration: AggregatedTimeSeriesQueryDefaultValues,
          format: outputTypeEnum.AGGREGATED_DATA,
        } as unknown as AggregatedDataReportFormatInput; /* handle that paramater/aggregation ins't correct in the form */
      default:
        return {
          format: outputTypeEnum.LATEST,
          configuration: undefined,
        };
    }
  };

  const initialValues: FormValues = {
    start_date: dayjs().startOf("month"),
    end_date: dayjs().endOf("day").subtract(1, "day"),
    report_format: defaultValuesForReport(outputTypeFilter[0]),
  };

  const mapFormToReport = (
    reportType: OutputTypeEnum,
    values: any,
  ): ReportFormat => {
    const configuration = values.configuration;
    switch (reportType) {
      case "AGGREGATED_DATA":
        return {
          format: "AGGREGATED_DATA",
          configuration: {
            included_asset_types: configuration.asset_type,
            aggregation: {
              parameter: configuration.parameter,
              aggregation: configuration.aggregation,
            },
            period: configuration.period,
            view_by: configuration.view_by,
          },
        } as AggregatedDataReportFormatInput;
      case "ALL_DATA":
        return {
          format: outputTypeEnum.ALL_DATA,
          configuration: {
            data_format: configuration.data_format,
            file_type: configuration.file_type,
            included_asset_types: configuration.included_asset_types,
            output: configuration.output,
            time_filter: configuration.time_filter,
          } as AllDataReportConfigurationInput,
        };

      default:
        return values.reportFormat;
    }
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    props
      .create({
        report_format: mapFormToReport(
          values.report_format.format as unknown as OutputType,
          values.report_format,
        ),
        scheme_id: props.scheme?.scheme_id,
        company_id: props.company.company_id,
        start_date: values.start_date.format("YYYY-MM-DD"),
        end_date: values.end_date.format("YYYY-MM-DD"),
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        start_date: Yup.string().required(),
        end_date: Yup.string().required(),
      })}
    >
      {({ values, isSubmitting, setFieldValue, isValid }) => {
        return (
          <Form>
            <BlockSpinner loading={isSubmitting}>
              <div className={"m-w-xl"}>
                <div className={"pt-2"}>
                  <SelectComboField
                    fieldName={"report_format.format"}
                    label={{
                      label: "Report Format",
                      size: "sm",
                      showErrors: true,
                    }}
                    options={[
                      {
                        label: "Cumulative Data",
                        value: outputTypeEnum.CUMULATIVE_DATA,
                      },
                      {
                        label: "Aggregated Data",
                        value: outputTypeEnum.AGGREGATED_DATA,
                      },
                      {
                        label: "Latest Data",
                        value: outputTypeEnum.LATEST,
                      },
                      {
                        label: "Elvaco X109",
                        value: outputTypeEnum.ELVACO_X109,
                      },
                      {
                        label: "Elvaco X110",
                        value: outputTypeEnum.ELVACO_X110,
                      },
                      {
                        label: "All data",
                        value: outputTypeEnum.ALL_DATA,
                      },
                      {
                        label: "Billing",
                        value: outputTypeEnum.BILLING,
                      },
                    ].filter((v) => outputTypeFilter.includes(v.value))}
                    onChange={(event) => {
                      return setFieldValue(
                        "report_format",
                        defaultValuesForReport(
                          event as Value as OutputTypeEnum,
                        ),
                      );
                    }}
                    disabled={locked || outputTypeFilter.length === 1}
                  />
                </div>
                <div className={"py-2"}>
                  <DateRangePicker
                    label={{ label: "Report Date Range", size: "sm" }}
                    startDateName={"start_date"}
                    endDateName={"end_date"}
                  />
                </div>
                {values.report_format.format === outputTypeEnum.ELVACO_X109 && (
                  <X109ConfigurationSubForm
                    namespace={"report_format"}
                    disabled={locked}
                  />
                )}
                {values.report_format.format === outputTypeEnum.ELVACO_X110 && (
                  <X109ConfigurationSubForm
                    namespace={"report_format"}
                    disabled={locked}
                  />
                )}
                {values.report_format.format ===
                  outputTypeEnum.CUMULATIVE_DATA && (
                  <BillingConfigurationSubForm
                    namespace={"report_format"}
                    disabled={locked}
                  />
                )}
                {values.report_format.format === outputTypeEnum.ALL_DATA && (
                  <AllDataReportConfigurationSubForm
                    namespace={"report_format"}
                    disabled={locked}
                  />
                )}
                {values.report_format.format ===
                  outputTypeEnum.AGGREGATED_DATA && (
                  <AggregatedTimeSeriesQuerySubForm
                    namespace={"report_format.configuration"}
                    wide={false}
                    showErrors={true}
                    showLocationGroupSelect={false}
                    disabled={locked}
                  />
                )}
                <div className={"py-2"}>
                  <Button
                    color={"brandLight"}
                    disabled={!isValid}
                    type={"submit"}
                  >
                    Execute Report
                  </Button>
                </div>
              </div>
            </BlockSpinner>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReportConfigurationForm;
