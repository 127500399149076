import type React from "react";
import SelectedScheme from "../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import AlarmList from "./AlarmList";
import AlarmListFilterForm from "./AlarmListFilterForm";
import AlarmListSearchStringParser from "./AlarmListSearchStringParser.tsx";

const AlarmListPageInner = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      <AlarmList
        params={props.params}
        noDataRow={<h5>No alarms</h5>}
        showPagination={true}
      />
    </SelectedScheme>
  );
};

const AlarmListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Alarms"}
      breadcrumbs={[
        { url: "/admin/alarm", name: "Alarms" },
        { url: "/admin/alarm", name: "List" },
      ]}
      setPageTitle={schemeObjectPageTitle("Alarms")}
    >
      <AlarmListFilterForm />
      {/* TODO: alarm list page search */}
      <AlarmListSearchStringParser initialState={{ open_alarms_only: false }}>
        <AlarmListPageInner />
      </AlarmListSearchStringParser>
    </OneCardPage>
  );
};

export default AlarmListPage;
