export const bulkUpdateStatusEnum = {
  PREVIEW_GENERATING: 'PREVIEW_GENERATING',
  PREVIEW_CREATED: 'PREVIEW_CREATED',
  PREVIEW_FAILED: 'PREVIEW_FAILED',
  EXECUTION_STARTED: 'EXECUTION_STARTED',
  EXECUTION_COMPLETED: 'EXECUTION_COMPLETED',
  EXECUTION_FAILED: 'EXECUTION_FAILED',
} as const

export type BulkUpdateStatusEnum = (typeof bulkUpdateStatusEnum)[keyof typeof bulkUpdateStatusEnum]

export type BulkUpdateStatus = BulkUpdateStatusEnum