import client from 'client'
import type {
  GetLocationListLocationGetQueryResponse,
  GetLocationListLocationGetQueryParams,
  GetLocationListLocationGet422,
} from '../../types/GetLocationListLocationGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLocationListLocationGetQueryKey = (params?: GetLocationListLocationGetQueryParams) =>
  [{ url: '/location' }, ...(params ? [params] : [])] as const

export type GetLocationListLocationGetQueryKey = ReturnType<typeof getLocationListLocationGetQueryKey>

/**
 * @description Gets a list of locations
 * @summary Get Location List
 * {@link /location}
 */
export async function getLocationListLocationGet(
  params?: GetLocationListLocationGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetLocationListLocationGetQueryResponse, ResponseErrorConfig<GetLocationListLocationGet422>, unknown>({
    method: 'GET',
    url: `/location`,
    params,
    ...requestConfig,
  })
  return res
}

export function getLocationListLocationGetQueryOptions(
  params?: GetLocationListLocationGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getLocationListLocationGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetLocationListLocationGetQueryResponse>,
    ResponseErrorConfig<GetLocationListLocationGet422>,
    ResponseConfig<GetLocationListLocationGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLocationListLocationGet(params, config)
    },
  })
}

/**
 * @description Gets a list of locations
 * @summary Get Location List
 * {@link /location}
 */
export function useGetLocationListLocationGet<
  TData = ResponseConfig<GetLocationListLocationGetQueryResponse>,
  TQueryData = ResponseConfig<GetLocationListLocationGetQueryResponse>,
  TQueryKey extends QueryKey = GetLocationListLocationGetQueryKey,
>(
  params?: GetLocationListLocationGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetLocationListLocationGetQueryResponse>,
        ResponseErrorConfig<GetLocationListLocationGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLocationListLocationGetQueryKey(params)

  const query = useQuery({
    ...(getLocationListLocationGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetLocationListLocationGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}