import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { AxiosResponse } from "axios";
import clsx from "clsx";
import type React from "react";
import type { MutateEntityWrapperResult } from "../../hooks/updateEntity";
import ErrorModal from "../Error/ErrorModal";
import { Button, type ButtonSizingProps, type styles } from "../Theme/button";

export function UpdateButtonSpinner(props: {
  icon: IconProp;
  updating: boolean;
  withText?: boolean;
}) {
  return (
    <FontAwesomeIcon
      icon={props.updating ? faSpinner : props.icon}
      fixedWidth={true}
      className={clsx(props.withText && "mr-1")}
      spin={props.updating}
    />
  );
}

export interface UpdateEntityProps<TData, TBody = TData> {
  update: MutateEntityWrapperResult<TData, TBody>;
  postUpdate?: (
    d: TData,
    headers: AxiosResponse["headers"],
  ) => Promise<void> | void;
  showErrorModal?: boolean;
}

type ButtonProps<T> = ButtonSizingProps & {
  errorMessageBody?: React.ReactElement;
  updatedEntity: T;
  disabled?: boolean;
  color?: keyof typeof styles.colors;
} & (
    | {
        icon: IconProp;
        text?: never;
        children?: never;
      }
    | {
        icon?: IconProp;
        text: string;
        children?: never;
      }
    | {
        icon?: never;
        text?: never;
        children: React.ReactNode;
      }
  );

export type UpdateButtonProps<TData, TBody = TData> = UpdateEntityProps<
  TData,
  TBody
> &
  ButtonProps<TBody>;
export default function UpdateButton<TData, TBody = TData>(
  props: UpdateButtonProps<TData, TBody>,
): React.ReactElement {
  const {
    postUpdate,
    errorMessageBody,
    updatedEntity,
    update,
    disabled,
    icon,
    children,
    text,
    showErrorModal = true,
    ...buttonProps
  } = props;

  return (
    <>
      {showErrorModal && (
        <ErrorModal error={update.error} body={errorMessageBody} />
      )}
      <Button
        onClick={() =>
          update
            .update(updatedEntity)
            .then(([d, h]) => props.postUpdate?.(d, h))
        }
        disabled={update.loading || disabled || false}
        {...buttonProps}
      >
        <>
          {icon && (
            <UpdateButtonSpinner
              icon={icon}
              updating={update.loading}
              withText={text !== undefined}
            />
          )}
          {children || text}
        </>
      </Button>
    </>
  );
}
