import client from 'client'
import type { GetNoteListNoteGetQueryResponse, GetNoteListNoteGetQueryParams, GetNoteListNoteGet422 } from '../../types/GetNoteListNoteGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getNoteListNoteGetQueryKey = (params?: GetNoteListNoteGetQueryParams) => [{ url: '/note' }, ...(params ? [params] : [])] as const

export type GetNoteListNoteGetQueryKey = ReturnType<typeof getNoteListNoteGetQueryKey>

/**
 * @summary Get Note List
 * {@link /note}
 */
export async function getNoteListNoteGet(params?: GetNoteListNoteGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetNoteListNoteGetQueryResponse, ResponseErrorConfig<GetNoteListNoteGet422>, unknown>({
    method: 'GET',
    url: `/note`,
    params,
    ...requestConfig,
  })
  return res
}

export function getNoteListNoteGetQueryOptions(params?: GetNoteListNoteGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getNoteListNoteGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetNoteListNoteGetQueryResponse>,
    ResponseErrorConfig<GetNoteListNoteGet422>,
    ResponseConfig<GetNoteListNoteGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getNoteListNoteGet(params, config)
    },
  })
}

/**
 * @summary Get Note List
 * {@link /note}
 */
export function useGetNoteListNoteGet<
  TData = ResponseConfig<GetNoteListNoteGetQueryResponse>,
  TQueryData = ResponseConfig<GetNoteListNoteGetQueryResponse>,
  TQueryKey extends QueryKey = GetNoteListNoteGetQueryKey,
>(
  params?: GetNoteListNoteGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetNoteListNoteGetQueryResponse>, ResponseErrorConfig<GetNoteListNoteGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getNoteListNoteGetQueryKey(params)

  const query = useQuery({
    ...(getNoteListNoteGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetNoteListNoteGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}