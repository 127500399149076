import client from 'client'
import type {
  CreateNewSchemeSchemePostMutationRequest,
  CreateNewSchemeSchemePostMutationResponse,
  CreateNewSchemeSchemePostQueryParams,
  CreateNewSchemeSchemePost422,
} from '../../types/CreateNewSchemeSchemePost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewSchemeSchemePostMutationKey = () => [{ url: '/scheme' }] as const

export type CreateNewSchemeSchemePostMutationKey = ReturnType<typeof createNewSchemeSchemePostMutationKey>

/**
 * @summary Create New Scheme
 * {@link /scheme}
 */
export async function createNewSchemeSchemePost(
  data: CreateNewSchemeSchemePostMutationRequest,
  params?: CreateNewSchemeSchemePostQueryParams,
  config: Partial<RequestConfig<CreateNewSchemeSchemePostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewSchemeSchemePostMutationResponse,
    ResponseErrorConfig<CreateNewSchemeSchemePost422>,
    CreateNewSchemeSchemePostMutationRequest
  >({ method: 'POST', url: `/scheme`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Scheme
 * {@link /scheme}
 */
export function useCreateNewSchemeSchemePost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewSchemeSchemePostMutationResponse>,
      ResponseErrorConfig<CreateNewSchemeSchemePost422>,
      { data: CreateNewSchemeSchemePostMutationRequest; params?: CreateNewSchemeSchemePostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewSchemeSchemePostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewSchemeSchemePostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewSchemeSchemePostMutationResponse>,
    ResponseErrorConfig<CreateNewSchemeSchemePost422>,
    { data: CreateNewSchemeSchemePostMutationRequest; params?: CreateNewSchemeSchemePostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewSchemeSchemePost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}