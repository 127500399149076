export const eventTypesEnum = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  LINK: 'LINK',
  UNLINK: 'UNLINK',
  DELETE: 'DELETE',
  ASSET_SYNC_CONFIG_START: 'ASSET_SYNC_CONFIG_START',
  ASSET_SYNC_CONFIG_SUCCEEDED: 'ASSET_SYNC_CONFIG_SUCCEEDED',
  ASSET_SYNC_CONFIG_FAILED: 'ASSET_SYNC_CONFIG_FAILED',
  ASSET_SYNC_CONFIG_ERROR: 'ASSET_SYNC_CONFIG_ERROR',
  ASSET_SYNC_CONFIG_INFO: 'ASSET_SYNC_CONFIG_INFO',
  ASSET_SYNC_CONFIG_FINISHED: 'ASSET_SYNC_CONFIG_FINISHED',
  ASSET_FIRMWARE_UPDATE_SUCCEEDED: 'ASSET_FIRMWARE_UPDATE_SUCCEEDED',
  ASSET_FIRMWARE_UPDATE_FAILED: 'ASSET_FIRMWARE_UPDATE_FAILED',
  ASSET_ASSIGN_TO_COMPANY: 'ASSET_ASSIGN_TO_COMPANY',
  ASSET_STATE_CHANGE: 'ASSET_STATE_CHANGE',
  ASSET_STATE_CHANGE_REQUEST: 'ASSET_STATE_CHANGE_REQUEST',
  ASSET_STATE_CHANGE_START: 'ASSET_STATE_CHANGE_START',
  ASSET_STATE_CHANGE_ERROR: 'ASSET_STATE_CHANGE_ERROR',
  ASSET_STATE_CHANGE_SUCCEEDED: 'ASSET_STATE_CHANGE_SUCCEEDED',
  ASSET_SEND_SMS: 'ASSET_SEND_SMS',
  ASSET_SEND_TELNET: 'ASSET_SEND_TELNET',
  ASSET_MISSING_DATA_IDENTIFIED: 'ASSET_MISSING_DATA_IDENTIFIED',
  ASSET_MISSING_DATA_REQUESTED: 'ASSET_MISSING_DATA_REQUESTED',
  ASSET_FILE_PROCESSING_INFO: 'ASSET_FILE_PROCESSING_INFO',
  ALARM_OPENED: 'ALARM_OPENED',
  ALARM_CLOSED: 'ALARM_CLOSED',
  JOB_CREATED: 'JOB_CREATED',
  JOB_SCHEDULED: 'JOB_SCHEDULED',
  JOB_INFO: 'JOB_INFO',
  JOB_ERROR: 'JOB_ERROR',
  JOB_COMPLETED: 'JOB_COMPLETED',
  JOB_FAILED: 'JOB_FAILED',
  REPORT_CREATED: 'REPORT_CREATED',
  REPORT_SCHEDULED: 'REPORT_SCHEDULED',
  REPORT_STARTED: 'REPORT_STARTED',
  REPORT_COMPLETED: 'REPORT_COMPLETED',
  REPORT_ERROR: 'REPORT_ERROR',
  REPORT_FAILED: 'REPORT_FAILED',
  UPDATE_CREATED: 'UPDATE_CREATED',
  UPDATE_INFO: 'UPDATE_INFO',
  UPDATE_ERROR: 'UPDATE_ERROR',
  UPDATE_COMPLETED: 'UPDATE_COMPLETED',
  UPDATE_FAILED: 'UPDATE_FAILED',
} as const

export type EventTypesEnum = (typeof eventTypesEnum)[keyof typeof eventTypesEnum]

export type EventTypes = EventTypesEnum