import { Form, Formik, type FormikHelpers } from "formik";
import { type ApiUserIn, companyRoleEnum } from "kubb";
import type { CompanyRole } from "kubb";
import type React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import {
  yupMatchPassword,
  yupPassword,
  yupRequiredString,
} from "../../components/Forms/yupValidators";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Button } from "../../components/Theme/button";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { useCreateNewApiUser } from "../../hooks/createEntity";
import { companyObjectPageTitle } from "../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../reducers/company";

interface FormValues {
  clientId: string;
  userType: CompanyRole;
  clientSecret: string;
  confirmClientSecret: string;
}

const CreateApiUserForm = (): React.ReactElement => {
  const company_id = useRequiredSelectedCompanyId();

  const { update, error } = useCreateNewApiUser();

  const navigate = useNavigate();

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    update({
      client_id: values.clientId,
      client_secret: values.clientSecret,
      role: values.userType,
      company_id: company_id,
    } as ApiUserIn)
      .then(() => {
        resetForm();
        navigate({ pathname: "/admin/users" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    clientId: "",
    userType: companyRoleEnum.viewer,
    clientSecret: "",
    confirmClientSecret: "",
  };
  return (
    <OneCardPage
      headerTitle={"Create New Api Key"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users/api/create", name: "Create Api Key" },
      ]}
      showCompanyInBreadcrumbs={false}
      setPageTitle={companyObjectPageTitle("New Api Key")}
    >
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          clientId: yupRequiredString.min(10).max(30),
          userType: yupRequiredString,
          clientSecret: yupPassword(20),
          confirmClientSecret: yupMatchPassword("clientSecret"),
        })}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <h3 className="text-white mb-0">Add Gateway</h3>

              <NewTextFormComponent
                fieldName={"clientId"}
                label={{ label: "Client Id" }}
              />
              <ButtonGroupField
                fieldName={"userType"}
                label={{ label: "Role" }}
                options={[
                  { label: "admin", value: "admin" },
                  { label: "editor", value: "editor" },
                  { label: "viewer", value: "viewer" },
                ]}
              />
              <NewTextFormComponent
                fieldName={"clientSecret"}
                label={{ label: "Client Secret" }}
                type={"password"}
              />
              <NewTextFormComponent
                fieldName={"confirmClientSecret"}
                label={{ label: "Confirm Client Secret" }}
                type={"password"}
              />
              <Button
                type={"submit"}
                color={"brandLight"}
                disabled={isSubmitting || !isValid}
              >
                Add Key
              </Button>
              <ErrorBanner error={error} />
            </Form>
          </BlockSpinner>
        )}
      </Formik>
    </OneCardPage>
  );
};

export default CreateApiUserForm;
