import dayjs from "dayjs";
import type { AssetPositionAssetLinkOut } from "kubb";
import { isUndefined } from "lodash-es";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import AssetTypeHumanLabel from "../../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import PrivacyMode from "../../../../components/Text/PrivacyMode";
import { Badge } from "../../../../components/Theme/badge";
import { TableCell, TableRow } from "../../../../components/Theme/table";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../../../components/utils/HighlightNullAndOldDates";
import type { MutateEntityWrapperResult } from "../../../../hooks/updateEntity";
import UnlinkAssetPositionFromAssetButton from "../UnlinkAssetPositionFromAssetButton";
import AssetPositionAssetUpdateModal from "./AssetPositionAssetUpdateModal";

export interface AssetPositionAssetRowInnerProps {
  link: AssetPositionAssetLinkOut;
  updateEntity: MutateEntityWrapperResult<
    AssetPositionAssetLinkOut,
    AssetPositionAssetLinkOut
  >;
  refreshList?: () => void;
}
function AssetPositionAssetRow(
  props: AssetPositionAssetRowInnerProps & { view: "asset" | "assetPosition" },
) {
  const { link, refreshList, view } = props;

  const installedAlwaysText =
    !link.install_date && !link.uninstall_date ? "Forever" : "Not set";
  const canEdit = !link.cancelled || isUndefined(link.cancelled);
  return (
    <TableRow key={link.link_id} data-cy={"list-item"}>
      {view === "asset" ? (
        <>
          <TableCell>
            <AssetIdLink asset_id={link.asset_id} full={false} />
          </TableCell>
          <TableCell>{link.manufacturer}</TableCell>
          <TableCell>
            <PrivacyMode>{link.serial_number}</PrivacyMode>
          </TableCell>
          <TableCell>
            <AssetTypeHumanLabel assetType={link.asset_type} />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <AssetPositionIdLink
              asset_position_id={link.asset_position_id}
              full={true}
            />
          </TableCell>
          <TableCell>
            <PrivacyMode>{link.asset_position_customer_reference}</PrivacyMode>
          </TableCell>
          <TableCell>
            <AssetTypeHumanLabel assetType={link.asset_position_type} />
          </TableCell>
        </>
      )}
      <TableCell>
        {link.install_date
          ? formatDate(link.install_date)
          : installedAlwaysText}
        {canEdit && (
          <DisplayIfUserType userTypes={"superadmin"}>
            <AssetPositionAssetUpdateModal
              link={link}
              update={props.updateEntity}
              className={"mx-2"}
              postUpdate={refreshList}
            />
          </DisplayIfUserType>
        )}
      </TableCell>
      <TableCell>
        {link.uninstall_date
          ? formatDate(dayjs(link.uninstall_date).subtract(1, "minutes"))
          : installedAlwaysText}
        {canEdit && (
          <DisplayIfUserType userTypes={"superadmin"}>
            <AssetPositionAssetUpdateModal
              link={link}
              update={props.updateEntity}
              className={"mx-2"}
            />
          </DisplayIfUserType>
        )}
      </TableCell>
      <TableCell>
        {link.cancelled ? (
          <Badge color={"zinc"}>cancelled</Badge>
        ) : (
          <Badge color={"success"} className={"mx-2"}>
            active
          </Badge>
        )}
      </TableCell>
      <DisplayIfUserType userTypes={"admin"}>
        <TableCell>
          {!link.cancelled && (
            <UnlinkAssetPositionFromAssetButton
              assetPositionAssetLink={link}
              postUpdate={refreshList}
            />
          )}
        </TableCell>
      </DisplayIfUserType>
    </TableRow>
  );
}

AssetPositionAssetRow.whyDidYouRender = true;
export default AssetPositionAssetRow;
