import client from 'client'
import type {
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest,
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse,
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams,
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams,
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams,
  UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut422,
} from '../../types/UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationKey = () => [{ url: '/asset_position/link/{link_id}' }] as const

export type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationKey = ReturnType<
  typeof updateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationKey
>

/**
 * @summary Update Asset Position Asset Link
 * {@link /asset_position/link/:link_id}
 */
export async function updateAssetPositionAssetLinkAssetPositionLinkLinkIdPut(
  link_id: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams['link_id'],
  data: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest,
  params?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams,
  headers?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams,
  config: Partial<RequestConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse,
    ResponseErrorConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut422>,
    UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest
  >({ method: 'PUT', url: `/asset_position/link/${link_id}`, params, data, headers: { ...headers, ...requestConfig.headers }, ...requestConfig })
  return res
}

/**
 * @summary Update Asset Position Asset Link
 * {@link /asset_position/link/:link_id}
 */
export function useUpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse>,
      ResponseErrorConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut422>,
      {
        link_id: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams['link_id']
        data: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest
        params?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams
        headers?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams
      }
    >
    client?: Partial<RequestConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse>,
    ResponseErrorConfig<UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut422>,
    {
      link_id: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams['link_id']
      data: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest
      params?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams
      headers?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams
    }
  >({
    mutationFn: async ({ link_id, data, params, headers }) => {
      return updateAssetPositionAssetLinkAssetPositionLinkLinkIdPut(link_id, data, params, headers, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}