export const billableAssetsEnum = {
  HEAT_METER: 'HEAT_METER',
  HEATING_AND_COOLING_METER: 'HEATING_AND_COOLING_METER',
  GAS_METER: 'GAS_METER',
  COOLING_METER: 'COOLING_METER',
  ELECTRICITY_METER: 'ELECTRICITY_METER',
  WATER_METER: 'WATER_METER',
} as const

export type BillableAssetsEnum = (typeof billableAssetsEnum)[keyof typeof billableAssetsEnum]

export type BillableAssets = BillableAssetsEnum