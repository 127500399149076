import { OneCardPage } from "components/utils/OneCardPage";
import type React from "react";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import JobCreateForm from "./JobCreateForm";

const JobCreatePage = (): React.ReactElement => {
  const company_id = useSelectedCompany()?.company_id;
  const scheme_id = useSelectedScheme()?.scheme_id;
  return (
    <>
      {company_id && (
        <OneCardPage
          headerTitle={"Create New Data Job"}
          setPageTitle={schemeObjectPageTitle("Create New Job")}
        >
          <JobCreateForm company_id={company_id} scheme_id={scheme_id} />
        </OneCardPage>
      )}
    </>
  );
};

export default JobCreatePage;
