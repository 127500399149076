import client from 'client'
import type {
  CreateCompanyStreamingConfigurationStreamingPutMutationRequest,
  CreateCompanyStreamingConfigurationStreamingPutMutationResponse,
  CreateCompanyStreamingConfigurationStreamingPutQueryParams,
  CreateCompanyStreamingConfigurationStreamingPut422,
} from '../../types/CreateCompanyStreamingConfigurationStreamingPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createCompanyStreamingConfigurationStreamingPutMutationKey = () => [{ url: '/streaming' }] as const

export type CreateCompanyStreamingConfigurationStreamingPutMutationKey = ReturnType<typeof createCompanyStreamingConfigurationStreamingPutMutationKey>

/**
 * @description <h3>ALPHA</h3><p>Updates the streaming configuration for the company.</p>
 * @summary Create Company Streaming Configuration
 * {@link /streaming}
 */
export async function createCompanyStreamingConfigurationStreamingPut(
  data: CreateCompanyStreamingConfigurationStreamingPutMutationRequest,
  params?: CreateCompanyStreamingConfigurationStreamingPutQueryParams,
  config: Partial<RequestConfig<CreateCompanyStreamingConfigurationStreamingPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateCompanyStreamingConfigurationStreamingPutMutationResponse,
    ResponseErrorConfig<CreateCompanyStreamingConfigurationStreamingPut422>,
    CreateCompanyStreamingConfigurationStreamingPutMutationRequest
  >({ method: 'PUT', url: `/streaming`, params, data, ...requestConfig })
  return res
}

/**
 * @description <h3>ALPHA</h3><p>Updates the streaming configuration for the company.</p>
 * @summary Create Company Streaming Configuration
 * {@link /streaming}
 */
export function useCreateCompanyStreamingConfigurationStreamingPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateCompanyStreamingConfigurationStreamingPutMutationResponse>,
      ResponseErrorConfig<CreateCompanyStreamingConfigurationStreamingPut422>,
      { data: CreateCompanyStreamingConfigurationStreamingPutMutationRequest; params?: CreateCompanyStreamingConfigurationStreamingPutQueryParams }
    >
    client?: Partial<RequestConfig<CreateCompanyStreamingConfigurationStreamingPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createCompanyStreamingConfigurationStreamingPutMutationKey()

  return useMutation<
    ResponseConfig<CreateCompanyStreamingConfigurationStreamingPutMutationResponse>,
    ResponseErrorConfig<CreateCompanyStreamingConfigurationStreamingPut422>,
    { data: CreateCompanyStreamingConfigurationStreamingPutMutationRequest; params?: CreateCompanyStreamingConfigurationStreamingPutQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createCompanyStreamingConfigurationStreamingPut(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}