import type { DeviceAssetOut, DeviceConfigurationOutput } from "kubb";
import type React from "react";

const MBusAssetListContext = (props: {
  asset: DeviceAssetOut;
}): React.ReactElement => {
  const configuration: DeviceConfigurationOutput | undefined =
    props.asset.configuration &&
    Object.keys(props.asset.configuration).includes("mbus")
      ? (props.asset.configuration as DeviceConfigurationOutput)
      : undefined;

  if (configuration?.mbus?.addressing_mode === "primary") {
    return (
      <small className={"warning"}>
        Warning. MBus Primary Addressing Mode in use. Address{" "}
        {configuration.mbus?.primary_address}
      </small>
    );
  }
  return <></>;
};
export default MBusAssetListContext;
