export const gatewaySettingsTypeEnum = {
  ELVACO_DEFAULT: 'ELVACO_DEFAULT',
  ELVACO_CMI_WIRELESS_ONLY: 'ELVACO_CMI_WIRELESS_ONLY',
  ELVACO_AGGRESSIVE: 'ELVACO_AGGRESSIVE',
  ELVACO_SLOW: 'ELVACO_SLOW',
  CUSTOM: 'CUSTOM',
} as const

export type GatewaySettingsTypeEnum = (typeof gatewaySettingsTypeEnum)[keyof typeof gatewaySettingsTypeEnum]

export type GatewaySettingsType = GatewaySettingsTypeEnum