import { configure } from "axios-hooks";

interface Config {
  apiUrl: string;
  webSocketUrl: string;
  apiUrl2100: string;
  sftpServer: string;
  cognitoPool: string;
  cognitoClient: string;
  region: string;
  environment: string;
  release: string;
  sentry?: {
    dsn: string;
  };
  hes: {
    cme: string;
    cme2100: string;
  };
}

declare global {
  interface Window {
    runConfig?: Config;
  }
}

export function appConfig(): Config {
  if (window.runConfig === undefined) {
    throw new Error("No runtime config");
  }
  return window.runConfig as Config;
}

export const getApiUrl = (): string => {
  return import.meta.env?.VITE_API_URL
    ? import.meta.env?.VITE_API_URL
    : window.runConfig
      ? window.runConfig.apiUrl
      : "";
};

export const appUrl = (): string => {
  return import.meta.env?.PUBLIC_APP_URL;
};

export const getCognitoConfig = () => {
  return {
    REGION: window.runConfig ? window.runConfig.region : "",
    USER_POOL_ID:
      import.meta.env?.VITE_COGNITO_POOL_ID ||
      (window.runConfig ? window.runConfig.cognitoPool : ""),
    APP_CLIENT_ID:
      import.meta.env?.VITE_COGNITO_CLIENT_ID ||
      (window.runConfig ? window.runConfig.cognitoClient : ""),
  };
};

export const getWebsocketBaseUrl = (): string => {
  return (
    import.meta.env?.VITE_WEBSOCKET_URL ||
    (window.runConfig ? window.runConfig.webSocketUrl : "")
  );
};

const config = {
  apiGateway: {
    REGION: window.runConfig ? window.runConfig.region : "",
    URL: getApiUrl(),
  },
  cognito: getCognitoConfig(),
};

configure({ cache: false });

export default config;
