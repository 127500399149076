import ErrorOverlappingBanner from "components/Error/ErrorOverlappingBanner";
import type { WirelessReportRequest } from "kubb";
import {
  type AssetRegisterWirelessTimeSeriesResponse,
  type GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest,
  type GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse,
  type GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams,
  useGetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost,
} from "kubb";
import type React from "react";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { useMutateGetData } from "../../../hooks/getData";

export interface WirelessRFDataApiWrapperProps {
  error?: Error;
  data?: AssetRegisterWirelessTimeSeriesResponse;
  loading: boolean;
}

export default function WirelessRFDataApiWrapper(props: {
  request: WirelessReportRequest;
  children: (props: WirelessRFDataApiWrapperProps) => React.ReactNode;
}): React.ReactElement {
  const { error, data, loading } = useMutateGetData<
    GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse,
    GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest,
    GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams
  >(useGetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost(), {
    company_id: props.request.company_id as string,
    scheme_id: props.request.scheme_id,
    ...props.request,
  });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({ error: error || undefined, data, loading })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
}
