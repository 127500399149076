import { Form, Formik, type FormikHelpers } from "formik";
import {
  type FTPImporterParams,
  type GuruSystemsCSVIntegrationParams,
  type GuruSystemsIntegrationParams,
  type SFTPReceiverParams,
  assetTypeEnum,
} from "kubb";
import type * as React from "react";
import type { SingleValue } from "react-select";
import * as Yup from "yup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import {
  type Option,
  SelectComboField,
} from "../../../components/Forms/SelectCombo/SelectCombo";
import { yupRequiredString } from "../../../components/Forms/yupValidators";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "../../../components/Theme/button";
import {
  useCreateAssetPosition,
  useCreateLocation,
} from "../../../hooks/createEntity";
import GuruApiIntegrationForm from "./GuruAPIIntegrationForm";
import SftpReceiverIntegrationForm from "./SFTPReceiverIntegrationForm";

type Props = {
  company_id: string;
  scheme_id: string;
  postCreateActions?: () => any;
};

interface FormValues {
  address: string;
  integration:
    | GuruSystemsIntegrationParams
    | GuruSystemsCSVIntegrationParams
    | FTPImporterParams
    | SFTPReceiverParams;
}

export function IntegrationCreateForm(props: Props) {
  const createLocation = useCreateLocation(props.company_id);
  const createAssetPosition = useCreateAssetPosition(props.company_id);

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    createLocation
      .update({
        scheme_id: props.scheme_id,
        company_id: props.company_id,
        address: values.address,
      })
      .then(([location]) => {
        return createAssetPosition.update({
          scheme_id: props.scheme_id,
          company_id: props.company_id,
          location_id: location.location_id,
          asset_position_type: assetTypeEnum.api_integration,
          configuration: {
            integration: values.integration,
          },
        });
      })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    address: "",
    integration: {
      integration_type: "guru_systems",
      api_key: "",
      site_id: 0,
    },
  };
  const creating = createLocation.loading || createAssetPosition.loading;

  function configurationDetails(
    integrationType: string,
  ): React.ReactElement<any> {
    switch (integrationType) {
      case "guru_systems":
        return <GuruApiIntegrationForm namespace={"integration"} />;
      case "sftp_receiver":
        return <SftpReceiverIntegrationForm namespace={"integration"} />;
      case "guru_systems_csv":
        return <SftpReceiverIntegrationForm namespace={"integration"} />;
      default:
        return <></>;
    }
  }

  function defaultConfigurationValues(
    integrationType: string,
  ):
    | GuruSystemsIntegrationParams
    | GuruSystemsCSVIntegrationParams
    | FTPImporterParams
    | SFTPReceiverParams {
    switch (integrationType) {
      case "guru_systems":
        return {
          integrationType: "guru_systems",
          apiKey: "",
          siteId: 0,
        } as GuruSystemsIntegrationParams;
      case "sftp_receiver":
        return {
          integrationType: "sftp_receiver",
          sftpCredentials: { publicKey: "" },
        } as SFTPReceiverParams;
      case "guru_systems_csv":
        return {
          integrationType: "guru_systems_csv",
          sftpCredentials: { publicKey: "" },
        } as GuruSystemsCSVIntegrationParams;
      default:
        return {};
    }
  }

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        address: yupRequiredString,
      })}
    >
      {({ values, setFieldValue, isValid, isSubmitting }): JSX.Element => (
        <BlockSpinner loading={creating}>
          <Form data-cy={"integration-create"}>
            <h3 className="text-white mb-0">Add Data Integration</h3>
            <NewTextFormComponent
              fieldName={"address"}
              label={{ label: "Description" }}
            />
            <SelectComboField
              fieldName={"integration.integrationType"}
              label={{ label: "Integration Type" }}
              isMulti={false}
              options={[
                { value: "guru_systems", label: "Guru Systems (API)" },
                { value: "guru_systems_csv", label: "Guru Systems (CSV)" },
                { value: "sftp_receiver", label: "SFTP Receiver" },
                { value: "ftp_importer", label: "FTP Importer" },
              ]}
              onChange={(v) => {
                setFieldValue(
                  "integration",
                  defaultConfigurationValues(
                    (v as SingleValue<Option>)?.value as string,
                  ),
                );
              }}
            />
            {configurationDetails(
              values.integration.integration_type as string,
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Data Integration
            </Button>
            <ErrorBanner
              error={createLocation.error || createAssetPosition.error}
            />
          </Form>
        </BlockSpinner>
      )}
    </Formik>
  );
}
