import client from 'client'
import type {
  GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest,
  GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse,
  GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams,
  GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost422,
} from '../../types/GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const getLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationKey = () => [{ url: '/report/time_series/latest' }] as const

export type GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationKey = ReturnType<typeof getLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationKey>

/**
 * @summary Get Latest Time Series Data V2
 * {@link /report/time_series/latest}
 */
export async function getLatestTimeSeriesDataV2ReportTimeSeriesLatestPost(
  data?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest,
  params?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams,
  config: Partial<RequestConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse,
    ResponseErrorConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost422>,
    GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest
  >({ method: 'POST', url: `/report/time_series/latest`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Get Latest Time Series Data V2
 * {@link /report/time_series/latest}
 */
export function useGetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse>,
      ResponseErrorConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost422>,
      { data?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest; params?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams }
    >
    client?: Partial<RequestConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationKey()

  return useMutation<
    ResponseConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationResponse>,
    ResponseErrorConfig<GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPost422>,
    { data?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostMutationRequest; params?: GetLatestTimeSeriesDataV2ReportTimeSeriesLatestPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return getLatestTimeSeriesDataV2ReportTimeSeriesLatestPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}