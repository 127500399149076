import client from 'client'
import type {
  GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest,
  GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse,
  GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams,
  GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost422,
} from '../../types/GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const getSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationKey = () => [{ url: '/report/time_series/rf' }] as const

export type GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationKey = ReturnType<
  typeof getSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationKey
>

/**
 * @summary Get Summarised Time Series Report For Rf Data
 * {@link /report/time_series/rf}
 */
export async function getSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost(
  data?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest,
  params?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams,
  config: Partial<RequestConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse,
    ResponseErrorConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost422>,
    GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest
  >({ method: 'POST', url: `/report/time_series/rf`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Get Summarised Time Series Report For Rf Data
 * {@link /report/time_series/rf}
 */
export function useGetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse>,
      ResponseErrorConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost422>,
      {
        data?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest
        params?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams
      }
    >
    client?: Partial<RequestConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationKey()

  return useMutation<
    ResponseConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationResponse>,
    ResponseErrorConfig<GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost422>,
    {
      data?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostMutationRequest
      params?: GetSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPostQueryParams
    }
  >({
    mutationFn: async ({ data, params }) => {
      return getSummarisedTimeSeriesReportForRfDataReportTimeSeriesRfPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}