import classNames from "classnames";
import type { AssetPosition, AssetType, AssetTypeEnum } from "kubb";
import { startCase } from "lodash-es";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import type { Asset } from "../../utils/object.tsx";
import { Badge } from "../Theme/badge";

export const AssetTypeToHumanString = (asset_type: AssetType): string => {
  return startCase(asset_type);
};

const AssetTypeHumanLabel = (
  props: RequireExactlyOne<
    {
      asset?: Asset | AssetPosition;
      assetType?: AssetTypeEnum;
      iconLocation?: "left" | "right";
      className?: string;
    },
    "asset" | "assetType"
  >,
): React.ReactElement => {
  const assetType: AssetTypeEnum = props.assetType
    ? props.assetType
    : "asset_type" in props.asset
      ? props.asset.asset_type
      : props.asset.asset_position_type;
  const virtual: boolean | undefined = props.assetType
    ? false
    : "virtual_asset" in props.asset
      ? props.asset.virtual_asset
      : "virtual_asset_position" in props.asset
        ? props.asset.virtual_asset_position
        : false;
  const { iconLocation = "right" } = props;
  const icon = virtual && (
    <Badge
      color={"teal"}
      className={classNames({
        "px-2 text-white": true,
        "ml-2": iconLocation === "right",
        "mr-2": iconLocation === "left",
      })}
    >
      virtual
    </Badge>
  );
  return (
    <span className={props.className}>
      {iconLocation === "left" && icon}
      {AssetTypeToHumanString(assetType)}
      {iconLocation === "right" && icon}
    </span>
  );
};

export default AssetTypeHumanLabel;
