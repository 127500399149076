import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import {
  type AssetPosition,
  type AssetRegisterItem,
  type GatewayAssetOut,
  assetTypeEnum,
} from "kubb";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import { AssetRegisterListHeading } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import WirelessClusterGatewayAssignButton from "../../wirelessCluster/WirelessClusterGatewayAssignButton";
import WirelessClusterGatewayLeaveButton from "../../wirelessCluster/WirelessClusterGatewayLeaveButton";
import AssetRegisterItemContainer from "../AssetRegisterItemContainer";
import GatewayConfigurationDropdown from "./GatewayConfigurationDropdown";
import GatewayConnectivityReportDataRow from "./GatewayConnectivityReportDataRow";

export default function GatewayConnectivityReport(props: {
  company_id: string;
  scheme_id?: string;
  parent_asset_position_id?: string;
  clusterForAssignment?: AssetPosition;
  additionalFunctionButtons?: React.ReactElement;
}): React.ReactElement {
  // const isAdminUser = useUserHasRoles("admin");
  const { clusterForAssignment, ...rest } = props;
  return (
    <>
      <AssetRegisterList
        {...rest}
        row={GatewayConnectivityReportDataRowWithClusterAssignment(
          clusterForAssignment,
        )}
        header={
          <AssetRegisterListHeading
            column1={"Location - Position - Asset"}
            column2={"Status"}
            column3={"Details"}
            column1Size={"w-7/12 md:w-5/12"}
            column2Size={"w-5/12 md:w-3/12"}
            column3Size={"hidden md:w-4/12"}
          />
        }
        params={{ asset_type: [assetTypeEnum.gateway] }}
        createAssetTags={false}
      />
    </>
  );
}

function GatewayConnectivityReportDataRowWithClusterAssignment(
  clusterForAssignment?: AssetPosition,
) {
  const GatewayConnectivityReportDataRowWithClusterAssignmentInner = (props: {
    assetRegisterItem: AssetRegisterItem;
    showScheme?: boolean;
    refresh?: () => void;
  }) => {
    const { assetRegisterItem, ...rest } = props;
    return (
      <AssetRegisterItemContainer
        assetRegisterItem={assetRegisterItem}
        key={assetRegisterItem.asset_position.asset_position_id}
      >
        {({ assetRegisterItem, refresh }) => {
          return (
            <>
              {clusterForAssignment ? (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {clusterForAssignment &&
                      assetRegisterItem.asset_position
                        .parent_asset_position_id !==
                        clusterForAssignment.asset_position_id ? (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayAssignButton
                            clusterPosition={clusterForAssignment}
                            gatewayPosition={assetRegisterItem.asset_position}
                            postUpdate={props.refresh}
                          />
                        </DisplayIfUserType>
                      ) : (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayLeaveButton
                            gatewayPosition={assetRegisterItem.asset_position}
                            postUpdate={props.refresh}
                          />
                        </DisplayIfUserType>
                      )}
                    </>
                  )}
                />
              ) : (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {assetRegisterItem.asset && (
                        <GatewayConfigurationDropdown
                          size={"sm"}
                          color={"brandLight"}
                          gateway={assetRegisterItem.asset as GatewayAssetOut}
                          refresh={refresh}
                        />
                      )}
                    </>
                  )}
                />
              )}
            </>
          );
        }}
      </AssetRegisterItemContainer>
    );
  };
  return GatewayConnectivityReportDataRowWithClusterAssignmentInner;
}
