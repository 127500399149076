import type { GatewayAssetOut } from "kubb";
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getWebSocketURL } from "../../../../../actions/helpers/auth";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
} from "../../../../../components/Theme/table";
import StreamingGatewayDataTableRow from "./StreamingGatewayDataTableRow";

type StreamingGatewayDataRow = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

function removeDuplicateMessages(
  messages: StreamingGatewayDataRow[],
): StreamingGatewayDataRow[] {
  return messages.reduce(
    (
      accumulator: StreamingGatewayDataRow[],
      current: StreamingGatewayDataRow,
    ) => {
      if (!accumulator.find((item) => item[0] === current[0])) {
        return [...accumulator, current];
      }
      return accumulator;
    },
    [],
  );
}

function StreamingGatewayData(props: { gateway: GatewayAssetOut }) {
  const [messageHistory, setMessageHistory] = useState<
    StreamingGatewayDataRow[]
  >([]);
  const { sendMessage, lastMessage, readyState } =
    useWebSocket(getWebSocketURL);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prevState) =>
        removeDuplicateMessages([
          ...(JSON.parse(lastMessage.data) as StreamingGatewayDataRow[]),
          ...prevState,
        ]),
      );
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(
        JSON.stringify({
          action: "GetGatewayData",
          company_id: props.gateway.company_id,
          asset_id: props.gateway.asset_id,
        }),
      );
    }
  }, [
    readyState,
    sendMessage,
    props.gateway.company_id,
    props.gateway.asset_id,
  ]);

  return (
    <>
      {readyState !== ReadyState.OPEN && (
        <>Connection: {ReadyState[readyState]}</>
      )}
      <Table>
        <TableHead>
          <TableHeader>Timestamp</TableHeader>
          <TableHeader>Manufacturer</TableHeader>
          <TableHeader>Secondary Address</TableHeader>
          <TableHeader>Medium</TableHeader>
          <TableHeader>Version</TableHeader>
          <TableHeader>RSSI</TableHeader>
          <TableHeader>Data</TableHeader>
        </TableHead>
        <TableBody>
          {messageHistory.map((message) => (
            <StreamingGatewayDataTableRow
              key={message[0]}
              date={message[2]}
              data={message[3]}
              secondaryAddress={message[7]}
              manufacturer={message[4]}
              medium={message[5]}
              version={message[6]}
              rssi={message[8]}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default StreamingGatewayData;
