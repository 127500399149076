import { energyUnitsEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

const EnergyUnitSelect = (
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement => {
  const options: Option[] = [
    { label: "kWh", value: energyUnitsEnum.kWh },
    { label: "Wh", value: energyUnitsEnum.Wh },
    { label: "MWh", value: energyUnitsEnum.MWh },
  ];

  return <SelectComboField {...props} options={options} />;
};

export default EnergyUnitSelect;
