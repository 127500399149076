import dayjs from "dayjs";
import {
  type AllDataReportConfigurationInput,
  type ElvacoX109ReportConfiguration,
  type ReportExecutionOut,
  outputTypeEnum,
} from "kubb";
import { useEffect, useState } from "react";
import { StackedListTwoColumnItem } from "../../../components/Theme/stacked-list";
import ReportExecutionArtifactsDownloadButton from "./ReportExecutionArtifactsDownloadButton";
import ReportExecutionCalculatingBadge from "./ReportExecutionCalculatingBadge";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { lowerCase, startCase } from "lodash-es";

dayjs.extend(advancedFormat);

export default function ReportExecutionListRow(props: {
  execution: ReportExecutionOut;
  refresh: () => void;
}) {
  const { execution, refresh } = props;
  const calculating = execution.status === "CALCULATING";
  const [wasCalculating, _0] = useState<boolean>(calculating);

  useEffect(() => {
    if (calculating) {
      const timer = setTimeout(refresh, 10000);
      return () => clearTimeout(timer);
    }
  }, [calculating, refresh]);

  function description(execution: ReportExecutionOut): string {
    switch (execution.report_format.format) {
      case outputTypeEnum.ELVACO_X109:
        return `Time Filter: ${
          (
            execution.report_format
              .configuration as ElvacoX109ReportConfiguration
          ).time_filter
        }`;
      case outputTypeEnum.ELVACO_X110:
        return `Time Filter: ${
          (
            execution.report_format
              .configuration as ElvacoX109ReportConfiguration
          ).time_filter
        }`;
      case outputTypeEnum.ALL_DATA:
        return `Time Filter: ${
          (
            execution.report_format
              .configuration as AllDataReportConfigurationInput
          ).time_filter
        }`;

      default:
        return "";
    }
  }

  const name = startCase(lowerCase(execution.report_format.format));
  const dates = `${
    execution.start_date
      ? dayjs(execution.start_date).format("Do MMM YYYY")
      : dayjs(execution.end_date).format("Do MMM YYYY")
  } - ${dayjs(execution.end_date).format("Do MMM YYYY")}`;

  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          <div className={"inline-block w-60"}>
            {name}
            <ReportExecutionCalculatingBadge
              execution={execution}
              wasCalculating={wasCalculating}
              className={"px-2 mx-2"}
            />
          </div>
          <div className={"inline-block w-60"}>{dates}</div>
          <div className={"inline-block w-48 "}>{description(execution)}</div>
          <div className={"inline-block italic text-gray-600"}>
            Created: {dayjs(execution.created_at).format("Do MMM YYYY HH:mm")}
          </div>
        </div>
      }
      column2={<ReportExecutionArtifactsDownloadButton execution={execution} />}
      column1Size={"sm:w-11/12"}
      column2Size={"sm:w-1/12"}
    />
  );
}
