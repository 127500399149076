import GetListPaginatedList from "components/Pagination/GetListPaginatedList";
import PaginatedApiToCSVExportButton from "components/utils/CSVExport/PaginatedApiToCSVExportButton";
import dayjs from "dayjs";
import {
  type AssetRegisterItem,
  getAssetRegisterListAssetRegisterGet,
} from "kubb";
import {
  type GetAssetRegisterListAssetRegisterGetQueryParams,
  useGetAssetRegisterListAssetRegisterGet,
} from "kubb";
import React from "react";
import type { Asset } from "../../../utils/object.tsx";
import AssetRegisterListItem, {
  AssetRegisterListHeading,
} from "./AssetRegisterListItem";
import AssetRegisterToAsseTagButton from "./AssetRegisterToAssetTagButton";

interface Props {
  company_id: string;
  scheme_id?: string;
  params: Omit<
    GetAssetRegisterListAssetRegisterGetQueryParams,
    "companyId" | "schemeId"
  >;
  row?: (props: {
    showScheme?: boolean;
    assetRegisterItem: AssetRegisterItem;
    // index: number;
    refresh?: () => void;
  }) => React.ReactElement;
  header?: React.ReactElement;
  download?: boolean;
  createAssetTags?: boolean;
  showLocation?: boolean;
  lastChange?: Date;
  additionalFunctionButtons?: React.ReactElement;
}

const assetToAssetRegisterExportDict = (asset: Asset): any => {
  const {
    asset_id,
    serial_number,
    manufacturer,
    model,
    type,
    parent_asset_id,
    version,
    last_updated: _0,
    created_at: _1,
    asset_type: _2,
    asset_position_id: _3,
    scheme_id: _4,
    company_id: _5 /* pull this row out so not exported*/,
    ...rest
  } = asset;

  if ("credentials" in rest) {
    (rest as any).credentials = undefined;
  }

  return {
    asset_id,
    serial_number,
    manufacturer,
    model,
    type,
    parent_asset_id,
    version,
    asset_properties: rest,
  };
};

const mapAssetRegisterItemForCSV = (
  assetRegisterItem: AssetRegisterItem,
): any => {
  const { location, asset_position, asset } = assetRegisterItem;

  const { company_id, scheme_id, address, group, location_id } = location;
  const {
    asset_position_type,
    customer_reference,
    parent_asset_position_id,
    asset_position_id,
    use_case,
  } = asset_position;
  const assetProperties = asset ? assetToAssetRegisterExportDict(asset) : {};
  return {
    company_id,
    scheme_id,
    address,
    location_id,
    group,
    location_customer_reference: location.customer_reference,
    asset_position_type,
    customer_reference,
    parent_asset_position_id,
    asset_position_id,
    use_case,
    ...assetProperties,
  };
};

export default function AssetRegisterList(props: Props): React.ReactElement {
  const {
    company_id,
    scheme_id,
    row,
    header,
    download = true,
    createAssetTags = true,
    showLocation = true,
    additionalFunctionButtons,
  } = props;
  const showScheme = scheme_id === undefined;
  const rowFunction = (
    item: AssetRegisterItem,
    // index: number,
    // refresh?: () => void
  ): React.ReactElement => {
    const Row = row ? row : AssetRegisterListItem;
    return (
      <Row
        assetRegisterItem={item}
        showScheme={showScheme}
        showLocation={showLocation}
      />
    );
  };

  const Header = header ? header : <AssetRegisterListHeading />;
  const filtered = true;

  const filename =
    `${company_id}_${scheme_id ? `${scheme_id}_` : ""}` +
    `${filtered ? "filtered_" : ""}asset_register`;

  const params = { ...props.params, company_id, scheme_id };
  return (
    <GetListPaginatedList
      params={params}
      lastChange={dayjs(props.lastChange)}
      cursor={{ max_items: 25 }}
      header={Header}
      row={rowFunction}
      tableClassName={"list-group-flush"}
      data-cy={"asset-register-list"}
      useQuery={useGetAssetRegisterListAssetRegisterGet}
      additionalFunctionButtons={
        <div className={"flex gap-2"}>
          {download && (
            <PaginatedApiToCSVExportButton
              params={params}
              useQuery={getAssetRegisterListAssetRegisterGet}
              mapFunction={mapAssetRegisterItemForCSV}
              filename={filename}
              camelCaseColumns={false}
            />
          )}
          {props.scheme_id && createAssetTags && (
            <AssetRegisterToAsseTagButton
              company_id={props.company_id}
              scheme_id={props.scheme_id}
            />
          )}
          {additionalFunctionButtons &&
            React.cloneElement(additionalFunctionButtons)}
        </div>
      }
    />
  );
}
