import type { WirelessSurveyDataCollectorOut } from "kubb";
import type { WirelessSurveyOut } from "kubb";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Heading } from "../../../components/Theme/heading";
import WirelessSurveyDataCollectorCreateForm from "./WirelessSurveyDataCollectorCreateForm";
import WirelessSurveyDataCollectorsList from "./WirelessSurveyDataCollectorsList";

export default function WirelessSurveyDataCollectorsTab(props: {
  survey: WirelessSurveyOut;
  dataCollectors?: WirelessSurveyDataCollectorOut[];
  loading: boolean;
  refresh?: () => any;
}) {
  const { dataCollectors, loading, survey } = props;
  return (
    <>
      <BlockSpinner loading={loading}>
        {!loading && (
          <>
            <Heading>Data Collectors</Heading>
            <WirelessSurveyDataCollectorsList
              dataCollectors={dataCollectors || []}
              refresh={props.refresh}
            />

            <Heading>Add New Data Collector</Heading>
            <WirelessSurveyDataCollectorCreateForm
              survey={survey}
              refresh={props.refresh}
            />
          </>
        )}
      </BlockSpinner>
    </>
  );
}
