import type React from "react";
import type { Asset } from "utils/object";
import type { DeviceAssetOut, GatewayAssetOut } from "../../../../kubb";
import GatewayListContent from "../gateway/GatewayListContent";
import MBusAssetListContext from "../meters/MBusAssetListContext";
import SimCardListContent from "../sim_cards/SimCardListContent";

export default function DeviceSpecificListContent(props: {
  asset: Asset;
  refreshElement: () => any;
}): React.ReactElement {
  switch (props.asset.asset_type) {
    case "sim_card":
      return (
        <SimCardListContent
          asset={props.asset}
          refresh={props.refreshElement}
        />
      );
    case "gateway":
      return <GatewayListContent asset={props.asset as GatewayAssetOut} />;
    default:
      return <MBusAssetListContext asset={props.asset as DeviceAssetOut} />;
  }
}
