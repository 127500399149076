import { ErrorBanner } from "components/Error/ErrorBanner";
import dayjs from "dayjs";
import {
  type InputOutputModuleStateInput,
  type RelayStateCommand,
  type RelayStateEnum,
  actionTypesEnum,
  relayStateCommandEnum,
  relayStateEnum,
} from "kubb";
import type React from "react";
import { useEffect, useState } from "react";
import type { Asset } from "utils/object";
import { Button } from "../../../../components/Theme/button";
import { useCreateAssetAction } from "../../../../hooks/createEntity";

export const getRefreshDelay = (
  state: InputOutputModuleStateInput,
): number | undefined => {
  if (
    state.relay_state &&
    (
      [
        relayStateEnum.SWITCHING_ON,
        relayStateEnum.SWITCHING_OFF,
      ] as RelayStateEnum[]
    ).includes(state.relay_state)
  ) {
    const now = dayjs();
    const update = state.last_update ? dayjs(state.last_update) : undefined;
    if (update !== undefined) {
      const diff = now.valueOf() - update.valueOf();
      switch (true) {
        case diff <= 2 * 60 * 1000:
          return 10000;
        case diff <= 10 * 60 * 1000:
          return 20000;
        default:
          return 60000;
      }
    } else {
      return 60000;
    }
  }
  return 0;
};

const SwitchrelayStateEnumButtons = (props: {
  asset: Asset;
  refresh?: () => void;
}): React.ReactElement => {
  const [assetStateSwitching, setAssetStateSwitching] =
    useState<boolean>(false);

  const state: InputOutputModuleStateInput = props.asset
    .state as InputOutputModuleStateInput;

  const { update, error } = useCreateAssetAction(props.asset);

  const { refresh } = props;

  useEffect(() => {
    const delay = getRefreshDelay(state);
    if (delay && delay > 0 && refresh !== undefined) {
      const t = setTimeout(() => {
        refresh();
      }, delay);
      return () => clearTimeout(t);
    }
  }, [refresh, state]);

  const currentState: RelayStateEnum =
    state.relay_state || relayStateEnum.UNKNOWN;

  const switchableOn = (
    [
      relayStateEnum.OFF,
      relayStateEnum.UNKNOWN,
      relayStateEnum.SWITCHING_ERROR,
    ] as RelayStateEnum[]
  ).includes(currentState);
  const switchableOff = (
    [
      relayStateEnum.ON,
      relayStateEnum.UNKNOWN,
      relayStateEnum.SWITCHING_ERROR,
    ] as RelayStateEnum[]
  ).includes(currentState);
  const switching = (
    [
      relayStateEnum.SWITCHING_OFF,
      relayStateEnum.SWITCHING_ON,
    ] as RelayStateEnum[]
  ).includes(currentState);

  const buttonClick = (state: RelayStateCommand): void => {
    setAssetStateSwitching(true);
    update({
      action_type: actionTypesEnum.SWITCH_RELAY,
      company_id: props.asset.company_id,
      payload: { new_relay_state: state },
    }).then(() => {
      if (props.refresh) {
        props.refresh();
      }
    });
  };

  return (
    <>
      <Button
        disabled={!switchableOn || assetStateSwitching}
        color={"green"}
        onClick={() => buttonClick(relayStateCommandEnum.ON)}
        left={true}
      >
        Turn On
      </Button>
      <Button
        disabled={!switchableOff || assetStateSwitching}
        color={"red"}
        onClick={() => buttonClick(relayStateCommandEnum.OFF)}
        right={true}
      >
        Turn Off
      </Button>
      {(switching || assetStateSwitching) && (
        <span className={"text=sm text-gray-600 italic"}>
          State is currently switching. No actions possible.
        </span>
      )}
      <ErrorBanner error={error} />
    </>
  );
};

export default SwitchrelayStateEnumButtons;
