import BlockSpinner from "components/Spinners/BlockSpinner";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import { type JobExecutionInstruction, jobStatusEnum } from "kubb";
import type { JobDefinitionOut } from "kubb";
import type React from "react";
import { useState } from "react";
import AlertBanner from "../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import SingleDatePicker from "../../components/Forms/SingleDatePicker";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import { useCreateJobExecution } from "../../hooks/createEntity";
import { useRequiredSelectedCompanyId } from "../../reducers/company.tsx";

interface Props {
  job: JobDefinitionOut;
  refresh: () => void;
}

interface FormValues {
  date: Dayjs;
}

export default function ExecuteJobForm(props: Props): React.ReactElement {
  const create = useCreateJobExecution(useRequiredSelectedCompanyId());

  const [success, setSuccess] = useState<string | undefined>(undefined);

  const { job } = { ...props };
  const yesterday = dayjs().startOf("day").subtract(1, "second");

  const submit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setSuccess(undefined);
    const definition: JobExecutionInstruction = {
      company_id: job.company_id,
      job_id: job.job_id,
      end_date: values.date.format("YYYY-MM-DD"),
    };
    create
      .update(definition)
      .then(() => {
        setSuccess("Job Scheduled.");
        props.refresh();
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues: FormValues = { date: yesterday };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      {job.status === jobStatusEnum.active ? (
        <Formik initialValues={initialValues} onSubmit={submit}>
          {({ isSubmitting, submitForm }) => (
            <BlockSpinner loading={isSubmitting}>
              <p>
                Job {job.name} ({job.job_id}) can be executed for any date
                before
                {formatDate(yesterday, "L")}.
              </p>
              <p>
                Select a date to run the report for below and press execute.
              </p>
              <p>Your output report may take a few minutes to be produced.</p>
              <Form>
                <SingleDatePicker
                  dateName={"date"}
                  maxDate={yesterday}
                  showPresets={false}
                  label={{ label: "Execute Date" }}
                />
                <div className={"form-group row float-right"}>
                  <Button
                    type={"submit"}
                    color={"brandLight"}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Execute
                  </Button>
                </div>
              </Form>
            </BlockSpinner>
          )}
        </Formik>
      ) : (
        <>
          Job {job.name} ({job.job_id}) is not active and can not be executed.
        </>
      )}
      {success && <AlertBanner className={"bg-success"}>{success}</AlertBanner>}
      {create.error && <ErrorBanner error={create.error} />}
    </DisplayIfUserType>
  );
}
