import client from 'client'
import type {
  GetAccountListAccountGetQueryResponse,
  GetAccountListAccountGetQueryParams,
  GetAccountListAccountGet422,
} from '../../types/GetAccountListAccountGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountListAccountGetQueryKey = (params?: GetAccountListAccountGetQueryParams) => [{ url: '/account' }, ...(params ? [params] : [])] as const

export type GetAccountListAccountGetQueryKey = ReturnType<typeof getAccountListAccountGetQueryKey>

/**
 * @summary Get Account List
 * {@link /account}
 */
export async function getAccountListAccountGet(params?: GetAccountListAccountGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetAccountListAccountGetQueryResponse, ResponseErrorConfig<GetAccountListAccountGet422>, unknown>({
    method: 'GET',
    url: `/account`,
    params,
    ...requestConfig,
  })
  return res
}

export function getAccountListAccountGetQueryOptions(
  params?: GetAccountListAccountGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAccountListAccountGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetAccountListAccountGetQueryResponse>,
    ResponseErrorConfig<GetAccountListAccountGet422>,
    ResponseConfig<GetAccountListAccountGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountListAccountGet(params, config)
    },
  })
}

/**
 * @summary Get Account List
 * {@link /account}
 */
export function useGetAccountListAccountGet<
  TData = ResponseConfig<GetAccountListAccountGetQueryResponse>,
  TQueryData = ResponseConfig<GetAccountListAccountGetQueryResponse>,
  TQueryKey extends QueryKey = GetAccountListAccountGetQueryKey,
>(
  params?: GetAccountListAccountGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAccountListAccountGetQueryResponse>,
        ResponseErrorConfig<GetAccountListAccountGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountListAccountGetQueryKey(params)

  const query = useQuery({
    ...(getAccountListAccountGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAccountListAccountGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}