import client from 'client'
import type {
  CreateNewUserUserPostMutationRequest,
  CreateNewUserUserPostMutationResponse,
  CreateNewUserUserPostQueryParams,
  CreateNewUserUserPost422,
} from '../../types/CreateNewUserUserPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewUserUserPostMutationKey = () => [{ url: '/user' }] as const

export type CreateNewUserUserPostMutationKey = ReturnType<typeof createNewUserUserPostMutationKey>

/**
 * @description Creates a new user.
 * @summary Create New User
 * {@link /user}
 */
export async function createNewUserUserPost(
  data?: CreateNewUserUserPostMutationRequest,
  params?: CreateNewUserUserPostQueryParams,
  config: Partial<RequestConfig<CreateNewUserUserPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CreateNewUserUserPostMutationResponse, ResponseErrorConfig<CreateNewUserUserPost422>, CreateNewUserUserPostMutationRequest>({
    method: 'POST',
    url: `/user`,
    params,
    data,
    ...requestConfig,
  })
  return res
}

/**
 * @description Creates a new user.
 * @summary Create New User
 * {@link /user}
 */
export function useCreateNewUserUserPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewUserUserPostMutationResponse>,
      ResponseErrorConfig<CreateNewUserUserPost422>,
      { data?: CreateNewUserUserPostMutationRequest; params?: CreateNewUserUserPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewUserUserPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewUserUserPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewUserUserPostMutationResponse>,
    ResponseErrorConfig<CreateNewUserUserPost422>,
    { data?: CreateNewUserUserPostMutationRequest; params?: CreateNewUserUserPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewUserUserPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}