import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import sankey from "highcharts/modules/sankey";

sankey(Highcharts);

interface SankeyChartProps {
  title?: string;
  unit: string;
  data: Array<Highcharts.SeriesSankeyPointOptionsObject>;
  nodes?: Array<Highcharts.SeriesSankeyNodesOptionsObject>;
}

function SankeyChart(props: SankeyChartProps) {
  const options: Highcharts.Options = {
    title: {
      text: props.title,
    },
    chart: {
      height: "400px",
    },
    accessibility: {
      point: {
        valueDescriptionFormat:
          "{index}. {point.from} to {point.to}, {point.weight:.0f}.",
      },
    },
    tooltip: {
      pointFormat: `{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:.0f} ${props.unit}`,
    },
    plotOptions: {
      sankey: {
        linkOpacity: 0.75,
        // curveFactor: 0.33
      },
    },
    credits: { enabled: false },
    series: [
      {
        dataLabels: {
          nodeFormat: `{point.name}: {point.sum:.0f} ${props.unit}`,
        },
        nodes: props.nodes,
        data: props.data,
        type: "sankey",
        name: "Energy Balance",
        tooltip: { nodeFormat: `{point.name}: {point.sum:.0f} ${props.unit}` },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default SankeyChart;
