import {
  type AssetRFTimeSeriesResponse,
  useGetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet,
} from "kubb";
import type { AssetRegisterDataItemBaseWithAsset } from "kubb";
import type React from "react";
import { useGetDataNew } from "../../../../../hooks/getData";
import {
  type ColumnDefinitionWithData,
  type ColumnMap,
  convertTimesToDayjs,
} from "../../../../../model/deviceTimeSeries";
import type { Asset } from "../../../../../utils/object.tsx";
import type { AssetTimeSeriesRequestParams } from "../AssetDataSelectionForm";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "../model";

export interface AssetRegisterDataRf<T = string | undefined>
  extends AssetRegisterDataItemBaseWithAsset,
    ColumnDefinitionWithData<T> {}

interface Props {
  device: Asset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent<
    string | undefined,
    AssetRegisterDataRf
  >;
}

export default function AssetTimeSeriesRFGetter(
  props: Props,
): React.ReactElement {
  const { device, searchParams } = props;
  const { asset_id, company_id, scheme_id } = device;

  const { data, error, loading } = useGetDataNew<AssetRFTimeSeriesResponse>(
    () =>
      useGetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet(asset_id, {
        company_id,
        start_datetime: searchParams.start_datetime?.format(),
        end_datetime: searchParams.end_datetime?.format(),
      }),
  );

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps<
    string | undefined,
    AssetRegisterDataRf
  > => {
    if (data) {
      return {
        mappedTimeSeriesColumns: mapRFColumns(data),
        times: convertTimesToDayjs(data.times),
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
}

export function mapRFColumns(
  data: AssetRFTimeSeriesResponse,
): ColumnMap<string | undefined, AssetRegisterDataRf> {
  return data.columns.reduce(
    (result, curr, index) => ({
      ...result,
      [curr.asset_position_id]: {
        ...curr,
        name: "rssi",
        type: "instantaneous",
        unit: "dB",
        data: data.rssi_data.map((tsValue) => {
          return tsValue[index];
        }),
      },
    }),
    {},
  );
}
