import type { Scheme } from "kubb";
import type React from "react";
import { useSchemes } from "../../reducers/scheme";
import PrivacyMode from "../Text/PrivacyMode";

const SchemeLabel = (props: {
  scheme?: Scheme;
  scheme_id?: string;
}): React.ReactElement => {
  const allSchemes = useSchemes();
  if (props.scheme) {
    return <span>{props.scheme.scheme_name}</span>;
  }
  if (props.scheme_id) {
    const schemes = allSchemes
      ? allSchemes.filter((scheme) => scheme.scheme_id === props.scheme_id)
      : [];
    if (schemes.length === 1) {
      return (
        <span>
          <PrivacyMode>{schemes[0].scheme_name}</PrivacyMode>
        </span>
      );
    } else {
      return (
        <span>
          <PrivacyMode>{props.scheme_id}</PrivacyMode>
        </span>
      );
    }
  }
  throw Error("One of scheme or scheme_id must be set");
};

export default SchemeLabel;
