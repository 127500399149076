import type React from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { useGetLocation } from "../../../hooks/getEntity.tsx";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import LocationDisplay from "./LocationDisplay";

const LocationHomePage = (): React.ReactElement => {
  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();

  const { location_id } = useParams<{ location_id: string }>();

  if (location_id === undefined) {
    throw new Error("location_id must be specified.");
  }

  const { data, loading, error, refresh } = useGetLocation(
    location_id,
    company_id,
    scheme_id,
  );

  return (
    <>
      {error ? (
        <OneCardPage
          headerTitle={"Location Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={() => "Location: Error"}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      ) : !data ? (
        <OneCardPage
          headerTitle={"Location Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={() => "Location: Loading"}
        />
      ) : (
        <LocationDisplay
          data={data}
          loading={loading}
          error={error}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default LocationHomePage;
