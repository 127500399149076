import type { AssetPosition } from "kubb";
import type React from "react";
import type { Asset } from "../../../../../utils/object.tsx";
import AssetDataSearchStringParser from "../AssetDataSearchStringParser";
import AssetRFChart from "./AssetRFChart";
import AssetTimeSeriesRFGetter from "./AssetTimeSeriesRFGetter";

interface Props {
  device: Asset;
  assetPosition: AssetPosition;
}

export default function AssetRFAssessmentContainer(
  props: Props,
): React.ReactElement {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesRFGetter
            device={props.device}
            searchParams={searchParams}
            render={(props) => <AssetRFChart {...props} />}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
}
