import { Heading } from "components/Theme/heading";
import { assetTypeEnum } from "kubb";
import { statusEnum } from "kubb";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import type React from "react";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import type { Tab } from "../../../components/TabGroup/model";
import {
  assetPositionToBreadcrumb,
  locationToBreadcrumb,
} from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import NoteList from "../../../containers/notes/NotesList";
import type { AssetRegisterEntitySuccessProps } from "../../../core/action/GetAssetRegisterEntitiesCombination";
import { useUpdateAssetPosition } from "../../../hooks/updateEntity";
import IntegrationHomePage from "../integrations/IntegrationHomePage";
import WirelessClusterGatewayAddList from "../wirelessCluster/WirelessClusterGatewayAddList";
import WirelessClusterGatewayList from "../wirelessCluster/WirelessClusterGatewayList";
import WirelessClusterReceiverAssignmentTab from "../wirelessCluster/WirelessClusterReceiverAssignmentTab";
import AssetPositionPreview from "./AssetPositionPreview";
import SimpleAssetRegisterUploadContainer from "./simpleAssetRegisterUpload/SimpleAssetRegisterUploadContainer";
import AssetPositionConfigurationTab from "./tabs/AssetPositionConfigurationTab";
import AssetPositionDeleteTab from "./tabs/AssetPositionDeleteTab";
import AssetPositionAssetHistoryTab from "./tabs/assetPositionAssetHistoryTab";

const notNoneNullOrUndefined = (obj: any): boolean => {
  return obj !== "NONE" && obj !== undefined && obj !== null;
};

export default function AssetPositionDisplay({
  assetPosition,
  location,
  asset,
  loading,
  refresh,
}: AssetRegisterEntitySuccessProps): React.ReactElement {
  const deleted = assetPosition?.status === statusEnum.DELETED;
  const gateway = assetPosition?.asset_position_type === assetTypeEnum.gateway;
  const wireless_cluster =
    assetPosition?.asset_position_type === assetTypeEnum.wireless_cluster;

  if (assetPosition === undefined) {
    throw new Error("Asset Position Not Found");
  }

  const update = useUpdateAssetPosition(assetPosition);

  const deleteAssetPosition = (): Promise<any> => {
    if (assetPosition) {
      return update.update({
        ...assetPosition,
        status: statusEnum.DELETED,
      });
    } else {
      throw new Error("Can not delete without an asset position");
    }
  };

  if (assetPosition?.asset_position_type === assetTypeEnum.api_integration) {
    return (
      <IntegrationHomePage
        integration={{
          assetPosition,
          location,
          asset,
          loading,
          refresh,
        }}
        deleteAssetPosition={deleteAssetPosition}
      />
    );
  }
  const clusterGatewayTab = {
    tabName: "Cluster Gateways",
    tabPath: "gateways",
    tabContent: <WirelessClusterGatewayList clusterPosition={assetPosition} />,
  };
  const clusterReceiverAssignmentTab = {
    tabName: "Receiver Assignment",
    tabPath: "receiver_assignment",
    tabContent: (
      <WirelessClusterReceiverAssignmentTab clusterPosition={assetPosition} />
    ),
  };
  const clusterAddGatewaysTab = {
    tabName: "Add Cluster Gateways",
    tabPath: "add_gateways",
    tabContent: (
      <WirelessClusterGatewayAddList clusterPosition={assetPosition} />
    ),
    userTypes: "admin",
  };

  const ownedAssetPositionsTab = {
    tabName: "Owned Asset Positions",
    tabPath: "positions",
    tabContent: (
      <>
        <Heading>
          {gateway && "Gateway"}
          {wireless_cluster && "Cluster"} Owned Asset Positions
        </Heading>
        <AssetRegisterList
          params={{ parent_asset_position_id: assetPosition.asset_position_id }}
          company_id={assetPosition.company_id}
          scheme_id={assetPosition.scheme_id}
          createAssetTags={false}
        />
      </>
    ),
  };
  const uploadAssetRegisterTab = {
    tabName: "Add Owned Asset Positions",
    tabPath: "add_positions",
    tabContent: (
      <>
        <Heading>Add Simple Asset Register</Heading>
        <SimpleAssetRegisterUploadContainer assetPosition={assetPosition} />
      </>
    ),
    userTypes: "admin",
    disabled: deleted,
  };
  const configuration = {
    tabName: "Configuration",
    tabPath: "configuration",
    tabContent: (
      <AssetPositionConfigurationTab
        assetPosition={assetPosition}
        refresh={refresh}
      />
    ),
    userTypes: "admin",
    disabled: !wireless_cluster && !gateway,
  };

  const assetHistoryTab = {
    tabName: "Asset History",
    tabPath: "link",
    tabContent: (
      <AssetPositionAssetHistoryTab
        assetPosition={assetPosition}
        refresh={refresh}
      />
    ),
    disabled: false,
  };
  const notesTab = {
    tabName: "Notes",
    tabPath: "note",
    tabContent: (
      <>
        <Heading>Notes</Heading>
        <NoteList assetPosition={assetPosition} />
      </>
    ),
    disabled: false,
  };
  const eventsTab = {
    tabName: "Events",
    tabPath: "events",
    tabContent: (
      <>
        <Heading>Asset Position Events</Heading>
        <EventList
          asset_position_id={assetPosition.asset_position_id}
          company_id={assetPosition.company_id}
        />
      </>
    ),
    disabled: false,
  };
  const deleteTab = {
    tabName: "Delete",
    tabPath: "delete",
    tabContent: (
      <AssetPositionDeleteTab
        assetPosition={assetPosition}
        // refresh={loadAssetPosition}
        delete={deleteAssetPosition}
        title={"Asset Position"}
      />
    ),
    userTypes: "admin",
    disabled: false,
  };
  const getTabs = (): Tab[] => {
    switch (assetPosition.asset_position_type) {
      case assetTypeEnum.gateway:
        return assetPosition.asset_id
          ? [
              ownedAssetPositionsTab,
              uploadAssetRegisterTab,
              configuration,
              assetHistoryTab,
              notesTab,
              eventsTab,
              deleteTab,
            ]
          : [
              assetHistoryTab,
              ownedAssetPositionsTab,
              uploadAssetRegisterTab,
              configuration,
              notesTab,
              eventsTab,
              deleteTab,
            ];
      case assetTypeEnum.wireless_cluster:
        return [
          clusterGatewayTab,
          clusterAddGatewaysTab,
          clusterReceiverAssignmentTab,
          ownedAssetPositionsTab,
          uploadAssetRegisterTab,
          configuration,
          notesTab,
          eventsTab,
          deleteTab,
        ];
      default:
        return [assetHistoryTab, notesTab, eventsTab, deleteTab];
    }
  };

  const niceRef =
    assetPosition.customer_reference ||
    `${assetPosition.asset_position_id.slice(0, 8)}...`;

  return (
    <OneCardPage
      headerTitle={
        wireless_cluster ? "Wireless Cluster Homepage" : "Asset Position Detail"
      }
      breadcrumbs={
        assetPosition &&
        location && [
          locationToBreadcrumb(location),
          assetPositionToBreadcrumb(assetPosition),
        ]
      }
      loading={loading}
      setPageTitle={() => {
        return wireless_cluster
          ? `Wireless Cluster: ${niceRef}`
          : `Asset Position: ${niceRef}`;
      }}
    >
      {assetPosition && location && (
        <>
          <AssetPositionPreview
            assetPosition={assetPosition}
            asset={asset}
            location={location}
            refresh={refresh}
          />
          <RoutedTabGroupInterface
            tabs={getTabs()}
            topLevelPath={`/admin/asset_register/asset_position/${assetPosition.asset_position_id}`}
          />
        </>
      )}
    </OneCardPage>
  );
}
