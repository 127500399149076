import type { Placement, Side } from "@floating-ui/react";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import React, { type SetStateAction } from "react";
import { removeMapBlanks } from "../../utils/object";

interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function useTooltip({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export function Tooltip({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    );
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});

type UncontrolledTooltipProps = React.PropsWithChildren<{
  message: React.ReactNode;
  placement?: Side;
  width?: number;
  text?: keyof typeof textClasses | null;
  background?: colors;
  defaultStyles?: boolean;
  className?: string;
}>;

const textClasses = {
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
};

const backgroundColorClasses = {
  black: "bg-[#333]",
  zinc: "bg-zinc-100 border-zinc-300",
};

type colors = keyof typeof backgroundColorClasses;

type placementClassesType = {
  [key in Side]: { [key in colors]: string };
};
const placementClasses: placementClassesType = {
  top: {
    black:
      "px-3 py-[6px] rounded before:w-4 before:h-4 before:rotate-45 before:bg-[#333] before:absolute before:z-[-1] before:-bottom-1 before:left-0  before:right-0 before:mx-auto",
    zinc: "px-3 py-[6px] rounded before:w-4 before:h-4 before:rotate-45 before:bg-zinc-100 before:absolute before:z-[-1] before:-bottom-1 before:left-0  before:right-0 before:mx-auto",
  },
  right: {
    black:
      "px-3 py-2 ml-3 rounded before:w-4 before:h-4 before:rotate-45 before:bg-[#333] before:absolute before:z-[-1] before:bottom-0 before:top-0 before:my-auto before:-left-1 before:mx-auto",
    zinc: "px-3 py-2 ml-3 rounded before:w-4 before:h-4 before:rotate-45 before:bg-zinc-100 before:absolute before:z-[-1] before:bottom-0 before:top-0 before:my-auto before:-left-1 before:mx-auto",
  },
  left: {
    black:
      "px-3 py-2 mr-3 rounded before:w-4 before:h-4 before:rotate-45 before:bg-[#333] before:absolute before:z-[-1] before:bottom-0 before:top-0 before:my-auto before:-right-1 before:mx-auto",
    zinc: "px-3 py-2 mr-3 rounded before:w-4 before:h-4 before:rotate-45 before:bg-zinc-100 before:absolute before:z-[-1] before:bottom-0 before:top-0 before:my-auto before:-right-1 before:mx-auto",
  },
  bottom: {
    black:
      "px-3 py-[6px] rounded before:w-4 before:h-4 before:rotate-45 before:bg-[#333] before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto",
    zinc: "px-3 py-[6px] rounded before:w-4 before:h-4 before:rotate-45 before:bg-zinc-100 before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto",
  },
};

export default function UncontrolledTooltip(props: UncontrolledTooltipProps) {
  const {
    placement = "top",
    text = "md",
    background = "black",
    defaultStyles = true,
  } = props;
  return (
    <Tooltip placement={placement}>
      <TooltipTrigger>{props.children}</TooltipTrigger>
      <TooltipContent
        className={clsx(
          text && textClasses[text],
          backgroundColorClasses[background],
          defaultStyles && "shadow-lg  text-white font-semibold",
          props.className,
          placementClasses[placement][background],
          "z-10",
        )}
        style={removeMapBlanks({ maxWidth: props.width })}
      >
        {props.message}
      </TooltipContent>
    </Tooltip>
  );
}

interface ControlledTooltipProps extends UncontrolledTooltipProps {
  open?: boolean;
  setOpen?: React.Dispatch<SetStateAction<boolean>>;
}

export function ControlledTooltip(props: ControlledTooltipProps) {
  const {
    open = false,
    setOpen = undefined,
    placement = "top",
    text = "md",
    background = "black",
    defaultStyles = true,
  } = props;
  return (
    <Tooltip open={open} onOpenChange={setOpen} placement={placement}>
      <TooltipTrigger onClick={() => setOpen?.((v) => !v)}>
        {props.children}
      </TooltipTrigger>
      <TooltipContent
        className={clsx(
          text && textClasses[text],
          backgroundColorClasses[background],
          defaultStyles && "shadow-lg  text-white font-semibold",
          props.className,
          placementClasses[placement][background],
        )}
        style={removeMapBlanks({ maxWidth: props.width })}
      >
        {props.message}
      </TooltipContent>
    </Tooltip>
  );
}
