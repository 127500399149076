export const periodEnum = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  SEVEN_DAY: 'SEVEN_DAY',
  MONTHLY: 'MONTHLY',
  ALL: 'ALL',
} as const

export type PeriodEnum = (typeof periodEnum)[keyof typeof periodEnum]

export type Period = PeriodEnum