export const cumulativeValueTimeSeriesFieldEnum = {
  'Energy (Heating)': 'Energy (Heating)',
  'Energy (Cooling)': 'Energy (Cooling)',
  'Energy (Electrical Active Import)': 'Energy (Electrical Active Import)',
  'Energy (Electrical Active Export)': 'Energy (Electrical Active Export)',
  'Energy (Electrical Reactive Import)': 'Energy (Electrical Reactive Import)',
  'Energy (Electrical Reactive Export)': 'Energy (Electrical Reactive Export)',
  Volume: 'Volume',
} as const

export type CumulativeValueTimeSeriesFieldEnum = (typeof cumulativeValueTimeSeriesFieldEnum)[keyof typeof cumulativeValueTimeSeriesFieldEnum]

export type CumulativeValueTimeSeriesField = CumulativeValueTimeSeriesFieldEnum