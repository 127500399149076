export const assetPositionActionTypesEnum = {
  ASSIGN_ALL_ASSETS_TO_ALL_WIRELESS_RECEIVERS: 'ASSIGN_ALL_ASSETS_TO_ALL_WIRELESS_RECEIVERS',
  AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS: 'AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS',
  AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS_AND_SYNC: 'AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS_AND_SYNC',
  SYNC_CONFIGURATION_ALL_ASSETS: 'SYNC_CONFIGURATION_ALL_ASSETS',
  SYNC_DEVICE_LIST_ALL_ASSETS: 'SYNC_DEVICE_LIST_ALL_ASSETS',
} as const

export type AssetPositionActionTypesEnum = (typeof assetPositionActionTypesEnum)[keyof typeof assetPositionActionTypesEnum]

export type AssetPositionActionTypes = AssetPositionActionTypesEnum