import { assetTypeEnum, groupByEnum, periodEnum } from "kubb";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import EnergyBalancerView from "../../components/TimeSeries/EnergyBalanceView/EnergyBalancerView";
import type { HeatNetworkReportParams } from "./heatNetworkAnalysis/model";
import TimeSeriesStatsApiWrapper, {
  type AggregatedTimeSeriesStatsParams,
} from "./latestDataReport/TimeSeriesStatsApiWrapper";

interface Props {
  company_id?: string;
  scheme_id?: string;
  query: HeatNetworkReportParams;
}

function EnergyBalanceReportPage(props: Props) {
  const [start_datetime, end_datetime] = [
    props.query.startDate,
    props.query.endDate,
  ];
  const params = (): AggregatedTimeSeriesStatsParams => {
    if (props.company_id && props.scheme_id) {
      return {
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        parameter: "Energy (Heating)",
        aggregation: "difference",
        asset_types: [
          assetTypeEnum.heat_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ],
        start_datetime,
        end_datetime,
        period: periodEnum.MONTHLY,
        group_by: groupByEnum.GROUP_ALL,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };
  return (
    <>
      {props.company_id && props.scheme_id && (
        <TimeSeriesStatsApiWrapper request={params()}>
          {({ data, loading }) => (
            <BlockSpinner loading={loading}>
              <EnergyBalancerView
                title={"Energy Balance"}
                data={data || {}}
                startDate={start_datetime}
                endDate={end_datetime}
              />
            </BlockSpinner>
          )}
        </TimeSeriesStatsApiWrapper>
      )}
    </>
  );
}

export default EnergyBalanceReportPage;
