import Card from "components/Theme/card";
import type React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import { useProfile } from "../../reducers/profile";
import { convertUserToCompanyUser } from "../users/users";
import UserCompanyListItemForm from "./UserCompanyListItemForm";

export default function UserCompanyList(): React.ReactElement {
  const user = useProfile();

  return (
    <Card title={"Companies"} className={"my-4"}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Company</TableHeader>
            <TableHeader>User Group</TableHeader>
            <TableHeader>Receive Alerts</TableHeader>
            <TableHeader> </TableHeader>
          </TableRow>
        </TableHead>
        {user ? (
          <TableBody>
            {user.companies.map((c) => {
              return (
                <UserCompanyListItemForm
                  key={c.company_id}
                  showcompany_id={true}
                  userCompany={convertUserToCompanyUser(user, c.company_id)}
                />
              );
            })}
          </TableBody>
        ) : (
          <></>
        )}
      </Table>
    </Card>
  );
}
