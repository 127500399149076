import client from 'client'
import type {
  UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest,
  UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse,
  UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams,
  UpdateUserCompanyUserUserIdCompanyCompanyIdPut422,
} from '../../types/UpdateUserCompanyUserUserIdCompanyCompanyIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateUserCompanyUserUserIdCompanyCompanyIdPutMutationKey = () => [{ url: '/user/{user_id}/company/{company_id}' }] as const

export type UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationKey = ReturnType<typeof updateUserCompanyUserUserIdCompanyCompanyIdPutMutationKey>

/**
 * @description Updates that access permissions and preferences for a user to a specific company.
 * @summary Update User Company
 * {@link /user/:user_id/company/:company_id}
 */
export async function updateUserCompanyUserUserIdCompanyCompanyIdPut(
  user_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['user_id'],
  company_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['company_id'],
  data: UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest,
  config: Partial<RequestConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse,
    ResponseErrorConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPut422>,
    UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest
  >({ method: 'PUT', url: `/user/${user_id}/company/${company_id}`, data, ...requestConfig })
  return res
}

/**
 * @description Updates that access permissions and preferences for a user to a specific company.
 * @summary Update User Company
 * {@link /user/:user_id/company/:company_id}
 */
export function useUpdateUserCompanyUserUserIdCompanyCompanyIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse>,
      ResponseErrorConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPut422>,
      {
        user_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['user_id']
        company_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['company_id']
        data: UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateUserCompanyUserUserIdCompanyCompanyIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse>,
    ResponseErrorConfig<UpdateUserCompanyUserUserIdCompanyCompanyIdPut422>,
    {
      user_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['user_id']
      company_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams['company_id']
      data: UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest
    }
  >({
    mutationFn: async ({ user_id, company_id, data }) => {
      return updateUserCompanyUserUserIdCompanyCompanyIdPut(user_id, company_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}