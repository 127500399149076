import type { ParsedQs } from "qs";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse_to_enum } from "../../../../components/Search/SearchParamPage.tsx";
import { parseSearchString } from "../../../../components/navigation/SearchString.tsx";
import {
  type GetAssetListAssetGetQueryParams,
  type GetAssetPositionListAssetPositionGetQueryParams,
  type GetAssetRegisterListAssetRegisterGetQueryParams,
  assetTypeEnum,
  assetUseCaseEnum,
  statusEnum,
} from "../../../../kubb";
import { removeMapBlanks } from "../../../../utils/object.tsx";

export function SearchParamParser<T>(props: {
  initialState: Partial<T>;
  children: React.ReactNode;
  parser: (parsed: ParsedQs) => T;
}): React.ReactElement {
  const [state, setState] = React.useState<{
    params: Partial<T>;
    initialized: boolean;
  }>({
    params: props.initialState,
    initialized: false,
  });
  const location = useLocation();

  const update = useCallback((): void => {
    setState({
      params: {
        ...props.initialState,
        ...props.parser(parseSearchString(location.search)),
      },
      initialized: true,
    });
  }, [props.initialState, location.search, props.parser]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: waht to trigger update if the search changes
  useEffect(update, [location.search]);

  return state.initialized && React.isValidElement(props.children) ? (
    React.cloneElement(props.children, state)
  ) : (
    <></>
  );
}

export function AssetListSearchParam(props: {
  initialState: Partial<GetAssetListAssetGetQueryParams>;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <SearchParamParser
      {...props}
      parser={(parsed) =>
        removeMapBlanks({
          asset_id: parsed.asset_id as string,
          asset_position_id: parsed.asset_position_id,
          parent_asset_id: parsed.parent_asset_id,
          asset_type: parse_to_enum(parsed, assetTypeEnum, "asset_type"),
          text_search: parsed.text_search,
          sort: parsed.sort,
          after: parsed.after,
          before: parsed.before,
          max_items: parsed.max_items,
          direction: parsed.direction,
        })
      }
    />
  );
}

export function AssetPositionListSearchParam(props: {
  initialState: Partial<GetAssetPositionListAssetPositionGetQueryParams>;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <SearchParamParser
      {...props}
      parser={(parsed) =>
        removeMapBlanks({
          location_id: parsed.location_id,
          asset_id: parsed.asset_id as string,
          asset_position_id: parsed.asset_position_id,
          parent_asset_position_id: parsed.parent_asset_position_id,
          asset_type: parse_to_enum(parsed, assetTypeEnum, "asset_type"),
          text_search: parsed.text_search,
          status: parse_to_enum(parsed, statusEnum, "status"),
          use_case: parse_to_enum(parsed, assetUseCaseEnum, "use_case"),
          group: parsed.group,
          sort: parsed.sort,
          after: parsed.after,
          before: parsed.before,
          max_items: parsed.max_items,
          direction: parsed.direction,
        })
      }
    />
  );
}

export function AssetRegisterListSearchParam(props: {
  initialState: Partial<GetAssetRegisterListAssetRegisterGetQueryParams>;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <SearchParamParser
      {...props}
      parser={(parsed) =>
        removeMapBlanks({
          location_id: parsed.location_id,
          asset_id: parsed.asset_id as string,
          asset_position_id: parsed.asset_position_id,
          parent_asset_position_id: parsed.parent_asset_position_id,
          asset_type: parse_to_enum(parsed, assetTypeEnum, "asset_type"),
          text_search: parsed.text_search,
          status: parse_to_enum(parsed, statusEnum, "status"),
          use_case: parse_to_enum(parsed, assetUseCaseEnum, "use_case"),
          group: parsed.group,
          sort: parsed.sort,
          after: parsed.after,
          before: parsed.before,
          max_items: parsed.max_items,
          direction: parsed.direction,
        })
      }
    />
  );
}
