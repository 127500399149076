import type { FormikValues } from "formik";
import type { MutateEntityWrapperResult } from "hooks/updateEntity";
import type React from "react";
import { useRef } from "react";
import * as yup from "yup";
import EditModal, { type EditModalProps } from "./EditModal";
import NewTextFormComponent from "./NewTextFormComponent";

export interface EditStringModalProps<T extends FormikValues>
  extends Omit<EditModalProps<T, T>, "setModalFocus" | "children"> {
  fieldName: keyof T;
  label: string;
  update: MutateEntityWrapperResult<T>;
  validator?: yup.StringSchema<string | undefined>;
}

function EditStringModal<T extends FormikValues>(
  props: EditStringModalProps<T>,
): [() => void, React.ReactElement] {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  const { fieldName, label, values, validator, ...rest } = props;

  return EditModal<T, T>({
    ...rest,
    values,
    validator: validator && yup.object({ [fieldName]: validator }),
    children: (
      <NewTextFormComponent
        fieldName={props.fieldName as string}
        label={{ label: label, margin: 3 }}
      />
    ),
    setModalFocus,
  });
}

export default EditStringModal;
