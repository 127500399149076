import type { AssetAction } from "kubb";
import type { AssetPosition } from "kubb";
import type { AssetPositionAction } from "kubb";
import type React from "react";
import { useState } from "react";
import type { Asset } from "utils/object";
import { errorToTitleAndText } from "../../../components/Error/errorMessages";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import {
  useCreateAssetAction,
  useCreateAssetPositionAction,
} from "../../../hooks/createEntity";

interface Props {
  close: () => void;
  show: boolean;
  description: string;
  onComplete?: () => void;
  onClose?: () => void;
}

type PProps = Props &
  (
    | {
        asset: Asset;
        action: AssetAction;
        assetPosition?: never;
        assetPositionAction?: never;
      }
    | {
        asset?: never;
        action?: never;
        assetPosition: AssetPosition;
        assetPositionAction: AssetPositionAction;
      }
  );

const AssetActionConfirmModal = (props: PProps): React.ReactElement => {
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { update, error } = props.asset
    ? useCreateAssetAction(props.asset)
    : useCreateAssetPositionAction(props.assetPosition);

  const handleConfirm = (): void => {
    setStarted(true);
    (props.asset
      ? (update as (d: AssetAction) => Promise<[AssetAction, any]>)(
          props.action,
        )
      : (
          update as (
            d: AssetPositionAction,
          ) => Promise<[AssetPositionAction, any]>
        )(props.assetPositionAction)
    )
      .then(() => {
        setCompleted(true);
        if (props.onComplete) {
          props.onComplete();
        }
      })
      .catch(() => {
        setCompleted(true);
      });
  };

  const handleClose = (): void => {
    if (props.onClose) {
      props.onClose();
    }
    props.close();
    setTimeout(() => {
      setStarted(false);
      setCompleted(false);
    }, 200);
  };

  return (
    <ConfirmModal
      buttonLabel={"OK"}
      title={
        completed
          ? error
            ? ` Error${
                errorToTitleAndText(error).title
                  ? `: ${errorToTitleAndText(error).title}`
                  : ""
              }`
            : "Action Started"
          : "Are you sure?"
      }
      body={
        completed ? (
          error ? (
            <p>
              There was an error starting your action
              {errorToTitleAndText(error).text}
            </p>
          ) : (
            "Your action has been started"
          )
        ) : (
          `Do you really want to ${props.description}?`
        )
      }
      show={props.show}
      handleAction={!completed ? handleConfirm : undefined}
      disabled={!started}
      closeLabel={completed ? "Close" : "Cancel"}
      handleClose={handleClose}
    />
  );
};

export default AssetActionConfirmModal;
