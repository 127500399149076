import RequireScheme from "../../../components/Scheme/RequireScheme";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { IntegrationCreateForm } from "./IntegrationCreateForm";

export function IntegrationCreatePage() {
  return (
    <SelectedScheme>
      {(schemeProps) => (
        <OneCardPage
          headerTitle={
            schemeProps.scheme_id
              ? `Add an Integration to ${schemeProps.scheme_id}`
              : "Add an Integration"
          }
          breadcrumbs={[
            {
              url: "/admin/integrations",
              name: "Integrations",
            },
            {
              url: "/admin/integrations/create",
              name: "Create",
            },
          ]}
          setPageTitle={schemeObjectPageTitle("Add Data Integration")}
        >
          <RequireScheme>
            <IntegrationCreateForm
              scheme_id={schemeProps.scheme_id as string}
              company_id={schemeProps.company_id}
            />
          </RequireScheme>
        </OneCardPage>
      )}
    </SelectedScheme>
  );
}
