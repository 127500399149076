export const configurationStateEnum = {
  IN_SYNC: 'IN_SYNC',
  UPDATING_CONFIGURATION: 'UPDATING_CONFIGURATION',
  UPDATING_DEVICE_LIST: 'UPDATING_DEVICE_LIST',
  ERROR_UPDATING_CONFIGURATION: 'ERROR_UPDATING_CONFIGURATION',
  ERROR_UPDATING_DEVICE_LIST: 'ERROR_UPDATING_DEVICE_LIST',
  OUT_OF_SYNC: 'OUT_OF_SYNC',
  UNKNOWN: 'UNKNOWN',
} as const

export type ConfigurationStateEnum = (typeof configurationStateEnum)[keyof typeof configurationStateEnum]

export type ConfigurationState = ConfigurationStateEnum