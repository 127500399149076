import CSVExportButton from "../../../../components/utils/CSVExport/CSVExportButton";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./model";

const produceCSVPromise = (
  props: DeviceTimeSeriesDisplayComponentProps,
): Promise<any> => {
  return new Promise<any>((resolve) => {
    const data: any[] = [];
    props.times.forEach((time, index) => {
      const d: { [key: string]: any } = { time: time.format() };

      for (const [_key, columnDefinition] of Object.entries(
        props.mappedTimeSeriesColumns,
      )) {
        const fieldName =
          columnDefinition.unit !== ""
            ? `${columnDefinition.name} (${columnDefinition.unit})`
            : columnDefinition.name;
        d[fieldName] = columnDefinition.data[index];
      }

      data.push(d);
    });

    resolve(data);
  });
};

const DeviceTimeSeriesDataCsvExportButton: DeviceTimeSeriesDisplayComponent = (
  props: DeviceTimeSeriesDisplayComponentProps,
) => {
  return (
    <CSVExportButton
      download={(): Promise<any> => produceCSVPromise(props)}
      columnOrder={["time"]}
    />
  );
};

export default DeviceTimeSeriesDataCsvExportButton;
