import { createSearchString } from "components/navigation/SearchString";
import type { Dayjs } from "dayjs";
import type React from "react";
import type { Path } from "react-router-dom";
import CompanyNavLink from "../navigation/CompanyNavLink";

const AssetPositionRowLink = (props: {
  asset_position_id?: string;
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
  children: React.ReactNode;
}): React.ReactElement => {
  const { asset_position_id, startDatetime, endDatetime } = props;

  const link: Partial<Path> = {
    pathname: `/admin/asset_position/${asset_position_id}`,
    search:
      (startDatetime || endDatetime) &&
      `?${createSearchString({
        startDatetime: startDatetime?.format(),
        endDatetime: endDatetime?.format(),
      })}`,
  };

  return (
    <>
      {asset_position_id && (
        <CompanyNavLink to={link} className={"px-0"} external={true} inline>
          {props.children}
        </CompanyNavLink>
      )}
    </>
  );
};

export default AssetPositionRowLink;
