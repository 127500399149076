import type { LocationGroup } from "kubb";
import React from "react";
import { SchemeContext } from "../../reducers/scheme";
import {
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";
import { locationGroupListToOptions } from "../Location/model";

type NewLocationGroupSelectProps = Omit<
  SelectComboFieldProps,
  "options" | "placeholder"
>;

export default function LocationGroupSelect(
  props: NewLocationGroupSelectProps,
): React.ReactElement {
  const locationGroups = React.useContext(SchemeContext).state.groups;

  const ready = !locationGroups.loading && !locationGroups.error;
  const haveGroups = locationGroups.groups && locationGroups.groups.length > 0;
  const altText: string = ready
    ? haveGroups
      ? "All"
      : "No Groups"
    : locationGroups.loading
      ? "Loading Groups"
      : locationGroups.error?.message || "Error Loading Groups";
  return (
    <SelectComboField
      {...props}
      options={
        haveGroups
          ? locationGroupListToOptions(
              locationGroups.groups as LocationGroup[],
              0,
            )
          : []
      }
      disabled={props.disabled || (ready && !haveGroups) || !ready}
      placeholder={altText}
    />
  );
}
