import client from 'client'
import type {
  CreateNewAssetPositionAssetPositionPostMutationRequest,
  CreateNewAssetPositionAssetPositionPostMutationResponse,
  CreateNewAssetPositionAssetPositionPostQueryParams,
  CreateNewAssetPositionAssetPositionPost422,
} from '../../types/CreateNewAssetPositionAssetPositionPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewAssetPositionAssetPositionPostMutationKey = () => [{ url: '/asset_position' }] as const

export type CreateNewAssetPositionAssetPositionPostMutationKey = ReturnType<typeof createNewAssetPositionAssetPositionPostMutationKey>

/**
 * @summary Create New Asset Position
 * {@link /asset_position}
 */
export async function createNewAssetPositionAssetPositionPost(
  data: CreateNewAssetPositionAssetPositionPostMutationRequest,
  params?: CreateNewAssetPositionAssetPositionPostQueryParams,
  config: Partial<RequestConfig<CreateNewAssetPositionAssetPositionPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewAssetPositionAssetPositionPostMutationResponse,
    ResponseErrorConfig<CreateNewAssetPositionAssetPositionPost422>,
    CreateNewAssetPositionAssetPositionPostMutationRequest
  >({ method: 'POST', url: `/asset_position`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Asset Position
 * {@link /asset_position}
 */
export function useCreateNewAssetPositionAssetPositionPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewAssetPositionAssetPositionPostMutationResponse>,
      ResponseErrorConfig<CreateNewAssetPositionAssetPositionPost422>,
      { data: CreateNewAssetPositionAssetPositionPostMutationRequest; params?: CreateNewAssetPositionAssetPositionPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewAssetPositionAssetPositionPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewAssetPositionAssetPositionPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewAssetPositionAssetPositionPostMutationResponse>,
    ResponseErrorConfig<CreateNewAssetPositionAssetPositionPost422>,
    { data: CreateNewAssetPositionAssetPositionPostMutationRequest; params?: CreateNewAssetPositionAssetPositionPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewAssetPositionAssetPositionPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}