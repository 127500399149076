import client from 'client'
import type {
  GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest,
  GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse,
  GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams,
  GetTimeSeriesReportStatsReportTimeSeriesStatsPost422,
} from '../../types/GetTimeSeriesReportStatsReportTimeSeriesStatsPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const getTimeSeriesReportStatsReportTimeSeriesStatsPostMutationKey = () => [{ url: '/report/time_series/stats' }] as const

export type GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationKey = ReturnType<typeof getTimeSeriesReportStatsReportTimeSeriesStatsPostMutationKey>

/**
 * @summary Get Time Series Report Stats
 * {@link /report/time_series/stats}
 */
export async function getTimeSeriesReportStatsReportTimeSeriesStatsPost(
  data?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest,
  params?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams,
  config: Partial<RequestConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse,
    ResponseErrorConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPost422>,
    GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest
  >({ method: 'POST', url: `/report/time_series/stats`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Get Time Series Report Stats
 * {@link /report/time_series/stats}
 */
export function useGetTimeSeriesReportStatsReportTimeSeriesStatsPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse>,
      ResponseErrorConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPost422>,
      { data?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest; params?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams }
    >
    client?: Partial<RequestConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getTimeSeriesReportStatsReportTimeSeriesStatsPostMutationKey()

  return useMutation<
    ResponseConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse>,
    ResponseErrorConfig<GetTimeSeriesReportStatsReportTimeSeriesStatsPost422>,
    { data?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest; params?: GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return getTimeSeriesReportStatsReportTimeSeriesStatsPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}