import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
  type sizes,
} from "components/Theme/dialog";
import { Form, Formik, type FormikValues } from "formik";
import type { FormikProps } from "formik/dist/types";
import { isFunction } from "lodash-es";
import type React from "react";
import { useEffect, useState } from "react";
import type * as yup from "yup";
import type { MutateEntityWrapperResult } from "../../hooks/updateEntity";
import { ErrorBanner } from "../Error/ErrorBanner";
import { Button } from "../Theme/button";

export interface EditModalProps<TData, TBody = TData> {
  values: TBody;
  title: string;
  validator?: yup.ObjectSchema<any> | yup.StringSchema<string | undefined>;
  update: MutateEntityWrapperResult<TData | null, TBody>;
  postUpdate?: () => void;
  children: ((props: FormikProps<TBody>) => React.ReactNode) | React.ReactNode;
  setModalFocus: () => void;
  size?: keyof typeof sizes;
}

export default function EditModal<TData, TBody extends FormikValues>(
  props: EditModalProps<TData, TBody>,
): [() => void, React.ReactElement] {
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState(props.values);

  const { update, error, loading } = props.update;

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const modal = (
    <Dialog
      open={editing}
      // onOpened={() => {
      //   reset();
      //   props.setModalFocus();
      // }}
      // unmountOnClose={true}
      onClose={() => {
        setEditing(false);
        /* TODO: reset form */
      }}
      data-cy={"edit-modal"}
      size={props.size}
    >
      <DialogTitle>Update {props.title}</DialogTitle>
      <Formik
        initialValues={values}
        enableReinitialize={true}
        onSubmit={async (d) => {
          await update(d);
          console.log("here");
          console.log(props.postUpdate);
          props.postUpdate?.();
          setEditing(false);
        }}
        onReset={() => {
          // setEditing(false);
        }}
        validationSchema={props.validator}
      >
        {(formikProps) => {
          return (
            <Form>
              <DialogBody>
                <>
                  {isFunction(props.children)
                    ? props.children(formikProps)
                    : props.children}
                  <ErrorBanner error={error} />
                </>
              </DialogBody>
              <DialogActions>
                <Button
                  color="green"
                  onClick={formikProps.submitForm}
                  disabled={
                    loading || !formikProps.dirty || !formikProps.isValid
                  }
                >
                  <FontAwesomeIcon
                    icon={loading ? faSpinner : faCheck}
                    fixedWidth
                    spin={loading}
                  />
                  Confirm
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    formikProps.resetForm();
                    setEditing(false);
                  }}
                  disabled={loading}
                >
                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

  return [() => setEditing((v) => !v), modal];
}
