import { type AssetUseCaseEnum, assetUseCaseEnum } from "kubb";
import { isArray, isNil, isString } from "lodash-es";
import type { ParsedQs } from "qs";
import type React from "react";
import { convertStringToOpenApiEnum } from "../../utils/enum";
import {
  type OptionsList,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

type NewAssetUseCaseSelectProps = Omit<
  SelectComboFieldProps,
  "options" | "placeholder"
>;

export function matchStringsToassetUseCaseEnumEnum(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined,
): AssetUseCaseEnum[] | undefined {
  if (isNil(lst)) {
    return undefined;
  }
  if (isArray(lst)) {
    return lst
      .map((v) => {
        if (isString(v)) {
          return convertStringToOpenApiEnum<AssetUseCaseEnum>(
            v,
            assetUseCaseEnum,
            true,
          );
        } else {
          return undefined;
        }
      })
      .filter((v) => !isNil(v)) as AssetUseCaseEnum[];
  }
  const val = isString(lst)
    ? convertStringToOpenApiEnum<AssetUseCaseEnum>(lst, assetUseCaseEnum, true)
    : undefined;
  if (val) {
    return [val as AssetUseCaseEnum];
  }
}
export default function AssetUseCaseSelect(
  props: NewAssetUseCaseSelectProps,
): React.ReactElement {
  const options: OptionsList = [
    { label: "None", value: assetUseCaseEnum.NONE },
    { label: "Domestic", value: assetUseCaseEnum.DOMESTIC },
    { label: "Commercial", value: assetUseCaseEnum.COMMERCIAL },
    { label: "Process", value: assetUseCaseEnum.PROCESS },
  ].filter((v) => !isNil(v)) as OptionsList;

  return <SelectComboField options={options} {...props} placeholder={"All"} />;
}
