export const assetRegisterSortEnum = {
  'location.address': 'location.address',
  'location.location_id': 'location.location_id',
  'asset.serial_number': 'asset.serial_number',
  'asset.last_contact': 'asset.last_contact',
  'asset.asset_id': 'asset.asset_id',
  'asset_position.customer_reference': 'asset_position.customer_reference',
  'asset_position.asset_position_id': 'asset_position.asset_position_id',
  'asset_position.created_at': 'asset_position.created_at',
} as const

export type AssetRegisterSortEnum = (typeof assetRegisterSortEnum)[keyof typeof assetRegisterSortEnum]

export type AssetRegisterSort = AssetRegisterSortEnum