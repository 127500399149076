import type React from "react";
import { parse_to_bool } from "../../components/Search/SearchParamPage.tsx";
import type { GetAlarmListEventAlarmGetQueryParams } from "../../kubb";
import { SearchParamParser } from "../../pages/assetRegister/assets/list/AssetListSearchParam.tsx";
import { removeMapBlanks } from "../../utils/object.tsx";

interface AlarmListSearchStringParserProps {
  initialState: Partial<GetAlarmListEventAlarmGetQueryParams>;
  children: React.ReactNode;
}

export default function AlarmListSearchStringParser(
  props: AlarmListSearchStringParserProps,
): React.ReactElement {
  return (
    <SearchParamParser
      {...props}
      parser={(parsed) => {
        return removeMapBlanks({
          open_alarms_only: parse_to_bool(parsed, "open_alarms_only"),
        });
      }}
    />
  );
}
