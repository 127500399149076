import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Account } from "kubb";
import type { Location } from "kubb";
import type { NewAccount } from "kubb";
import * as Yup from "yup";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import SingleDatePicker from "../../components/Forms/SingleDatePicker";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { MutateEntityWrapperResult } from "../../hooks/updateEntity.tsx";

interface FormValues
  extends Omit<
    NewAccount,
    "company_id" | "scheme_id" | "location_id" | "opening_date"
  > {
  opening_date: Dayjs;
}

const NewAccountSchema = Yup.object().shape({
  accountReference: yupRequiredString,
  openingDate: Yup.date().required(),
});

function NewAccountForm(props: {
  location: Location;
  create: MutateEntityWrapperResult<Account, NewAccount>;
}) {
  const { create, location } = props;

  const initialValues: FormValues = {
    account_reference: "",
    opening_date: dayjs().startOf("day"),
  };
  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    create
      .update({
        ...values,
        opening_date: values.opening_date.format().slice(0, 10),
        company_id: location.company_id,
        scheme_id: location.scheme_id,
        location_id: location.location_id,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={NewAccountSchema}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <NewTextFormComponent
              fieldName={"accountReference"}
              label={{ label: "Account Reference" }}
            />
            <SingleDatePicker
              dateName={"openingDate"}
              showPresets={false}
              label={{ label: "Opening Date" }}
              timeZone={"Europe/London"}
            />
            <Button
              color={"brandLight"}
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
            >
              Create Account
            </Button>
          </Form>
        )}
      </Formik>
      <ErrorBanner error={create.error} />
    </DisplayIfUserType>
  );
}

export default NewAccountForm;
