import type { FormikProps } from "formik";
import type React from "react";
import type { AssetPositionAssetLinkOut } from "../../../../kubb";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";

function AssetPositionAssetLinkForm(
  props: FormikProps<AssetPositionAssetLinkOut>,
): React.ReactElement {
  return (
    <>
      <AssetPositionAssetLinkDateSelect
        serial_number={props.values.serial_number}
        customerReference={props.values.asset_position_customer_reference}
      />
    </>
  );
}

export default AssetPositionAssetLinkForm;
