import client from 'client'
import type {
  GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse,
  GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryParams,
  GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet422,
} from '../../types/GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey = (
  params?: GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryParams,
) => [{ url: '/report/time_series/latest/stats' }, ...(params ? [params] : [])] as const

export type GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey = ReturnType<
  typeof getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey
>

/**
 * @summary Get Latest Time Series Data Stats
 * {@link /report/time_series/latest/stats}
 */
export async function getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet(
  params?: GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse,
    ResponseErrorConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet422>,
    unknown
  >({ method: 'GET', url: `/report/time_series/latest/stats`, params, ...requestConfig })
  return res
}

export function getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryOptions(
  params?: GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>,
    ResponseErrorConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet422>,
    ResponseConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet(params, config)
    },
  })
}

/**
 * @summary Get Latest Time Series Data Stats
 * {@link /report/time_series/latest/stats}
 */
export function useGetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet<
  TData = ResponseConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>,
  TQueryData = ResponseConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>,
  TQueryKey extends QueryKey = GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey,
>(
  params?: GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>,
        ResponseErrorConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryKey(params)

  const query = useQuery({
    ...(getLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}