import dayjs, { type Dayjs } from "dayjs";
import { startCase } from "lodash-es";
import type React from "react";
import {
  AssetPositionRow,
  AssetRow,
  LocationRow,
} from "../../../components/Table/AssetRegisterData";
import {
  singleAggregationTimeAssetDataFormatter,
  singleAggregationTimeAssetDataSelector,
} from "../../../components/Table/Formatters";
import PrivacyModeDataTable, {
  type PrivacyModeTableColumn,
} from "../../../components/Text/PrivacyModeDataTableCell";
import type { SingleAggregationTimeAssetData } from "../../../components/TimeSeries/model";
import {
  assetPositionSortValue,
  locationSortValue,
} from "../allPeriodReportTable/AssetRegisterReportTable";

type Props = {
  reportData: Array<SingleAggregationTimeAssetData>;
  times: Array<Dayjs>;
  start_time: Dayjs;
  end_time: Dayjs;
  showAsset?: boolean;
};

export default function TimeSeriesReportTable(
  props: Props,
): React.ReactElement {
  const { start_time, end_time, showAsset = true } = props;
  const columns: PrivacyModeTableColumn<SingleAggregationTimeAssetData>[] = [
    ...[
      {
        name: "Location",
        selector: locationSortValue,
        cell: LocationRow(start_time, end_time),
        sortable: true,
        width: "250px",
        compact: true,
        privacyMode: true,
      },

      {
        name: "Asset Position",
        selector: assetPositionSortValue,
        cell: AssetPositionRow(start_time, end_time),
        sortable: true,
        width: "200px",
        compact: true,
        privacyMode: true,
      },
      {
        name: "Asset Type",
        selector: (row: SingleAggregationTimeAssetData) => row.asset_type,
        cell: (row: SingleAggregationTimeAssetData) =>
          startCase(row.asset_type),
        sortable: true,
        compact: true,
        width: "120px",
      },
    ],
    ...(showAsset
      ? [
          {
            name: "Asset",
            selector: (row: SingleAggregationTimeAssetData) =>
              row.serial_number || "-",
            sortable: true,
            cell: AssetRow(start_time, end_time, false, true),
            width: "150px",
            compact: true,
            privacyMode: true,
          },
        ]
      : []),

    ...props.times.map((value) => {
      return {
        name: dayjs(value).format("DD/MM/YYYY"),
        selector: singleAggregationTimeAssetDataSelector(value),
        right: true,
        compact: true,
        sortable: true,
        minWidth: "100px",
        format: singleAggregationTimeAssetDataFormatter(value, 1),
      } as PrivacyModeTableColumn<SingleAggregationTimeAssetData>;
    }),
  ];

  return (
    <PrivacyModeDataTable
      columns={columns}
      data={props.reportData}
      pagination
      fixedHeader
    />
  );
}
