import client from 'client'
import type { GetExportJobListJobGetQueryResponse, GetExportJobListJobGetQueryParams, GetExportJobListJobGet422 } from '../../types/GetExportJobListJobGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getExportJobListJobGetQueryKey = (params?: GetExportJobListJobGetQueryParams) => [{ url: '/job' }, ...(params ? [params] : [])] as const

export type GetExportJobListJobGetQueryKey = ReturnType<typeof getExportJobListJobGetQueryKey>

/**
 * @summary Get Export Job List
 * {@link /job}
 */
export async function getExportJobListJobGet(params?: GetExportJobListJobGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetExportJobListJobGetQueryResponse, ResponseErrorConfig<GetExportJobListJobGet422>, unknown>({
    method: 'GET',
    url: `/job`,
    params,
    ...requestConfig,
  })
  return res
}

export function getExportJobListJobGetQueryOptions(
  params?: GetExportJobListJobGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getExportJobListJobGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetExportJobListJobGetQueryResponse>,
    ResponseErrorConfig<GetExportJobListJobGet422>,
    ResponseConfig<GetExportJobListJobGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getExportJobListJobGet(params, config)
    },
  })
}

/**
 * @summary Get Export Job List
 * {@link /job}
 */
export function useGetExportJobListJobGet<
  TData = ResponseConfig<GetExportJobListJobGetQueryResponse>,
  TQueryData = ResponseConfig<GetExportJobListJobGetQueryResponse>,
  TQueryKey extends QueryKey = GetExportJobListJobGetQueryKey,
>(
  params?: GetExportJobListJobGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetExportJobListJobGetQueryResponse>, ResponseErrorConfig<GetExportJobListJobGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getExportJobListJobGetQueryKey(params)

  const query = useQuery({
    ...(getExportJobListJobGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetExportJobListJobGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}