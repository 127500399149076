import type React from "react";
import LatestDataReportQueryForm from "./LatestDataReportQueryForm";
import LatestDataReportQueryUrlWrapper from "./LatestDataReportQueryUrlWrapper";
import LatestDataReportTable from "./LatestDataReportTable";
import LatestTimeSeriesApiWrapper from "./LatestTimeSeriesApiWrapper";
import type { LatestDataReportParams } from "./model";

interface PProps {
  company_id: string;
  scheme_id: string;
}

const LatestTimeSeriesDataReport = (props: PProps): React.ReactElement => {
  return (
    <LatestDataReportQueryUrlWrapper defaultValues={{}}>
      {(
        params: LatestDataReportParams,
        onChange: (v: LatestDataReportParams) => void,
      ) => {
        return (
          <>
            <LatestDataReportQueryForm
              onSubmit={(v) => {
                onChange(v);
                return Promise.resolve();
              }}
              initialValues={params}
            />
            <LatestTimeSeriesApiWrapper request={{ ...props, ...params }}>
              {({ data }) => {
                return <LatestDataReportTable data={data} {...props} />;
              }}
            </LatestTimeSeriesApiWrapper>
          </>
        );
      }}
    </LatestDataReportQueryUrlWrapper>
  );
};

export default LatestTimeSeriesDataReport;
