import type { ResponseConfig } from "@kubb/plugin-client/clients/axios";
import type { UseQueryResult } from "@tanstack/react-query";
import type { Cursor } from "kubb";
import type React from "react";
import { useEffect, useState } from "react";
import NewApiPaginatedTable from "./NewApiPaginatedTable";

export interface FilterFormProps {
  refresh: () => void;
}

export default function NewGetListPaginatedTable<
  TItem,
  TQuery,
  TData = TItem[],
>(props: {
  header: [React.ReactNode, number] | null;
  cursor?: Cursor;
  row: (
    item: TItem,
    update: (updatedItem: any) => void,
  ) => React.ReactElement<{ key: string }>;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
  filterForm?: ({ refresh }: FilterFormProps) => React.ReactElement;
  params: TQuery;
  useQuery: (
    variables: TQuery,
  ) => UseQueryResult<ResponseConfig<TData> | undefined, any>;
  lastUpdateRequested?: Date;
}) {
  const [cursor, setCursor] = useState<Cursor>(
    props.cursor || { max_items: 25 },
  );
  const [lastUpdateRequested, setLastUpdateRequested] = useState(
    props.lastUpdateRequested,
  );

  const { useQuery, params, ...rest } = props;
  const { data, isPending, error, refetch } = useQuery({
    ...params,
    ...cursor,
  });

  useEffect(() => {
    if (
      props.lastUpdateRequested &&
      props.lastUpdateRequested !== lastUpdateRequested
    ) {
      setLastUpdateRequested(props.lastUpdateRequested);
      refetch();
    }
  }, [props.lastUpdateRequested, lastUpdateRequested, refetch]);

  console.log(error);
  return (
    <>
      {/*{props.filterForm?.({ refresh })}*/}
      <NewApiPaginatedTable
        data={{
          data: data ? (data.data as Array<TItem>) : [],
          cursor: data?.headers?.["x-cursor"],
          setCursor: (cursor) => {
            if (cursor) {
              setCursor(cursor);
            }
          },
        }}
        error={error ? (error as unknown as Error) : null}
        loading={isPending}
        refresh={refetch}
        // updateItem={updateItem}
        {...rest}
      />
    </>
  );
}
