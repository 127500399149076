import type { AssetPosition, AssetUseCaseEnum } from "kubb";
import { assetUseCaseEnum } from "kubb";
import { startCase } from "lodash-es";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import { Badge } from "../Theme/badge";

export default function AssetUseCaseHumanLabel(
  props: RequireExactlyOne<
    {
      asset?: AssetPosition;
      useCase?: AssetUseCaseEnum;
      asBadge?: boolean;
    },
    "asset" | "useCase"
  >,
): React.ReactElement {
  const { asset, asBadge = true } = props;
  const useCase: AssetUseCaseEnum =
    asset?.use_case || props.useCase || assetUseCaseEnum.NONE;

  if (asBadge) {
    return <Badge>{useCase}</Badge>;
  } else {
    return <>{startCase(useCase.toLowerCase())}</>;
  }
}
