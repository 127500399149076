import type { UserIdentifier } from "kubb";
import { isNil } from "lodash-es";
import type React from "react";

export default function UserReference(props: {
  userId?: string;
  user?: UserIdentifier;
}): React.ReactElement {
  const haveUserId = props.userId && props.userId.length === 36;
  const haveUserName = !isNil(props.user?.user_name);

  if (haveUserName) {
    return <>{props.user?.user_name}</>;
  }
  if (haveUserId) {
    return <>...{props.userId?.slice(24)}</>;
  }
  return <>{props.userId}</>;
}
