import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import {
  type TimeSeriesDataProjection,
  timeSeriesDataProjectionEnum,
} from "kubb";
import type React from "react";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import DateRangePicker from "../../../../components/Forms/DateRangePicker/DateRangePicker";
import { Button } from "../../../../components/Theme/button";
import { Col, Container } from "../../../../components/Theme/grid";
import useScreenBreakpoint from "../../../../hooks/useScreenBreakpoint";
import { doesDeviceHaveParsedData } from "../../../../model/assets/deviceData";
import type { Asset } from "../../../../utils/object.tsx";

export interface AssetTimeSeriesRequestParams {
  start_datetime?: Dayjs;
  end_datetime?: Dayjs;
  projection?: TimeSeriesDataProjection;
}

interface PProps {
  device: Asset;
  loadData: (values: AssetTimeSeriesRequestParams) => void;
  showProjection: boolean;
}

export default function AssetDataSelectionForm(
  props: PProps & AssetTimeSeriesRequestParams,
): React.ReactElement {
  const initialValues = (): AssetTimeSeriesRequestParams => {
    const { start_datetime, end_datetime, projection } = props;
    return { start_datetime, end_datetime, projection };
  };

  const { device, loadData } = props;

  const showProjection =
    doesDeviceHaveParsedData(device) && props.showProjection;

  const breakpoint = useScreenBreakpoint("md");

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={({ start_datetime, end_datetime, projection }): void => {
        loadData({ start_datetime, end_datetime, projection });
      }}
    >
      {(): React.ReactElement => (
        <Form>
          <Container gap={breakpoint ? 4 : 1}>
            <Col sm={12} md={6} xl={8}>
              <DateRangePicker
                startDateName={"start_datetime"}
                endDateName={"end_datetime"}
                maxDate={dayjs().endOf("day")}
                label={{ label: "Date Range", size: "sm" }}
              />
            </Col>
            {showProjection && (
              <Col sm={12} md={4} xl={3}>
                <ButtonGroupField
                  fieldName={"projection"}
                  label={{ label: "Data Style", size: "sm" }}
                  options={[
                    {
                      label: "Standard",
                      value: timeSeriesDataProjectionEnum.parsed,
                    },
                    { label: "Raw", value: timeSeriesDataProjectionEnum.raw },
                  ]}
                  fullWidth={true}
                />
              </Col>
            )}
            <Col
              sm={12}
              md={2}
              xl={1}
              className={"text-right md:pt-[23px] pb-[1px]"}
            >
              <Button
                type={"submit"}
                color={"brandLight"}
                className={"w-full"}
                paddingY={1.5}
              >
                Search
              </Button>
            </Col>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
