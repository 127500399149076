import { type AxiosError, isAxiosError } from "axios";
import { isNil, startCase } from "lodash-es";
import type React from "react";
import { TypedError } from "../../model/error";

interface ErrorMessage {
  title?: string;
  text: React.ReactElement;
}

type MeterpointAxiosError = AxiosError<{ message?: string }>;

function isMeterpointAxiosError(
  error: AxiosError,
): error is MeterpointAxiosError {
  if (
    error.response?.data &&
    !isNil((error.response?.data as { message?: undefined }).message)
  )
    return true;
  return false;
}

export function axiosErrorAsText(error: AxiosError): ErrorMessage {
  const defaultErrorText = `Something went wrong: ${error.message}`;
  switch (error.response?.status) {
    case 400:
      if (isMeterpointAxiosError(error))
        return {
          text: <p>{error.response?.data?.message || defaultErrorText}</p>,
        };
      else return { text: <p>{defaultErrorText}</p> };

    case 422:
      return { text: <p>The request is not valid.</p> };
    case 500:
      return {
        text: (
          <p>
            <b>Server Error</b>. Something went wrong on the Meterpoint server.
            You can try again or let us know if you see this consistently.
          </p>
        ),
      };
    default:
      return { text: <p>{defaultErrorText}</p> };
  }
}

const typedErrorAsText = (error: TypedError): ErrorMessage => {
  return { text: <p>{error.message}</p>, title: startCase(error.errorType) };
};

const errorToTitleAndText = (
  error: Error | AxiosError | TypedError,
): ErrorMessage => {
  if (isAxiosError(error)) {
    return axiosErrorAsText(error as AxiosError);
  }
  if (error instanceof TypedError) {
    return typedErrorAsText(error);
  }
  return { text: <p>error.message</p>, title: `Unhandled Error ${error.name}` };
};

export { errorToTitleAndText };
