import type { AssetRegisterItem } from "kubb";
import { startCase } from "lodash-es";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import AssetPositionNumbersToStringList from "../../../components/AssetPosition/AssetPositionNumberSummaryList";
import FormatTextAtSize from "../../../components/Text/FormatTextAtSize";
import { StackedListThreeColumnItem } from "../../../components/Theme/stacked-list";

function IntegrationListRow(props: {
  showScheme?: boolean;
  assetRegisterItem: AssetRegisterItem;
  refresh?: () => void;
}) {
  const { assetRegisterItem } = props;
  const { asset_position } = assetRegisterItem;

  return (
    <StackedListThreeColumnItem
      key={asset_position.asset_position_id}
      column1={
        <div className="min-w-0 flex-auto">
          <AssetPositionIdLink
            assetPosition={assetRegisterItem.asset_position}
            text={assetRegisterItem.location.address}
          />
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          <FormatTextAtSize
            breakpoint={"sm"}
            smallClassName={"text-xs"}
            smallBrackets={true}
          >
            {startCase(
              assetRegisterItem.asset_position.configuration?.integration
                ?.integration_type,
            )}
          </FormatTextAtSize>
        </div>
      }
      column3={
        <div className="min-w-0 flex-auto text-xs md:text-base">
          <AssetPositionNumbersToStringList
            numbers={assetRegisterItem.asset_position.child_asset_positions}
            includeGateways={false}
          />
        </div>
      }
      column1Size={"w-5/12"}
      column2Size={"w-3/12"}
      column3Size={"w-4/12"}
    />
  );
}

export default IntegrationListRow;
