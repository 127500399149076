import { frequencyEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function frequencyEnumSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Daily", value: frequencyEnum.DAILY },
    { label: "Weekly", value: frequencyEnum.WEEKLY },
    { label: "Monthly", value: frequencyEnum.MONTHLY },
  ];

  return <SelectComboField options={options} {...props} />;
}
