import dayjs, { type Dayjs } from "dayjs";
import { type AggregatedReportRequest, assetTypeEnum, periodEnum } from "kubb";
import type React from "react";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import AllPeriodReportTable, {
  type DataColumnDefinition,
} from "../allPeriodReportTable/NewAllPeriodReportTable";
import type { HeatNetworkReportParams } from "./model";

interface Props {
  company_id?: string;
  scheme_id?: string;
  query: HeatNetworkReportParams;
}

export const RUN_TIME_REPORT_AGGREGATIONS = [
  "sum_(domestic_hot_water)",
  "sum_(space_heating)",
  "sum_(standby)",
  "sum_(passive_standby)",
  "sum_(error)",
  "sum_(unknown)",
];

const RUN_TIME_COLUMNS: Array<DataColumnDefinition> = [
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(domestic_hot_water)",
    name: "Average Daily Hours Domestic Hot Water",
  },
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(space_heating)",
    name: "Average Daily Hours Space Heating",
  },
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(standby)",
    name: "Average Daily Hours Standby",
  },
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(passive_standby)",
    name: "Average Daily Hours Passive Standby",
  },
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(error)",
    name: "Average Daily Hours Error",
  },
  {
    parameter: "Synthetic Run Time",
    aggregation: "sum_(unknown)",
    name: "Average Daily Hours Unknown",
  },
];

const RunTimeReportPage = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const params = (): AggregatedReportRequest => {
    if (props.company_id && props.scheme_id) {
      return {
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        aggregations: RUN_TIME_REPORT_AGGREGATIONS.map((value) => {
          return {
            parameter: "Synthetic Run Time",
            aggregation: value,
          };
        }),
        asset_types: [
          assetTypeEnum.heat_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ],
        start_time: startDatetime.toISOString(),
        end_time: endDatetime.toISOString(),
        use_case: props.query.useCases,
        period: periodEnum.ALL,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const edt: Dayjs = dayjs(endDatetime);
  edt.add(1, "ms");
  const duration = 24 / edt.diff(startDatetime, "minutes");

  return (
    <>
      {props.company_id && props.scheme_id && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => (
            <AllPeriodReportTable
              data={data}
              columnDefinitions={RUN_TIME_COLUMNS.map((value) => {
                return { ...value, factor: duration };
              })}
              downloadFilename={`${props.scheme_id}_run_time_report`}
              startDatetime={startDatetime}
              endDatetime={endDatetime}
            />
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default RunTimeReportPage;
