import { useFormikContext } from "formik";
import useUserHasRoles from "hooks/user/getCurrentUserRole";
import { type MBusAddressingModeEnum, mBusAddressingModeEnum } from "kubb";
import { isArray } from "lodash-es";
import type React from "react";
import type { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";
import NewTextFormComponent from "../../../../../components/Forms/NewTextFormComponent";
import {
  SelectComboField,
  type Value,
} from "../../../../../components/Forms/SelectCombo/SelectCombo";
import { yupRequiredString } from "../../../../../components/Forms/yupValidators";

export const MBusConfigurationFormSchema = Yup.object().shape({
  addressing_mode: yupRequiredString,
  primary_address: Yup.number().when(
    ["addressing_mode"],
    (addressingMode, schema) => {
      console.log(addressingMode);
      console.log(schema);
      console.log(addressingMode === mBusAddressingModeEnum.secondary);
      return addressingMode === mBusAddressingModeEnum.secondary
        ? schema.notRequired().nullable(true)
        : schema.min(0).max(255).integer().required();
    },
  ),
  encryption_key: Yup.string().max(32).nullable(true),
  baud_rate: Yup.number().required(),
});

export default function MbusConfigurationForm({
  namespace,
  addressing_mode,
}: {
  namespace?: string;
  addressing_mode?: MBusAddressingModeEnum;
}): React.ReactElement {
  const allowComplexEditing = useUserHasRoles("superadmin");
  const allowEditing = useUserHasRoles("admin");
  const { setFieldValue } = useFormikContext();
  const primaryAddress = addressing_mode === mBusAddressingModeEnum.primary;

  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>MBus Configuration</h4>

      <SelectComboField
        label={{ label: "Addressing Mode" }}
        options={[
          { label: "Primary", value: mBusAddressingModeEnum.primary },
          { label: "Secondary", value: mBusAddressingModeEnum.secondary },
        ]}
        fieldName={withNamespace("addressing_mode")}
        disabled={!allowComplexEditing}
        onChange={(value: SingleValue<Value> | MultiValue<Value>) => {
          if (isArray(value)) {
            throw new Error("MBus Addressing Mode should not be a list.");
          }
          if (
            (value as SingleValue<Value>) === mBusAddressingModeEnum.primary
          ) {
            setFieldValue(withNamespace("primary_address"), 0);
          }
          if (
            (value as SingleValue<Value>) === mBusAddressingModeEnum.secondary
          ) {
            setFieldValue(withNamespace("primary_address"), "");
          }
        }}
      />
      <NewTextFormComponent
        fieldName={withNamespace("primary_address")}
        label={{ label: "Primary Address" }}
        type={"number"}
        disabled={!allowComplexEditing || !primaryAddress}
      />
      <NewTextFormComponent
        fieldName={withNamespace("encryption_key")}
        label={{ label: "Wireless Encryption Key" }}
        disabled={!allowEditing}
      />
      <SelectComboField
        label={{ label: "Baud Rate" }}
        options={[
          { label: 300, value: 300 },
          { label: 2400, value: 2400 },
          { label: 9600, value: 9600 },
        ]}
        fieldName={withNamespace("baud_rate")}
        disabled={!allowEditing || !allowComplexEditing}
      />
    </>
  );
}
