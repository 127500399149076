import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "components/utils/HighlightNullAndOldDates";
import type { Event, Note } from "kubb";
import { useGetEventListEventGet } from "kubb";
import type React from "react";
import { useState } from "react";
import NewGetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { Button } from "../../components/Theme/button";
import Collapse from "../../components/Theme/collapse";
import { StackedListItem } from "../../components/Theme/stacked-list";

export default function NoteHistoryAccordian(props: {
  note: Note;
  lastUpdate: Date;
}): React.ReactElement {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(!visible)} className={"btn-link px-0"}>
        <h5 className={"mb-0"}>
          <FontAwesomeIcon
            icon={visible ? faCaretDown : faCaretRight}
            fixedWidth
          />
          History
        </h5>
      </Button>
      <Collapse isOpen={visible}>
        <NewGetListPaginatedTable
          params={{
            note_id: props.note.note_id,
            company_id: props.note.company_id,
            scheme_id: props.note.scheme_id,
          }}
          header={null}
          useQuery={useGetEventListEventGet}
          row={(item: Event): React.ReactElement => {
            return (
              <StackedListItem key={item.event_id}>
                {item.event_type} on {formatDate(item.event_date_time)} by{" "}
                {item.user.user_name}
              </StackedListItem>
            );
          }}
        />
      </Collapse>
    </>
  );
}
