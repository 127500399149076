import type { AssetPosition } from "kubb";
import { useRefreshList } from "../../../../components/Pagination/NewApiPaginatedTable.tsx";
import StateTabGroupInterface from "../../../../components/TabGroup/StateTabGroupInterface";
import { Divider } from "../../../../components/Theme/divider";
import { Heading } from "../../../../components/Theme/heading";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CreateAssetForm from "../CreateAssetForm";
import AssetPositionAssetLinkCreateForm from "../assetPositionAssetLinks/AssetPositionAssetLinkCreateForm";
import AssetPositionAssetList from "../assetPositionAssetLinks/AssetPositionAssetList";

function AssetPositionAssetHistoryTab(props: {
  assetPosition: AssetPosition;
  refresh: () => void;
}) {
  const refreshList = useRefreshList();

  const { assetPosition, refresh } = props;

  function handleRefresh() {
    refresh();
    refreshList.refresh();
  }
  return (
    <>
      <Heading>Asset Position Asset History</Heading>
      <AssetPositionAssetList
        company_id={assetPosition.company_id}
        scheme_id={assetPosition.scheme_id}
        asset_position_id={assetPosition.asset_position_id}
        lastUpdated={refreshList.lastRefresh}
        refreshList={handleRefresh}
      />
      <DisplayIfUserType userTypes={"admin"}>
        <Divider className={"py-4"} />
        <div className={"py-4"}>
          <Heading>Assign Asset</Heading>
          <StateTabGroupInterface
            tabs={[
              {
                tabName: "Select",
                tabPath: "select",
                tabContent: (
                  <AssetPositionAssetLinkCreateForm
                    assetPosition={assetPosition}
                    refresh={handleRefresh}
                  />
                ),
              },
              {
                tabName: "Create",
                tabPath: "create",
                tabContent: (
                  <>
                    <h4>Create Asset</h4>
                    <CreateAssetForm
                      assetPosition={assetPosition}
                      refresh={handleRefresh}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </DisplayIfUserType>
    </>
  );
}

export default AssetPositionAssetHistoryTab;
