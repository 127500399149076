import type React from "react";
import SelectedScheme from "../../../../components/Scheme/SelectedScheme";
import { OneCardPage } from "../../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../../hooks/setPageTitle";
import AssetList from "./AssetList";
import AssetListFilterForm from "./AssetListFilterForm";
import { AssetListSearchParam } from "./AssetListSearchParam.tsx";

const InnerAssetListPage = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      {(schemeProps) => {
        return (
          <>
            <AssetListFilterForm
              includeGroup={false}
              includeUseCase={false}
              includeStatus={false}
              includeTextSearch={true}
              {...props}
            />
            <AssetList {...props} {...schemeProps} />
          </>
        );
      }}
    </SelectedScheme>
  );
};
const AssetListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Asset List"}
      breadcrumbs={[{ url: "/admin/asset", name: "Asset List" }]}
      setPageTitle={schemeObjectPageTitle("Assets")}
    >
      <AssetListSearchParam
        initialState={{
          asset_type: undefined,
          asset_position_id: undefined,
          text_search: undefined,
        }}
      >
        <InnerAssetListPage sort={"serial_number"} />
      </AssetListSearchParam>
    </OneCardPage>
  );
};

export default AssetListPage;
