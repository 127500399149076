import { faLink } from "@fortawesome/free-solid-svg-icons";
import type { AssetPosition } from "kubb";
import type React from "react";
import type { Asset } from "utils/object";
import UpdateButton from "../../../components/Buttons/UpdateButton.tsx";
import { useCreateNewAssetPositionLink } from "../../../hooks/createEntity.tsx";

type Props = {
  assetPosition: AssetPosition;
  asset: Asset;
  refresh?: () => any;
};

const LinkAssetPositionFromAssetButton = (props: Props): React.ReactElement => {
  return (
    <UpdateButton
      update={useCreateNewAssetPositionLink()}
      updatedEntity={{
        asset_id: props.asset.asset_id,
        asset_position_id: props.assetPosition.asset_position_id,
        company_id: props.asset.company_id,
      }}
      color={"brandLight"}
      postUpdate={props.refresh}
      text={"Link Asset"}
      icon={faLink}
    />
  );
};

export default LinkAssetPositionFromAssetButton;
