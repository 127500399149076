import client from 'client'
import type {
  CreateNewLocationLocationPostMutationRequest,
  CreateNewLocationLocationPostMutationResponse,
  CreateNewLocationLocationPostQueryParams,
  CreateNewLocationLocationPost422,
} from '../../types/CreateNewLocationLocationPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewLocationLocationPostMutationKey = () => [{ url: '/location' }] as const

export type CreateNewLocationLocationPostMutationKey = ReturnType<typeof createNewLocationLocationPostMutationKey>

/**
 * @summary Create New Location
 * {@link /location}
 */
export async function createNewLocationLocationPost(
  data: CreateNewLocationLocationPostMutationRequest,
  params?: CreateNewLocationLocationPostQueryParams,
  config: Partial<RequestConfig<CreateNewLocationLocationPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewLocationLocationPostMutationResponse,
    ResponseErrorConfig<CreateNewLocationLocationPost422>,
    CreateNewLocationLocationPostMutationRequest
  >({ method: 'POST', url: `/location`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Location
 * {@link /location}
 */
export function useCreateNewLocationLocationPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewLocationLocationPostMutationResponse>,
      ResponseErrorConfig<CreateNewLocationLocationPost422>,
      { data: CreateNewLocationLocationPostMutationRequest; params?: CreateNewLocationLocationPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewLocationLocationPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewLocationLocationPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewLocationLocationPostMutationResponse>,
    ResponseErrorConfig<CreateNewLocationLocationPost422>,
    { data: CreateNewLocationLocationPostMutationRequest; params?: CreateNewLocationLocationPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewLocationLocationPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}