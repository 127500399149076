import type { Company } from "kubb";
import React, { createContext, useReducer } from "react";

interface CompanyState {
  loading: boolean;
  companies?: Array<Company>;
  selected?: Company;
  error?: Error;
  stale?: boolean;
}

type CompanyActions =
  | "LOADING_COMPANIES_STARTED"
  | "LOADING_COMPANIES_SUCCESS"
  | "LOADING_COMPANIES_ERROR"
  | "SET_STALE"
  | "SELECT_COMPANY"
  | "SELECT_COMPANY_BY_ID"
  | "LOGOUT";

export interface CompanyAction {
  type: CompanyActions;
  payload?: any;
}

function findCompanyById(
  company_id: string,
  state: CompanyState,
): Company | undefined {
  if (company_id === "NONE") {
    return undefined;
  }
  if (state.companies !== undefined && state.companies.length > 0) {
    const company = state.companies.filter(
      (company) => company.company_id === company_id,
    );
    if (company.length === 1) {
      return company[0];
    }
  }
  throw new Error(`Can not find company ${company_id}`);
}

const Reducer = (state: CompanyState, action: CompanyAction): CompanyState => {
  console.log(action);
  switch (action.type) {
    case "LOADING_COMPANIES_STARTED":
      return { loading: true };
    case "LOADING_COMPANIES_SUCCESS":
      return {
        loading: false,
        companies: action.payload,
        stale: false,
        selected: state.selected,
      };
    case "LOADING_COMPANIES_ERROR":
      return { loading: false, error: action.payload, stale: false };
    case "SELECT_COMPANY":
      return { ...state, selected: action.payload };
    case "SET_STALE":
      return { ...state, stale: true };
    case "SELECT_COMPANY_BY_ID":
      return { ...state, selected: findCompanyById(action.payload, state) };
    case "LOGOUT":
      return { loading: false };

    default:
      return state;
  }
};

const CompanyContext = createContext<{
  state: CompanyState;
  dispatch: React.Dispatch<CompanyAction>;
}>({ state: { loading: false }, dispatch: () => null });

const CompanyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(Reducer, { loading: false });

  return (
    <CompanyContext.Provider value={{ state, dispatch }}>
      {children}
    </CompanyContext.Provider>
  );
};

function useSelectedCompany(): Company | undefined {
  return React.useContext(CompanyContext).state.selected as Company;
}

function useRequiredSelectedCompany(): Company {
  const company = useSelectedCompany();
  if (!company) {
    throw new Error("Company not found");
  }
  return company;
}

function useRequiredSelectedCompanyId(): string {
  const company = useSelectedCompany();
  if (!company) {
    throw new Error("Company not found");
  }
  return company.company_id;
}

function useCompanies(): Array<Company> | undefined {
  return React.useContext(CompanyContext).state.companies;
}

export {
  CompanyContext,
  CompanyProvider,
  useSelectedCompany,
  useCompanies,
  useRequiredSelectedCompany,
  useRequiredSelectedCompanyId,
};
