import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import dayjs from "dayjs";
import {
  type AssetPosition,
  type AssetRegisterDataItemBaseWithAsset,
  type GroupedAssetRegisterItemAggregatedTimeSeries,
  assetPositionActionTypesEnum,
} from "kubb";
import type React from "react";
import type { TableColumn } from "react-data-table-component";
import type { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";
import AssetRegisterReportTable from "../../../containers/report/allPeriodReportTable/AssetRegisterReportTable";
import { AssetPositionCacheProvider } from "../../../reducers/assetPositionCachingReducer";
import CreateActionButton from "../assetActions/CreateActionButton";
import ReceiverAssignmentTableCell, {
  getReceiverAssignmentTableValue,
} from "./ReceiverAssignmentTableCell";
import type { WirelessRFDataApiWrapperProps } from "./WirelessRFDataApiWrapper";

function convertGroupedAssetRegisterItemAggregatedTimeSeries(
  data: GroupedAssetRegisterItemAggregatedTimeSeries,
  field: string,
  aggregation: string,
): { [key: string]: Array<number | null> } {
  return Object.fromEntries(
    Object.keys(data.aggregations).map((k) => {
      return [k, data.aggregations[k][field][aggregation]];
    }),
  );
}

function makeConvertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTableFunction(
  field: string,
  aggregation: string,
) {
  return function convertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTable(
    data: GroupedAssetRegisterItemAggregatedTimeSeries,
  ): AssetRegisterDataItem {
    return {
      asset_id: data.asset_id,
      serial_number: data.serial_number,
      asset_type: data.asset_type,
      asset_position_id: data.asset_position_id,
      asset_position_reference: data.asset_position_reference,
      location_id: data.location_id,
      location_address: data.location_address,
      install_date: data.install_date ? dayjs(data.install_date) : undefined,
      uninstall_date: data.uninstall_date
        ? dayjs(data.uninstall_date)
        : undefined,
      ...convertGroupedAssetRegisterItemAggregatedTimeSeries(
        data,
        field,
        aggregation,
      ),
    };
  };
}

export default function ReceiverAssignmentTable(
  props: WirelessRFDataApiWrapperProps & { wirelessCluster: AssetPosition },
): React.ReactElement {
  const { data } = props;

  const columns = (): TableColumn<AssetRegisterDataItem>[] => {
    if (data) {
      return data.receivers?.map(
        (receiver: AssetRegisterDataItemBaseWithAsset, index: number) => {
          return {
            name: (
              <span
                style={{
                  textAlign: "right",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {receiver.serial_number}
              </span>
            ),
            selector: getReceiverAssignmentTableValue(receiver),
            cell: ReceiverAssignmentTableCell(receiver, index === 0),
            sortable: true,
            right: true,
            minWidth: "150px",
          };
        },
      );
    }
    return [];
  };

  const mappedData = data?.data.map(
    makeConvertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTableFunction(
      "rssi",
      "mean",
    ),
  );

  return (
    <>
      {data && (
        <AssetPositionCacheProvider>
          <AssetRegisterReportTable
            startDatetime={dayjs()}
            endDatetime={dayjs()}
            columnDefinitions={columns()}
            data={mappedData}
            extraActions={
              <DisplayIfUserType userTypes={"admin"}>
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={
                    assetPositionActionTypesEnum.ASSIGN_ALL_ASSETS_TO_ALL_WIRELESS_RECEIVERS
                  }
                  actionName={"Assign All Assets to All Receivers"}
                />
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={
                    assetPositionActionTypesEnum.AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS
                  }
                  actionName={"Optimise Assets to Receivers"}
                />
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={
                    assetPositionActionTypesEnum.AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS_AND_SYNC
                  }
                  actionName={
                    "Optimise Assets to Receivers And Sync Device Lists"
                  }
                />
              </DisplayIfUserType>
            }
          />
        </AssetPositionCacheProvider>
      )}
    </>
  );
}
