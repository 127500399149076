import client from 'client'
import type {
  UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest,
  UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse,
  UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams,
  UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut422,
} from '../../types/UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationKey = () =>
  [{ url: '/user/profile/company/{company_id}/properties' }] as const

export type UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationKey = ReturnType<
  typeof updateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationKey
>

/**
 * @summary Update Own User Company Properties
 * {@link /user/profile/company/:company_id/properties}
 */
export async function updateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut(
  company_id: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams['company_id'],
  data?: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest,
  config: Partial<RequestConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse,
    ResponseErrorConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut422>,
    UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest
  >({ method: 'PUT', url: `/user/profile/company/${company_id}/properties`, data, ...requestConfig })
  return res
}

/**
 * @summary Update Own User Company Properties
 * {@link /user/profile/company/:company_id/properties}
 */
export function useUpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse>,
      ResponseErrorConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut422>,
      {
        company_id: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams['company_id']
        data?: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse>,
    ResponseErrorConfig<UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut422>,
    {
      company_id: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams['company_id']
      data?: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest
    }
  >({
    mutationFn: async ({ company_id, data }) => {
      return updateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut(company_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}