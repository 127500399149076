import { Button } from "components/Theme/button";
import { Form, Formik } from "formik";
import type { AssetPosition } from "kubb";
import type { AssetPositionConfigurationInput } from "kubb";
import type React from "react";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { useUpdateAssetPosition } from "../../../../hooks/updateEntity";
import DataProcessingConfigurationForm from "../DataProcessingConfigurationForm";
import WirelessClusterConfigurationForm from "../WirelessClusterConfigurationForm";

interface AssetPositionConfigurationTabProps {
  assetPosition: AssetPosition;
  refresh?: () => any;
}

interface FormValues {
  dataProcessing?: { autoCreateNewAssets: string };
  wirelessCluster?: { autoOptimiseAssets: string };
}

function assetPositionToFormValues(assetPosition: AssetPosition): FormValues {
  return {
    dataProcessing: {
      autoCreateNewAssets:
        assetPosition.configuration?.data_processing?.auto_create_new_assets?.toString() ||
        "true",
    },
    wirelessCluster: {
      autoOptimiseAssets:
        assetPosition.configuration?.wireless_cluster?.auto_optimise_assets?.toString() ||
        "false",
    },
  };
}

function formValuesToConfiguration(wirelessCluster = false) {
  return (values: FormValues): AssetPositionConfigurationInput => {
    return {
      data_processing: {
        auto_create_new_assets:
          values.dataProcessing?.autoCreateNewAssets !== "false",
      },
      wireless_cluster: wirelessCluster
        ? {
            auto_optimise_assets:
              values.wirelessCluster?.autoOptimiseAssets === "true",
          }
        : undefined,
    };
  };
}

function AssetPositionConfigurationTab(
  props: AssetPositionConfigurationTabProps,
): React.ReactElement {
  const wirelessCluster =
    props.assetPosition.asset_position_type === "wireless_cluster";

  const { update, loading, error } = useUpdateAssetPosition(
    props.assetPosition,
  );

  return (
    <BlockSpinner loading={loading}>
      <Formik
        initialValues={assetPositionToFormValues(props.assetPosition)}
        onSubmit={(v, { setSubmitting }) => {
          setSubmitting(true);
          update({
            ...props.assetPosition,
            configuration: formValuesToConfiguration(wirelessCluster)(v),
          }).then(() => {
            props.refresh?.();
            setSubmitting(false);
          });
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <DataProcessingConfigurationForm namespace={"dataProcessing"} />
            {wirelessCluster && (
              <WirelessClusterConfigurationForm namespace={"wirelessCluster"} />
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid || !dirty}
            >
              Update
            </Button>
            <ErrorBanner error={error} />
          </Form>
        )}
      </Formik>
    </BlockSpinner>
  );
}

export default AssetPositionConfigurationTab;
