import client from 'client'
import type {
  GetWirelessSurveyListWirelessSurveyGetQueryResponse,
  GetWirelessSurveyListWirelessSurveyGetQueryParams,
  GetWirelessSurveyListWirelessSurveyGet422,
} from '../../types/GetWirelessSurveyListWirelessSurveyGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getWirelessSurveyListWirelessSurveyGetQueryKey = (params?: GetWirelessSurveyListWirelessSurveyGetQueryParams) =>
  [{ url: '/wireless_survey' }, ...(params ? [params] : [])] as const

export type GetWirelessSurveyListWirelessSurveyGetQueryKey = ReturnType<typeof getWirelessSurveyListWirelessSurveyGetQueryKey>

/**
 * @summary Get Wireless Survey List
 * {@link /wireless_survey}
 */
export async function getWirelessSurveyListWirelessSurveyGet(
  params?: GetWirelessSurveyListWirelessSurveyGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetWirelessSurveyListWirelessSurveyGetQueryResponse, ResponseErrorConfig<GetWirelessSurveyListWirelessSurveyGet422>, unknown>({
    method: 'GET',
    url: `/wireless_survey`,
    params,
    ...requestConfig,
  })
  return res
}

export function getWirelessSurveyListWirelessSurveyGetQueryOptions(
  params?: GetWirelessSurveyListWirelessSurveyGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getWirelessSurveyListWirelessSurveyGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetWirelessSurveyListWirelessSurveyGetQueryResponse>,
    ResponseErrorConfig<GetWirelessSurveyListWirelessSurveyGet422>,
    ResponseConfig<GetWirelessSurveyListWirelessSurveyGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getWirelessSurveyListWirelessSurveyGet(params, config)
    },
  })
}

/**
 * @summary Get Wireless Survey List
 * {@link /wireless_survey}
 */
export function useGetWirelessSurveyListWirelessSurveyGet<
  TData = ResponseConfig<GetWirelessSurveyListWirelessSurveyGetQueryResponse>,
  TQueryData = ResponseConfig<GetWirelessSurveyListWirelessSurveyGetQueryResponse>,
  TQueryKey extends QueryKey = GetWirelessSurveyListWirelessSurveyGetQueryKey,
>(
  params?: GetWirelessSurveyListWirelessSurveyGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetWirelessSurveyListWirelessSurveyGetQueryResponse>,
        ResponseErrorConfig<GetWirelessSurveyListWirelessSurveyGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getWirelessSurveyListWirelessSurveyGetQueryKey(params)

  const query = useQuery({
    ...(getWirelessSurveyListWirelessSurveyGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetWirelessSurveyListWirelessSurveyGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}