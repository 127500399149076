import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { AssetPosition } from "kubb";
import type React from "react";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import AssetPositionIdLink from "../../../../components/AssetPosition/AssetPositionIdLink";
import ObjectPropertyItem from "../../../../components/Headers/ObjectPropertyItem";
import GetAssetRegisterEntitiesCombination from "../../../../core/action/GetAssetRegisterEntitiesCombination";

const GatewayLink = (props: {
  deviceAssetPosition?: AssetPosition;
}): React.ReactElement => {
  const { deviceAssetPosition } = props;
  return (
    <>
      {deviceAssetPosition?.parent_asset_position_id ? (
        <GetAssetRegisterEntitiesCombination
          asset_position_id={deviceAssetPosition.parent_asset_position_id}
          company_id={deviceAssetPosition.company_id}
          scheme_id={deviceAssetPosition.scheme_id}
          placeholder={() => (
            <ObjectPropertyItem title={"Parent"}>
              <FontAwesomeIcon spin icon={faSpinner} aria-hidden="true" />
            </ObjectPropertyItem>
          )}
          error={() => <></>}
        >
          {({ asset, assetPosition, location }) => (
            <>
              {assetPosition &&
              assetPosition.asset_position_type === "wireless_cluster" ? (
                <ObjectPropertyItem title={"Wireless Cluster"}>
                  <AssetPositionIdLink
                    assetPosition={assetPosition}
                    text={location?.address}
                  />
                </ObjectPropertyItem>
              ) : asset ? (
                <ObjectPropertyItem title={"Gateway"}>
                  <AssetIdLink asset={asset} text={asset.serial_number} />
                </ObjectPropertyItem>
              ) : (
                <ObjectPropertyItem title={"Gateway"}>None</ObjectPropertyItem>
              )}
            </>
          )}
        </GetAssetRegisterEntitiesCombination>
      ) : (
        <>-</>
      )}
    </>
  );
};

export default GatewayLink;
