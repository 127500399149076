import client from 'client'
import type {
  CreateNewCompanyCompanyPostMutationRequest,
  CreateNewCompanyCompanyPostMutationResponse,
  CreateNewCompanyCompanyPostQueryParams,
  CreateNewCompanyCompanyPost422,
} from '../../types/CreateNewCompanyCompanyPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewCompanyCompanyPostMutationKey = () => [{ url: '/company' }] as const

export type CreateNewCompanyCompanyPostMutationKey = ReturnType<typeof createNewCompanyCompanyPostMutationKey>

/**
 * @summary Create New Company
 * {@link /company}
 */
export async function createNewCompanyCompanyPost(
  data: CreateNewCompanyCompanyPostMutationRequest,
  params?: CreateNewCompanyCompanyPostQueryParams,
  config: Partial<RequestConfig<CreateNewCompanyCompanyPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewCompanyCompanyPostMutationResponse,
    ResponseErrorConfig<CreateNewCompanyCompanyPost422>,
    CreateNewCompanyCompanyPostMutationRequest
  >({ method: 'POST', url: `/company`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Company
 * {@link /company}
 */
export function useCreateNewCompanyCompanyPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewCompanyCompanyPostMutationResponse>,
      ResponseErrorConfig<CreateNewCompanyCompanyPost422>,
      { data: CreateNewCompanyCompanyPostMutationRequest; params?: CreateNewCompanyCompanyPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewCompanyCompanyPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewCompanyCompanyPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewCompanyCompanyPostMutationResponse>,
    ResponseErrorConfig<CreateNewCompanyCompanyPost422>,
    { data: CreateNewCompanyCompanyPostMutationRequest; params?: CreateNewCompanyCompanyPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewCompanyCompanyPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}