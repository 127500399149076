import client from 'client'
import type {
  CreateWirelessSurveyWirelessSurveyPostMutationRequest,
  CreateWirelessSurveyWirelessSurveyPostMutationResponse,
  CreateWirelessSurveyWirelessSurveyPostQueryParams,
  CreateWirelessSurveyWirelessSurveyPost422,
} from '../../types/CreateWirelessSurveyWirelessSurveyPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createWirelessSurveyWirelessSurveyPostMutationKey = () => [{ url: '/wireless_survey' }] as const

export type CreateWirelessSurveyWirelessSurveyPostMutationKey = ReturnType<typeof createWirelessSurveyWirelessSurveyPostMutationKey>

/**
 * @summary Create Wireless Survey
 * {@link /wireless_survey}
 */
export async function createWirelessSurveyWirelessSurveyPost(
  data: CreateWirelessSurveyWirelessSurveyPostMutationRequest,
  params?: CreateWirelessSurveyWirelessSurveyPostQueryParams,
  config: Partial<RequestConfig<CreateWirelessSurveyWirelessSurveyPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateWirelessSurveyWirelessSurveyPostMutationResponse,
    ResponseErrorConfig<CreateWirelessSurveyWirelessSurveyPost422>,
    CreateWirelessSurveyWirelessSurveyPostMutationRequest
  >({ method: 'POST', url: `/wireless_survey`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Wireless Survey
 * {@link /wireless_survey}
 */
export function useCreateWirelessSurveyWirelessSurveyPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateWirelessSurveyWirelessSurveyPostMutationResponse>,
      ResponseErrorConfig<CreateWirelessSurveyWirelessSurveyPost422>,
      { data: CreateWirelessSurveyWirelessSurveyPostMutationRequest; params?: CreateWirelessSurveyWirelessSurveyPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateWirelessSurveyWirelessSurveyPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createWirelessSurveyWirelessSurveyPostMutationKey()

  return useMutation<
    ResponseConfig<CreateWirelessSurveyWirelessSurveyPostMutationResponse>,
    ResponseErrorConfig<CreateWirelessSurveyWirelessSurveyPost422>,
    { data: CreateWirelessSurveyWirelessSurveyPostMutationRequest; params?: CreateWirelessSurveyWirelessSurveyPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createWirelessSurveyWirelessSurveyPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}