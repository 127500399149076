import React from "react";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";

export interface SchemeElementProps {
  company_id: string;
  scheme_id?: string;
}

type Props = {
  children:
    | ((props: SchemeElementProps) => React.ReactElement)
    | React.ReactElement<SchemeElementProps>;
};

export default function SelectedScheme(props: Props): React.ReactElement {
  const scheme_id: string | undefined = useSelectedScheme()?.scheme_id;
  const company_id: string = useSelectedCompany()?.company_id || "NONE";

  return (
    <>
      {company_id &&
        (React.isValidElement(props.children)
          ? React.cloneElement(props.children, {
              company_id,
              scheme_id,
            })
          : props.children({ company_id, scheme_id }))}
    </>
  );
}
