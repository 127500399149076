import type React from "react";
import { Col, Container } from "../../../../components/Theme/grid";
import type { Asset } from "../../../../utils/object.tsx";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import DeviceTimeSeriesDataContainer from "./DeviceTimeSeriesDataContainer";
import DeviceTimeSeriesDataCsvExportButton from "./DeviceTimeSeriesDataCsvExportButton";
import GenericCharts from "./GenericCharts";
import AssetDataQualityContainer from "./dataQuality/AssetDataQualityContainer";
import ElectricityMeterCharts from "./electricityMeters/ElectricityMeterCharts";
import HeatMeterCharts from "./heatMeters/HeatMeterCharts";
import type { DeviceTimeSeriesDisplayComponentProps } from "./model";

const MeterTimeSeriesCharts = (props: {
  device: Asset;
}): React.ReactElement => {
  let child = GenericCharts;

  switch (props.device.asset_type) {
    case "heat_meter":
      child = HeatMeterCharts;
      break;
    case "cooling_meter":
      child = HeatMeterCharts;
      break;
    case "heating_and_cooling_meter":
      child = HeatMeterCharts;
      break;
    case "electricity_meter":
      child = ElectricityMeterCharts;
      break;
  }

  const render = (
    innerProps: DeviceTimeSeriesDisplayComponentProps,
  ): React.ReactElement => {
    return (
      <Container className={"py-2"}>
        <Col className={"float-right w-full"}>
          <DeviceTimeSeriesDataCsvExportButton {...innerProps} />
        </Col>
        {props.device.asset_type !== "gateway" &&
          props.device.asset_type !== "unknown" && (
            <Col>
              <AssetDataQualityContainer device={props.device} />
            </Col>
          )}
        {child(innerProps)}
      </Container>
    );
  };

  return (
    <>
      <AssetDataSearchStringParser device={props.device}>
        {({ searchParams }) => {
          return (
            <AssetDataSelectorUrlQuerySetter
              device={props.device}
              {...searchParams}
            />
          );
        }}
      </AssetDataSearchStringParser>

      <DeviceTimeSeriesDataContainer device={props.device} render={render} />
    </>
  );
};

export default MeterTimeSeriesCharts;
