import { assetTypeEnum } from "kubb";

export const AssetTypeMap = {
  heat_meter: assetTypeEnum.heat_meter,
  cooling_meter: assetTypeEnum.cooling_meter,
  heating_and_cooling_meter: assetTypeEnum.heating_and_cooling_meter,
  electricity_meter: assetTypeEnum.electricity_meter,
  gas_meter: assetTypeEnum.gas_meter,
  water_meter: assetTypeEnum.water_meter,
  room_sensor: assetTypeEnum.room_sensor,
  co2_room_sensor: assetTypeEnum.co2_room_sensor,
  input_output_module: assetTypeEnum.input_output_module,
  gateway: assetTypeEnum.gateway,
  wireless_receiver: assetTypeEnum.wireless_receiver,
  wireless_cluster: assetTypeEnum.wireless_cluster,
  api_integration: assetTypeEnum.api_integration,
  sim_card: assetTypeEnum.sim_card,
  unknown: assetTypeEnum.unknown,
  wireless_survey: assetTypeEnum.wireless_survey,
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export function checkValueIsInEnum<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): boolean {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0;
}
