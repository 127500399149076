import { Form, Formik } from "formik";
import { isString } from "lodash-es";
import type React from "react";
import AutoSubmit from "../../components/Forms/AutoSubmit.tsx";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import useGetParsedSearchString from "../../hooks/getParsedSearchString";

export default function AlarmListFilterForm(): React.ReactElement {
  const { ready, updateFilters, params } = useGetParsedSearchString<{
    status: string;
  }>();

  const status = isString(params.status) ? params.status : "all";

  return (
    <>
      {ready && (
        <div className={"justify-content-end"}>
          <Formik
            initialValues={{ status }}
            onSubmit={(x): void => {
              updateFilters(x);
            }}
          >
            {(): React.ReactElement => (
              <Form>
                <AutoSubmit />
                <ButtonGroupField
                  fieldName={"open_alarms_only"}
                  label={{ label: "Alarm Status" }}
                  options={[
                    { label: "All", value: false },
                    {
                      label: "Open",
                      value: true,
                    },
                  ]}
                  // labelPosition={"right"}
                  // autoSize={true}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
