import type React from "react";
import { ButtonGroupField } from "../Forms/ButtonGroup";

import { gatewaySettingsTypeEnum } from "kubb";

export default function AssetGatewaySettingTypeSwitch(props: {
  fieldName: string;
  submit?: any;
  title?: string;
  includeDeviceNormal?: boolean;
}): React.ReactElement {
  const { fieldName, title = "Device Settings" } = props;

  return (
    <ButtonGroupField
      fieldName={fieldName}
      label={{ label: title }}
      options={[
        { label: "Default", value: gatewaySettingsTypeEnum.ELVACO_DEFAULT },
        {
          label: "CMi Wireless Only",
          value: gatewaySettingsTypeEnum.ELVACO_CMI_WIRELESS_ONLY,
        },
        {
          label: "Aggressive",
          value: gatewaySettingsTypeEnum.ELVACO_AGGRESSIVE,
        },
        {
          label: "Slow",
          value: gatewaySettingsTypeEnum.ELVACO_SLOW,
        },
      ]}
      onChange={(): void => {
        if (props.submit) {
          props.submit();
        }
      }}
    />
  );
}
