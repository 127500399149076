import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import { AssetRegisterListHeading } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { assetTypeEnum } from "../../../kubb";
import IntegrationListRow from "./IntegrationListRow";

export function IntegrationListPage() {
  return (
    <OneCardPage
      headerTitle={"Data Integrations"}
      breadcrumbs={[
        {
          url: "/admin/integrations",
          name: "Data Integrations",
        },
      ]}
      setPageTitle={schemeObjectPageTitle("Data Integrations")}
    >
      <SelectedScheme>
        {(scheme) => (
          <>
            <AssetRegisterList
              {...scheme}
              params={{ asset_type: [assetTypeEnum.api_integration] }}
              download={false}
              createAssetTags={false}
              header={
                <AssetRegisterListHeading
                  column1={"Name"}
                  column2={"Type"}
                  column3={"Assets"}
                />
              }
              row={IntegrationListRow}
            />
            {scheme.scheme_id && (
              <DisplayIfUserType userTypes={"superadmin"}>
                <CompanyNavLink
                  to={{ pathname: "create" }}
                  button={{ color: "brandLight" }}
                >
                  Add Data Integration
                </CompanyNavLink>
              </DisplayIfUserType>
            )}
          </>
        )}
      </SelectedScheme>
    </OneCardPage>
  );
}
