import client from 'client'
import type {
  GetCompanyListCompanyGetQueryResponse,
  GetCompanyListCompanyGetQueryParams,
  GetCompanyListCompanyGet422,
} from '../../types/GetCompanyListCompanyGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCompanyListCompanyGetQueryKey = (params?: GetCompanyListCompanyGetQueryParams) => [{ url: '/company' }, ...(params ? [params] : [])] as const

export type GetCompanyListCompanyGetQueryKey = ReturnType<typeof getCompanyListCompanyGetQueryKey>

/**
 * @summary Get Company List
 * {@link /company}
 */
export async function getCompanyListCompanyGet(params?: GetCompanyListCompanyGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetCompanyListCompanyGetQueryResponse, ResponseErrorConfig<GetCompanyListCompanyGet422>, unknown>({
    method: 'GET',
    url: `/company`,
    params,
    ...requestConfig,
  })
  return res
}

export function getCompanyListCompanyGetQueryOptions(
  params?: GetCompanyListCompanyGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getCompanyListCompanyGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetCompanyListCompanyGetQueryResponse>,
    ResponseErrorConfig<GetCompanyListCompanyGet422>,
    ResponseConfig<GetCompanyListCompanyGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCompanyListCompanyGet(params, config)
    },
  })
}

/**
 * @summary Get Company List
 * {@link /company}
 */
export function useGetCompanyListCompanyGet<
  TData = ResponseConfig<GetCompanyListCompanyGetQueryResponse>,
  TQueryData = ResponseConfig<GetCompanyListCompanyGetQueryResponse>,
  TQueryKey extends QueryKey = GetCompanyListCompanyGetQueryKey,
>(
  params?: GetCompanyListCompanyGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetCompanyListCompanyGetQueryResponse>,
        ResponseErrorConfig<GetCompanyListCompanyGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCompanyListCompanyGetQueryKey(params)

  const query = useQuery({
    ...(getCompanyListCompanyGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetCompanyListCompanyGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}