import type { AssetPosition } from "kubb";
import { Heading } from "../../../../components/Theme/heading";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { Asset } from "../../../../utils/object.tsx";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import ReceiverHistoryGetter from "./receivers/ReceiverHistoryGetter";
import ReceiverHistoryTable from "./receivers/ReceiverHistoryTable";
import AssetRFAssessmentContainer from "./rfAssessment/AssetRFAssessmentContainer";

interface Props {
  device: Asset;
  assetPosition: AssetPosition;
}
function AssetRfTab(props: Props) {
  return (
    <>
      <AssetDataSearchStringParser device={props.device}>
        {({ searchParams }) => (
          <AssetDataSelectorUrlQuerySetter
            device={props.device}
            {...searchParams}
            showProjection={false}
          />
        )}
      </AssetDataSearchStringParser>
      <AssetRFAssessmentContainer
        assetPosition={props.assetPosition}
        device={props.device}
      />
      <DisplayIfUserType userTypes={"superadmin"}>
        <Heading>Receiver History</Heading>
        <ReceiverHistoryGetter assetPosition={props.assetPosition}>
          {({ history }) => <ReceiverHistoryTable history={history} />}
        </ReceiverHistoryGetter>
      </DisplayIfUserType>
    </>
  );
}

export default AssetRfTab;
