import dayjs, { type Dayjs } from "dayjs";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import { isNil } from "lodash-es";
import type { StreamingSurveyDataRow } from "../../pages/assetRegister/wirelessSurvey/model";

export class WirelessSurveyDataPoint {
  dataCollectorId: string;
  sampleId: string;
  timestamp: Dayjs;
  secondaryAddress: string;
  serial_number: string;
  manufacturer: string;
  medium: string;
  version: string;
  rssi: string;
  data: string;

  constructor(
    dataCollectorId: string,
    sampleId: string,
    timestamp: Dayjs,
    secondaryAddress: string,
    manufacturer: string,
    serial_number: string,
    medium: string,
    version: string,
    rssi: string,
    data: string,
  ) {
    this.dataCollectorId = dataCollectorId;
    this.sampleId = sampleId;
    this.timestamp = timestamp;
    this.serial_number = serial_number;
    this.medium = medium;
    this.manufacturer = manufacturer;
    this.version = version;
    this.rssi = rssi;
    this.data = data;
    this.secondaryAddress = secondaryAddress;
  }

  age() {
    return dayjs().diff(this.timestamp, "seconds");
  }
}

export function wirelessSurveyDataPointFromStreamingSurveyDataRow(
  row: StreamingSurveyDataRow,
): WirelessSurveyDataPoint {
  return new WirelessSurveyDataPoint(
    row[0],
    row[1],
    dayjs(row[3]),
    `${row[5]}${row[6]}${row[7]}${row[8]}`,
    row[5],
    row[8],
    row[6],
    row[7],
    row[9],
    row[4],
  );
}

export function parseWebsocketMessage(
  message: string | null,
): WirelessSurveyDataPoint[] {
  if (isNil(message) || message === "null") {
    return [];
  } else {
    return JSON.parse(message).map(
      wirelessSurveyDataPointFromStreamingSurveyDataRow,
    );
  }
}

export function handleReceivingNewStreamingSurveyDataRowMessage(
  newRows: WirelessSurveyDataPoint[],
  currentData: WirelessSurveyDataPoint[],
): WirelessSurveyDataPoint[] {
  if (newRows.length === 0) {
    return currentData;
  }
  if (
    newRows.length === 1 &&
    newRows[0].timestamp.unix() > currentData[0]?.timestamp.unix()
  ) {
    return [
      ...newRows,
      ...currentData,
    ]; /* no sort required, already in order */
  } else {
    return [...newRows, ...currentData].sort(sortWirelessSurveyDataPoint);
  }
}

function sortWirelessSurveyDataPoint(
  a: WirelessSurveyDataPoint,
  b: WirelessSurveyDataPoint,
) {
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  return 0;
}

export interface WirelessDataSurveyMapItem {
  serial_number: string;
  manufacturer: string;
  medium: string;
  version: string;
  dataCollectorDataMap: Map<string, WirelessSurveyDataPoint[]>;
  dataCollectorSamplePointMap: Map<string, WirelessSurveyDataPoint[]>;
}

export type WirelessDataSurveyMap = Map<string, WirelessDataSurveyMapItem>;

export type DataCollectorMap = Map<string, WirelessSurveyDataCollectorOut>;

export function groupWirelessSurveyDataByDataCollector(
  newRows: WirelessSurveyDataPoint[],
  currentMap: WirelessDataSurveyMap,
  dataCollectors: DataCollectorMap = new Map(),
): WirelessDataSurveyMap {
  return newRows.reduce((accumulator, v) => {
    const newMap = new Map(accumulator.entries());
    const newDataCollectorDataMap = new Map(
      (
        accumulator.get(v.secondaryAddress) || {
          serial_number: v.serial_number,
          manufacturer: v.manufacturer,
          medium: v.medium,
          version: v.version,
          dataCollectorDataMap: new Map(),
          dataCollectorSamplePointMap: new Map(),
        }
      ).dataCollectorDataMap,
    );

    const newDataCollectorSamplePointMap = new Map(
      (
        accumulator.get(v.secondaryAddress) || {
          serial_number: v.serial_number,
          manufacturer: v.manufacturer,
          medium: v.medium,
          version: v.version,
          dataCollectorDataMap: new Map(),
          dataCollectorSamplePointMap: new Map(),
        }
      ).dataCollectorSamplePointMap,
    );

    const samplePointName = identifySamplePointForData(v, dataCollectors);
    newMap.set(v.secondaryAddress, {
      serial_number: v.serial_number,
      medium: v.medium,
      manufacturer: v.manufacturer,
      version: v.version,
      dataCollectorDataMap: newDataCollectorDataMap.set(v.dataCollectorId, [
        ...(newDataCollectorDataMap.get(v.dataCollectorId) || []),
        v,
      ]),
      dataCollectorSamplePointMap: newDataCollectorSamplePointMap.set(
        samplePointName,
        [...(newDataCollectorSamplePointMap.get(samplePointName) || []), v],
      ),
    });
    return newMap;
  }, currentMap);
}

export function identifySamplePointForData(
  data: WirelessSurveyDataPoint,
  dataCollectors: DataCollectorMap,
): string {
  const dataCollector = dataCollectors.get(data.dataCollectorId);
  if (dataCollector?.sample_points) {
    const filtered = dataCollector.sample_points.filter((v) => {
      return (
        dayjs(v.start_date_time) <= data.timestamp &&
        (isNil(v.end_date_time) || dayjs(v.end_date_time) > data.timestamp)
      );
    });
    if (filtered.length > 0) {
      return `${data.dataCollectorId}-${filtered[0].sample_point_name}`;
    }
  }
  return `${data.dataCollectorId}-NONE`;
}
