import { outputTypeEnum } from "kubb";
import { PageHeadingBlock } from "../../components/Theme/heading";
import { useCreateReportExecution } from "../../hooks/createEntity.tsx";
import { useRequiredSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import ReportConfigurationForm from "./executions/ReportConfigurationForm.tsx";
import ReportExecutionList from "./executions/ReportExecutionList";

function BillingReportPage() {
  const company = useRequiredSelectedCompany();
  const scheme = useSelectedScheme();

  const create = useCreateReportExecution(company.company_id);
  return (
    <>
      <PageHeadingBlock>Export Billing Report</PageHeadingBlock>
      <ReportConfigurationForm
        company={company}
        scheme={scheme}
        create={create.update}
        outputTypeFilter={[outputTypeEnum.BILLING]}
      />
      <ReportExecutionList
        company_id={company.company_id}
        scheme_id={scheme?.scheme_id}
        params={{ format: [outputTypeEnum.BILLING] }}
        cursor={{ max_items: 15 }}
      />
    </>
  );
  // return (
  //   <CreateAndGetListCombo
  //     entityType={"REPORT_EXECUTION"}
  //     listParams={{
  //       company_id: company?.company_id,
  //       scheme_id: scheme?.scheme_id,
  //       format: [outputTypeEnum.BILLING],
  //     }}
  //     cursor={{ max_items: 15 }}
  //   >
  //     {({ create, createError, creating, list }) => (
  //       <>
  //         <PageHeadingBlock>Export Billing Report</PageHeadingBlock>
  //
  //         <ReportExecutionCreator
  //           scheme={scheme}
  //           company={company}
  //           {...{ create, createError, creating }}
  //           outputTypeFilter={[outputTypeEnum.BILLING]}
  //         />
  //         <PageHeadingBlock>Previous Executions</PageHeadingBlock>
  //         <ReportExecutionList list={list} />
  //       </>
  //     )}
  //   </CreateAndGetListCombo>
  // );
}

export default BillingReportPage;
