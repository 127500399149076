export const actionTypesEnum = {
  FACTORY_RESET_2100: 'FACTORY_RESET_2100',
  FIRMWARE_UPGRADE: 'FIRMWARE_UPGRADE',
  SYNC_CONFIG: 'SYNC_CONFIG',
  SYNC_DEVICE_LIST: 'SYNC_DEVICE_LIST',
  SYNC_CONFIG_SMS: 'SYNC_CONFIG_SMS',
  SYNC_DEVICE_LIST_SMS: 'SYNC_DEVICE_LIST_SMS',
  SETUP_WIRELESS_RECEIVERS: 'SETUP_WIRELESS_RECEIVERS',
  MBUS_SEARCH: 'MBUS_SEARCH',
  GET_CONFIG: 'GET_CONFIG',
  ASSIGN_TO_COMPANY: 'ASSIGN_TO_COMPANY',
  CREATE_ELVACO_2100: 'CREATE_ELVACO_2100',
  RELEASE_SIM_CARD: 'RELEASE_SIM_CARD',
  ROTATE_CREDENTIALS: 'ROTATE_CREDENTIALS',
  SWITCH_RELAY: 'SWITCH_RELAY',
} as const

export type ActionTypesEnum = (typeof actionTypesEnum)[keyof typeof actionTypesEnum]

export type ActionTypes = ActionTypesEnum