import { type GatewayAssetOut, actionTypesEnum } from "kubb";
import { isUndefined } from "lodash-es";
import type React from "react";
import type { Asset } from "utils/object";
import AssetIdLink from "../../../../components/Asset/AssetIdLink";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CreateActionButton from "../../assetActions/CreateActionButton";
import AssetContainer, {
  type OptionalAssetChildProps,
} from "../AssetContainer";
import AssetStateLabel from "../AssetStateLabel";
import GatewayConfigurationDropdown from "../gateway/GatewayConfigurationDropdown";

const SimCardListContent = (props: {
  asset: Asset;
  refresh: () => any;
}): React.ReactElement => {
  if (props.asset.asset_type !== "sim_card") {
    throw new Error("Unsupported asset type");
  }
  if (
    props.asset.parent_asset_id === undefined ||
    props.asset.parent_asset_id === null
  ) {
    return (
      <DisplayIfUserType userTypes={"superadmin"}>
        <CreateActionButton
          asset={props.asset}
          actionType={actionTypesEnum.CREATE_ELVACO_2100}
          actionName={"Create New Gateway"}
          onComplete={props.refresh}
        />
      </DisplayIfUserType>
    );
  } else {
    return (
      <AssetContainer
        asset_id={props.asset.parent_asset_id}
        company_id={props.asset.company_id}
      >
        {({ asset, loading, refresh }: OptionalAssetChildProps) => (
          <BlockSpinner loading={loading}>
            <div>
              <small>
                <span className={"font-weight-bold"}>Assigned Gateway</span>
                <AssetIdLink
                  asset_id={asset?.asset_id}
                  placeholder={isUndefined(asset)}
                />{" "}
                {asset?.serial_number || "Serial Number"}
              </small>
            </div>
            <div>
              <AssetStateLabel asset={asset} size={"sm"} />
            </div>
            <div>
              {asset ? (
                <GatewayConfigurationDropdown
                  gateway={asset as GatewayAssetOut}
                  size={"sm"}
                  refresh={refresh}
                />
              ) : (
                <Button disabled={true} color={"brandLight"}>
                  Configuration
                </Button>
              )}
            </div>
          </BlockSpinner>
        )}
      </AssetContainer>
    );
  }
};
export default SimCardListContent;
