import { type AxiosError, isAxiosError } from "axios";
import { Table, TableBody, TableCell, TableRow } from "components/Theme/table";
import type { PaginatedData } from "hooks/getList";
import type { Cursor } from "kubb";
import { isString } from "lodash";
import { range } from "lodash-es";
import React from "react";
import { useEffect } from "react";
import AlertBanner from "../Banner/AlertBanner";
import { errorToTitleAndText } from "../Error/errorMessages.tsx";
import BlockSpinner from "../Spinners/BlockSpinner";
import ApiPagination from "./ApiPagination";

export function useRefreshList() {
  const [lastRefresh, setLastRefresh] = React.useState(new Date());

  function refresh() {
    setLastRefresh(new Date());
  }

  return {
    lastRefresh,
    refresh: refresh,
  };
}

export default function ApiPaginatedTable(props: {
  cursor?: Cursor;
  error?: Error | null;
  data?: PaginatedData<any>;
  loading?: boolean;
  refresh?: () => any;
  updateItem?: (item: any, index: number) => void;
  header: [React.ReactNode, number] | null;
  row: (
    item: any,
    update: (updatedItem: any) => void,
    key: number,
  ) => React.ReactElement<{ key: string }>;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
  lastChange?: Date;
}): React.ReactElement {
  const { refresh, lastChange } = props;

  // biome-ignore lint/correctness/useExhaustiveDependencies(lastChange): changing lastChange is the refresh trigger
  useEffect(() => {
    refresh?.();
  }, [refresh, lastChange]);
  const errorRow = (
    error: AxiosError | string,
    tableWidth = 1,
  ): React.ReactElement => {
    const color = "bg-red-700";

    const { text } = isString(error)
      ? { text: error }
      : errorToTitleAndText(error);
    return (
      <TableBody key={"list-error"}>
        <TableRow>
          <TableCell colSpan={tableWidth} data-cy={"list-error"}>
            <AlertBanner className={color}>
              <h3 style={{ color: "black" }}>An error has occurred.</h3>
              <p style={{ color: "black" }}>{text}</p>
            </AlertBanner>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const displayError = (
    error: Error | AxiosError,
    tableWidth = 1,
  ): React.ReactElement => {
    if (isAxiosError(error)) {
      return errorRow(error as AxiosError, tableWidth);
    } else {
      return errorRow(error.message, tableWidth);
    }
  };

  const requestedSize = (props.cursor as Cursor)?.max_items || 10;
  const error = props.error;
  const [header, tableWidth] = props.header || [null, 1];

  const missingElements = Math.max(
    requestedSize - (props.data?.data.length || 0),
    0,
  );

  return (
    <>
      <BlockSpinner loading={props.loading || false} scrollX={true}>
        <Table
          className={`align-items-center table-flush ${props.tableClassName}`}
          data-cy={props["data-cy"]}
        >
          {header}
          {props.data?.data && (
            <TableBody>
              {props.data?.data.map((value, index) =>
                props.row(
                  value,
                  (updateItem) => props.updateItem?.(updateItem, index),
                  index,
                ),
              )}
            </TableBody>
          )}
          {props.loading &&
            range(missingElements).map((i) => (
              <TableBody key={i}>
                <TableRow>
                  <TableCell colSpan={tableWidth}>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            ))}
          {!props.loading && props.data?.data.length === 0 && !error && (
            <TableBody key={"no-data"}>
              {props.noDataRow || (
                <TableRow>
                  <TableCell colSpan={tableWidth} data-cy={"no-data"}>
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          {!props.loading && error && displayError(error, tableWidth)}
        </Table>
      </BlockSpinner>

      {(props.showPagination === undefined || props.showPagination) &&
        props.data?.data &&
        props.data.cursor && (
          <ApiPagination
            cursor={props.data.cursor}
            newCursor={props.data.setCursor}
            refresh={props.refresh}
            // prevCursor={this.props.state.list.prevCursor}
            // newCursor={this.debouncedGetPage}
          />
        )}
      {props.additionalFunctionButtons}
    </>
  );
}
