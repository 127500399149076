import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import ReportConfigurationForm, {
  type ReportConfigurationFormProps,
} from "./ReportConfigurationForm";

function ReportExecutionCreator(
  props: ReportConfigurationFormProps & { createError?: Error },
) {
  return (
    <>
      <ReportConfigurationForm {...props} />
      <ErrorBanner error={props.createError} />
    </>
  );
}

export default ReportExecutionCreator;
