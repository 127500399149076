import type { GetAssetRegisterListAssetRegisterGetQueryParams } from "kubb";
import {
  type AssetTypeEnum,
  assetRegisterSortEnum,
  assetTypeEnum,
  assetUseCaseEnum,
  statusEnum,
} from "kubb";
import { isArray, isString, isUndefined } from "lodash-es";
import type { ParsedQs } from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { checkValueIsInEnum } from "../../model/enums";
import { removeMapBlanks } from "../../utils/object.tsx";
import { parseSearchString } from "../navigation/SearchString";

type Props = {
  initialState: Partial<GetAssetRegisterListAssetRegisterGetQueryParams>;
  children: React.ReactNode;
};

function parse_asset_type(parsed: ParsedQs): AssetTypeEnum[] | undefined {
  if (!isUndefined(parsed.asset_type)) {
    const v = isArray(parsed.asset_type)
      ? parsed.asset_type
      : [parsed.asset_type];
    return v.map((u) => assetTypeEnum[u as keyof typeof assetTypeEnum]);
  }
  return undefined;
}

export function singularParam<T>(arr?: T[]): T | undefined {
  if (arr) {
    if (arr.length >= 1) {
      return arr[0];
    }
  }
}

export function parse_to_bool(
  parsed: ParsedQs,
  key: string,
): boolean | undefined {
  const val = parsed[key];
  if (!isUndefined(val) && !isArray(val) && isString(val)) {
    switch (val.toLowerCase()) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return undefined;
    }
  }
  return undefined;
}
export function parse_to_enum<T extends { [index: string]: string }>(
  parsed: ParsedQs,
  myEnum: T,
  key: string,
): T[] | undefined {
  if (!isUndefined(parsed[key])) {
    const v: string[] = isArray(parsed[key])
      ? (parsed[key] as string[])
      : ([parsed[key]] as string[]);
    return v.filter((u) => checkValueIsInEnum(myEnum, u)) as unknown as T[];
  }
  return undefined;
}

export default function SearchParamPage(props: Props): React.ReactElement {
  /*
    Simple component to parse search strings, detect changes and pass the params as an object to a single child element.
     */
  const [state, setState] = useState<
    GetAssetRegisterListAssetRegisterGetQueryParams & { initialized: boolean }
  >({
    ...props.initialState,
    initialized: false,
  });
  const location = useLocation();

  const update = useCallback((): void => {
    const getSearchParams =
      (): GetAssetRegisterListAssetRegisterGetQueryParams => {
        const parsed = parseSearchString(location.search);

        const v = removeMapBlanks({
          asset_type: parse_asset_type(parsed),
          asset_id: parsed.asset_id as string,
          location_id: parsed.location_id as string,
          asset_position_id: parsed.asset_position_id as string,
          parent_asset_position_id: parsed.parent_asset_position_id as string,
          group: parsed.group as string,
          text_search: parsed.text_search as string,
          use_case: parse_to_enum(parsed, assetUseCaseEnum, "use_case"),
          sort: assetRegisterSortEnum[
            parsed.sort as keyof typeof assetRegisterSortEnum
          ],
          status: parse_to_enum(parsed, statusEnum, "status"),
          after: parsed.after as string,
          before: parsed.before as string,
          max_items: parsed.max_items
            ? Number.parseInt(parsed.max_items as string)
            : undefined,
          direction: parsed.direction as string,
        });
        console.log(v);
        return v;
      };
    setState({
      ...props.initialState,
      initialized: true,
      ...getSearchParams(),
    });
  }, [props.initialState, location.search]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: waht to trigger update if the search changes
  useEffect(update, [location.search]);

  return state.initialized && React.isValidElement(props.children) ? (
    React.cloneElement(props.children, state)
  ) : (
    <></>
  );
}
