import dayjs, { type Dayjs } from "dayjs";
import type {
  AggregatedReportRequest,
  AggregatedTimeSeriesStatsItem,
  AssetTypeEnum,
  AssetUseCaseEnum,
  GroupBy,
  Period,
} from "kubb";
import { assetUseCaseEnum } from "kubb";
import type { LocationGroup } from "kubb";
import { periodEnum } from "kubb";
import type { ReportView } from "kubb";
import type { Scheme } from "kubb";
import { isArray, isNumber, max, min, sortBy, sum, uniq } from "lodash-es";
import type { TableRow } from "react-data-table-component";
import timeSeriesTemplates from "../../constants/timeSeriesTemplates.json";
import type {
  AggregatedStatsResponse,
  AggregatedTimeSeriesStatsParams,
} from "../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import type { DataPoint } from "../charts/SimpleLineChart";

export type AssetType = keyof typeof timeSeriesTemplates;
export type AssetTypeList = AssetTypeEnum[];

export interface NewTimeSeriesRequestParams {
  start_date: Dayjs;
  end_date: Dayjs;
  parameter: string;
  aggregation: string;
  period: Period;
  group?: string;
  group_by?: GroupBy;
  view_by?: ReportView;
  asset_type?: AssetTypeEnum[];
  use_case?: AssetUseCaseEnum[];
}

export function mapNewTimeSeriesRequestParamsToAggregatedReportRequest(
  params: NewTimeSeriesRequestParams,
): AggregatedReportRequest {
  return {
    start_time: params.start_date.toISOString(),
    end_time: params.end_date.toISOString(),
    aggregations: [
      { parameter: params.parameter, aggregation: params.aggregation },
    ],
    period: params.period,
    group: params.group,
    view_by: params.view_by,
    asset_types: params.asset_type,
    use_case: params.use_case,
  };
}

export function mapNewTimeSeriesRequestParamsToAggregatedTimeSeriesStatsParams(
  p: NewTimeSeriesRequestParams,
): AggregatedTimeSeriesStatsParams {
  return {
    start_datetime: p.start_date,
    end_datetime: p.end_date,
    parameter: p.parameter,
    aggregation: p.aggregation,
    period: p.period,
    group: p.group,
    group_by: p.group_by,
    asset_types: p.asset_type,
    use_case: p.use_case,
  };
}

export function getFields(assetTypes: AssetTypeList): string[] {
  const parameters: string[] = isArray(assetTypes)
    ? assetTypes.reduce(
        (accumulator: string[], value) => [
          ...accumulator,
          ...getFieldForAssetType(value),
        ],
        [],
      )
    : getFieldForAssetType(assetTypes);
  return sortBy(uniq(parameters));
}

const getFieldForAssetType = (assetType: AssetTypeEnum): string[] => {
  if (Object.hasOwn(timeSeriesTemplates, assetType)) {
    return Object.values(
      timeSeriesTemplates[
        assetType as unknown as keyof typeof timeSeriesTemplates
      ].data_fields,
    )
      .map((value) => {
        return value.name;
      })
      .concat(
        Object.values(
          timeSeriesTemplates[
            assetType as unknown as keyof typeof timeSeriesTemplates
          ].compound_aggregations,
        ).map((value) => {
          return value.name;
        }),
      )
      .concat(["Quality Assessment"]);
  } else {
    throw new Error(`Unknown asset type for ${assetType}`);
  }
};

export function getUnitsForParameter(parameter: string): string {
  const units = new Map([
    ["Energy (Heating)", "Wh"],
    ["Energy (Cooling)", "Wh"],
    ["Energy (Electrical Active Import)", "Wh"],
    ["Energy (Electrical Active Export)", "Wh"],
    ["Energy (Electrical Reactive Import)", "VAR"],
    ["Energy (Electrical Reactive Export)", "VAR"],
    ["Volume", "m3"],
    ["Flow Temperature", "°C"],
    ["Return Temperature", "°C"],
    ["Power", "W"],
    ["Synthetic Volume", "m3"],
    ["Synthetic Energy", "m3"],
    ["Flow", "m3/hr"],
    ["Reactive Power", "VA"],
    ["Current", "A"],
    ["Frequency", "Hz"],
    ["Power Factor", ""],
    ["Humidity", "%"],
    ["Temperature", "°C"],
    ["CO2", "ppm"],
    ["RSSI", "dB"],
    ["Data Received", ""],
    ["Devices (Received)", ""],
    ["Devices (Total)", ""],
    ["Data Points Received (Total)", ""],
    ["Data Points Received (Good)", ""],
    ["Data Points Received (Parse Error)", ""],
    ["Data Points Received (Value Error)", ""],
    ["Data Points Received (Duplicate)", ""],
    ["Synthetic Run Time", "mins"],
  ]);
  return units.get(parameter) || "";
}

export function getAggregations(
  dataFieldName: string,
  assetType?: keyof typeof timeSeriesTemplates,
): string[] {
  if (dataFieldName === "Quality Assessment") {
    return [
      "expected_rows",
      "received_rows_complete",
      "received_rows_basic_complete",
      "received_rows_incomplete",
      "received_rows_with_errors",
      "received_rows_with_value_errors",
      "expected_data_points",
      "good_data_points",
      "value_error_data_points",
    ];
  }
  if (assetType) {
    const fields = [
      ...timeSeriesTemplates[assetType].data_fields,
      ...timeSeriesTemplates[assetType].compound_aggregations,
    ];
    return Object.values(fields).filter((value) => {
      return value.name === dataFieldName;
    })[0].aggregations;
  } else {
    const aggregationSet: Set<string> = Object.values(
      timeSeriesTemplates,
    ).reduce((accumulator, assetAggregations) => {
      const fields = [
        ...assetAggregations.data_fields,
        ...assetAggregations.compound_aggregations,
      ];
      const filtered = Object.values(fields).filter(
        (assetFieldAggregations) => {
          return assetFieldAggregations.name === dataFieldName;
        },
      );

      if (filtered.length > 0) {
        return new Set([...accumulator, ...filtered[0].aggregations]);
      }
      return accumulator;
    }, new Set<string>());

    return Array.from(aggregationSet.values());
  }
}

export interface SingleAggregationTimeAssetData extends TableRow {
  company_id: string;
  company_name: string;
  scheme_name: string;
  scheme_id: string;
  asset_id?: string;
  serial_number?: string;
  asset_type: AssetTypeEnum;
  asset_position_id: string;
  asset_position_reference?: string;
  location_id: string;
  location_address?: string;
  location_customer_reference?: string;

  data: { [key: string]: number | undefined | string };
}

export function filterGroupList(
  groups: LocationGroup[],
  filterPrefix?: string,
  level = 1,
): LocationGroup[] {
  if (filterPrefix === undefined) {
    return groups;
  } else {
    const currentGroup = filterPrefix.split("/").slice(0, level).join("/");
    const filteredGroups = groups.filter((v) => v.group === currentGroup);
    if (filteredGroups.length === 1) {
      if (currentGroup === filterPrefix) {
        return filteredGroups[0].children &&
          filteredGroups[0].children.length > 0
          ? filteredGroups[0].children
          : [filteredGroups[0]];
      } else {
        return filterGroupList(
          filteredGroups[0].children || [],
          filterPrefix,
          level + 1,
        );
      }
    } else {
      return [];
    }
  }
}

export const aggregatedStatsCountToPercentage = (
  state: AggregatedStatsResponse,
) => {
  return (
    scheme: string,
    use_case: AssetUseCaseEnum[] = [
      assetUseCaseEnum.NONE,
      assetUseCaseEnum.DOMESTIC,
      assetUseCaseEnum.PROCESS,
      assetUseCaseEnum.COMMERCIAL,
    ],
  ): [Dayjs, number][] => {
    return Object.entries(
      filterStatsByGroupAndUseCase(state, scheme, use_case),
    ).map(([dateString, item]) => {
      return [
        dayjs(dateString),
        item.asset_count > 0
          ? (item.non_zero_count / item.asset_position_count) * 100
          : 0,
      ];
    });
  };
};

type AggregatedTimeSeriesDateItem = {
  [datetime: string]: AggregatedTimeSeriesStatsItem;
};

function filterStatsByGroupAndUseCase(
  stats: AggregatedStatsResponse,
  scheme: string,
  useCase: AssetUseCaseEnum[],
): AggregatedTimeSeriesDateItem {
  if (Object.prototype.hasOwnProperty.call(stats, scheme)) {
    return combinedAggregatedTimeSeriesStatsItemsByUseCase(
      Object.entries(stats[scheme]).filter(
        ([dataUseCase, _]) =>
          useCase.map((v) => v.toString()).indexOf(dataUseCase) !== -1,
      ),
    );
  }
  return {};
}

function combinedAggregatedTimeSeriesStatsItemsByUseCase(
  items: [string, AggregatedTimeSeriesDateItem][],
): AggregatedTimeSeriesDateItem {
  switch (items.length) {
    case 0:
      return {};
    case 1:
      return items[0][1];
    default:
      return Object.fromEntries(
        Object.keys(items[0][1]).map((date) => {
          return [
            date,
            combineAggregatedTimeSeriesStatsItems(items.map((v) => v[1][date])),
          ];
        }),
      );
  }
}

function combineAggregatedTimeSeriesStatsItems(
  items: AggregatedTimeSeriesStatsItem[],
): AggregatedTimeSeriesStatsItem {
  if (items.length === 1) {
    return items[0];
  } else {
    const theNonZeroCount = sum(items.map((v) => v.non_zero_count));
    const mean =
      sum(items.map((v) => (v.sum || 0) * v.non_zero_count)) / theNonZeroCount;
    return {
      asset_count: sum(items.map((v) => v.asset_count)),
      asset_position_count: sum(items.map((v) => v.asset_position_count)),
      max: max(items.map((v) => v.max)),
      mean: mean,
      min: min(items.map((v) => v.min)),
      non_zero_count: theNonZeroCount,
      present_count: sum(items.map((v) => v.present_count)),
      sum: sum(items.map((v) => v.sum)),
    };
  }
}

export function matchPeriod(period?: string): Period {
  try {
    const p = periodEnum[period?.toUpperCase() as keyof typeof periodEnum];
    if (p) {
      return p;
    }
    return periodEnum.DAILY;
  } catch (_err) {
    return periodEnum.DAILY;
  }
}

export function aggregatedStatsSelector(
  key: keyof AggregatedTimeSeriesStatsItem,
) {
  return (
    state: AggregatedStatsResponse,
    scheme: string,
    use_case: AssetUseCaseEnum[] = [
      assetUseCaseEnum.NONE,
      assetUseCaseEnum.DOMESTIC,
      assetUseCaseEnum.PROCESS,
      assetUseCaseEnum.COMMERCIAL,
    ],
  ): [Dayjs, number][] => {
    return Object.entries(
      filterStatsByGroupAndUseCase(state, scheme, use_case),
    ).map(([dateString, item]) => {
      return [
        dayjs(dateString),

        isNumber(item[key]) ? (item[key] as number) : 0,
      ];
    });
  };
}

export function mapSchemesData(
  stats: AggregatedStatsResponse,
  schemes: Array<Scheme>,
  func: (stats: AggregatedStatsResponse, scheme_id: string) => DataPoint[],
): [string, DataPoint[], string][] {
  return schemes.map((scheme: Scheme) => {
    return [
      scheme.scheme_name ? scheme.scheme_name : scheme.scheme_id,
      func(stats, scheme.scheme_id),
      scheme.scheme_id,
    ];
  });
}

export function mapLocationGroupData(
  stats: AggregatedStatsResponse,
  groups: LocationGroup[],
  func: (stats: AggregatedStatsResponse, groupId: string) => DataPoint[],
): [string, DataPoint[], string][] {
  const res: [string, DataPoint[], string][] = groups.map(
    (group: LocationGroup) => [
      group.group,
      func(stats, group.group),
      group.group,
    ],
  );
  if (Object.prototype.hasOwnProperty.call(stats, "NONE")) {
    return [...res, ["NONE", func(stats, "NONE"), "NONE"]];
  }
  return res;
}
