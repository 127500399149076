import { useGetAssetPositionAssetLinkListAssetPositionLinkGet } from "kubb";
import type { AssetPositionAssetLinkOut } from "kubb";
import type React from "react";
import NewGetListPaginatedTable from "../../../../components/Pagination/GetListPaginatedTable";
import { TableHeader, TableRow } from "../../../../components/Theme/table";
import DisplayIfUserType from "../../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AssetPositionAssetRow from "./AssetPositionAssetRow";

interface Props {
  asset_id?: string;
  company_id: string;
  scheme_id?: string;
  asset_position_id?: string;
  lastUpdated?: Date;
  view?: "asset" | "assetPosition";
  refreshList?: () => void;
}

const AssetPositionAssetList = (props: Props): React.ReactElement => {
  const { asset_id, company_id, scheme_id, asset_position_id } = props;

  const { view = "asset" } = props;

  const header = (
    <TableRow>
      {view === "asset" ? (
        <>
          <TableHeader>Asset</TableHeader>
          <TableHeader>Manufacturer</TableHeader>
          <TableHeader>Serial Number</TableHeader>
          <TableHeader>Asset Type</TableHeader>
        </>
      ) : (
        <>
          <TableHeader>Asset Position</TableHeader>
          <TableHeader>Reference</TableHeader>
          <TableHeader>Asset Position Type</TableHeader>
        </>
      )}
      <TableHeader>Install Date</TableHeader>
      <TableHeader>Uninstall Date</TableHeader>
      <TableHeader>Status</TableHeader>
      <DisplayIfUserType userTypes={"admin"}>
        <TableHeader />
      </DisplayIfUserType>
    </TableRow>
  );

  return (
    <NewGetListPaginatedTable
      params={{
        asset_id,
        asset_position_id,
        company_id,
        scheme_id,
      }}
      cursor={{ max_items: 10 }}
      header={[header, 4]}
      data-cy={"asset-position-asset-history"}
      useQuery={useGetAssetPositionAssetLinkListAssetPositionLinkGet}
      lastUpdateRequested={props.lastUpdated}
      row={(item: AssetPositionAssetLinkOut) => (
        <AssetPositionAssetRow
          value={item}
          refreshList={props.refreshList}
          view={view}
        />
      )}
    />
  );
};

export default AssetPositionAssetList;
