import type React from "react";

import { ErrorBanner } from "components/Error/ErrorBanner";
import GetAssetRegisterEntitiesCombination from "core/action/GetAssetRegisterEntitiesCombination";
import { useParams } from "react-router-dom";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import AssetPositionDisplay from "./AssetPositionDisplay";

export default function AssetPositionHomepage(): React.ReactElement {
  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();
  const { asset_position_id } = useParams();

  if (asset_position_id === undefined) {
    throw new Error("asset_position_id must be set.");
  }

  return (
    <GetAssetRegisterEntitiesCombination
      asset_position_id={asset_position_id}
      scheme_id={scheme_id}
      company_id={company_id}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={() => "Asset Position: Error"}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={() => "Asset Position: Loading"}
        />
      )}
    >
      {(displayProps) => <AssetPositionDisplay {...displayProps} />}
    </GetAssetRegisterEntitiesCombination>
  );
}
