import {
  type GetTariffListTariffGetQueryResponse,
  type Scheme,
  type TariffOut,
  useGetTariffListTariffGet,
} from "kubb";
import { isUndefined } from "lodash-es";
import type React from "react";
import ErrorOverlappingBanner from "../../components/Error/ErrorOverlappingBanner";
import RequireScheme from "../../components/Scheme/RequireScheme";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { useGetDataNew } from "../../hooks/getData.tsx";
import type { PaginatedData } from "../../hooks/getList";
import { useSelectedScheme } from "../../reducers/scheme";

interface SchemeTariffListWrapperProps {
  children: (props: { data: PaginatedData<TariffOut> }) => React.ReactNode;
}

function InnerTariffWrapper(
  props: { scheme: Scheme } & SchemeTariffListWrapperProps,
): React.ReactNode {
  const { scheme } = props;
  const { data, error, loading } =
    useGetDataNew<GetTariffListTariffGetQueryResponse>(() =>
      useGetTariffListTariffGet({
        company_id: scheme.company_id,
        scheme_id: scheme.scheme_id,
      }),
    );

  return (
    <BlockSpinner loading={loading}>
      <ErrorOverlappingBanner error={error}>
        {!isUndefined(data) ? (
          props.children({ data: { data, setCursor: () => null, cursor: {} } })
        ) : (
          <></>
        )}
      </ErrorOverlappingBanner>
    </BlockSpinner>
  );
}

function SchemeTariffListWrapper(props: SchemeTariffListWrapperProps) {
  const scheme = useSelectedScheme();

  return (
    <RequireScheme>
      {scheme && <InnerTariffWrapper scheme={scheme} {...props} />}
    </RequireScheme>
  );
}

export default SchemeTariffListWrapper;
