import type { Location } from "kubb";
import type React from "react";
import CompanyNavLink from "../navigation/CompanyNavLink";

const LocationIdLink = (props: {
  location?: Location;
  location_id?: string;
  full?: boolean;
  text?: string;
}): React.ReactElement => {
  const { location, location_id } = props;
  const actuallocation_id = location ? location.location_id : location_id;
  if (actuallocation_id) {
    const displayLocation = props.full
      ? actuallocation_id
      : `...${actuallocation_id.slice(24)}`;
    return (
      <CompanyNavLink
        inline={true}
        to={{ pathname: `/admin/location/${actuallocation_id}` }}
      >
        {props.text || displayLocation}
      </CompanyNavLink>
    );
  }
  throw new Error("One of location or location_id must be set");
};

export default LocationIdLink;
