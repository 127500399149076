import { periodEnum } from "kubb";
import {
  type OptionsList,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

type PeriodSelectComboProps = Omit<SelectComboFieldProps, "options">;

function getOptionsList(): OptionsList {
  return [
    { label: "Daily", value: periodEnum.DAILY },
    { label: "Weekly", value: periodEnum.WEEKLY },
    { label: "7 Day", value: periodEnum.SEVEN_DAY },
    { label: "Monthly", value: periodEnum.MONTHLY },
  ];
}

function PeriodSelectCombo(props: PeriodSelectComboProps) {
  return <SelectComboField {...props} options={getOptionsList()} />;
}

export default PeriodSelectCombo;
