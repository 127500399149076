import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { EventTypes } from "kubb";
import { isString } from "lodash-es";
import type { ParsedQs } from "qs";
import type React from "react";
import { useCallback } from "react";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import type { FilterFormProps } from "../../components/Pagination/GetListPaginatedTable";
import { Button } from "../../components/Theme/button";
import { Col, Container } from "../../components/Theme/grid";
import useGetParsedSearchString from "../../hooks/getParsedSearchString";
import EventTypeSelect from "./EventTypeSelect";

export interface EventListFilterParams {
  eventTypes?: EventTypes[];
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}

export const useGetParsedEventListFilterParams = () =>
  useGetParsedSearchString<EventListFilterParams>(
    useCallback((p: ParsedQs): EventListFilterParams => {
      return {
        startDatetime: isString(p.startDatetime)
          ? dayjs(p.startDatetime)
          : undefined,
        endDatetime: isString(p.endDatetime) ? dayjs(p.endDatetime) : undefined,
        eventTypes: isString(p.eventTypes)
          ? [p.eventTypes as EventTypes]
          : (p.eventTypes as EventTypes[]),
      };
    }, []),
  );

export default function EventListFilterForm(
  props: FilterFormProps,
): React.ReactElement {
  const { ready, updateFilters, params } = useGetParsedEventListFilterParams();

  return (
    <>
      {ready && (
        <Formik
          initialValues={params}
          onSubmit={(x): void => {
            updateFilters(x);
          }}
        >
          {(): React.ReactElement => (
            <Form>
              <Container>
                <Col sm={12} lg={5} xl={6}>
                  <EventTypeSelect fieldName={"eventTypes"} />
                </Col>
                <Col sm={8} lg={5} xl={4}>
                  <DateRangePicker
                    startDateName={"startDatetime"}
                    endDateName={"endDatetime"}
                    maxDate={dayjs().endOf("day")}
                    clearable={true}
                    minDays={0}
                  />
                </Col>
                <Col sm={4} lg={2} xl={2} className={"mt-auto"}>
                  <div className={"py-2 flex gap-2"}>
                    <Button
                      type={"submit"}
                      color={"brandLight"}
                      data-cy={"filter"}
                      className={"w-1/2 h-[38px]"}
                    >
                      Filter
                    </Button>
                    <Button
                      type={"button"}
                      color={"brandLight"}
                      onClick={props.refresh}
                      aria-label={"Refresh"}
                      className={"w-1/2 h-[38px]"}
                    >
                      <span>
                        <FontAwesomeIcon icon={faRefresh} />
                      </span>
                    </Button>
                  </div>
                </Col>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
