import {
  faBan,
  faDownload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { Button } from "./Theme/button";
import UncontrolledTooltip from "./Theme/tooltip";

interface DownloadFileTooltipProps {
  loading: boolean;
  downloadable: boolean;
  fileDescription: string;
  unavailableDescription?: string;
  clickHandler: () => Promise<any>;
  className?: string;
}

export default function DownloadFileTooltip(
  props: DownloadFileTooltipProps,
): React.ReactElement {
  const {
    loading,
    downloadable,
    fileDescription,
    unavailableDescription = "This file has no content",
  } = props;
  return (
    <Button
      size={"sm"}
      plain
      disabled={!downloadable || loading}
      onClick={async (): Promise<any> => {
        downloadable && (await props.clickHandler());
      }}
    >
      <UncontrolledTooltip
        placement={"left"}
        message={downloadable ? fileDescription : unavailableDescription}
      >
        <span className={"text-brand-dark-700"}>
          {loading ? (
            <FontAwesomeIcon fixedWidth icon={faSpinner} spin size={"sm"} />
          ) : (
            <FontAwesomeIcon
              fixedWidth
              icon={downloadable ? faDownload : faBan}
              size={"sm"}
            />
          )}
        </span>
      </UncontrolledTooltip>
    </Button>
  );
}
