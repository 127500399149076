export const dayOfWeekEnum = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const

export type DayOfWeekEnum = (typeof dayOfWeekEnum)[keyof typeof dayOfWeekEnum]

export type DayOfWeek = DayOfWeekEnum