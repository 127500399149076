import type { ResponseConfig } from "client";
import { ErrorBanner } from "components/Error/ErrorBanner";
import { Form, Formik, type FormikHelpers } from "formik";
import { type Location, useCreateNewLocationLocationPost } from "kubb";
import type React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import { locationSchema } from "../../../model/location";

interface ComponentProps {
  company_id: string;
  scheme_id: string;
}

interface FormValues {
  scheme_id: string;
  address: string;
}

type Props = ComponentProps;

const NewLocationForm = ({ company_id, scheme_id }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync, isPending } = useCreateNewLocationLocationPost();

  const [result, setResult] = useState<ResponseConfig<Location> | undefined>(
    undefined,
  );
  const [error, setError] = useState<Error | undefined>(undefined);

  const submit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): Promise<void> => {
    setSubmitting(true);
    setError(undefined);
    try {
      const v = await mutateAsync({
        data: {
          ...values,
          company_id: company_id,
          scheme_id: scheme_id,
        },
      });
      if (v.status >= 200 && v.status < 300) {
        setResult(v);
        await resetForm({});
        navigate({
          ...location,
          pathname: `/admin/location/${v.data.location_id}`,
        });
      } else {
        setError(new Error(v.statusText));
      }
    } catch (err) {
      setError(err as Error);
    }
    setSubmitting(false);
  };

  const initialValues = {
    address: "",
    scheme_id: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape(locationSchema)}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <>
            <Form>
              <NewTextFormComponent
                fieldName={"address"}
                label={{ label: "Address" }}
              />
              <Button
                type="submit"
                color="brandLight"
                disabled={isSubmitting || !isValid}
              >
                Create
              </Button>
            </Form>
            <ErrorBanner error={error} />
          </>
        )}
      </Formik>
    </>
  );
};

export default NewLocationForm;
