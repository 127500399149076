import client from 'client'
import type {
  GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse,
  GetAssetPositionAssetLinkListAssetPositionLinkGetQueryParams,
  GetAssetPositionAssetLinkListAssetPositionLinkGet422,
} from '../../types/GetAssetPositionAssetLinkListAssetPositionLinkGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetPositionAssetLinkListAssetPositionLinkGetQueryKey = (params?: GetAssetPositionAssetLinkListAssetPositionLinkGetQueryParams) =>
  [{ url: '/asset_position/link' }, ...(params ? [params] : [])] as const

export type GetAssetPositionAssetLinkListAssetPositionLinkGetQueryKey = ReturnType<typeof getAssetPositionAssetLinkListAssetPositionLinkGetQueryKey>

/**
 * @summary Get Asset Position Asset Link List
 * {@link /asset_position/link}
 */
export async function getAssetPositionAssetLinkListAssetPositionLinkGet(
  params?: GetAssetPositionAssetLinkListAssetPositionLinkGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse,
    ResponseErrorConfig<GetAssetPositionAssetLinkListAssetPositionLinkGet422>,
    unknown
  >({ method: 'GET', url: `/asset_position/link`, params, ...requestConfig })
  return res
}

export function getAssetPositionAssetLinkListAssetPositionLinkGetQueryOptions(
  params?: GetAssetPositionAssetLinkListAssetPositionLinkGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetPositionAssetLinkListAssetPositionLinkGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse>,
    ResponseErrorConfig<GetAssetPositionAssetLinkListAssetPositionLinkGet422>,
    ResponseConfig<GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetPositionAssetLinkListAssetPositionLinkGet(params, config)
    },
  })
}

/**
 * @summary Get Asset Position Asset Link List
 * {@link /asset_position/link}
 */
export function useGetAssetPositionAssetLinkListAssetPositionLinkGet<
  TData = ResponseConfig<GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetPositionAssetLinkListAssetPositionLinkGetQueryKey,
>(
  params?: GetAssetPositionAssetLinkListAssetPositionLinkGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetPositionAssetLinkListAssetPositionLinkGetQueryResponse>,
        ResponseErrorConfig<GetAssetPositionAssetLinkListAssetPositionLinkGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetPositionAssetLinkListAssetPositionLinkGetQueryKey(params)

  const query = useQuery({
    ...(getAssetPositionAssetLinkListAssetPositionLinkGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetPositionAssetLinkListAssetPositionLinkGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}