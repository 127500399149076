import { useFormikContext } from "formik";
import { useEffect } from "react";

function AutoSubmit() {
  /*
    This component is used to automatically submit the form when the form is valid
    and has been changed(dirty).
   */

  const { submitForm, isValid, values, dirty } = useFormikContext();
  // biome-ignore lint/correctness/useExhaustiveDependencies(values): want the trigger to occur when the values change
  useEffect(() => {
    if (isValid && dirty) {
      void submitForm();
    }
  }, [isValid, values, dirty, submitForm]);
  return <></>;
}

export default AutoSubmit;
