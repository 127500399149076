import type { AxiosResponseHeaders } from "axios";
import {
  type Account,
  type ApiUserIn,
  type AssetAction,
  type AssetPosition,
  type AssetPositionAction,
  type AssetPositionAssetLinkIn,
  type AssetPositionAssetLinkOut,
  type BulkUpdateDetailed,
  type Company,
  type CreateBulkUpdateBulkPostMutationResponse,
  type CreateBulkUpdateBulkPostQueryParams,
  type CreateNewCompanyCompanyPostMutationRequest,
  type CreateNewCompanyCompanyPostMutationResponse,
  type CreateNewCompanyCompanyPostQueryParams,
  type CreateNewCompanyRegisterPostMutationRequest,
  type CreateNewCompanyRegisterPostMutationResponse,
  type CreateNewCompanyRegisterPostQueryParams,
  type CreateNoteNotePostMutationRequest,
  type CreateNoteNotePostMutationResponse,
  type CreateNoteNotePostQueryParams,
  type CreateReportExecutionReportPostMutationRequest,
  type CreateReportExecutionReportPostMutationResponse,
  type CreateReportExecutionReportPostQueryParams,
  type CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest,
  type CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse,
  type CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams,
  type CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams,
  type DeviceAssetNewIn,
  type ExecuteBulkUpdateBulkUpdateIdPostMutationResponse,
  type ExecuteBulkUpdateBulkUpdateIdPostPathParams,
  type ExecuteBulkUpdateBulkUpdateIdPostQueryParams,
  type GatewayAssetNewIn,
  type IntegrationFile,
  type JobDefinitionIn,
  type JobDefinitionOut,
  type JobExecutionInstruction,
  type Location,
  type NewAccount,
  type NewAssetPosition,
  type NewCompanyUser,
  type NewLocation,
  type NewScheme,
  type NoteIn,
  type ProduceAssetTagLabels,
  type ReportExecutionIn,
  type ReportExecutionOut,
  type ResendFileBody,
  type ScheduleJobExecutionJobExecutePostMutationRequest,
  type ScheduleJobExecutionJobExecutePostMutationResponse,
  type ScheduleJobExecutionJobExecutePostQueryParams,
  type Scheme,
  type SimpleAssetDefinition,
  type StartAssetActionAssetAssetIdActionPostMutationRequest,
  type StartAssetActionAssetAssetIdActionPostMutationResponse,
  type StartAssetActionAssetAssetIdActionPostPathParams,
  type StartAssetActionAssetAssetIdActionPostQueryParams,
  type StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest,
  type StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse,
  type StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams,
  type StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams,
  type TariffIn,
  type TariffInstanceIn,
  type TariffInstanceOut,
  type TariffOut,
  type User,
  type VirtualAssetDefinition,
  type WirelessSurveyDataCollectorIn,
  type WirelessSurveyDataCollectorOut,
  type WirelessSurveyIn,
  type WirelessSurveyOut,
  useCreateAssetPositionAssetLinkAssetPositionLinkPost,
  useCreateBulkUpdateBulkPost,
  useCreateExportJobJobPost,
  useCreateNewAccountAccountPost,
  useCreateNewApiUserUserApiPost,
  useCreateNewAssetAssetPost,
  useCreateNewAssetPositionAssetPositionPost,
  useCreateNewCompanyCompanyPost,
  useCreateNewCompanyRegisterPost,
  useCreateNewLocationLocationPost,
  useCreateNewSchemeSchemePost,
  useCreateNewUserUserPost,
  useCreateNoteNotePost,
  useCreateReportExecutionReportPost,
  useCreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost,
  useCreateTariffInstanceTariffTariffIdInstancePost,
  useCreateTariffTariffPost,
  useCreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost,
  useCreateWirelessSurveyWirelessSurveyPost,
  useExecuteBulkUpdateBulkUpdateIdPost,
  useGetAssetTagsLabelsPdfAssetRegisterLabelsPost,
  useHandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost,
  useResendAssetPositionFileAssetPositionAssetPositionIdFilesFileIdPost,
  useScheduleJobExecutionJobExecutePost,
  useStartAssetActionAssetAssetIdActionPost,
  useStartAssetPositionActionAssetPositionAssetPositionIdActionPost,
} from "../kubb";
import type { Asset } from "../utils/object.tsx";
import {
  type MutateEntityWrapperResult,
  useMutateEntity,
} from "./updateEntity.tsx";

export interface CreateEntity<T = any, I = T> {
  createEntity: (entity: I) => Promise<[T, AxiosResponseHeaders]>;
  creating: boolean;
  error?: Error;
}

export function useCreateNote(): MutateEntityWrapperResult<NoteIn, NoteIn> {
  return useMutateEntity<
    CreateNoteNotePostMutationResponse,
    CreateNoteNotePostMutationRequest,
    CreateNoteNotePostQueryParams
  >(useCreateNoteNotePost(), {});
}

export function useCreateJobExecution(
  company_id: string,
): MutateEntityWrapperResult<boolean, JobExecutionInstruction> {
  return useMutateEntity<
    ScheduleJobExecutionJobExecutePostMutationResponse,
    ScheduleJobExecutionJobExecutePostMutationRequest,
    ScheduleJobExecutionJobExecutePostQueryParams,
    {
      data: ScheduleJobExecutionJobExecutePostMutationRequest;
      params?: ScheduleJobExecutionJobExecutePostQueryParams;
    }
  >(useScheduleJobExecutionJobExecutePost(), {}, { company_id: company_id });
}

export function useCreateReportExecution(
  company_id: string,
): MutateEntityWrapperResult<ReportExecutionOut, ReportExecutionIn> {
  return useMutateEntity<
    CreateReportExecutionReportPostMutationResponse,
    CreateReportExecutionReportPostMutationRequest,
    CreateReportExecutionReportPostQueryParams,
    {
      data: CreateReportExecutionReportPostMutationRequest;
      params?: CreateReportExecutionReportPostQueryParams;
    }
  >(useCreateReportExecutionReportPost(), {}, { company_id: company_id });
}

export function useCreateCompanyAndUser(): MutateEntityWrapperResult<
  Company,
  CreateNewCompanyRegisterPostMutationRequest
> {
  return useMutateEntity<
    CreateNewCompanyRegisterPostMutationResponse,
    CreateNewCompanyRegisterPostMutationRequest,
    CreateNewCompanyRegisterPostQueryParams,
    {
      data: CreateNewCompanyRegisterPostMutationRequest;
      params?: CreateNewCompanyRegisterPostQueryParams;
    }
  >(useCreateNewCompanyRegisterPost(), {}, {});
}

export function useCreateCompany(): MutateEntityWrapperResult<
  Company,
  CreateNewCompanyCompanyPostMutationRequest
> {
  return useMutateEntity<
    CreateNewCompanyCompanyPostMutationResponse,
    CreateNewCompanyCompanyPostMutationRequest,
    CreateNewCompanyCompanyPostQueryParams,
    {
      data: CreateNewCompanyCompanyPostMutationRequest;
      params?: CreateNewCompanyCompanyPostQueryParams;
    }
  >(useCreateNewCompanyCompanyPost(), {}, {});
}

export function useCreateNewJob(
  company_id: string,
): MutateEntityWrapperResult<JobDefinitionOut, JobDefinitionIn> {
  return useMutateEntity(useCreateExportJobJobPost(), {}, { company_id });
}

export function useCreateNewTariff(
  company_id: string,
): MutateEntityWrapperResult<TariffOut, TariffIn> {
  return useMutateEntity(useCreateTariffTariffPost(), {}, { company_id });
}

export function useCreateNewAccount(): MutateEntityWrapperResult<
  Account,
  NewAccount
> {
  return useMutateEntity(useCreateNewAccountAccountPost(), {}, {});
}

export function useCreateNewTariffInstance(
  tariff: TariffOut,
): MutateEntityWrapperResult<TariffInstanceOut, TariffInstanceIn> {
  return useMutateEntity(
    useCreateTariffInstanceTariffTariffIdInstancePost(),
    { tariff_id: tariff.tariff_id },
    { company_id: tariff.company_id },
  );
}

export function useCreateNewCompanyUser(): MutateEntityWrapperResult<
  User,
  NewCompanyUser
> {
  return useMutateEntity(useCreateNewUserUserPost(), {}, {});
}

export function useCreateNewApiUser(): MutateEntityWrapperResult<
  User,
  ApiUserIn
> {
  return useMutateEntity(useCreateNewApiUserUserApiPost(), {}, {});
}

export function useCreateAssetPosition(
  company_id: string,
): MutateEntityWrapperResult<AssetPosition, NewAssetPosition> {
  return useMutateEntity(
    useCreateNewAssetPositionAssetPositionPost(),
    {},
    { company_id },
  );
}

export function useCreateLocation(
  company_id: string,
): MutateEntityWrapperResult<Location, NewLocation> {
  return useMutateEntity(
    useCreateNewLocationLocationPost(),
    {},
    { company_id },
  );
}

export function useCreateAsset(
  company_id: string,
): MutateEntityWrapperResult<Asset, DeviceAssetNewIn | GatewayAssetNewIn> {
  return useMutateEntity(useCreateNewAssetAssetPost(), {}, { company_id });
}

export function useCreateNewAssetPositionLink(): MutateEntityWrapperResult<
  AssetPositionAssetLinkOut,
  AssetPositionAssetLinkIn
> {
  return useMutateEntity(
    useCreateAssetPositionAssetLinkAssetPositionLinkPost(),
    {},
    {},
  );
}

export function useCreateNewSimpleAssetRegister(
  parentAssetPosition: AssetPosition,
): MutateEntityWrapperResult<SimpleAssetDefinition[]> {
  return useMutateEntity<
    CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse,
    CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest,
    CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams,
    {
      asset_position_id: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams["asset_position_id"];
      data?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest;
      params?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams;
    }
  >(
    useCreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost(),
    { asset_position_id: parentAssetPosition.asset_position_id },
    {},
  );
}

export function useResendIntegrationFile(
  file: IntegrationFile,
): MutateEntityWrapperResult<ResendFileBody> {
  return useMutateEntity(
    useResendAssetPositionFileAssetPositionAssetPositionIdFilesFileIdPost(),
    { asset_position_id: file.asset_position_id, file_id: file.file_id },
    {},
  );
}

export function useCreateAssetAction(
  asset: Asset,
): MutateEntityWrapperResult<AssetAction> {
  return useMutateEntity<
    StartAssetActionAssetAssetIdActionPostMutationResponse,
    StartAssetActionAssetAssetIdActionPostMutationRequest,
    StartAssetActionAssetAssetIdActionPostQueryParams,
    {
      asset_id: StartAssetActionAssetAssetIdActionPostPathParams["asset_id"];
      data: StartAssetActionAssetAssetIdActionPostMutationRequest;
      params?: StartAssetActionAssetAssetIdActionPostQueryParams;
    }
  >(
    useStartAssetActionAssetAssetIdActionPost(),
    { asset_id: asset.asset_id },
    {
      company_id: asset.company_id,
    },
  );
}

export function useCreateAssetPositionAction(
  asset_position: AssetPosition,
): MutateEntityWrapperResult<AssetPositionAction> {
  return useMutateEntity<
    StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse,
    StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest,
    StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams,
    {
      asset_position_id: StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams["asset_position_id"];
      data: StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest;
      params?: StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams;
    }
  >(
    useStartAssetPositionActionAssetPositionAssetPositionIdActionPost(),
    { asset_position_id: asset_position.asset_position_id },
    {
      company_id: asset_position.company_id,
    },
  );
}

export function useCreateVirtualAsset(
  assetPosition: AssetPosition,
): MutateEntityWrapperResult<VirtualAssetDefinition> {
  return useMutateEntity(
    useHandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost(),
    { asset_position_id: assetPosition.asset_position_id },
    {},
  );
}

export function useCreateWirelessSurvey(): MutateEntityWrapperResult<
  WirelessSurveyOut,
  WirelessSurveyIn
> {
  return useMutateEntity(useCreateWirelessSurveyWirelessSurveyPost(), {}, {});
}

export function useCreateWirelessSurveyDataCollector(
  survey: WirelessSurveyOut,
): MutateEntityWrapperResult<
  WirelessSurveyDataCollectorOut,
  WirelessSurveyDataCollectorIn
> {
  return useMutateEntity(
    useCreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost(),
    { survey_id: survey.survey_id },
    { company_id: survey.company_id },
  );
}

export function useCreateScheme(): MutateEntityWrapperResult<
  Scheme,
  NewScheme
> {
  return useMutateEntity(useCreateNewSchemeSchemePost(), {}, {});
}

export function useCreateNewBulkUpdate(
  company_id: string,
): MutateEntityWrapperResult<BulkUpdateDetailed, any> {
  return useMutateEntity<
    CreateBulkUpdateBulkPostMutationResponse,
    any,
    CreateBulkUpdateBulkPostQueryParams,
    { params?: CreateBulkUpdateBulkPostQueryParams; data?: any }
  >(useCreateBulkUpdateBulkPost(), {}, { company_id });
}

export function useCreateBulkUpdateExecution(
  update_id: string,
  company_id: string,
): MutateEntityWrapperResult<any, null> {
  return useMutateEntity<
    ExecuteBulkUpdateBulkUpdateIdPostMutationResponse,
    null,
    ExecuteBulkUpdateBulkUpdateIdPostQueryParams,
    {
      update_id: ExecuteBulkUpdateBulkUpdateIdPostPathParams["update_id"];
      params?: ExecuteBulkUpdateBulkUpdateIdPostQueryParams;
    }
  >(useExecuteBulkUpdateBulkUpdateIdPost(), { update_id }, { company_id });
}

export function useCreateAssetTags(): MutateEntityWrapperResult<
  string,
  ProduceAssetTagLabels
> {
  return useMutateEntity(
    useGetAssetTagsLabelsPdfAssetRegisterLabelsPost(),
    {},
    {},
  );
}
