import client from 'client'
import type {
  GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse,
  GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetPathParams,
  GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryParams,
  GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet422,
} from '../../types/GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey = (
  asset_id: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetPathParams['asset_id'],
  params?: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryParams,
) => [{ url: '/asset/:asset_id/configuration/detailed', params: { asset_id: asset_id } }, ...(params ? [params] : [])] as const

export type GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey = ReturnType<
  typeof getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey
>

/**
 * @summary Get Asset Configuration Detailed
 * {@link /asset/:asset_id/configuration/detailed}
 */
export async function getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet(
  asset_id: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetPathParams['asset_id'],
  params?: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse,
    ResponseErrorConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet422>,
    unknown
  >({ method: 'GET', url: `/asset/${asset_id}/configuration/detailed`, params, ...requestConfig })
  return res
}

export function getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryOptions(
  asset_id: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetPathParams['asset_id'],
  params?: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey(asset_id, params)
  return queryOptions<
    ResponseConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse>,
    ResponseErrorConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet422>,
    ResponseConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet(asset_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Configuration Detailed
 * {@link /asset/:asset_id/configuration/detailed}
 */
export function useGetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet<
  TData = ResponseConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey,
>(
  asset_id: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetPathParams['asset_id'],
  params?: GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryResponse>,
        ResponseErrorConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryKey(asset_id, params)

  const query = useQuery({
    ...(getAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGetQueryOptions(asset_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}