import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import {
  type EquationContentDefinitionInput,
  type SingleColumnContentDefinitionInput,
  type StandardSpreadsheetOptions,
  type StaticContentDefinitionInput,
  labellingCasesEnum,
  spreadsheetFileTypeEnum,
  timeFilterEnum,
} from "kubb";
import {
  type AllDataReportConfigurationInput as AllDataReportConfigurationModel,
  assetTypeEnum,
} from "kubb";
import { lowerCase, upperFirst } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import NewCheckboxComponent from "../../../components/Forms/NewCheckboxComponent";
import NewCheckboxField from "../../../components/Forms/NewCheckboxField";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../../components/Forms/SelectCombo/SelectCombo";
import type { SubFormProps } from "../../../components/Forms/WithSubForm";
import Collapse from "../../../components/Theme/collapse";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";
import HeaderLabelCaseSelect from "../Common/HeaderLabelCaseSelect";
import OutputFileTypeSelect from "../Common/OutputFileTypeSelect";

export interface AllDataReportConfigurationProps {
  file_name?: string;
  configuration: AllDataReportConfigurationModel;
}

export const AllDataReportConfigurationSubFormSchema = () => {
  return Yup.object().shape({
    configuration: Yup.object().shape({
      includedAssetTypes: Yup.array().required().min(1),
    }),
  });
};

export const AllDataReportConfigurationInitialValues: AllDataReportConfigurationProps =
  {
    file_name: "all_data_{company_id}_{scheme_id}_{date}.csv",
    configuration: {
      time_filter: timeFilterEnum.ALL,
      file_type: spreadsheetFileTypeEnum.CSV,
      included_asset_types: [
        assetTypeEnum.heat_meter,
        assetTypeEnum.cooling_meter,
        assetTypeEnum.heating_and_cooling_meter,
        assetTypeEnum.electricity_meter,
        assetTypeEnum.gas_meter,
        assetTypeEnum.water_meter,
        assetTypeEnum.room_sensor,
        assetTypeEnum.co2_room_sensor,
      ],
      output: {
        output_type: "standard",
        header_labels: labellingCasesEnum.TITLE_CASE,
        include_units: false,
      } as StandardSpreadsheetOptions,
    },
  };

function customColumnContent(
  row:
    | SingleColumnContentDefinitionInput
    | EquationContentDefinitionInput
    | StaticContentDefinitionInput,
): React.ReactElement {
  switch (row.output_type) {
    case "static":
      return <>{row.value}</>;
    case "equation":
      return <>{row.equation}</>;
    case "column":
      return (
        <>
          {row.column}
          {row.difference && " (difference)"}
        </>
      );
    default:
      return <></>;
  }
}

const AllDataReportConfigurationForm = ({
  namespace,
  disabled,
}: SubFormProps): React.ReactElement => {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  const [{ value }] = useField(namespace);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  // const custom = value.configuration.output.output_type === "custom";
  const custom = false;
  return (
    <>
      <NewTextFormComponent
        fieldName={withNamespace("file_name")}
        label={{ label: "Filename" }}
        placeholder={
          "Keywords for filename are: company_id, scheme_id and date"
        }
        disabled={disabled}
      />
      <h4>
        Advanced Configuration
        <FontAwesomeIcon
          icon={isOpen ? faMinusCircle : faPlusCircle}
          onClick={toggle}
          className={"mx-1"}
        />
      </h4>
      <Collapse isOpen={isOpen}>
        {custom ? (
          <>
            <NewLabelWrapper label={"This report has a custom output"}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Header</TableHeader>
                    <TableHeader>Type</TableHeader>
                    <TableHeader>Content</TableHeader>
                    <TableHeader>Factor</TableHeader>
                    <TableHeader>Rounding</TableHeader>
                    <TableHeader>Format</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.configuration.output.columns.map(
                    (
                      v:
                        | SingleColumnContentDefinitionInput
                        | EquationContentDefinitionInput
                        | StaticContentDefinitionInput,
                    ) => {
                      return (
                        <TableRow key={v.header_label}>
                          <TableCell>{v.header_label}</TableCell>
                          <TableCell>{v.output_type}</TableCell>
                          <TableCell>{customColumnContent(v)}</TableCell>
                          <TableCell>{v.factor || "-"}</TableCell>
                          <TableCell>{v.rounding || "-"}</TableCell>
                          <TableCell>{v.format || "-"}</TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </NewLabelWrapper>
          </>
        ) : (
          <>
            <HeaderLabelCaseSelect
              fieldName={withNamespace("configuration.output.header_labels")}
              label={{ label: "Headers Case" }}
              disabled={disabled}
            />
            <SelectComboField
              label={{ label: "Include units in header" }}
              fieldName={withNamespace("configuration.output.include_units")}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              disabled={disabled}
            />
          </>
        )}
        <TimeFilterSelect
          label={{ label: "Time Filter" }}
          fieldName={withNamespace("configuration.time_filter")}
          disabled={disabled}
        />

        <OutputFileTypeSelect
          label={{ label: "File Type" }}
          fieldName={withNamespace("configuration.file_type")}
          disabled={disabled}
        />
        <NewCheckboxField
          name={withNamespace("configuration.included_asset_types")}
        >
          {(fieldProps) => (
            <NewCheckboxComponent
              label={{ label: "Asset Type Filter" }}
              options={Object.values([
                assetTypeEnum.heat_meter,
                assetTypeEnum.heating_and_cooling_meter,
                assetTypeEnum.electricity_meter,
                assetTypeEnum.gas_meter,
                assetTypeEnum.water_meter,
                assetTypeEnum.cooling_meter,
                assetTypeEnum.room_sensor,
                assetTypeEnum.co2_room_sensor,
                assetTypeEnum.gateway,
              ]).map((key) => {
                return { label: upperFirst(lowerCase(key)), value: key };
              })}
              disabled={disabled}
              {...fieldProps}
            />
          )}
        </NewCheckboxField>
      </Collapse>
    </>
  );
};

export default AllDataReportConfigurationForm;
