import { useParams } from "react-router-dom";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { useGetWirelessSurvey } from "../../../hooks/getEntity";
import { useSelectedCompany } from "../../../reducers/company";
import WirelessSurveyHeading from "./WirelessSurveyHeading";
import WirelessSurveyTabs from "./WirelessSurveyTabs";

function WirelessSurveyHomepage() {
  const { surveyId } = useParams();
  const company = useSelectedCompany();

  if (surveyId === undefined || company === undefined) {
    throw new Error("survey and company must be defined");
  }

  const { data, loading, error, refresh } = useGetWirelessSurvey(
    surveyId,
    company.company_id,
  );

  return (
    <OneCardPage
      headerTitle={"Survey"}
      loading={loading}
      setPageTitle={() =>
        `Wireless Survey: ${data ? data.survey_name : "loading"}`
      }
    >
      {data && (
        <>
          <WirelessSurveyHeading survey={data} />
          <WirelessSurveyTabs survey={data} refreshSurvey={refresh} />
        </>
      )}
    </OneCardPage>
  );
}

export default WirelessSurveyHomepage;
