import type { AssetRegisterItem } from "kubb";
import React from "react";
import { useGetAssetRegisterItem } from "../../../hooks/getEntity";

interface ChildProps {
  assetRegisterItem: AssetRegisterItem;
  refresh: () => void;
}

interface Props {
  assetRegisterItem: AssetRegisterItem;
  children: (props: ChildProps) => React.ReactElement;
}

function AssetRegisterItemContainer(props: Props) {
  const [enabled, setEnabled] = React.useState(false);

  const { data, refresh } = useGetAssetRegisterItem(
    props.assetRegisterItem.asset_position.company_id,
    undefined,
    props.assetRegisterItem.asset_position.asset_position_id,
    props.assetRegisterItem.asset_position.scheme_id,
    enabled,
  );

  return props.children({
    assetRegisterItem: data || props.assetRegisterItem,
    refresh: enabled ? refresh : () => setEnabled(true),
  });
}

export default AssetRegisterItemContainer;
