import client from 'client'
import type {
  CreateNoteNotePostMutationRequest,
  CreateNoteNotePostMutationResponse,
  CreateNoteNotePostQueryParams,
  CreateNoteNotePost422,
} from '../../types/CreateNoteNotePost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNoteNotePostMutationKey = () => [{ url: '/note' }] as const

export type CreateNoteNotePostMutationKey = ReturnType<typeof createNoteNotePostMutationKey>

/**
 * @summary Create Note
 * {@link /note}
 */
export async function createNoteNotePost(
  data: CreateNoteNotePostMutationRequest,
  params?: CreateNoteNotePostQueryParams,
  config: Partial<RequestConfig<CreateNoteNotePostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CreateNoteNotePostMutationResponse, ResponseErrorConfig<CreateNoteNotePost422>, CreateNoteNotePostMutationRequest>({
    method: 'POST',
    url: `/note`,
    params,
    data,
    ...requestConfig,
  })
  return res
}

/**
 * @summary Create Note
 * {@link /note}
 */
export function useCreateNoteNotePost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNoteNotePostMutationResponse>,
      ResponseErrorConfig<CreateNoteNotePost422>,
      { data: CreateNoteNotePostMutationRequest; params?: CreateNoteNotePostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNoteNotePostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNoteNotePostMutationKey()

  return useMutation<
    ResponseConfig<CreateNoteNotePostMutationResponse>,
    ResponseErrorConfig<CreateNoteNotePost422>,
    { data: CreateNoteNotePostMutationRequest; params?: CreateNoteNotePostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNoteNotePost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}