import RequireScheme from "../../components/Scheme/RequireScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import { useSelectedScheme } from "../../reducers/scheme";
import AccountList from "./AccountList";

function AccountListPage() {
  const scheme = useSelectedScheme();

  return (
    <OneCardPage
      headerTitle={"Account list"}
      setPageTitle={schemeObjectPageTitle("Accounts")}
    >
      <RequireScheme>
        {scheme && (
          <AccountList
            company_id={scheme.company_id}
            scheme_id={scheme.scheme_id}
          />
        )}
      </RequireScheme>
    </OneCardPage>
  );
}

export default AccountListPage;
