import { Button } from "components/Theme/button";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Scheme } from "kubb";
import type { TariffIn } from "kubb";
import type { TariffOut } from "kubb";
import * as Yup from "yup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { Heading } from "../../components/Theme/heading";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import type { MutateEntityWrapperResult } from "../../hooks/updateEntity.tsx";
import { nameRegEx } from "../../model/utils/schema";

const TariffSchema = Yup.object().shape({
  tariff_id: Yup.string()
    .required()
    .matches(...nameRegEx),
  tariff_name: Yup.string().required(),
});

type FormValues = Omit<TariffOut, "company_id" | "scheme_id">;

const TariffCreateForm = (props: {
  scheme: Scheme;
  create: MutateEntityWrapperResult<TariffOut, TariffIn>;
}) => {
  const { scheme, create } = props;

  const initialValues: FormValues = {
    tariff_id: scheme.scheme_id,
    tariff_name: scheme.scheme_name,
  };
  console.log(props.create);

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    console.log("submit", values);
    create
      .update({
        ...values,
        company_id: scheme.company_id,
        scheme_id: scheme.scheme_id,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <>
      <Heading>
        Billing has not been enabled for{" "}
        <PrivacyMode>{props.scheme.scheme_name}</PrivacyMode>. Please contact
        support.
      </Heading>
      <DisplayIfUserType userTypes={"superadmin"}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={TariffSchema}
        >
          {({ submitForm, isSubmitting, isValid }) => (
            <Form>
              <NewTextFormComponent
                fieldName={"tariff_id"}
                label={{ label: "Tariff Id" }}
              />
              <NewTextFormComponent
                fieldName={"tariff_name"}
                label={{ label: "Tariff Name" }}
              />
              <Button
                color={"brandLight"}
                onClick={submitForm}
                disabled={isSubmitting || !isValid}
              >
                Create Tariff
              </Button>
            </Form>
          )}
        </Formik>
      </DisplayIfUserType>
    </>
  );
};

export default TariffCreateForm;
