import PieChart, {
  type ColouredPieChartData,
  type ColouredPieChartDataInner,
} from "../../../../components/charts/PieChart";

import SelectedScheme from "components/Scheme/SelectedScheme";
import type { PieChartDataItem } from "kubb";
import ErrorOverlappingBanner from "../../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import Card, { FixHeightCardBody } from "../../../../components/Theme/card";
import { pieChartColors } from "../../../../components/charts/ChartColors";
import AssetLatestTimeSeriesStatsGetter from "../AssetTimeLatestTimeSeriesStatsGetter";

type Props = { group: string; title: string };

const preprocessData = (data: PieChartDataItem[]): ColouredPieChartData => {
  /* removes 0 data returned by API and adds a color */
  return data.reduce<ColouredPieChartDataInner[]>((accumulator, value) => {
    if (value.count && value.count > 0) {
      let colour: string;
      switch (value.label) {
        case "< 1 hour":
          colour = pieChartColors.great;
          break;
        case "< 1 day":
          colour = pieChartColors.good;
          break;
        case "1 - 7 days":
          colour = pieChartColors.bad;
          break;
        case "7 - 28 days":
          colour = pieChartColors.reallyBad;
          break;
        case "> 28 days":
          colour = pieChartColors.reallyReallyBad;
          break;
        default:
          colour = pieChartColors.awful;
      }
      return [
        ...accumulator,
        { ...value, color: colour } as ColouredPieChartDataInner,
      ];
    }
    return accumulator;
  }, [] as ColouredPieChartDataInner[]);
};

const LatestTimeSeriesStatsWidget = (props: Props) => {
  return (
    <SelectedScheme>
      {(schemeProps) => {
        return (
          <AssetLatestTimeSeriesStatsGetter
            {...schemeProps}
            group={props.group}
            render={({ data, error, loading }) => {
              return (
                <Card title={props.title}>
                  <FixHeightCardBody height={36} overflowScroll={false}>
                    <BlockSpinner loading={loading || false}>
                      <ErrorOverlappingBanner error={error || undefined}>
                        {
                          <PieChart
                            data={data ? preprocessData(data) : []}
                            half={true}
                            donut={true}
                          />
                        }
                      </ErrorOverlappingBanner>
                    </BlockSpinner>
                  </FixHeightCardBody>
                </Card>
              );
            }}
          />
        );
      }}
    </SelectedScheme>
  );
};

export default LatestTimeSeriesStatsWidget;
