import type { AssetPosition } from "kubb";
import type React from "react";
import GatewayConnectivityReport from "../assets/gateway/GatewayConnectivityReport";

export default function WirelessClusterGatewayAddList(props: {
  clusterPosition: AssetPosition;
}): React.ReactElement {
  const { clusterPosition } = props;

  return (
    <GatewayConnectivityReport
      company_id={clusterPosition.company_id}
      scheme_id={clusterPosition.scheme_id}
      clusterForAssignment={clusterPosition}
      parent_asset_position_id={"NONE"}
    />
  );
}
