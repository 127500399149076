import AggregatedTimeSeriesApiWrapper from "containers/report/aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import dayjs from "dayjs";
import { type AssetTypeEnum, groupByEnum } from "kubb";
import { periodEnum } from "kubb";
import TimeSeriesStatsApiWrapper from "../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper.tsx";
import { meterOptions } from "../../Asset/AssetTypeSelectCombo";
import { mapFormValuesToTimeSeriesParams } from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import AggregatedTimeSeriesStatsReportView from "../AggregatedTimeSeriesStatsReportView/AggregatedTimeSeriesStatsReportView";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import {
  type NewTimeSeriesRequestParams,
  mapNewTimeSeriesRequestParamsToAggregatedReportRequest,
  mapNewTimeSeriesRequestParamsToAggregatedTimeSeriesStatsParams,
} from "../model";

export interface AggregatedTimeSeriesStatsReportProps {
  company_id: string;
  scheme_id?: string;
}

export function AggregatedTimeSeriesStatsReportComponent({
  company_id,
  scheme_id,
  params,
  onChange,
  showForm,
}: {
  company_id: string;
  scheme_id?: string;
  params: NewTimeSeriesRequestParams;
  onChange?: (v: NewTimeSeriesRequestParams) => void;
  showForm?: boolean;
}) {
  return (
    <TimeSeriesStatsApiWrapper
      request={{
        ...mapNewTimeSeriesRequestParamsToAggregatedTimeSeriesStatsParams({
          ...params,
          group: undefined,
          use_case: undefined,
        }),
        company_id,
        scheme_id,
      }}
    >
      {(data) => (
        <>
          {scheme_id ? (
            <AggregatedTimeSeriesApiWrapper
              request={{
                ...mapNewTimeSeriesRequestParamsToAggregatedReportRequest(
                  params,
                ),
                period: periodEnum.ALL,
                company_id,
                scheme_id,
              }}
            >
              {(aggregatedData) => (
                <AggregatedTimeSeriesStatsReportView
                  company_id={company_id}
                  scheme_id={scheme_id}
                  data={data}
                  aggregatedData={aggregatedData}
                  showForm={showForm}
                  onSubmit={(v) => {
                    onChange?.(
                      mapFormValuesToTimeSeriesParams(v, true, false, true),
                    );
                    return Promise.resolve();
                  }}
                  request={params}
                />
              )}
            </AggregatedTimeSeriesApiWrapper>
          ) : (
            <AggregatedTimeSeriesStatsReportView
              company_id={company_id}
              scheme_id={scheme_id}
              data={data}
              showForm={showForm}
              onSubmit={(v) => {
                onChange?.(mapFormValuesToTimeSeriesParams(v, true, false));
                return Promise.resolve();
              }}
              request={params}
            />
          )}
        </>
      )}
    </TimeSeriesStatsApiWrapper>
  );
}

export default function AggregatedTimeSeriesStatsReport(
  props: AggregatedTimeSeriesStatsReportProps,
) {
  return (
    <TimeSeriesQueryUrlWrapper
      defaultValues={{
        asset_type: meterOptions as AssetTypeEnum[],
        parameter: "Power",
        aggregation: "mean",
        period: periodEnum.DAILY,
        start_date: dayjs().startOf("day").subtract(14, "days"),
        end_date: dayjs().endOf("day").subtract(1, "days"),
        group_by: groupByEnum.NONE,
      }}
    >
      {(
        params: NewTimeSeriesRequestParams,
        onChange: (v: NewTimeSeriesRequestParams) => void,
      ) => (
        <AggregatedTimeSeriesStatsReportComponent
          params={params}
          company_id={props.company_id}
          scheme_id={props.scheme_id}
          onChange={onChange}
        />
      )}
    </TimeSeriesQueryUrlWrapper>
  );
}
