import { Label } from "@headlessui/react";
import { isArray, isArrayLike } from "lodash-es";
import { Checkbox, CheckboxField } from "../Theme/checkbox";
import NewLabelWrapper, { type LabelProps } from "./NewLabelWrapper";
import {
  type Option,
  type Value,
  type ValueList,
  isGroupBase,
  isOption,
} from "./SelectCombo/SelectCombo";

export interface NewCheckboxComponentProps {
  name: string;
  label?: LabelProps;
  onChange: (newValue: ValueList) => void;
  options?: Option[];
  value: ValueList;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
}

function isValueInOptions(value: ValueList, option: Option): boolean {
  if (isOption(option)) {
    if (isArrayLike(value)) {
      return value.indexOf(option.value as string) !== -1;
    } else {
      return option.value === value;
    }
  } else {
    return false;
  }
}

function NewCheckboxComponent(props: NewCheckboxComponentProps) {
  const { label, error, disabled } = props;

  const clickHandler = (value: Option) => {
    const currentValue: Value[] = isArray(props.value)
      ? props.value
      : [props.value];
    const newValue = value.value as unknown as string;
    if (isValueInOptions(currentValue, value)) {
      props.onChange(currentValue.filter((v) => v !== newValue));
    } else {
      props.onChange([...currentValue, newValue]);
    }
  };

  return (
    <NewLabelWrapper {...{ ...label, error: error }}>
      {props.options?.map((option) => {
        if (isGroupBase(option)) {
          throw new Error("Groups are not supported for checkboxes");
        } else {
          const selected = isValueInOptions(props.value, option);
          return (
            <CheckboxField key={option.value.toString()}>
              <Checkbox
                checked={selected}
                disabled={disabled}
                onChange={() => clickHandler(option)}
              />
              <Label> {option.label}</Label>
            </CheckboxField>
          );
        }
      })}
    </NewLabelWrapper>
  );
}

export default NewCheckboxComponent;
