import PrivacyMode from "components/Text/PrivacyMode";
import {
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/Theme/table";
import {
  type Location,
  getLocationListLocationGet,
  objectTypesEnum,
} from "kubb";
import { useGetLocationListLocationGet } from "kubb";
import type React from "react";
import BulkUpdate from "../../../components/BulkUpdate/BulkUpdate";
import NumberOfAssetPositionsDisplayWidget from "../../../components/Common/NumberOfAssetPositionsDisplayWidget";
import LocationIdLink from "../../../components/Location/LocationIdLink";
import NewGetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";

interface Props {
  company_id: string;
  scheme_id?: string;
}

export default function LocationsList(props: Props): React.ReactElement {
  const { company_id, scheme_id } = props;

  const params = {
    company_id,
    scheme_id,
  };

  const header = (
    <TableHead>
      <TableRow>
        <TableHeader>Location ID</TableHeader>
        <TableHeader>Scheme ID</TableHeader>
        <TableHeader>Address</TableHeader>
        <TableHeader>Reference</TableHeader>
        <TableHeader>Asset Positions</TableHeader>
        <DisplayIfUserType userTypes={"superadmin"}>
          <TableHeader>Group</TableHeader>
        </DisplayIfUserType>
      </TableRow>
    </TableHead>
  );

  const row = (location: Location): React.ReactElement => {
    return (
      <TableRow data-cy="locations-list-item" key={location.location_id}>
        <TableCell data-cy={"location-id"}>
          <LocationIdLink location={location} />
        </TableCell>
        <TableCell data-cy="location-name">
          <PrivacyMode>{location.scheme_id}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="location-location">
          <PrivacyMode>{location.address}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="location-reference">
          <PrivacyMode>{location.customer_reference || "-"}</PrivacyMode>
        </TableCell>
        <TableCell data-cy={"location-asset-positions"}>
          <NumberOfAssetPositionsDisplayWidget
            positions={location.number_of_asset_positions}
            index={location.location_id}
          />
        </TableCell>
        <DisplayIfUserType userTypes={"superadmin"}>
          <TableCell>
            <PrivacyMode>{location.group || "-"}</PrivacyMode>
          </TableCell>
        </DisplayIfUserType>
      </TableRow>
    );
  };
  const extraButtons = (
    <div className={"flex gap-2"}>
      <PaginatedApiToCSVExportButton
        useQuery={getLocationListLocationGet}
        params={params}
      />
      {scheme_id && (
        <>
          <CompanyNavLink
            to={{ pathname: "/admin/location/create" }}
            button={{
              color: "brandLight",
            }}
          >
            Add Location
          </CompanyNavLink>
          <BulkUpdate
            company_id={company_id}
            scheme_id={scheme_id}
            objectType={objectTypesEnum.Location}
          />
          <CompanyNavLink
            to={{ pathname: "/admin/location/updates" }}
            button={{
              color: "brandLight",
            }}
          >
            Previous Uploads
          </CompanyNavLink>
        </>
      )}
    </div>
  );

  return (
    <NewGetListPaginatedTable
      params={params}
      cursor={{ max_items: 20 }}
      data-cy={"location-list"}
      header={[header, 4]}
      row={row}
      useQuery={useGetLocationListLocationGet}
      additionalFunctionButtons={extraButtons}
    />
  );
}
