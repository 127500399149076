import { type SimpleAssetDefinition, assetTypeEnum } from "kubb";
import type { ParseResult } from "papaparse";
import type React from "react";
import * as yup from "yup";
import BulkUpdateCSVDropZone from "../../../../components/BulkUpdate/BulkUpdateCSVDropZone";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import {
  type UploadDefinition,
  convertCSVToDefinitions,
} from "./GenericUploadPage";

const importSchema = yup.object().shape({
  serial_number: yup.string().required(),
  address: yup.string().required(),
  asset_type: yup.string().required().oneOf(Object.values(assetTypeEnum)),
  asset_position_customer_reference: yup.string(),
  location_reference: yup.string(),
});

export type ParsedAssetDefinition = UploadDefinition<SimpleAssetDefinition>;

export const UploadPage = (props: {
  handleFile: (definitions: Promise<ParsedAssetDefinition[]>) => void;
}): React.ReactElement => {
  const processFile = (data: Promise<ParseResult<any>>): void => {
    props.handleFile(convertCSVToDefinitions(data, importSchema));
  };

  return (
    <>
      <p>
        Here you can upload a combined list of locations, references and assets
        that will be assigned to this gateway position.
      </p>
      <p>
        Your csv file must contain the columns:
        <ul>
          <li>
            <b>serial_number</b> - The physical serial number of the asset. Take
            care with serial numbers that start with a &ldquo;0&ldquo;, many
            spreadsheet programmes will delete these when they save the data.
            Make sure to add a &apos; when you type the data.
          </li>
          <li>
            <b>asset_type</b> - The type of asset you wish to create (from{" "}
            {Object.values(assetTypeEnum)
              .filter(
                (assetType) =>
                  !["gateway", "sim_card", "unknown"].includes(assetType),
              )
              .join(", ")}
            )
          </li>
          <li>
            <b>address</b> - The address of the asset. This will be assigned to
            the address of the location that is created
          </li>
        </ul>
      </p>
      <p>
        Your csv file can optionally contain the column:
        <ul>
          <li>
            <b>customer_asset_position_reference</b> - The reference for the
            asset position that is created.
          </li>
          <li>
            <b>configuration.xxx.yyy</b> - Additional asset configurations.
          </li>
          <li>
            <b>location_reference</b> - The reference for the location that is
            created.
          </li>
          <li>
            <b>group</b> - The group that is assigned the location that is
            created.
          </li>
        </ul>
      </p>
      <p>Any additional columns in your spreadsheet will be ignored.</p>
      <h4>Adding multiple assets to one location</h4>
      <p>
        To add multiple assets to one location create one line per asset in the
        CSV file all with identical <i>`location_reference`</i> values.
      </p>
      <p>
        The `address` and `group` values, if set, must also be identical for
        each asset line.
      </p>
      <h4>Upload</h4>
      <p>
        <CompanyNavLink
          to={{
            pathname: "/examples/simple_asset_register.csv",
          }}
          onlyExternal={true}
          retainCompany={false}
        >
          Click to download an example file.
        </CompanyNavLink>
      </p>
      <p>
        After you upload the spreadsheet you will be shown a preview of the
        sheet with any errors highlighted. After you are shown the preview you
        can then submit the file and create the items that do not have errors.
      </p>

      <BulkUpdateCSVDropZone
        buttonLabel={"Upload asset register CSV"}
        parseComplete={processFile}
      />
    </>
  );
};
