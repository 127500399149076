import client from 'client'
import type {
  StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest,
  StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse,
  StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams,
  StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams,
  StartAssetPositionActionAssetPositionAssetPositionIdActionPost422,
} from '../../types/StartAssetPositionActionAssetPositionAssetPositionIdActionPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const startAssetPositionActionAssetPositionAssetPositionIdActionPostMutationKey = () => [{ url: '/asset_position/{asset_position_id}/action' }] as const

export type StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationKey = ReturnType<
  typeof startAssetPositionActionAssetPositionAssetPositionIdActionPostMutationKey
>

/**
 * @summary Start Asset Position Action
 * {@link /asset_position/:asset_position_id/action}
 */
export async function startAssetPositionActionAssetPositionAssetPositionIdActionPost(
  asset_position_id: StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams['asset_position_id'],
  data: StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest,
  params?: StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams,
  config: Partial<RequestConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse,
    ResponseErrorConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPost422>,
    StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest
  >({ method: 'POST', url: `/asset_position/${asset_position_id}/action`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Start Asset Position Action
 * {@link /asset_position/:asset_position_id/action}
 */
export function useStartAssetPositionActionAssetPositionAssetPositionIdActionPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse>,
      ResponseErrorConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPost422>,
      {
        asset_position_id: StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams['asset_position_id']
        data: StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest
        params?: StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams
      }
    >
    client?: Partial<RequestConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? startAssetPositionActionAssetPositionAssetPositionIdActionPostMutationKey()

  return useMutation<
    ResponseConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationResponse>,
    ResponseErrorConfig<StartAssetPositionActionAssetPositionAssetPositionIdActionPost422>,
    {
      asset_position_id: StartAssetPositionActionAssetPositionAssetPositionIdActionPostPathParams['asset_position_id']
      data: StartAssetPositionActionAssetPositionAssetPositionIdActionPostMutationRequest
      params?: StartAssetPositionActionAssetPositionAssetPositionIdActionPostQueryParams
    }
  >({
    mutationFn: async ({ asset_position_id, data, params }) => {
      return startAssetPositionActionAssetPositionAssetPositionIdActionPost(asset_position_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}