import { type AxiosError, isAxiosError } from "axios";
import { isString } from "lodash";
import { range } from "lodash-es";
import type React from "react";
import AlertBanner from "../Banner/AlertBanner";
import { errorToTitleAndText } from "../Error/errorMessages.tsx";
import BlockSpinner from "../Spinners/BlockSpinner";
import { Heading } from "../Theme/heading";
import { StackedList, StackedListItem } from "../Theme/stacked-list";

export interface ListProps {
  data?: Array<any>;
  loading?: boolean;
  header?: React.ReactElement;
  row: (item: any, index: number, refresh?: () => void) => React.ReactElement;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: React.ReactElement;
  error?: Error | null;
  refresh?: () => void;
  missingElements?: number;
}

function List(props: ListProps) {
  const error = props.error;
  const header = props.header;

  const displayError = (error: Error | AxiosError): React.ReactElement => {
    if (isAxiosError(error)) {
      return errorRow(error as AxiosError);
    } else {
      return errorRow(error.message);
    }
  };

  const errorRow = (error: AxiosError | string): React.ReactElement => {
    console.log(error);
    const color = "bg-red-700";

    const { text, title } = isString(error)
      ? { text: error, title: "An error has occurred." }
      : errorToTitleAndText(error);

    return (
      <StackedListItem key={"list-error"}>
        <AlertBanner className={color}>
          <Heading level={5}>{title}</Heading>
          <p>{text}</p>
        </AlertBanner>
      </StackedListItem>
    );
  };
  return (
    <BlockSpinner loading={props.loading || false} scrollX={false}>
      <StackedList data-cy={props["data-cy"]}>
        {header}

        {props.data?.map((value, index) => {
          return props.row(value, index, props.refresh);
        })}
        {props.loading &&
          range(props.missingElements || 0).map((i) => (
            <StackedListItem
              key={i.toString()}
              data-cy={props["data-cy"] && `${props["data-cy"]}-loading-item`}
            />
          ))}
        {!props.loading && props.data?.length === 0 && !error && (
          <StackedListItem key={"no-data"}>
            {props.noDataRow || "No Data Found"}
          </StackedListItem>
        )}
        {!props.loading && error && displayError(error)}
      </StackedList>
    </BlockSpinner>
  );
}

export default List;
