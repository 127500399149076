import {
  type GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse,
  type PieChartDataItem,
  useGetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet,
} from "kubb";
import type React from "react";
import { useGetDataNew } from "../../../hooks/getData";

interface Props {
  company_id: string;
  scheme_id?: string;
  group?: string;
  render: PieChartDataComponent;
}

export type PieChartDataComponent = (props: {
  data?: Array<PieChartDataItem>;
  loading?: boolean;
  error?: Error | null;
}) => React.ReactElement;

const AssetLatestTimeSeriesStatsGetter = (props: Props): React.ReactElement => {
  const { company_id, scheme_id, render } = props;

  const { data, loading, error } =
    useGetDataNew<GetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGetQueryResponse>(
      () =>
        useGetLatestTimeSeriesDataStatsReportTimeSeriesLatestStatsGet({
          group: props.group || "meter",
          scheme_id,
          company_id,
        }),
    );

  return <>{render({ data, loading, error })}</>;
};

export default AssetLatestTimeSeriesStatsGetter;
