import * as Sentry from "@sentry/browser";
import type { Dayjs } from "dayjs";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import type { WirelessSurveyOut } from "kubb";
import DataTable, { type TableColumn } from "react-data-table-component";
import { Badge } from "../../../components/Theme/badge";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import type { WirelessSurveyDataPoint } from "../../../model/assets/wirelessSurveyData";
import { b64ToHex } from "../assets/gateway/data/StreamingGatewayDataTableRow";

function safeToISOString(date: Dayjs): string {
  try {
    return date.toISOString();
  } catch (e) {
    Sentry.captureException(e);
    return "";
  }
}

function WirelessSurveyLiveData(props: {
  survey: WirelessSurveyOut;
  dataCollectors: WirelessSurveyDataCollectorOut[];
  data: WirelessSurveyDataPoint[];
}) {
  const dataCollectorMap = new Map(
    props.dataCollectors.map((v) => [v.data_collector_id, v]),
  );

  const columns: TableColumn<WirelessSurveyDataPoint>[] = [
    {
      name: "Sample Point",
      grow: 2,
      cell: (row) => (
        <>
          {dataCollectorMap.get(row.dataCollectorId)?.data_collector_name ||
            row.dataCollectorId}
        </>
      ),
    },
    {
      name: "Timestamp",
      cell: (row) => (
        <>
          {formatDate(row.timestamp, undefined, true)}
          {row.age() <= 15 && <Badge color={"brandLight"}>New</Badge>}
        </>
      ),
      selector: (row) => row.timestamp.unix(),
      sortable: true,
    },
    {
      name: "Manufacturer",
      compact: true,
      selector: (row) => row.manufacturer,
    },
    {
      name: "Serial Number",
      compact: true,
      selector: (row) => row.serial_number,
    },
    { name: "Medium", compact: true, selector: (row) => row.medium },
    { name: "Version", compact: true, selector: (row) => row.version },
    { name: "RSSI", compact: true, selector: (row) => row.rssi },
    {
      name: "Data",
      cell: (row) => (
        <CopyToClipboard
          value={b64ToHex(row.data)
            .toUpperCase()
            .match(/.{1,2}/g)
            ?.join(" ")}
          slice={10}
          className={"text-monospace small"}
        />
      ),
    },
  ];
  return (
    <>
      <CSVExportButton
        data={props.data.map((v) => {
          return {
            ...v,
            timestamp: safeToISOString(v.timestamp),
            data: b64ToHex(v.data)
              .toUpperCase()
              .match(/.{1,2}/g)
              ?.join(" "),
            dataCollectorName:
              dataCollectorMap.get(v.dataCollectorId)?.data_collector_name ||
              v.dataCollectorId,
          };
        })}
        columnOrder={[
          "dataCollectorId",
          "dataCollectorName",
          "timestamp",
          "manufacturer",
          "medium",
          "version",
          "serial_number",
          "rssi",
          "samplePointId",
          "data",
        ]}
        filename={`wireless_survey_${props.survey.survey_name}_data.csv`}
      />
      <DataTable
        columns={columns}
        data={props.data}
        dense={true}
        pagination={true}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[25, 50, 100, 250]}
        conditionalRowStyles={[
          {
            when: (row) => row.age() < 15,
            classNames: ["bg-light"],
          },
        ]}
        fixedHeader={true}
        fixedHeaderScrollHeight={"600px"}
      />
    </>
  );
}

export default WirelessSurveyLiveData;
