import { ErrorBanner } from "components/Error/ErrorBanner";
import { type Location, statusEnum } from "kubb";
import AssetRegisterList from "pages/assetRegister/AssetRegisterListPage/AssetRegisterList";
import type React from "react";
import { useState } from "react";
import HeadingEditorForm from "../../../components/Forms/HeadingEditorForm";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import type { GetDataWrapperResult } from "../../../components/MutateWrapper.tsx";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import { Heading } from "../../../components/Theme/heading";
import { locationToBreadcrumb } from "../../../components/navigation/breadcrumbs/Breadcrumbs";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import EventList from "../../../containers/event/EventList";
import { useUpdateLocation } from "../../../hooks/updateEntity";
import { locationSchema } from "../../../model/location";
import AssetPositionCreateForm from "../assetPositions/AssetPositionCreateForm";
import AssetPositionDeleteTab from "../assetPositions/tabs/AssetPositionDeleteTab";
import LocationAccountTab from "./LocationAccountTab";

export default function LocationDisplay({
  data,
  refresh,
  error,
  loading,
}: GetDataWrapperResult<Location>): React.ReactElement {
  const location = data;
  const deleted = location?.status === statusEnum.DELETED;

  const update = location ? useUpdateLocation(location) : null;

  const [refreshed, setRefreshed] = useState<Date>(new Date());

  const updateList = (): void => {
    setRefreshed(new Date());
  };

  const deleteLocation = (): Promise<any> => {
    if (location && update) {
      return update
        .update({
          ...location,
          status: statusEnum.DELETED,
        })
        .then(() => {
          refresh();
        });
    } else {
      throw new Error("Can not delete with a location");
    }
  };

  return (
    <OneCardPage
      headerTitle={"Location Detail"}
      breadcrumbs={location && [locationToBreadcrumb(location)]}
      loading={loading}
      data-cy={"location-detail"}
      setPageTitle={() => `${location?.address}`}
    >
      {error && <ErrorBanner error={error} />}
      {location && update && (
        <>
          <ObjectDetailHeading
            leftColumn={{
              content: (
                <>
                  <HeadingEditorForm
                    update={update}
                    label={"Address"}
                    values={location}
                    title={"Address"}
                    fieldName={"address"}
                    validator={locationSchema.address}
                    editActionUserType={"editor"}
                    postUpdate={refresh}
                  />
                  <HeadingEditorForm
                    update={update}
                    label={"Reference"}
                    values={location}
                    title={"Reference"}
                    fieldName={"customer_reference"}
                    editActionUserType={"editor"}
                    defaultValue={"-"}
                    postUpdate={refresh}
                  />
                  <HeadingEditorForm
                    update={update}
                    label={"Group"}
                    values={location}
                    title={"Group"}
                    fieldName={"group"}
                    defaultValue={"-"}
                    validator={locationSchema.group}
                    editActionUserType={"superadmin"}
                    postUpdate={refresh}
                  />
                </>
              ),
            }}
            rightColumn={{
              content: (
                <>
                  {deleted && (
                    <ObjectPropertyItem title={"Status"}>
                      <AssetRegisterStatusLabel status={location.status} />
                    </ObjectPropertyItem>
                  )}
                  <ObjectPropertyItem title={"Scheme"} privacyMode>
                    {location.scheme_id}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Location ID"}>
                    <CopyToClipboard value={location?.location_id} slice={8} />
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Created"}>
                    {formatDate(location?.created_at, undefined, false)}
                  </ObjectPropertyItem>
                  <ObjectPropertyItem title={"Updated"}>
                    {formatDate(location?.last_updated, undefined, false)}
                  </ObjectPropertyItem>
                </>
              ),
            }}
          />
          <RoutedTabGroupInterface
            tabs={[
              {
                tabName: "Asset Positions",
                tabPath: "positions",
                tabContent: (
                  <>
                    <Heading>Asset Positions</Heading>
                    <AssetRegisterList
                      company_id={location.company_id}
                      scheme_id={location.scheme_id}
                      params={{ location_id: location.location_id }}
                      download={false}
                      createAssetTags={false}
                      showLocation={false}
                      lastChange={refreshed}
                    />
                    <DisplayIfUserType userTypes={"admin"}>
                      <Heading>Add New Asset Position</Heading>
                      <AssetPositionCreateForm
                        location={location}
                        postCreateActions={updateList}
                      />
                    </DisplayIfUserType>
                  </>
                ),
                disabled: deleted,
              },
              {
                tabName: "Accounts",
                tabPath: "accounts",
                tabContent: <LocationAccountTab location={location} />,
                userTypes: "superadmin",
                disabled: deleted,
              },

              {
                tabName: "Events",
                tabPath: "events",
                tabContent: (
                  <>
                    <Heading>Location Events</Heading>
                    <EventList
                      location_id={location.location_id}
                      company_id={location.company_id}
                    />
                  </>
                ),
              },
              {
                tabName: "Delete",
                tabPath: "delete",
                tabContent: (
                  <AssetPositionDeleteTab
                    assetPosition={location}
                    refresh={refresh}
                    delete={deleteLocation}
                    title={"Location"}
                  />
                ),
                userTypes: "admin",
                disabled: false,
              },
            ]}
            topLevelPath={`/admin/asset_register/location/${location.location_id}`}
          />
        </>
      )}
    </OneCardPage>
  );
}
