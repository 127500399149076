import { yupPassword } from "components/Forms/yupValidators";
import { Form, Formik, type FormikHelpers } from "formik";
import type { Company, NewCompany, NewUser } from "kubb";
import {
  type CompanyRole,
  type CompanyType,
  companyRoleEnum,
  companyTypeEnum,
} from "kubb";
import type React from "react";
import * as Yup from "yup";
import AlertBanner from "../../components/Banner/AlertBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { Button } from "../../components/Theme/button";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { useSelectedCompany } from "../../reducers/company";

export interface CreateCompanyFormValues {
  company_name: string;
  company_id: string;
  email_address: string;
  create_user: boolean;
  company_type: CompanyType;
  password: string;
  user_type: CompanyRole;
}

interface Props {
  onSubmit: (newCompany: NewCompany, newUser?: NewUser) => Promise<any>;
}

export default function CreateCompanyForm(props: Props): React.ReactElement {
  const selectedCompany = useSelectedCompany();

  const partner: Company | undefined =
    selectedCompany?.company_type === "PARTNER" ? selectedCompany : undefined;

  const submit = (
    values: CreateCompanyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CreateCompanyFormValues>,
  ): void => {
    setSubmitting(true);
    const newCompany = {
      name: values.company_name,
      company_id: values.company_id,
      company_type: values.company_type,
      partner_id:
        values.company_type === companyTypeEnum.CLIENT
          ? selectedCompany?.company_id
          : undefined,
    };
    const newUser = values.create_user
      ? {
          email_address: values.email_address,
          fullname: values.email_address,
          password: values.password,
          user_type: values.user_type,
        }
      : undefined;
    props
      .onSubmit(newCompany, newUser)
      .then(() => resetForm())
      .finally(() => setSubmitting(false));
  };

  const initialValues: CreateCompanyFormValues = {
    company_name: "",
    email_address: "",
    password: "",
    create_user: true,
    company_type: companyTypeEnum.CLIENT,
    company_id: "",
    user_type: companyRoleEnum.viewer,
  };
  return (
    <DisplayIfUserType
      userTypes={"admin"}
      companyType={companyTypeEnum.PARTNER}
    >
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          company_name: Yup.string().required("Required"),
          company_id: Yup.string()
            .required()
            .min(5, "Id is too short -  should be 5 chars minimum")
            .max(50, "Id is too long - should be be 50 chars maximum")
            .matches(
              /[a-z0-9-]/,
              "Id can only contain letters a-z, numbers 0-9 or a `-`",
            ),
          email_address: Yup.string()
            .email()
            .when("create_user", {
              is: true,
              // biome-ignore lint/suspicious/noThenProperty: then is the yup name
              then: Yup.string().email().required("Required"),
            }),
          password: Yup.string().when("create_user", {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty: then is the yup name
            then: yupPassword(),
          }),
        })}
      >
        {({ isValid, isSubmitting, values }): React.ReactElement => (
          <Form aria-label={"create-company-form"}>
            <h3 className="text-white mb-0">Add Company</h3>
            <NewTextFormComponent
              fieldName={"company_id"}
              label={{ label: "Id" }}
            />
            <NewTextFormComponent
              fieldName={"company_name"}
              label={{ label: "Name" }}
            />
            <DisplayIfUserType userTypes={"superadmin"}>
              <ButtonGroupField
                fieldName={"company_type"}
                label={{ label: "Company type" }}
                options={[
                  { label: "partner", value: "PARTNER" },
                  { label: "client", value: "CLIENT" },
                ]}
              />
            </DisplayIfUserType>{" "}
            <ButtonGroupField
              fieldName={"create_user"}
              label={{ label: "Create User" }}
              options={[
                { label: "yes", value: true },
                { label: "no", value: false },
              ]}
            />
            {values.create_user && (
              <>
                <NewTextFormComponent
                  fieldName={"email_address"}
                  label={{ label: "User email" }}
                  type={"email"}
                  disabled={!values.create_user}
                />
                <NewTextFormComponent
                  fieldName={"password"}
                  label={{ label: "User password" }}
                  type={"password"}
                  disabled={!values.create_user}
                />
                <ButtonGroupField
                  fieldName={"user_type"}
                  label={{ label: "User type" }}
                  options={[
                    { label: "admin", value: "admin" },
                    { label: "editor", value: "editor" },
                    { label: "viewer", value: "viewer" },
                  ]}
                  disabled={!values.create_user}
                />
              </>
            )}
            {partner && (
              <AlertBanner className={"bg-brand-light-600"}>
                This new company will be created as a client of {partner.name}
              </AlertBanner>
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </DisplayIfUserType>
  );
}
