import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";
import ReportExecutionList from "./ReportExecutionList";

interface ReportExecutionListContainerProps {
  jobId?: string;
}

function ReportExecutionListContainer(
  props: ReportExecutionListContainerProps,
) {
  const company = useRequiredSelectedCompanyId();
  const scheme = useSelectedScheme();

  return (
    <ReportExecutionList
      params={{ job_id: props.jobId }}
      company_id={company}
      scheme_id={scheme?.scheme_id}
    />
  );
}

export default ReportExecutionListContainer;
