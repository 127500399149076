import dayjs from "dayjs";
import {
  type TimeSeriesDataProjection,
  timeSeriesDataProjectionEnum,
} from "kubb";
import { isNil } from "lodash-es";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import inclusiveDatePeriod from "../../../../components/Forms/DateTimePicker";
import { parse_to_enum } from "../../../../components/Search/SearchParamPage.tsx";
import { parseSearchString } from "../../../../components/navigation/SearchString";
import { doesDeviceHaveParsedData } from "../../../../model/assets/deviceData";
import type { Asset } from "../../../../utils/object.tsx";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";

interface Props {
  children: ({
    searchParams,
  }: {
    searchParams: AssetTimeSeriesRequestParams;
  }) => React.ReactElement;
  device: Asset;
}

const AssetDataSearchStringParser = (props: Props): React.ReactElement => {
  const [searchParams, setSearchParams] = useState<
    AssetTimeSeriesRequestParams | undefined
  >(undefined);

  const location = useLocation();

  useEffect(() => {
    const [startDate, endDate] = inclusiveDatePeriod(
      undefined,
      undefined,
      undefined,
      true,
    );
    const searchParams = parseSearchString(location.search);

    const end_datetime = searchParams.start_datetime
      ? dayjs(searchParams.end_datetime as string)
      : dayjs(+endDate);

    const start_datetime = searchParams.start_datetime
      ? dayjs(searchParams.start_datetime as string)
      : startDate;

    const style: TimeSeriesDataProjection = !isNil(
      parse_to_enum(
        searchParams,
        timeSeriesDataProjectionEnum,
        "projection",
      )?.[0],
    )
      ? (parse_to_enum(
          searchParams,
          timeSeriesDataProjectionEnum,
          "projection",
        )?.[0] as unknown as TimeSeriesDataProjection)
      : doesDeviceHaveParsedData(props.device)
        ? timeSeriesDataProjectionEnum.parsed
        : timeSeriesDataProjectionEnum.raw;

    setSearchParams({
      start_datetime,
      end_datetime,
      projection: style,
    });
  }, [location.search, props.device]);

  return <>{searchParams && props.children({ searchParams })}</>;
};

export default AssetDataSearchStringParser;
