import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "kubb";
import type { AssetRegisterItemAggregatedTimeSeries } from "kubb";
import type React from "react";
import { usePrivacyMode } from "../../reducers/privacy";
// import { usePrivacyMode } from "reducers/privacy";
import HighchartsPrivacyModeFilter from "../Text/HighchartsPrivacyModeFilter";
import {
  type AggregatedChartsAmendedOptions,
  type AggregatedDataItemClickHandlerProps,
  type AssetRegisterItemColumn,
  type AssetRegisterItemPoint,
  aggregationDataItemClickHandler,
} from "./AggregationChartFunctions";

import nodata from "highcharts/modules/no-data-to-display.js";
import { camelCase, round } from "lodash-es";

nodata(Highcharts);

export default function AggregationDistributionChart(
  props: {
    data?: AssetRegisterAggregatedTimeSeriesResponse;
    aggregation: string;
    parameter: string;
    loading: boolean;
    privacyModeEnabled?: boolean;
    name?: string;
    unit: string;
    height?: string;
  } & AggregatedDataItemClickHandlerProps,
): React.ReactElement {
  const { height = "400px" } = props;
  const privacyMode = usePrivacyMode();

  const aggregation = camelCase(props.aggregation);
  const parameter = camelCase(props.parameter);

  const dataMapper = (
    v: AssetRegisterItemAggregatedTimeSeries,
  ): AssetRegisterItemColumn => {
    return {
      asset_position_id: v.asset_position_id,
      assetPositionReference: v.asset_position_reference,
      locationAddress: v.location_address,
      asset_id: v.asset_id,
      y: v.aggregations[parameter]?.[aggregation]?.[0] || undefined,
    };
  };

  const seriesDefinition = (
    data: AssetRegisterAggregatedTimeSeriesResponse,
    name?: string,
    userRef?: string,
    color?: string,
  ): Highcharts.SeriesColumnOptions & AggregatedChartsAmendedOptions => {
    return {
      id: userRef,
      data: data.data
        .map(dataMapper)
        .sort((n1, n2) => (n1.y || 0) - (n2.y || 0)),
      type: "column",
      color: color ? `var(--${color})` : undefined,
      animation: false,
      events: {
        click: aggregationDataItemClickHandler({
          start_time: props.start_time,
          end_time: props.end_time,
          privacyMode: privacyMode,
          company_id: props.company_id,
          scheme_id: props.scheme_id,
        }),
      },
      turboThreshold: 0,
      tooltip: {
        pointFormatter: function (this: AssetRegisterItemPoint) {
          return (
            `<tspan style='fill:${this.color}'>●</tspan>` +
            `<tspan style='${privacyMode && "filter: url(#blur)"}'> 
${this.locationAddress} - ${this.assetPositionReference}
             </tspan>` +
            `<tspan style='font-weight:bold'> ${round(this.y as number, 1)}${
              props.unit
            } </tspan>`
          );
        },
      },
    };
  };

  const series: Highcharts.SeriesColumnOptions[] = props.data
    ? [seriesDefinition(props.data)]
    : [];

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      type: "column",
      height: height,
    },
    // xAxis: {
    //   type: "datetime",
    //   min: props.params?.startDatetime?.valueOf(),
    //   max: props.params?.endDatetime?.valueOf(),
    // },
    series: series,
    yAxis: props.data
      ? [
          {
            labels: {
              format: `{value}${props.unit}`,
            },
            title: {
              text: props.name,
            },
          },
        ]
      : [],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    tooltip: {
      shared: true,
      // valueSuffix: props.unit,
      // valueDecimals: props.rounding,
      // pointFormat: `{series.name} {point.y}${props.unit}`
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          // enabled:
          //   props.displayExport !== undefined ? props.displayExport : true,
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return (
    <>
      <HighchartsPrivacyModeFilter />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
}
