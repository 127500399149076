import type { AssetRegisterItem } from "kubb";
import type React from "react";
import AssetPositionNumbersToStringList from "../../../components/AssetPosition/AssetPositionNumberSummaryList";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import { StackedListThreeColumnItem } from "../../../components/Theme/stacked-list";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";

export default function WirelessClusterListRowInner(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
}): React.ReactElement {
  const { assetRegisterItem } = props;
  const { location, asset_position } = assetRegisterItem;

  return (
    <StackedListThreeColumnItem
      key={asset_position.asset_position_id}
      column1={
        <div className="min-w-0 flex-auto">
          <CompanyNavLink
            className={"p-0 d-inline"}
            to={{
              pathname: `/admin/asset_position/${asset_position?.asset_position_id}`,
            }}
          >
            <PrivacyMode>{location?.address}</PrivacyMode>
          </CompanyNavLink>
        </div>
      }
      column2={
        <div className="min-w-0 flex-auto">
          {assetRegisterItem.asset_position.child_asset_positions?.gateway ||
            0}{" "}
        </div>
      }
      column3={
        <div className="min-w-0 flex-auto">
          <AssetPositionNumbersToStringList
            numbers={assetRegisterItem.asset_position.child_asset_positions}
            includeGateways={false}
          />
        </div>
      }
    />
  );
}
