import { periodEnum } from "kubb";
import type React from "react";
import {
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function PeriodSelector(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options = [
    { label: "Daily", value: periodEnum.DAILY },
    { label: "Weekly", value: periodEnum.WEEKLY },
    { label: "7 Day", value: periodEnum.SEVEN_DAY },
    { label: "Monthly", value: periodEnum.MONTHLY },
  ];
  return <SelectComboField options={options} {...props} />;
}
