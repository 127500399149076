import client from 'client'
import type {
  CreateReportExecutionReportPostMutationRequest,
  CreateReportExecutionReportPostMutationResponse,
  CreateReportExecutionReportPostQueryParams,
  CreateReportExecutionReportPost422,
} from '../../types/CreateReportExecutionReportPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createReportExecutionReportPostMutationKey = () => [{ url: '/report' }] as const

export type CreateReportExecutionReportPostMutationKey = ReturnType<typeof createReportExecutionReportPostMutationKey>

/**
 * @summary Create Report Execution
 * {@link /report}
 */
export async function createReportExecutionReportPost(
  data: CreateReportExecutionReportPostMutationRequest,
  params?: CreateReportExecutionReportPostQueryParams,
  config: Partial<RequestConfig<CreateReportExecutionReportPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateReportExecutionReportPostMutationResponse,
    ResponseErrorConfig<CreateReportExecutionReportPost422>,
    CreateReportExecutionReportPostMutationRequest
  >({ method: 'POST', url: `/report`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Report Execution
 * {@link /report}
 */
export function useCreateReportExecutionReportPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateReportExecutionReportPostMutationResponse>,
      ResponseErrorConfig<CreateReportExecutionReportPost422>,
      { data: CreateReportExecutionReportPostMutationRequest; params?: CreateReportExecutionReportPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateReportExecutionReportPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createReportExecutionReportPostMutationKey()

  return useMutation<
    ResponseConfig<CreateReportExecutionReportPostMutationResponse>,
    ResponseErrorConfig<CreateReportExecutionReportPost422>,
    { data: CreateReportExecutionReportPostMutationRequest; params?: CreateReportExecutionReportPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createReportExecutionReportPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}