import type React from "react";
import { useContext } from "react";
import { SchemeContext } from "../../reducers/scheme";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

type SchemeSelectFormComponentProps = Omit<SelectComboFieldProps, "options">;
export default function SchemeSelectFormComponent(
  props: SchemeSelectFormComponentProps,
): React.ReactElement {
  const { state } = useContext(SchemeContext);
  const { loading, schemes = [] } = state;
  const ready = !state.loading && !state.error;
  const haveSchemes = schemes && schemes.length > 0;
  const options: Option[] = schemes.map((scheme) => {
    return {
      label: `${scheme.scheme_name} (${scheme.scheme_id})`,
      value: scheme.scheme_id,
    };
  });

  const altText: string = ready
    ? haveSchemes
      ? "All"
      : "No Groups"
    : loading
      ? "Loading Schemes"
      : state.error?.message || "Error Loading Groups";

  return (
    <SelectComboField
      {...props}
      disabled={loading || props.disabled}
      options={[...options]}
      placeholder={altText}
    />
  );
}
