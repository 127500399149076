import { Heading } from "components/Theme/heading";
import type { AssetPosition } from "kubb";
import type { Location } from "kubb";
import { statusEnum } from "kubb";
import type React from "react";
import AssetPositionDeleteButton from "../AssetPositionDeleteButton";

const AssetPositionDeleteTab = (props: {
  assetPosition: AssetPosition | Location;
  title: string;
  refresh?: () => any;
  delete: () => Promise<any>;
}): React.ReactElement => {
  const deleted = props.assetPosition.status === statusEnum.DELETED;
  return (
    <>
      <Heading>Delete {props.title}</Heading>
      <AssetPositionDeleteButton {...props} />
      {deleted && <p>{props.title} has already been deleted.</p>}
    </>
  );
};

export default AssetPositionDeleteTab;
