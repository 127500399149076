import type React from "react";
import RequireScheme from "../../components/Scheme/RequireScheme";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle";
import NewLocationForm from "../../pages/assetRegister/locations/NewLocationForm";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import { useSelectedScheme, useSelectedSchemeId } from "../../reducers/scheme";

const SchemeNewLocationPage = (): React.ReactElement => {
  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();
  const scheme = useSelectedScheme();

  return (
    <RequireScheme>
      <OneCardPage
        headerTitle={`Add Location to ${scheme?.scheme_name}`}
        breadcrumbs={[
          { url: "/admin/location", name: "Locations" },
          {
            url: `/admin/schemes/${scheme_id}/location/create`,
            name: "Add Location",
          },
        ]}
        setPageTitle={schemeObjectPageTitle("Add Location")}
      >
        <NewLocationForm
          company_id={company_id}
          scheme_id={scheme_id as string}
        />
      </OneCardPage>
    </RequireScheme>
  );
};

export default SchemeNewLocationPage;
