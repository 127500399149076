import client from 'client'
import type {
  GetAssetPositionListAssetPositionGetQueryResponse,
  GetAssetPositionListAssetPositionGetQueryParams,
  GetAssetPositionListAssetPositionGet422,
} from '../../types/GetAssetPositionListAssetPositionGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetPositionListAssetPositionGetQueryKey = (params?: GetAssetPositionListAssetPositionGetQueryParams) =>
  [{ url: '/asset_position' }, ...(params ? [params] : [])] as const

export type GetAssetPositionListAssetPositionGetQueryKey = ReturnType<typeof getAssetPositionListAssetPositionGetQueryKey>

/**
 * @summary Get Asset Position List
 * {@link /asset_position}
 */
export async function getAssetPositionListAssetPositionGet(
  params?: GetAssetPositionListAssetPositionGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetAssetPositionListAssetPositionGetQueryResponse, ResponseErrorConfig<GetAssetPositionListAssetPositionGet422>, unknown>({
    method: 'GET',
    url: `/asset_position`,
    params,
    ...requestConfig,
  })
  return res
}

export function getAssetPositionListAssetPositionGetQueryOptions(
  params?: GetAssetPositionListAssetPositionGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetPositionListAssetPositionGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetAssetPositionListAssetPositionGetQueryResponse>,
    ResponseErrorConfig<GetAssetPositionListAssetPositionGet422>,
    ResponseConfig<GetAssetPositionListAssetPositionGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetPositionListAssetPositionGet(params, config)
    },
  })
}

/**
 * @summary Get Asset Position List
 * {@link /asset_position}
 */
export function useGetAssetPositionListAssetPositionGet<
  TData = ResponseConfig<GetAssetPositionListAssetPositionGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetPositionListAssetPositionGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetPositionListAssetPositionGetQueryKey,
>(
  params?: GetAssetPositionListAssetPositionGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetPositionListAssetPositionGetQueryResponse>,
        ResponseErrorConfig<GetAssetPositionListAssetPositionGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetPositionListAssetPositionGetQueryKey(params)

  const query = useQuery({
    ...(getAssetPositionListAssetPositionGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetPositionListAssetPositionGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}