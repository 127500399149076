import { useGetSchemeGroupsSchemeSchemeIdGroupsGet } from "kubb";
import React, { useContext, useEffect } from "react";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { SchemeContext, useSelectedScheme } from "../../reducers/scheme";

function InnerSchemeGroupListInit(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const { state, dispatch } = useContext(SchemeContext);
  const selectedScheme = useSelectedScheme();
  const { stale } = state;

  const [init, setInit] = React.useState(false);

  if (!selectedScheme) {
    throw new Error("Scheme must have been selected.");
  }

  const { data, isLoading, error, refetch } =
    useGetSchemeGroupsSchemeSchemeIdGroupsGet(selectedScheme.scheme_id, {
      company_id: selectedScheme.company_id,
    });

  useEffect(() => {
    if (data) {
      dispatch({ type: "LOADING_GROUPS_SUCCESS", payload: data.data });
    }
    setInit(true);
  }, [data, dispatch]);

  useEffect(() => {
    if (isLoading && !stale) {
      dispatch({ type: "LOADING_GROUPS_STARTED" });
    }
  }, [isLoading, dispatch, stale]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_GROUPS_ERROR", payload: error });
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (stale) {
      refetch();
    }
  }, [stale, refetch]);

  return (
    <>
      <BlockSpinner loading={state.loading}>
        {init && !state.loading && <>{props.children}</>}
      </BlockSpinner>
    </>
  );
}

export default function SchemeGroupListInit(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const selectedScheme = useSelectedScheme();
  const { dispatch } = useContext(SchemeContext);

  useEffect(() => {
    if (!selectedScheme) {
      dispatch({ type: "LOADING_GROUPS_SUCCESS", payload: [] });
    }
  }, [selectedScheme, dispatch]);

  return (
    <>
      {selectedScheme ? (
        <InnerSchemeGroupListInit>{props.children}</InnerSchemeGroupListInit>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}
