import type { AssetFile as GatewayFile } from "kubb";
import type { IntegrationFile } from "kubb";
import {
  loadFileFromApi,
  loadFileFromApiToMemory,
} from "../../../../actions/helpers/presigned_s3";

export function isGatewayFile(
  file: GatewayFile | IntegrationFile,
): file is GatewayFile {
  return (file as GatewayFile).asset_id !== undefined;
}

export function isIntegrationFile(
  file: GatewayFile | IntegrationFile,
): file is IntegrationFile {
  return (file as IntegrationFile).asset_position_id !== undefined;
}

export const getGatewayFileFromApi = async (
  file: GatewayFile | IntegrationFile,
): Promise<void> => {
  if (isGatewayFile(file)) {
    return loadFileFromApi(
      `/asset/${file.asset_id}/files/${file.file_id}?company_id=${file.company_id}`,
    );
  }
  if (isIntegrationFile(file)) {
    return loadFileFromApi(
      `/asset_position/${file.asset_position_id}/files/${file.file_id}?company_id=${file.company_id}`,
    );
  }
};

export const getGatewayFileFromApiToMemory = async (
  file: GatewayFile | IntegrationFile,
): Promise<any> => {
  if (isGatewayFile(file)) {
    return loadFileFromApiToMemory(
      `/asset/${file.asset_id}/files/${file.file_id}?company_id=${file.company_id}`,
    );
  }
  if (isIntegrationFile(file)) {
    return loadFileFromApiToMemory(
      `/asset_position/${file.asset_position_id}/files/${file.file_id}?company_id=${file.company_id}`,
    );
  }
};
