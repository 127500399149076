import { Button } from "components/Theme/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/Theme/table";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import { isNull } from "lodash-es";
import { Badge } from "../../../components/Theme/badge";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import AssetContainer, {
  type OptionalAssetChildProps,
} from "../assets/AssetContainer";
import { isGatewayAsset } from "../assets/model";
import WirelessSurveyDataCollectorCloseButton from "./WirelessSurveyDataCollectorCloseButton";

function WirelessSurveyDataCollectorsList(props: {
  dataCollectors: WirelessSurveyDataCollectorOut[];
  refresh?: () => any;
}) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Data Collector ID</TableHeader>
            <TableHeader>Data Collector Name</TableHeader>
            <TableHeader>Gateway Serial Number</TableHeader>
            <TableHeader>Data Collection Times</TableHeader>
            <TableHeader>&nbsp;</TableHeader>
          </TableRow>
        </TableHead>
        {props.dataCollectors.map((dataCollector) => (
          <TableBody key={dataCollector.data_collector_id}>
            <TableRow>
              <TableCell>
                <CopyToClipboard
                  value={dataCollector.data_collector_id}
                  slice={8}
                />
              </TableCell>
              <TableCell>{dataCollector.data_collector_name}</TableCell>
              <TableCell>
                {dataCollector.serial_number}{" "}
                <AssetContainer
                  asset_id={dataCollector.asset_id}
                  company_id={dataCollector.company_id}
                >
                  {({ asset, loading }: OptionalAssetChildProps) => (
                    <>
                      {loading
                        ? "LOADING"
                        : asset &&
                          isGatewayAsset(asset) &&
                          (asset.state?.connection || "UNKNOWN")}
                    </>
                  )}
                </AssetContainer>
              </TableCell>
              <TableCell>
                {formatDate(dataCollector.start_date_time, undefined, true)}-
                {isNull(dataCollector.end_date_time)
                  ? "now"
                  : formatDate(dataCollector.end_date_time, undefined, true)}
              </TableCell>
              <TableCell>
                {isNull(dataCollector.end_date_time) ? (
                  <WirelessSurveyDataCollectorCloseButton
                    dataCollector={dataCollector}
                    postUpdate={props.refresh}
                  />
                ) : (
                  <Badge color={"warning"}>Closed</Badge>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
      {props.refresh && (
        <div className={"text-right"}>
          {/*TODO: small button */}
          <Button size={"sm"} color={"brandLight"} onClick={props.refresh}>
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
}

export default WirelessSurveyDataCollectorsList;
