import client from 'client'
import type {
  GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest,
  GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationResponse,
  GetAssetTagsLabelsPdfAssetRegisterLabelsPostQueryParams,
  GetAssetTagsLabelsPdfAssetRegisterLabelsPost422,
} from '../../types/GetAssetTagsLabelsPdfAssetRegisterLabelsPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const getAssetTagsLabelsPdfAssetRegisterLabelsPostMutationKey = () => [{ url: '/asset_register/labels' }] as const

export type GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationKey = ReturnType<typeof getAssetTagsLabelsPdfAssetRegisterLabelsPostMutationKey>

/**
 * @summary Get Asset Tags Labels Pdf
 * {@link /asset_register/labels}
 */
export async function getAssetTagsLabelsPdfAssetRegisterLabelsPost(
  data: GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest,
  params?: GetAssetTagsLabelsPdfAssetRegisterLabelsPostQueryParams,
  config: Partial<RequestConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationResponse,
    ResponseErrorConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPost422>,
    GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest
  >({ method: 'POST', url: `/asset_register/labels`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Get Asset Tags Labels Pdf
 * {@link /asset_register/labels}
 */
export function useGetAssetTagsLabelsPdfAssetRegisterLabelsPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationResponse>,
      ResponseErrorConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPost422>,
      { data: GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest; params?: GetAssetTagsLabelsPdfAssetRegisterLabelsPostQueryParams }
    >
    client?: Partial<RequestConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getAssetTagsLabelsPdfAssetRegisterLabelsPostMutationKey()

  return useMutation<
    ResponseConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationResponse>,
    ResponseErrorConfig<GetAssetTagsLabelsPdfAssetRegisterLabelsPost422>,
    { data: GetAssetTagsLabelsPdfAssetRegisterLabelsPostMutationRequest; params?: GetAssetTagsLabelsPdfAssetRegisterLabelsPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return getAssetTagsLabelsPdfAssetRegisterLabelsPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}