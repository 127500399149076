import client from 'client'
import type {
  GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest,
  GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse,
  GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams,
  GetSummarisedTimeSeriesReportReportTimeSeriesPost422,
} from '../../types/GetSummarisedTimeSeriesReportReportTimeSeriesPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const getSummarisedTimeSeriesReportReportTimeSeriesPostMutationKey = () => [{ url: '/report/time_series' }] as const

export type GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationKey = ReturnType<typeof getSummarisedTimeSeriesReportReportTimeSeriesPostMutationKey>

/**
 * @summary Get Summarised Time Series Report
 * {@link /report/time_series}
 */
export async function getSummarisedTimeSeriesReportReportTimeSeriesPost(
  data?: GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest,
  params?: GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams,
  config: Partial<RequestConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse,
    ResponseErrorConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPost422>,
    GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest
  >({ method: 'POST', url: `/report/time_series`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Get Summarised Time Series Report
 * {@link /report/time_series}
 */
export function useGetSummarisedTimeSeriesReportReportTimeSeriesPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse>,
      ResponseErrorConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPost422>,
      { data?: GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest; params?: GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams }
    >
    client?: Partial<RequestConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getSummarisedTimeSeriesReportReportTimeSeriesPostMutationKey()

  return useMutation<
    ResponseConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationResponse>,
    ResponseErrorConfig<GetSummarisedTimeSeriesReportReportTimeSeriesPost422>,
    { data?: GetSummarisedTimeSeriesReportReportTimeSeriesPostMutationRequest; params?: GetSummarisedTimeSeriesReportReportTimeSeriesPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return getSummarisedTimeSeriesReportReportTimeSeriesPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}