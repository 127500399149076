import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as zip from "@zip.js/zip.js";
import type { AssetFile as GatewayFile } from "kubb";
import type { IntegrationFile } from "kubb";
import { isError } from "lodash-es";
import React from "react";
import { ErrorBanner } from "../../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../../components/Theme/button";
import {
  Dialog,
  DialogBody,
  DialogTitle,
} from "../../../../../components/Theme/dialog";
import { getGatewayFileFromApiToMemory } from "../model";
import EmailViewer from "./EmailViewer";

interface GatewayFilePreviewButtonProps {
  file: GatewayFile | IntegrationFile;
}

function hasPreview(file: GatewayFile | IntegrationFile): boolean {
  return file.type.toLowerCase() === "mime";
}

export default function GatewayFilePreviewButton(
  props: GatewayFilePreviewButtonProps,
): React.ReactElement {
  const show = hasPreview(props.file);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | undefined>();

  async function loadFile() {
    setLoading(true);
    try {
      const file = await getGatewayFileFromApiToMemory(props.file);
      const reader = new zip.ZipReader(new zip.BlobReader(file.data));
      const fileContent = await reader.getEntries({});
      if (fileContent.length === 1 && fileContent[0]?.getData) {
        const raw = await fileContent[0].getData(new zip.TextWriter());
        setFile(raw);
        return;
      }
      setError(Error("No file found in server response."));
    } catch (err) {
      console.error(err);
      if (isError(err)) {
        setError(err);
      } else {
        setError(Error("Unknown error"));
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleOpen() {
    setOpen(true);
    if (file === undefined && !loading) {
      await loadFile();
    }
  }

  return show ? (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} size={"5xl"}>
        <DialogTitle>Preview: {props.file.filename}</DialogTitle>
        <DialogBody>
          <BlockSpinner loading={loading}>
            <div className={"w-full h-[800px] overflow-scroll"}>
              {error && <ErrorBanner error={error} />}
              {file && <EmailViewer email={file} />}
            </div>
          </BlockSpinner>
        </DialogBody>
      </Dialog>

      <Button plain={true} size={"sm"} onClick={handleOpen}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className={"text-brand-dark-700"}
          fixedWidth={true}
          size={"sm"}
        />
      </Button>
    </>
  ) : (
    <></>
  );
}
