import client from 'client'
import type {
  CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest,
  CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationResponse,
  CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostPathParams,
  CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostQueryParams,
  CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost422,
} from '../../types/CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationKey = () =>
  [{ url: '/wireless_survey/{survey_id}/data_collector' }] as const

export type CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationKey = ReturnType<
  typeof createWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationKey
>

/**
 * @summary Create Wireless Survey Data Collector
 * {@link /wireless_survey/:survey_id/data_collector}
 */
export async function createWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost(
  survey_id: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostPathParams['survey_id'],
  data: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest,
  params?: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostQueryParams,
  config: Partial<RequestConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationResponse,
    ResponseErrorConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost422>,
    CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest
  >({ method: 'POST', url: `/wireless_survey/${survey_id}/data_collector`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Wireless Survey Data Collector
 * {@link /wireless_survey/:survey_id/data_collector}
 */
export function useCreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationResponse>,
      ResponseErrorConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost422>,
      {
        survey_id: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostPathParams['survey_id']
        data: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest
        params?: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationKey()

  return useMutation<
    ResponseConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationResponse>,
    ResponseErrorConfig<CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost422>,
    {
      survey_id: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostPathParams['survey_id']
      data: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostMutationRequest
      params?: CreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPostQueryParams
    }
  >({
    mutationFn: async ({ survey_id, data, params }) => {
      return createWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorPost(survey_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}