import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PrivacyMode from "components/Text/PrivacyMode";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { type Company, companyTypeEnum } from "kubb";
import { useGetCompanyListCompanyGet } from "kubb";
import { lowerCase, startCase } from "lodash-es";
import type React from "react";
import { useContext, useState } from "react";
import { NavLink } from "react-router";
import NewGetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import {
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Theme/table";
import UncontrolledTooltip from "../../components/Theme/tooltip";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { CompanyContext } from "../../reducers/company";
import CreateCompanyContainer from "./CreateCompanyContainer";

export default function CompanyList(): React.ReactElement {
  const { state } = useContext(CompanyContext);
  const [lastChange, setLastChange] = useState(new Date());
  const { selected } = state;

  const refresh = () => {
    setLastChange(new Date());
  };

  const header = (
    <TableHead>
      <TableRow>
        <TableHeader scope="col" className="text-left">
          Id
        </TableHeader>
        <TableHeader scope="col" className="text-left">
          Name
        </TableHeader>
        <TableHeader scope="col" className="text-left">
          Type
        </TableHeader>
        <TableHeader scope="col" className="text-left">
          Number of Assets
        </TableHeader>
        <TableHeader scope="col" className={"text-left"}>
          Partner
        </TableHeader>
        <TableHeader scope="col" className="text-left">
          &nbsp;
        </TableHeader>
      </TableRow>
    </TableHead>
  );

  const row = (value: Company) => {
    const isSelected = selected && value.company_id === selected.company_id;
    return (
      <TableRow
        key={value.company_id}
        data-cy="list-item"
        className={classNames({
          "bg-brand-light-200": isSelected,
        })}
      >
        <TableCell scope={"row"} data-cy="company-id">
          <b>
            <PrivacyMode>{value.company_id}</PrivacyMode>
          </b>
        </TableCell>
        <TableCell data-cy="company-name">
          <PrivacyMode>{value.name}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="company-type" className="text-left">
          {startCase(lowerCase(value.company_type))}
        </TableCell>
        <TableCell data-cy="no-of-gateways">{value.number_of_assets}</TableCell>
        <TableCell data-cy={"partner"}>
          <PrivacyMode>
            {value.partner
              ? `${value.partner.partner_name} (${value.partner.partner_id})`
              : "-"}
          </PrivacyMode>
        </TableCell>
        <TableCell className="table-actions">
          {!isSelected && (
            <UncontrolledTooltip message={`Select ${value.name}`}>
              <NavLink
                to={{
                  hash: `company=${value.company_id}`,
                }}
                id={`tooltip-select-company-${value.company_id}`}
              >
                <FontAwesomeIcon
                  icon={faHandPointUp}
                  fixedWidth
                  className={"px-2"}
                />
              </NavLink>
            </UncontrolledTooltip>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <OneCardPage
      headerTitle={"Companies List"}
      setPageTitle={() => "Companies list"}
    >
      <NewGetListPaginatedTable
        params={{
          partner_id:
            selected?.company_type === companyTypeEnum.PARTNER
              ? selected.company_id
              : undefined,
        }}
        cursor={{ max_items: 50 }}
        data-cy={"company-list"}
        header={[header, 6]}
        row={row}
        useQuery={useGetCompanyListCompanyGet}
        lastUpdateRequested={lastChange}
      />
      <DisplayIfUserType userTypes={"admin"}>
        <DisplayIfUserType
          userTypes={"admin"}
          companyType={companyTypeEnum.PARTNER}
        >
          <CreateCompanyContainer onSubmit={refresh} />
        </DisplayIfUserType>
      </DisplayIfUserType>
    </OneCardPage>
  );
}
