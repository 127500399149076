import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { AssetPositionNumbers } from "kubb";
import { startCase } from "lodash-es";
import type React from "react";
import UncontrolledTooltip from "../Theme/tooltip";

const NumberOfAssetPositionsDisplayWidget = (props: {
  positions?: AssetPositionNumbers;
  index: number | string;
}): React.ReactElement => {
  const positions: AssetPositionNumbers = props.positions
    ? props.positions
    : {};
  const total = Object.values(positions).reduce((a, b) => a + b, 0);
  const tooltipContent = (
    Object.keys(positions) as Array<keyof AssetPositionNumbers>
  ).map((key) => (
    <p key={key}>
      {startCase(key)}: {positions[key]}
    </p>
  ));

  return (
    <>
      {total}{" "}
      <UncontrolledTooltip placement="right" message={tooltipContent}>
        <FontAwesomeIcon
          className={"text-base"}
          fixedWidth={true}
          icon={faInfoCircle}
        />
      </UncontrolledTooltip>
    </>
  );
};

export default NumberOfAssetPositionsDisplayWidget;
