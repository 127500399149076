import dayjs from "dayjs";
import { periodEnum, reportViewEnum } from "kubb";
import AggregatedTimeSeriesApiWrapper from "../../../containers/report/aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper.tsx";
import { meterOptions } from "../../Asset/AssetTypeSelectCombo";
import AggregatedTimeSeriesDataReportView from "../AggregatedTimeSeriesDataReportView/AggregatedTImeSeriesDataReportView";
import { mapFormValuesToTimeSeriesParams } from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import {
  type NewTimeSeriesRequestParams,
  mapNewTimeSeriesRequestParamsToAggregatedReportRequest,
} from "../model";

export interface AggregatedTimeSeriesDataReportProps {
  company_id: string;
  scheme_id: string;
}

function AggregatedTimeSeriesDataReport(
  props: AggregatedTimeSeriesDataReportProps,
) {
  return (
    <TimeSeriesQueryUrlWrapper
      defaultValues={{
        asset_type: meterOptions,
        parameter: "Energy (Heating)",
        aggregation: "last",
        period: periodEnum.DAILY,
        start_date: dayjs().startOf("day").subtract(14, "days"),
        end_date: dayjs().endOf("day").subtract(1, "days"),
        view_by: reportViewEnum.ASSET_POSITION,
      }}
    >
      {(
        params: NewTimeSeriesRequestParams,
        onChange: (v: NewTimeSeriesRequestParams) => void,
      ) => (
        <AggregatedTimeSeriesApiWrapper
          request={{
            ...mapNewTimeSeriesRequestParamsToAggregatedReportRequest(params),
            ...props,
          }}
        >
          {(data) => (
            <AggregatedTimeSeriesDataReportView
              company_id={props.company_id}
              scheme_id={props.scheme_id}
              data={data}
              onSubmit={(v) => {
                console.log("s");
                onChange(mapFormValuesToTimeSeriesParams(v, false, true, true));
                return Promise.resolve();
              }}
              request={params}
            />
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </TimeSeriesQueryUrlWrapper>
  );
}

export default AggregatedTimeSeriesDataReport;
