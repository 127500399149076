import { Form, Formik, type FormikHelpers } from "formik";
import {
  type WirelessSurveyOut,
  type WirelessSurveySamplePointInput,
  assetTypeEnum,
} from "kubb";
import type React from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";
import { useCreateWirelessSurveyDataCollector } from "../../../hooks/createEntity";
import AssetSelectFormField from "../assets/AssetSelectFormField.tsx";

interface FormValues {
  data_collector_name: string;
  asset_id: string;
  sample_points: WirelessSurveySamplePointInput[];
}

function WirelessSurveyDataCollectorCreateForm(props: {
  survey: WirelessSurveyOut;
  refresh?: () => any;
}) {
  const { survey } = props;

  const { update, error } = useCreateWirelessSurveyDataCollector(props.survey);
  function submit(
    value: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(true);
    update({
      ...value,
      company_id: survey.company_id,
    })
      .then(() => {
        resetForm();
        props.refresh?.();
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <Formik
      initialValues={
        {
          data_collector_name: "",
          asset_id: "",
          sample_points: [],
        } as FormValues
      }
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        data_collector_name: Yup.string().required().min(5),
        asset_id: Yup.string().required(),
      })}
      onSubmit={submit}
    >
      {({ touched, isValid, isSubmitting }): React.ReactElement => (
        <>
          <Form>
            <NewTextFormComponent
              fieldName={"data_collector_name"}
              label={{ label: "Name" }}
            />
            <AssetSelectFormField
              assetType={[assetTypeEnum.gateway]}
              name={"asset_id"}
              company_id={survey.company_id}
            />
            <Button
              type="submit"
              color="brandLight"
              disabled={isSubmitting || !isValid || !touched}
            >
              Create
            </Button>
          </Form>
          <ErrorBanner error={error} />
        </>
      )}
    </Formik>
  );
}

export default WirelessSurveyDataCollectorCreateForm;
