import client from 'client'
import type {
  ScheduleJobExecutionJobExecutePostMutationRequest,
  ScheduleJobExecutionJobExecutePostMutationResponse,
  ScheduleJobExecutionJobExecutePostQueryParams,
  ScheduleJobExecutionJobExecutePost422,
} from '../../types/ScheduleJobExecutionJobExecutePost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const scheduleJobExecutionJobExecutePostMutationKey = () => [{ url: '/job/execute' }] as const

export type ScheduleJobExecutionJobExecutePostMutationKey = ReturnType<typeof scheduleJobExecutionJobExecutePostMutationKey>

/**
 * @summary Schedule Job Execution
 * {@link /job/execute}
 */
export async function scheduleJobExecutionJobExecutePost(
  data: ScheduleJobExecutionJobExecutePostMutationRequest,
  params?: ScheduleJobExecutionJobExecutePostQueryParams,
  config: Partial<RequestConfig<ScheduleJobExecutionJobExecutePostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    ScheduleJobExecutionJobExecutePostMutationResponse,
    ResponseErrorConfig<ScheduleJobExecutionJobExecutePost422>,
    ScheduleJobExecutionJobExecutePostMutationRequest
  >({ method: 'POST', url: `/job/execute`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Schedule Job Execution
 * {@link /job/execute}
 */
export function useScheduleJobExecutionJobExecutePost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<ScheduleJobExecutionJobExecutePostMutationResponse>,
      ResponseErrorConfig<ScheduleJobExecutionJobExecutePost422>,
      { data: ScheduleJobExecutionJobExecutePostMutationRequest; params?: ScheduleJobExecutionJobExecutePostQueryParams }
    >
    client?: Partial<RequestConfig<ScheduleJobExecutionJobExecutePostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? scheduleJobExecutionJobExecutePostMutationKey()

  return useMutation<
    ResponseConfig<ScheduleJobExecutionJobExecutePostMutationResponse>,
    ResponseErrorConfig<ScheduleJobExecutionJobExecutePost422>,
    { data: ScheduleJobExecutionJobExecutePostMutationRequest; params?: ScheduleJobExecutionJobExecutePostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return scheduleJobExecutionJobExecutePost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}