import type { Event } from "kubb";
import type React from "react";
import { useState } from "react";

import { isEmpty } from "lodash-es";
import { loadFileFromApi } from "../../actions/helpers/presigned_s3";
import DownloadFileTooltip from "../../components/downloadFileTooltip";

const getArtifactsFileFromUrl = async (event: Event): Promise<void> => {
  const linked_jobs = event.affected_objects.filter((e) => e.type === "job");
  const the_job = linked_jobs.length === 1 ? linked_jobs[0].id : undefined;

  return loadFileFromApi(
    `/job/artifacts?job_id=${the_job}&event_id=${event.event_id}&company_id=${event.company_id}`,
  );
};

const EventJobCompleteArtifactsDownload = (props: {
  event: Event;
  index: string;
}): React.ReactElement => {
  const { event } = { ...props };

  const [loading, setLoading] = useState(false);

  const downloadable =
    (event.event_data as { artifacts_file: string })?.artifacts_file !==
    undefined;

  const handleClick = async (): Promise<undefined> => {
    if (downloadable && !loading) {
      setLoading(true);

      getArtifactsFileFromUrl(event).finally(() => setLoading(false));
    }
    return;
  };

  return (
    <>
      {!isEmpty(event.event_data) &&
        (event.event_type === "JOB_COMPLETED" ||
          event.event_type === "REPORT_COMPLETED") && (
          <DownloadFileTooltip
            loading={loading}
            downloadable={downloadable}
            fileDescription={"Download job results"}
            clickHandler={handleClick}
          />
        )}
    </>
  );
};

export default EventJobCompleteArtifactsDownload;
