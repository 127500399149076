import { faLink } from "@fortawesome/free-solid-svg-icons";
import type { AssetPosition } from "kubb";
import type React from "react";
import UpdateButton, {
  type UpdateButtonProps,
} from "../../../components/Buttons/UpdateButton";
import { useUpdateAssetPosition } from "../../../hooks/updateEntity.tsx";

export default function WirelessClusterGatewayAssignButton(
  props: {
    clusterPosition: AssetPosition;
    gatewayPosition: AssetPosition;
  } & Omit<UpdateButtonProps<AssetPosition>, "update" | "updatedEntity">,
): React.ReactElement {
  const { clusterPosition, gatewayPosition } = props;

  return (
    <UpdateButton
      update={useUpdateAssetPosition(gatewayPosition)}
      updatedEntity={{
        ...gatewayPosition,
        parent_asset_position_id: clusterPosition.asset_position_id,
      }}
      text={"Join"}
      icon={faLink}
      color={"brandLight"}
      /* TODO: small button */
      postUpdate={props.postUpdate}
    />
  );
}
