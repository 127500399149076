import { type CompanyRole, type Status, userTypeEnum } from "kubb";
import type { User } from "kubb";
import type { UserCompanyLink } from "kubb";
import type { UserCompanyProperties } from "kubb";

export interface CompanyUser {
  email_address?: string;
  full_name: string;
  user_id: string;
  company_id: string;
  api_user: boolean;
  user_type: CompanyRole;
  properties?: UserCompanyProperties;
  status: Status;
}

export const convertUserToCompanyUser = (
  user: User,
  company_id?: string,
): CompanyUser => {
  const company: UserCompanyLink = company_id
    ? user.companies.filter((c) => {
        return c.company_id === company_id;
      })[0]
    : user.companies[0];

  return {
    ...user,
    full_name: user.fullname as string,
    api_user: user.user_type === userTypeEnum.api,
    user_type: company.user_type,
    company_id: company.company_id,
    properties: company.properties,
  };
};

export const updateUserCompany = (
  user: User,
  company_id: string,
  updateCompanyLink: UserCompanyLink,
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.company_id === company_id) {
        return updateCompanyLink;
      } else {
        return companyLink;
      }
    }),
  };
};

export const updateUserCompanyProperties = (
  user: User,
  company_id: string,
  updateCompanyProperties: UserCompanyProperties,
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.company_id === company_id) {
        return {
          ...companyLink,
          properties: updateCompanyProperties,
        };
      } else {
        return companyLink;
      }
    }),
  };
};
