import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "components/Theme/collapse";
import {
  type CumulativeDataConfigurationInput,
  billableAssetsEnum,
  billingReportFieldsEnum,
  energyUnitsEnum,
  missingDataHandlingEnum,
  roundingEnum,
  volumeUnitsEnum,
} from "kubb";
import { lowerCase, upperFirst } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import NewCheckboxComponent from "../../../components/Forms/NewCheckboxComponent";
import NewCheckboxField from "../../../components/Forms/NewCheckboxField";
import type { SubFormProps } from "../../../components/Forms/WithSubForm";
import LabelSelect from "../../../components/TimeSeries/AggregatedTimeSeriesDataReport/LabelSelect";
import EnergyUnitSelect from "../Common/EnergyUnitSelect";
import RoundingMethodSelect from "../Common/RoundingSelect";
import VolumeUnitSelect from "../Common/VolumeUnitSelect";

export const BillingConfigurationSubFormSchema = (
  includedFieldsEditable = true,
) => {
  return Yup.object().shape({
    configuration: includedFieldsEditable
      ? Yup.object().shape({
          includedFields: Yup.array().required().min(1),
          includedAssetTypes: Yup.array().required().min(1),
        })
      : Yup.object().shape({
          includedAssetTypes: Yup.array().required().min(1),
        }),
  });
};

export const BillingConfigurationInitialValues = (
  includedFieldsEditable = true,
): { configuration: CumulativeDataConfigurationInput } => {
  return {
    configuration: {
      energy: {
        rounding: roundingEnum.WHOLE,
        units: energyUnitsEnum.kWh,
      },
      volume: {
        rounding: roundingEnum.ONE_DP,
        units: volumeUnitsEnum.m3,
      },
      included_fields: includedFieldsEditable
        ? [
            billingReportFieldsEnum.schemeName,
            billingReportFieldsEnum.locationAddress,
            billingReportFieldsEnum.assetPositionId,
            billingReportFieldsEnum.assetPositionReference,
            billingReportFieldsEnum.serialNumber,
            billingReportFieldsEnum.billableType,
            billingReportFieldsEnum.billableUnit,
          ]
        : [],
      missing_data_handling: missingDataHandlingEnum.NULL_VALUE,
      included_asset_types: [
        billableAssetsEnum.HEAT_METER,
        billableAssetsEnum.GAS_METER,
        billableAssetsEnum.COOLING_METER,
        billableAssetsEnum.HEATING_AND_COOLING_METER,
        billableAssetsEnum.ELECTRICITY_METER,
        billableAssetsEnum.WATER_METER,
      ],
      period_label: "right",
    },
  };
};

interface BillingConfigurationFormProps extends SubFormProps {
  includedFieldsEditable?: boolean;
}

const BillingConfigurationForm = ({
  namespace,
  disabled,
  includedFieldsEditable = true,
}: BillingConfigurationFormProps): React.ReactElement => {
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <h4>
        Advanced Configuration
        <FontAwesomeIcon
          icon={isOpen ? faMinusCircle : faPlusCircle}
          onClick={toggle}
          className={"mx-1"}
        />
      </h4>
      <Collapse isOpen={isOpen}>
        <>
          <RoundingMethodSelect
            fieldName={withNamespace("energy.rounding")}
            label={{ label: "Energy Rounding" }}
            disabled={disabled}
          />
          <EnergyUnitSelect
            label={{ label: "Energy Units" }}
            fieldName={withNamespace("energy.units")}
            disabled={disabled}
          />
          <RoundingMethodSelect
            label={{ label: "Volume Rounding" }}
            fieldName={withNamespace("volume.rounding")}
            disabled={disabled}
          />
          <VolumeUnitSelect
            label={{ label: "Volume Units" }}
            fieldName={withNamespace("volume.units")}
            disabled={disabled}
          />
          {includedFieldsEditable && (
            <NewCheckboxField name={withNamespace("included_fields")}>
              {(fieldProps) => (
                <NewCheckboxComponent
                  label={{ label: "Report Field Filter" }}
                  options={Object.values(billingReportFieldsEnum).map((key) => {
                    return { label: key, value: key };
                  })}
                  disabled={disabled}
                  {...fieldProps}
                />
              )}
            </NewCheckboxField>
          )}
          <NewCheckboxField name={withNamespace("included_asset_types")}>
            {(fieldProps) => (
              <NewCheckboxComponent
                {...fieldProps}
                label={{ label: "Asset Type Filter" }}
                options={Object.values(billableAssetsEnum).map((key) => {
                  return { label: upperFirst(lowerCase(key)), value: key };
                })}
                disabled={disabled}
              />
            )}
          </NewCheckboxField>
          <LabelSelect
            label={{ label: "Period Labelling" }}
            fieldName={withNamespace("period_label")}
            disabled={disabled}
          />
        </>
      </Collapse>
    </>
  );
};

export default BillingConfigurationForm;
