import type React from "react";
import {
  AssetPositionRow,
  type AssetRegisterDataItem,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  LocationRow,
} from "../../../components/Table/AssetRegisterData";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";

import type { Dayjs } from "dayjs";
import { isNull, startCase } from "lodash-es";
import PrivacyModeDataTable, {
  type PrivacyModeTableColumn,
} from "../../../components/Text/PrivacyModeDataTableCell";

import type { BillingData } from "../../../components/Table/model";
import type { SingleAggregationTimeAssetData } from "../../../components/TimeSeries/model";

export interface AssetRegisterReportTableProps {
  data?: AssetRegisterDataItem[];
  columnDefinitions: PrivacyModeTableColumn<AssetRegisterDataItem>[];
  download?: boolean;
  downloadFilename?: string;
  startDatetime: Dayjs;
  endDatetime: Dayjs;
  csvColumnOrder?: Array<string>;
  expanded?: boolean;
  extraActions?: React.ReactNode;
}

export function assetPositionSortValue(
  row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
): string {
  if (
    row.asset_position_reference &&
    !isNull(row.asset_position_reference) &&
    row.asset_position_reference !== ""
  ) {
    return row.asset_position_reference;
  } else {
    return `_${row.asset_position_id}`; // underscore sets these after references
  }
}

export function locationSortValue(
  row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
): string {
  if (row.location_address) {
    return row.location_address.replace(
      /\d+/g,
      (m) => "00000".substr(m.length - 1) + m,
    );
  }
  return `_${row.location_id}`;
}

export default function AssetRegisterReportTable(
  props: AssetRegisterReportTableProps,
): React.ReactElement {
  const {
    columnDefinitions,
    startDatetime,
    endDatetime,
    download = true,
    downloadFilename = "export.csv",
    csvColumnOrder = DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  } = props;
  const showAsset = true;
  const columns: PrivacyModeTableColumn<AssetRegisterDataItem>[] = [
    ...[
      {
        name: "Location",
        selector: locationSortValue,
        cell: LocationRow(startDatetime, endDatetime),
        sortable: true,
        width: "250px",
        compact: true,
        privacyMode: true,
      },
      {
        name: "Asset Position",
        selector: assetPositionSortValue,
        cell: AssetPositionRow(startDatetime, endDatetime),
        sortable: true,
        width: "200px",
        compact: true,
        privacyMode: true,
      },
      {
        name: "Asset Type",
        selector: (row: AssetRegisterDataItem) => row.asset_type,
        cell: (row: AssetRegisterDataItem) => startCase(row.asset_type),
        sortable: true,
        compact: true,
        width: "120px",
      },
    ],
    ...(showAsset
      ? [
          {
            name: "Asset",
            selector: (row: AssetRegisterDataItem) => row.serial_number || "-",
            sortable: true,
            cell: AssetRow(startDatetime, endDatetime, false, true),
            width: "150px",
            compact: true,
            privacyMode: true,
          },
        ]
      : []),
    ...columnDefinitions,
  ];

  return (
    <>
      {props.data && (
        <>
          <PrivacyModeDataTable
            columns={columns}
            data={props.data}
            pagination
            fixedHeader
          />
          {(download || props.extraActions) && (
            <>
              {download && (
                <CSVExportButton
                  data={props.data}
                  filename={downloadFilename}
                  columnOrder={csvColumnOrder}
                />
              )}
              {props.extraActions}
            </>
          )}
        </>
      )}
    </>
  );
}
