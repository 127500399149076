import { dayOfWeekEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function DaySelect(
  props: Omit<SelectComboFieldProps, "option">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Monday", value: dayOfWeekEnum.MONDAY },
    { label: "Tuesday", value: dayOfWeekEnum.TUESDAY },
    { label: "Wednesday", value: dayOfWeekEnum.WEDNESDAY },
    { label: "Thursday", value: dayOfWeekEnum.THURSDAY },
    { label: "Friday", value: dayOfWeekEnum.FRIDAY },
    { label: "Saturday", value: dayOfWeekEnum.SATURDAY },
    { label: "Sunday", value: dayOfWeekEnum.SUNDAY },
  ];

  return <SelectComboField options={options} {...props} />;
}
