import type { ReportExecutionOut } from "kubb";
import React from "react";
import { useGetReportExecution } from "../../../hooks/getEntity";

export interface ReportExecutionChildProps {
  reportExecution: ReportExecutionOut;
  refresh: () => void;
  loading: boolean;
}

export interface OptionalReportExecutionChildProps {
  reportExecution?: ReportExecutionOut;
  refresh: () => void;
  loading: boolean;
}

interface ReportExecutionProps {
  reportExecution: ReportExecutionOut;
  children: (props: ReportExecutionChildProps) => React.ReactElement;
}

interface ReportExecutionIdProps {
  reportExecutionId: string;
  company_id: string;
  children: (props: OptionalReportExecutionChildProps) => React.ReactElement;
}

type Props = ReportExecutionIdProps | ReportExecutionProps;

function isReportExecutionProps(props: Props): props is ReportExecutionProps {
  return (props as ReportExecutionProps).reportExecution !== undefined;
}

function ReportExecutionContainer(props: Props) {
  const isExecution = isReportExecutionProps(props);
  const [enabled, setEnabled] = React.useState(!isExecution);

  const { data, loading, refresh } = useGetReportExecution(
    isReportExecutionProps(props)
      ? props.reportExecution.report_id
      : props.reportExecutionId,
    isReportExecutionProps(props)
      ? props.reportExecution.company_id
      : props.company_id,
    enabled,
  );

  if (isExecution) {
    return props.children({
      reportExecution: data || props.reportExecution, // prioritise data incase of a refreshed item
      refresh: enabled ? refresh : () => setEnabled(true),
      loading,
    });
  } else {
    return props.children({
      reportExecution: data,
      refresh,
      loading,
    });
  }
}

export default ReportExecutionContainer;
