import { ErrorBanner } from "components/Error/ErrorBanner";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { Formik } from "formik";
import {
  type DeviceAssetOut,
  type DeviceAssetUpdateIn,
  type WirelessReceiverConfiguration,
  actionTypesEnum,
} from "kubb";
import CreateActionButton from "pages/assetRegister/assetActions/CreateActionButton";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import SuccessBanner from "../../../../../components/Banner/SuccessBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../../components/Theme/button";
import { useUpdateAsset } from "../../../../../hooks/updateEntity";
import WirelessReceiverConfigurationForm, {
  WirelessReceiverConfigurationFormSchema,
} from "./WirelessReceiverConfigurationForm";

interface Configuration {
  receiver: WirelessReceiverConfiguration;
}

export default function DeviceConfigurationTab(props: {
  asset: DeviceAssetOut;
}): React.ReactElement {
  const configuration: WirelessReceiverConfiguration | undefined = props.asset
    .configuration as WirelessReceiverConfiguration | undefined;

  const [updated, setUpdated] = useState(false);

  const { update, loading, error } = useUpdateAsset(
    props.asset as DeviceAssetUpdateIn,
  );

  const initialValues: Configuration = {
    receiver: {
      mbus_baud_rate: configuration?.mbus_baud_rate || 2400,
      age_limit: configuration?.age_limit || 1440,
    },
  };

  function submit(values: Configuration) {
    setUpdated(false);
    update({
      ...props.asset,
      configuration: values.receiver,
    }).then(() => {
      setUpdated(true);
    });
  }

  return (
    <div>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          receiver: WirelessReceiverConfigurationFormSchema,
        })}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ submitForm, isValid }): React.ReactElement => {
          return (
            <BlockSpinner loading={loading}>
              <WirelessReceiverConfigurationForm namespace={"receiver"} />
              <DisplayIfUserType userTypes={"admin"}>
                <Button
                  type={"submit"}
                  color={"brandLight"}
                  onClick={submitForm}
                  disabled={loading || !isValid}
                >
                  Save
                </Button>
                {updated && (
                  <SuccessBanner>
                    Configuration was updated successfully.
                  </SuccessBanner>
                )}
                <ErrorBanner error={error} />
              </DisplayIfUserType>
            </BlockSpinner>
          );
        }}
      </Formik>
      <DisplayIfUserType userTypes={"admin"}>
        <CreateActionButton
          asset={props.asset}
          actionType={actionTypesEnum.SYNC_DEVICE_LIST}
          actionName={"Set AES keys"}
        />
        <CreateActionButton
          asset={props.asset}
          actionType={actionTypesEnum.SYNC_CONFIG}
          actionName={"Update receiver settings"}
        />
      </DisplayIfUserType>
    </div>
  );
}
