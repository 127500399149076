import client from 'client'
import type {
  GetUserProfileUserProfileGetQueryResponse,
  GetUserProfileUserProfileGetQueryParams,
  GetUserProfileUserProfileGet422,
} from '../../types/GetUserProfileUserProfileGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUserProfileUserProfileGetQueryKey = (params?: GetUserProfileUserProfileGetQueryParams) =>
  [{ url: '/user/profile' }, ...(params ? [params] : [])] as const

export type GetUserProfileUserProfileGetQueryKey = ReturnType<typeof getUserProfileUserProfileGetQueryKey>

/**
 * @description Gets the user profile for the user who's access token has been used.
 * @summary Get User Profile
 * {@link /user/profile}
 */
export async function getUserProfileUserProfileGet(
  params?: GetUserProfileUserProfileGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetUserProfileUserProfileGetQueryResponse, ResponseErrorConfig<GetUserProfileUserProfileGet422>, unknown>({
    method: 'GET',
    url: `/user/profile`,
    params,
    ...requestConfig,
  })
  return res
}

export function getUserProfileUserProfileGetQueryOptions(
  params?: GetUserProfileUserProfileGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getUserProfileUserProfileGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetUserProfileUserProfileGetQueryResponse>,
    ResponseErrorConfig<GetUserProfileUserProfileGet422>,
    ResponseConfig<GetUserProfileUserProfileGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUserProfileUserProfileGet(params, config)
    },
  })
}

/**
 * @description Gets the user profile for the user who's access token has been used.
 * @summary Get User Profile
 * {@link /user/profile}
 */
export function useGetUserProfileUserProfileGet<
  TData = ResponseConfig<GetUserProfileUserProfileGetQueryResponse>,
  TQueryData = ResponseConfig<GetUserProfileUserProfileGetQueryResponse>,
  TQueryKey extends QueryKey = GetUserProfileUserProfileGetQueryKey,
>(
  params?: GetUserProfileUserProfileGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetUserProfileUserProfileGetQueryResponse>,
        ResponseErrorConfig<GetUserProfileUserProfileGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUserProfileUserProfileGetQueryKey(params)

  const query = useQuery({
    ...(getUserProfileUserProfileGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUserProfileUserProfileGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}