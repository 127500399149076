import type React from "react";
import { useCallback, useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import PageSpinner from "components/Spinners/PageSpinner";
import { Form, Formik, type FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
// import "./Login.css";
import * as Yup from "yup";
import { currentUser, signIn, signOut } from "../../actions/helpers/auth";
import { Button } from "../../components/Theme/button";
import { getUserProfileUserProfileGet } from "../../kubb";
import AuthField from "../../layouts/AuthField";
import { AuthContext } from "../../reducers/auth";
import { ProfileContext } from "../../reducers/profile";
import { AppContext } from "../../reducers/reactStore";
import LoginErrorBanner from "./LoginErrorBanner";

interface FormValues {
  email: string;
  password: string;
}

export default function Login(): React.ReactElement {
  const navigate = useNavigate();
  const [silentAuthStatus, setSilentAuthStatus] = useState(true);

  const { state } = useContext(AppContext);

  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);
  const { dispatch } = authContext;

  // biome-ignore lint/correctness/useExhaustiveDependencies(navigate): shouldnt change?
  const handleSuccessfulAuthentication = useCallback(() => {
    const referrer = state.referer.path;
    if (
      referrer &&
      referrer.pathname !== "/" &&
      referrer.pathname !== "/auth/reset-password"
    ) {
      navigate(referrer);
    } else {
      navigate("/admin");
    }
  }, [state.referer.path]);

  const silentAuth = (): Promise<any> => {
    dispatch({ type: "AUTHENTICATING" });
    return currentUser()
      .then(() => {
        return loadProfile()
          .then(() => {
            dispatch({ type: "AUTHENTICATING_SUCCESS" });
          })
          .catch((err) => {
            signOut().finally(() => {
              dispatch({ type: "AUTHENTICATING_ERROR", payload: err }); // no actual error here, but the auth state needs resetting
            });
            throw err;
          });
      })
      .catch((err) => {
        dispatch({ type: "AUTHENTICATING_ERROR", payload: null }); // no actual error here, but the auth state needs resetting
        throw err;
      });
  };

  const loadProfile = async (): Promise<any> => {
    try {
      const d = await getUserProfileUserProfileGet();
      if (d.status === 200 && d.data) {
        Sentry.setUser({ email: d.data.email_address });
        profileContext.dispatch({
          type: "PROFILE_FETCHING_SUCCESS",
          payload: d.data,
        });
      } else {
        profileContext.dispatch({
          type: "PROFILE_FETCHING_ERROR",
          payload: d.statusText,
        });
      }
    } catch (err) {
      profileContext.dispatch({
        type: "PROFILE_FETCHING_ERROR",
        payload: err,
      });
      throw err;
    }
  };
  const authenticate = (email: string, password: string): Promise<any> => {
    dispatch({ type: "AUTHENTICATING" });
    Sentry.setUser({ email: email });
    return new Promise<void>((resolve, reject) => {
      signIn(email, password)
        .then(() => {
          loadProfile().then(() => {
            dispatch({ type: "AUTHENTICATING_SUCCESS" });
            resolve();
          });
        })
        .catch((err: Error) => {
          dispatch({ type: "AUTHENTICATING_ERROR", payload: err });
          reject();
        });
    });
  };

  const submit = (
    { email, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    authenticate(email, password)
      .then(handleSuccessfulAuthentication)
      .catch(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const sa = useCallback(silentAuth, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies(handleSuccessfulAuthentication): shouldnt change
  useEffect(() => {
    sa()
      .then(handleSuccessfulAuthentication)
      .catch(() => {
        setSilentAuthStatus(false);
      });
  }, [sa]);

  return (
    <PageSpinner spin={authContext.state.authenticating}>
      {silentAuthStatus ? (
        <div style={{ minHeight: "400px" }} />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Required."),
              password: Yup.string().required("Required."),
            })}
          >
            {({ isValid, isSubmitting }): React.ReactElement => (
              <Form>
                <AuthField name={"email"} label={"Email"} type={"text"} />
                <AuthField
                  name={"password"}
                  label={"Password"}
                  type={"password"}
                />
                <div className={"flex py-6 justify-end gap-4"}>
                  <div className={"my-auto"}>
                    <Button
                      outline={true}
                      bright={true}
                      paddingX={4}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        navigate("/auth/reset-password");
                      }}
                    >
                      Forgotten password?
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="brandLight"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      paddingX={4}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <LoginErrorBanner authError={authContext.state.authError} />
        </>
      )}
    </PageSpinner>
  );
}
