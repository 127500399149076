import type React from "react";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { useGetStreamingConfiguration } from "../../hooks/getEntity";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import StreamingSettingsForm from "./StreamingSettingsForm";

export default function StreamingSettingsFormPage(): React.ReactElement {
  const company_id = useRequiredSelectedCompanyId();

  const { data, loading, error } = useGetStreamingConfiguration(company_id);

  return (
    <>
      <BlockSpinner loading={loading}>
        {!loading && data && (
          <StreamingSettingsForm streamingConfiguration={data} />
        )}
        {!loading && error && <ErrorBanner error={error} />}
      </BlockSpinner>
    </>
  );
}
