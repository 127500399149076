export const roundingEnum = {
  NONE: 'NONE',
  WHOLE: 'WHOLE',
  ONE_DP: 'ONE_DP',
  TWO_DP: 'TWO_DP',
  THREE_DP: 'THREE_DP',
} as const

export type RoundingEnum = (typeof roundingEnum)[keyof typeof roundingEnum]

export type Rounding = RoundingEnum