import type { Dayjs } from "dayjs";
import type React from "react";
import type { TableRow } from "react-data-table-component";
import type { SingleAggregationTimeAssetData } from "../TimeSeries/model";
import { formatDate } from "../utils/HighlightNullAndOldDates";
import AssetPositionRowLink from "./AssetPositionRowLink";
import AssetRowLink from "./AssetRowLink";
import LocationRowLink from "./LocationRowLink";
import type { BillingData } from "./model";

export type AssetRegisterData = AssetRegisterDataItem[];

export interface AssetRegisterDataItem extends TableRow {
  company_id?: string;
  company_name?: string;
  scheme_name?: string;
  scheme_id?: string;
  asset_id?: string;
  serial_number?: string;
  asset_type: string;
  asset_position_id: string;
  asset_position_reference?: string;
  location_id: string;
  location_address?: string;
  location_customer_reference?: string;

  gateway_asset_id?: string;
  gateway_serial_number?: string;
  gateway_asset_position_id?: string;
  gateway_asset_position_reference?: string;
  gateway_location_id?: string;
  gateway_location_address?: string;
  gateway_last_contact_time?: Dayjs;

  install_date?: Dayjs;
  uninstall_date?: Dayjs;

  [dataIdentifier: string]: number | string | undefined | Date | Dayjs;
}

type AssetRowFunction = (
  row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
) => React.ReactElement;

export const AssetRow = (
  startDatetime?: Dayjs,
  endDatetime?: Dayjs,
  gateway?: boolean,
  showDates?: boolean,
): AssetRowFunction => {
  return (
    row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
  ): React.ReactElement => {
    return (
      <>
        {row.asset_id ||
        (gateway && (row as AssetRegisterDataItem).gateway_asset_id) ? (
          <div>
            <AssetRowLink
              asset_id={
                gateway
                  ? ((row as AssetRegisterDataItem).gateway_asset_id as string)
                  : (row.asset_id as string)
              }
              startDatetime={startDatetime}
              endDatetime={endDatetime}
            >
              {gateway
                ? ((row as AssetRegisterDataItem)
                    .gateway_serial_number as string)
                : (row.serial_number as string)}
            </AssetRowLink>
            <br />
            {showDates &&
            !gateway &&
            (row.install_date || row.uninstall_date) ? (
              <>
                <small>
                  {row.install_date
                    ? formatDate(row.install_date as Dayjs, "L")
                    : "Forever"}
                  {" - "}
                  {row.uninstall_date
                    ? formatDate(row.uninstall_date as Dayjs, "L")
                    : "Forever"}
                </small>
                <br />
              </>
            ) : null}
            <small>
              (...
              {(gateway
                ? ((row as AssetRegisterDataItem).gateway_asset_id as string)
                : row.asset_id
              )?.slice(-8)}
              )
            </small>
          </div>
        ) : (
          <div>
            No asset
            <br />
            {showDates && (row.install_date || row.uninstall_date) ? (
              <>
                <small>
                  {row.install_date
                    ? formatDate(row.install_date as Dayjs, "L")
                    : "Forever"}
                  {" - "}
                  {row.uninstall_date
                    ? formatDate(row.uninstall_date as Dayjs, "L")
                    : "Forever"}
                </small>
                <br />
              </>
            ) : null}
          </div>
        )}
      </>
    );
  };
};

export const AssetPositionRow = (
  startDatetime?: Dayjs,
  endDatetime?: Dayjs,
): AssetRowFunction => {
  const InnerAssetPositionRowFunction = (
    row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
  ): React.ReactElement => {
    return (
      <div>
        <AssetPositionRowLink
          asset_position_id={row.asset_position_id}
          startDatetime={startDatetime}
          endDatetime={endDatetime}
        >
          {row.asset_position_reference
            ? row.asset_position_reference
            : `...${row.asset_position_id?.slice(-8)}`}
        </AssetPositionRowLink>
        {row.asset_position_reference && (
          <>
            <br />
            <small>(...{row.asset_position_id?.slice(-8)})</small>
          </>
        )}
      </div>
    );
  };
  return InnerAssetPositionRowFunction;
};

export const LocationRow = (
  startDatetime?: Dayjs,
  endDatetime?: Dayjs,
): AssetRowFunction => {
  const InnerLocationRowFunction = (
    row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData,
  ): React.ReactElement => {
    return (
      <div>
        <LocationRowLink
          location_id={row.location_id}
          startDatetime={startDatetime}
          endDatetime={endDatetime}
        >
          {row.location_address}
        </LocationRowLink>
        <br />
        {row.location_customer_reference?.toString()}
        &nbsp;
        <small>(...{row.location_id?.slice(-8)})</small>
      </div>
    );
  };
  return InnerLocationRowFunction;
};

export const DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY =
  [
    "companyId",
    "companyName",
    "schemeId",
    "schemeName",
    "locationId",
    "locationAddress",
    "locationCustomerReference",
    "group",
    "assetPositionId",
    "assetPositionReference",
    "assetType",
    "useCase",
  ];

export const DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER = [
  ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY,
  "assetId",
  "serialNumber",

  "installDate",
  "uninstallDate",
];

export default AssetRegisterData;
