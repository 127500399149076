import type { Location } from "kubb";
import { Heading } from "../../../components/Theme/heading";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AccountList from "../../../containers/account/AccountList";
import NewAccountForm from "../../../containers/account/NewAccountForm";
import { useCreateNewAccount } from "../../../hooks/createEntity";

export interface LocationAccountTabProps {
  location: Location;
}

export default function LocationAccountTab(props: LocationAccountTabProps) {
  return (
    <>
      <Heading>Accounts</Heading>
      <AccountList
        location_id={props.location.location_id}
        company_id={props.location.company_id}
      />
      <DisplayIfUserType userTypes={"superadmin"}>
        <Heading>Create New Account</Heading>

        <NewAccountForm
          location={props.location}
          create={useCreateNewAccount()}
        />
      </DisplayIfUserType>
    </>
  );
}
