import PrivacyMode from "components/Text/PrivacyMode";
import {
  type AssetPosition,
  type GetAssetPositionListAssetPositionGetQueryParams,
  getAssetPositionListAssetPositionGet,
  objectTypesEnum,
} from "kubb";
import { useGetAssetPositionListAssetPositionGet } from "kubb";
import type React from "react";
import AssetIdLink from "../../../components/Asset/AssetIdLink";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import BulkUpdate from "../../../components/BulkUpdate/BulkUpdate";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import LocationIdLink from "../../../components/Location/LocationIdLink";
import NewGetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import {
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import { useRequiredSelectedCompanyId } from "../../../reducers/company.tsx";
import { useSelectedSchemeId } from "../../../reducers/scheme.tsx";

interface PProps {
  params: GetAssetPositionListAssetPositionGetQueryParams;
  show_location_id?: boolean;
  lastUpdated?: string;
  showDownload?: boolean;
  showUploads?: boolean;
}

const AssetPositionList = (props: PProps): React.ReactElement => {
  const companyId = useRequiredSelectedCompanyId();
  const schemeId = useSelectedSchemeId();

  const showLocationId =
    props.show_location_id !== undefined ? props.show_location_id : true;
  const showAssetPositionId =
    props.params.parent_asset_position_id === undefined;

  const header = (
    <TableRow>
      {showLocationId && (
        <TableHeader className="text-left">Location ID</TableHeader>
      )}
      <TableHeader className="text-left">Position ID</TableHeader>
      <TableHeader className={"text-left"}>Reference</TableHeader>
      <TableHeader className="text-left">Asset ID</TableHeader>
      <TableHeader className="text-left">Type</TableHeader>
      {showAssetPositionId && (
        <TableHeader className="text-left">
          Parent Asset Position Id
        </TableHeader>
      )}
      <TableHeader className="text-left">&nbsp;</TableHeader>
    </TableRow>
  );

  const colCount = 5 + (showLocationId ? 1 : 0) + (showAssetPositionId ? 1 : 0);

  const row = (value: AssetPosition): React.ReactElement => (
    <TableRow data-cy={"list-item"} key={value.asset_position_id}>
      {showLocationId && (
        <TableCell className="text-left" data-cy={"location_id"}>
          <LocationIdLink location_id={value.location_id} />
        </TableCell>
      )}
      <TableCell className="text-left" data-cy={"asset_position_id"}>
        <AssetPositionIdLink assetPosition={value} />
      </TableCell>
      <TableCell className={"text-left"}>
        <PrivacyMode>{value.customer_reference}</PrivacyMode>
      </TableCell>
      <TableCell className="text-left">
        {value.asset_id && <AssetIdLink asset_id={value.asset_id} />}
      </TableCell>
      <TableCell className="text-left">
        <AssetTypeHumanLabel asset={value} />
      </TableCell>
      {showAssetPositionId && (
        <TableCell className="text-left">
          {value.parent_asset_position_id && (
            <AssetPositionIdLink
              asset_position_id={value.parent_asset_position_id}
            />
          )}
        </TableCell>
      )}
      <TableCell className={"text-left"}>
        <AssetRegisterStatusLabel status={value.status} />
      </TableCell>
    </TableRow>
  );

  const params = {
    ...props.params,
    company_id: companyId,
    scheme_id: schemeId,
  };
  return (
    <NewGetListPaginatedTable
      params={params}
      cursor={{ max_items: 25 }}
      data-cy={"asset-position-list"}
      header={[header, colCount]}
      row={row}
      useQuery={useGetAssetPositionListAssetPositionGet}
      additionalFunctionButtons={
        <div className={"flex gap-2"}>
          <PaginatedApiToCSVExportButton
            params={params}
            useQuery={getAssetPositionListAssetPositionGet}
          />
          {schemeId &&
            (props.showUploads === undefined || props.showUploads) && (
              <>
                <BulkUpdate
                  company_id={companyId}
                  scheme_id={schemeId}
                  objectType={objectTypesEnum.AssetPosition}
                />
                <CompanyNavLink
                  to={{ pathname: "/admin/asset_position/updates" }}
                  button={{
                    color: "brandLight",
                  }}
                >
                  Previous Uploads
                </CompanyNavLink>
              </>
            )}
        </div>
      }
    />
  );
};

export default AssetPositionList;
