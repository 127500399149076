import { spreadsheetFileTypeEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function OutputFileTypeSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "CSV", value: spreadsheetFileTypeEnum.CSV },
    { label: "XLSX", value: spreadsheetFileTypeEnum.XLSX },
  ];

  return <SelectComboField options={options} {...props} />;
}
