import { Badge, type colors } from "components/Theme/badge";
import type {
  ConfigurationStateEnum,
  GatewayConfigurationStateEnum,
} from "kubb";
import { configurationStateEnum, gatewayConfigurationStateEnum } from "kubb";
import { startCase } from "lodash-es";
import type React from "react";

const getColor = (
  state?: ConfigurationStateEnum | GatewayConfigurationStateEnum,
): keyof typeof colors => {
  if (state) {
    switch (state) {
      case configurationStateEnum.IN_SYNC:
        return "success";
      case configurationStateEnum.OUT_OF_SYNC:
        return "danger";
      case configurationStateEnum.ERROR_UPDATING_CONFIGURATION:
        return "danger";
      case gatewayConfigurationStateEnum.ERROR_UPDATING_DEVICE_LIST:
        return "danger";
      case gatewayConfigurationStateEnum.ERROR_UPGRADING_FIRMWARE:
        return "danger";
      default:
        return "warning";
    }
  }
  return "teal";
};

const getShortText = (
  state?: ConfigurationStateEnum | GatewayConfigurationStateEnum,
): string => {
  if (state) {
    switch (state) {
      case configurationStateEnum.IN_SYNC:
        return "In Sync";
      case configurationStateEnum.OUT_OF_SYNC:
        return "Out of Sync";
      case configurationStateEnum.UNKNOWN:
        return "Unknown";
      case configurationStateEnum.UPDATING_CONFIGURATION:
        return "Updating";
      case configurationStateEnum.UPDATING_DEVICE_LIST:
        return "Updating";
      case gatewayConfigurationStateEnum.MBUS_SEARCH:
        return "MBus Search";
      case "UPGRADING_FIRMWARE":
        return "Upgrading";
      default:
        return "Error";
    }
  }
  return "Unknown";
};

export default function GatewayStateLabel(
  props: {
    short?: boolean;
    state?: ConfigurationStateEnum | GatewayConfigurationStateEnum;
    size?: "sm" | "md" | "lg";
  } & Omit<React.HTMLAttributes<HTMLSpanElement>, "color">,
): React.ReactElement {
  const { size = "lg", state, short = false, ...rest } = props;

  const text = short
    ? getShortText(state)
    : state
      ? startCase(state)
      : "Unknown";

  return (
    <span className={`text-${size}`}>
      <Badge color={getColor(state)} {...rest}>
        {text}
      </Badge>
    </span>
  );
}
