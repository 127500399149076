import {
  type AssetPosition,
  type AssetTypeEnum,
  type Location,
  assetTypeEnum,
  statusEnum,
} from "kubb";
import type React from "react";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetUseCaseHumanLabel from "../../../components/Asset/AssetUseCaseHumanLabel";
import HeadingEditorForm from "../../../components/Forms/HeadingEditorForm";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../../components/Layout/ObjectDetailHeading";
import CopyToClipboard from "../../../components/utils/CopyToClipboard";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import { useUpdateAssetPosition } from "../../../hooks/updateEntity";
import { assetPositionSchema } from "../../../model/assetPosition";
import type { Asset } from "../../../utils/object.tsx";
import { AssetPositionTypeChangeForm } from "../assets/AssetTypeChangeForm";
import GatewayLink from "../assets/gateway/GatewayLink";

interface Props {
  assetPosition: AssetPosition;
  asset?: Asset;
  location: Location;
  idLink?: boolean;
  refresh?: () => any;
}

export default function AssetPositionPreview(props: Props): React.ReactElement {
  const { assetPosition, asset, location } = props;

  const assigned =
    assetPosition.asset_id !== undefined && assetPosition.asset_id !== null;
  const deleted = assetPosition.status === statusEnum.DELETED;
  const isGateway = assetPosition.asset_position_type === "gateway";
  const isWirelessCluster =
    assetPosition.asset_position_type === "wireless_cluster";
  const hasUseCase =
    assetPosition.asset_position_type &&
    (
      [
        assetTypeEnum.cooling_meter,
        assetTypeEnum.heat_meter,
        assetTypeEnum.heating_and_cooling_meter,
      ] as AssetTypeEnum[]
    ).indexOf(assetPosition.asset_position_type) >= 0;

  const update = useUpdateAssetPosition(assetPosition);

  const [openModal, modal] = AssetPositionTypeChangeForm({
    values: assetPosition,
    title: "Asset Position ",
    update: update,
    postUpdate: props.refresh,
  });

  return (
    <>
      {modal}
      <ObjectDetailHeading
        leftColumn={{
          content: (
            <>
              <ObjectPropertyItem
                title={"Location Address"}
                type={"heading"}
                to={{ pathname: `/admin/location/${location?.location_id}` }}
              >
                {location?.address}
              </ObjectPropertyItem>

              {!isWirelessCluster && (
                <>
                  <HeadingEditorForm
                    update={update}
                    label={"Position Reference"}
                    values={assetPosition}
                    fieldName={"customer_reference"}
                    title={"Reference"}
                    defaultValue={"No Position Reference"}
                    validator={assetPositionSchema.customer_reference}
                    editActionUserType={"editor"}
                    postUpdate={props.refresh}
                  />
                  <ObjectPropertyItem
                    title={"Current Asset Serial Number"}
                    type={"heading"}
                  >
                    {assigned ? (
                      asset && (
                        <CopyToClipboard
                          value={asset.serial_number}
                          link={`/admin/asset/${asset.asset_id}`}
                          className={"d-inline px-0"}
                          privacyMode={true}
                        />
                      )
                    ) : (
                      <span className={"text-warning"}>No asset assigned</span>
                    )}
                  </ObjectPropertyItem>
                </>
              )}
            </>
          ),
        }}
        rightColumn={{
          content: (
            <>
              {deleted && (
                <ObjectPropertyItem title={"Status"}>
                  <AssetRegisterStatusLabel status={assetPosition.status} />
                </ObjectPropertyItem>
              )}

              {!isWirelessCluster && (
                <ObjectPropertyItem
                  title={"Type"}
                  editActionUserType={"superadmin"}
                  editAction={openModal}
                >
                  {assetPosition && (
                    <span>
                      <AssetTypeHumanLabel asset={assetPosition} />
                    </span>
                  )}
                </ObjectPropertyItem>
              )}
              {hasUseCase && (
                <ObjectPropertyItem
                  title={"Use Case"}
                  editActionUserType={"admin"}
                  editAction={openModal}
                >
                  <AssetUseCaseHumanLabel
                    asset={assetPosition}
                    asBadge={false}
                  />
                </ObjectPropertyItem>
              )}

              {!isGateway && !isWirelessCluster && (
                <GatewayLink deviceAssetPosition={assetPosition} />
              )}

              <ObjectPropertyItem title={"Asset Position ID"}>
                <CopyToClipboard
                  value={assetPosition?.asset_position_id}
                  slice={8}
                />
              </ObjectPropertyItem>

              <ObjectPropertyItem title={"Created"}>
                {formatDate(assetPosition?.created_at, undefined, false)}
              </ObjectPropertyItem>
              <ObjectPropertyItem title={"Updated"}>
                {formatDate(assetPosition?.last_updated, undefined, false)}
              </ObjectPropertyItem>
            </>
          ),
        }}
      />
    </>
  );
}
