import type { ResponseConfig } from "@kubb/plugin-client/clients/axios.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash-es";
import type React from "react";

export interface NewGetEntityResultProps<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  refresh: () => void;
}

export default function NewGetEntity<TItem, TQuery, TData = TItem[]>(props: {
  useQuery: (
    variables: TQuery,
  ) => UseQueryResult<ResponseConfig<TData> | undefined, any>;
  params: TQuery;
  children: (props: NewGetEntityResultProps<TItem>) => React.ReactElement;
  error: (props: { error: Error }) => React.ReactElement;
  placeholder: () => React.ReactElement;
}): React.ReactNode {
  const { children, ...rest } = props;

  const { data, error, isPending, refetch } = props.useQuery(props.params);

  const apiError = error !== null && error !== undefined;

  const notFoundError = data && data.data === undefined;

  const actualError = apiError
    ? isArray(error)
      ? error[0]
      : error
    : notFoundError && Error("Not found.");

  return (
    <>
      {isPending
        ? props.placeholder()
        : actualError
          ? props.error({ error: actualError })
          : !notFoundError &&
            children({
              data:
                data &&
                (data.data as TItem[]) &&
                (data.data as TItem[]).length === 1
                  ? ((data.data as TItem[])[0] as TItem)
                  : undefined,
              error: error !== null ? error : undefined,
              loading: isPending,
              refresh: refetch,
            })}
    </>
  );
}
