import type {
  ActionTypes,
  AssetPositionActionPayload,
  AssetPositionActionTypesEnum,
  CompanyActionPayload,
  SwitchRelayPayload,
} from "kubb";
import type { AssetAction } from "kubb";
import type { AssetPosition } from "kubb";
import type { AssetPositionAction } from "kubb";
import type React from "react";
import { useState } from "react";
import type { RequireExactlyOne } from "type-fest";
import type { Asset } from "utils/object";
import { Button } from "../../../components/Theme/button";
import AssetActionConfirmModal from "./AssetActionConfirmModal";

interface Props {
  asset?: Asset;
  assetPosition?: AssetPosition;
  actionType: ActionTypes | AssetPositionActionTypesEnum;
  actionName: string;
  actionPayload?:
    | CompanyActionPayload
    | SwitchRelayPayload
    | AssetPositionActionPayload;
  onComplete?: () => any;
}

type PProps = RequireExactlyOne<Props, "asset" | "assetPosition">;

export default function CreateActionButton(props: PProps): React.ReactElement {
  const [open, setOpen] = useState(false);

  const openModal = (): void => {
    setOpen(true);
  };

  const closeModal = (): void => {
    setOpen(false);
  };
  const action: AssetAction | AssetPositionAction = props.asset
    ? ({
        action_type: props.actionType as ActionTypes,
        payload: props.actionPayload as
          | CompanyActionPayload
          | SwitchRelayPayload,
      } as AssetAction)
    : ({
        action_type: props.actionType as AssetPositionActionTypesEnum,
        payload: props.actionPayload as AssetPositionActionPayload,
      } as AssetPositionAction);

  return (
    <>
      <Button color={"brandLight"} onClick={openModal}>
        {props.actionName}
      </Button>
      {props.asset && (
        <AssetActionConfirmModal
          show={open}
          asset={props.asset}
          action={action as AssetAction}
          close={closeModal}
          description={props.actionName}
          onComplete={props.onComplete}
        />
      )}
      {props.assetPosition && (
        <AssetActionConfirmModal
          show={open}
          assetPosition={props.assetPosition}
          assetPositionAction={action as AssetPositionAction}
          close={closeModal}
          description={props.actionName}
          onComplete={props.onComplete}
        />
      )}
    </>
  );
}
