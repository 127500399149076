import type { LocationGroup } from "kubb";
import React from "react";
import { SchemeContext } from "../../../reducers/scheme";
import {
  SelectComboField,
  type SelectComboFieldProps,
} from "../../Forms/SelectCombo/SelectCombo";
import { locationGroupListToOptions } from "../model";

type LocationGroupSelectComboProps = Omit<SelectComboFieldProps, "options">;

function LocationGroupSelectCombo(props: LocationGroupSelectComboProps) {
  const { disabled, ...rest } = props;
  const locationGroups = React.useContext(SchemeContext).state.groups;
  const ready = !locationGroups.loading && !locationGroups.error;
  const haveGroups = locationGroups.groups && locationGroups.groups.length > 0;
  const altText: string = ready
    ? haveGroups
      ? "All"
      : "No Groups"
    : locationGroups.loading
      ? "Loading Groups"
      : locationGroups.error?.message || "Error Loading Groups";

  return (
    <SelectComboField
      {...rest}
      options={
        haveGroups
          ? [
              { label: "All", value: "undefined" },
              ...locationGroupListToOptions(
                locationGroups.groups as LocationGroup[],
                0,
              ),
            ]
          : []
      }
      placeholder={altText}
      disabled={!haveGroups || disabled}
    />
  );
}

export default LocationGroupSelectCombo;
