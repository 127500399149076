import type { WirelessReceiverLink } from "kubb";
import AssetRegisterListItem, {
  AssetRegisterListHeading,
} from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import List from "../../../../../components/List/List";
import { formatDate } from "../../../../../components/utils/HighlightNullAndOldDates";

interface Props {
  history: Array<WirelessReceiverLink>;
}

function ReceiverHistoryListItem(
  item: WirelessReceiverLink,
  _index: number,
  _refresh?: () => void,
): React.ReactElement {
  const { location, asset_position, asset, created_on, deleted_on } = item;

  return AssetRegisterListItem({
    assetRegisterItem: {
      location,
      asset_position,
      asset,
      install_date: created_on,
      uninstall_date: deleted_on,
    },
    showType: false,
    showScheme: false,
    denseColumn: () => (
      <>
        {formatDate(created_on)} -{" "}
        {deleted_on ? formatDate(deleted_on) : "present"}
      </>
    ),
  });
}
function ReceiverHistoryTable(props: Props) {
  return (
    <List
      row={ReceiverHistoryListItem}
      header={<AssetRegisterListHeading />}
      data={props.history}
    />
  );
}

export default ReceiverHistoryTable;
