import dayjs from "dayjs";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import { isNil } from "lodash-es";
import { Button } from "../../../components/Theme/button";
import { useUpdateWirelessSurveyDataCollector } from "../../../hooks/updateEntity";
import WirelessSurveySamplePointAddModal from "./WirelessSurveySamplePointAddModal";

export default function WirelessSurveySamplePointTableRows(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  refresh?: () => any;
}) {
  const { dataCollector } = props;

  const samplePoints = dataCollector.sample_points.sort((a, b) =>
    a.start_date_time > b.start_date_time ? 1 : -1,
  );

  const add =
    samplePoints?.length === 0 ||
    isNil(samplePoints[samplePoints.length - 1].end_date_time);
  const hasData = samplePoints?.length > 0;

  const update = useUpdateWirelessSurveyDataCollector(dataCollector);

  return (
    <tbody key={dataCollector.data_collector_id}>
      {samplePoints?.map((sp, i) => (
        <tr key={sp.sample_point_name}>
          {i === 0 && (
            <td rowSpan={samplePoints.length + (add ? 1 : 1)}>
              {dataCollector.data_collector_name} ({dataCollector.serial_number}
              )
            </td>
          )}
          <td>{sp.sample_point_name}</td>
          <td>{dayjs(sp.start_date_time).format("DD-MM-YYYY HH:mm")}</td>
          <td>
            {sp.end_date_time
              ? dayjs(sp.end_date_time).format("DD-MM-YYYY HH:mm")
              : "-"}
          </td>
          <td className={"p-1"}>
            <div className={"flex gap-1"}>
              <WirelessSurveySamplePointAddModal
                disabled={update.loading}
                dataCollector={dataCollector}
                refresh={props.refresh}
                update={update}
                edit={i}
              />

              <Button
                color={"red"}
                size={"sm"}
                disabled={update.loading}
                onClick={() => {
                  if (!update.loading) {
                    update
                      .update({
                        ...dataCollector,
                        sample_points: samplePoints.filter((_v, j) => j !== i),
                      })
                      .then(() => {
                        props.refresh?.();
                      });
                  }
                }}
              >
                Delete
              </Button>
            </div>
          </td>
        </tr>
      ))}
      <tr>
        {!hasData && (
          <td rowSpan={1}>
            {dataCollector.data_collector_name} ({dataCollector.serial_number})
          </td>
        )}
        <td> </td>
        <td> </td>
        <td> </td>
        <td className={"p-1"}>
          <WirelessSurveySamplePointAddModal
            disabled={update.loading}
            dataCollector={dataCollector}
            refresh={props.refresh}
            update={update}
          />
        </td>
      </tr>
    </tbody>
  );
}
