export const billingReportFieldsEnum = {
  schemeId: 'schemeId',
  schemeName: 'schemeName',
  locationId: 'locationId',
  locationAddress: 'locationAddress',
  assetPositionId: 'assetPositionId',
  assetPositionReference: 'assetPositionReference',
  assetId: 'assetId',
  assetType: 'assetType',
  serialNumber: 'serialNumber',
  billableType: 'billableType',
  billableUnit: 'billableUnit',
} as const

export type BillingReportFieldsEnum = (typeof billingReportFieldsEnum)[keyof typeof billingReportFieldsEnum]

export type BillingReportFields = BillingReportFieldsEnum