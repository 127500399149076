import { useNavigate } from "react-router-dom";
import { Heading } from "../../../components/Theme/heading";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { useCreateWirelessSurvey } from "../../../hooks/createEntity";
import { companyObjectPageTitle } from "../../../hooks/setPageTitle";
import WirelessSurveyCreateForm from "./WirelessSurveyCreateForm";

function WirelessSurveyCreatePage() {
  const createEntity = useCreateWirelessSurvey();

  const navigate = useNavigate();

  return (
    <OneCardPage
      headerTitle={"Create Wireless Survey"}
      breadcrumbs={[
        {
          url: "/admin/wireless_survey",
          name: "Wireless Surveys",
        },
        {
          url: "/admin/wireless_survey/create",
          name: "Create",
        },
      ]}
      setPageTitle={companyObjectPageTitle("Create Wireless Survey")}
    >
      <Heading>Create New Wireless Survey</Heading>
      <WirelessSurveyCreateForm
        createWirelessSurvey={createEntity}
        onCreate={(surveyId) =>
          navigate({
            pathname: `/admin/asset_register/wireless_survey/view/${surveyId}`,
          })
        }
      />
    </OneCardPage>
  );
}

export default WirelessSurveyCreatePage;
