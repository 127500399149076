import { type AssetType, assetTypeEnum } from "kubb";
import {
  yupRequiredString,
  yupString,
} from "../components/Forms/yupValidators";
import { nameRegEx } from "./utils/schema";

export const assetPositionSchema = {
  asset_position_type: yupRequiredString.notOneOf(["undefined", ""]),
  customer_reference: yupString.notRequired().matches(...nameRegEx),
  parent_asset_position_id: yupString,
};

export const BillableAssets: AssetType[] = [
  assetTypeEnum.heat_meter,
  assetTypeEnum.cooling_meter,
  assetTypeEnum.heating_and_cooling_meter,
  assetTypeEnum.electricity_meter,
  assetTypeEnum.gas_meter,
  assetTypeEnum.water_meter,
];

export const NonBillableAssetTypes: AssetType[] = [
  assetTypeEnum.input_output_module,
  assetTypeEnum.room_sensor,
  assetTypeEnum.co2_room_sensor,
];

export const InfrastructureAssetTypes: AssetType[] = [
  assetTypeEnum.wireless_cluster,
  assetTypeEnum.wireless_receiver,
  assetTypeEnum.gateway,
  assetTypeEnum.sim_card,
];
