import type { AssetPosition } from "kubb";
import type { Location } from "kubb";
import { startCase } from "lodash-es";
import type { Asset } from "utils/object";

export interface PageDefinition {
  name: string;
  url?: string;
  privacyMode?: boolean;
}

export const assetToBreadcrumb = (asset: Asset): PageDefinition => {
  return {
    name: asset.serial_number,
    url: `/admin/asset/${asset.asset_id}`,
    privacyMode: true,
  };
};

export const assetPositionToBreadcrumb = (
  assetPosition: AssetPosition,
): PageDefinition => {
  const id = assetPosition.customer_reference
    ? assetPosition.customer_reference
    : `...${assetPosition.asset_position_id.slice(26)}`;

  return {
    name: `${id} (${startCase(assetPosition.asset_position_type)})`,
    url: `/admin/asset_position/${assetPosition.asset_position_id}`,
    privacyMode: true,
  };
};

export const locationToBreadcrumb = (location: Location): PageDefinition => {
  return {
    name: location.address,
    url: `/admin/location/${location.location_id}`,
    privacyMode: true,
  };
};
