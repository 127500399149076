import {
  SelectComboField,
  type SelectComboFieldProps,
} from "components/Forms/SelectCombo/SelectCombo";
import { type AssetTypeList, getFields } from "../model";

interface AssetParameterSelectComboProps
  extends Omit<SelectComboFieldProps, "options"> {
  assetType?: AssetTypeList;
}

function AssetParameterSelectCombo(props: AssetParameterSelectComboProps) {
  const { assetType, ...rest } = props;

  const fields = assetType ? getFields(assetType) : [];

  return (
    <SelectComboField
      {...rest}
      options={fields.map((v) => {
        return { label: v, value: v };
      })}
    />
  );
}

export default AssetParameterSelectCombo;
