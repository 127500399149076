import type React from "react";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import SimpleLineChart from "../../../../../components/charts/SimpleLineChart";
import type { DeviceTimeSeriesDisplayComponentProps } from "../model";
import type { AssetRegisterDataRf } from "./AssetTimeSeriesRFGetter";

export default function AssetRFChart(
  props: DeviceTimeSeriesDisplayComponentProps<
    string | undefined,
    AssetRegisterDataRf
  >,
): React.ReactElement {
  return (
    <BlockSpinner loading={props.loading}>
      <SimpleLineChart
        multipleData={Object.values(props.mappedTimeSeriesColumns).map((v) => [
          v.asset_position_id,
          v.data.map((d, i) => [props.times[i], d as unknown as number]),
          v.asset_id,
          undefined,
        ])}
        unit={"dB"}
        params={props.params}
        loading={false}
      />
    </BlockSpinner>
  );
}
