import dayjs, { type Dayjs } from "dayjs";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "kubb";
import type { AssetRegisterItemAggregatedTimeSeries } from "kubb";
import type React from "react";
import Card from "../../../components/Theme/card";
import { Col } from "../../../components/Theme/grid";
import DataQualityTimeSeriesAreaChart, {
  type DataQualityTimeSeriesAreaChartProps,
} from "../../../components/charts/DataQualityTimeSeriesAreaChart";
import SimpleLineChart from "../../../components/charts/SimpleLineChart";

interface SummaryDataQualityTimeSeriesAreaChartProps
  extends Omit<
    DataQualityTimeSeriesAreaChartProps,
    | "missing"
    | "errors"
    | "incomplete"
    | "expected"
    | "complete"
    | "times"
    | "loading"
    | "valueErrors"
    | "error"
  > {
  data?: AssetRegisterAggregatedTimeSeriesResponse;
}

interface DataQualitySummary {
  expectedRows: number;
  receivedRows: number;
  missingRows: number;
  incompleteRows: number;
  valueErrorRows: number;
  errorRows: number;
  assets: number;
  aboveThreshold050Assets: number;
  aboveThreshold075Assets: number;
  aboveThreshold095Assets: number;
  aboveThreshold100Assets: number;
  date?: Dayjs;
}

const sumDataQualityForDate =
  (dateIndex: number) =>
  (
    accumulator: DataQualitySummary,
    current: AssetRegisterItemAggregatedTimeSeries,
  ): DataQualitySummary => {
    const { aggregations } = current;
    const qualityAssessment = aggregations["Quality Assessment"];
    const expected = qualityAssessment.expected_rows[dateIndex] || 0;
    const good = qualityAssessment.received_rows_complete[dateIndex] || 0;

    const achieved = good / expected;

    return {
      ...accumulator,
      expectedRows: accumulator.expectedRows + expected,
      receivedRows: accumulator.receivedRows + good,
      incompleteRows:
        accumulator.incompleteRows +
        (qualityAssessment.received_rows_incomplete[dateIndex] || 0),
      errorRows:
        accumulator.errorRows +
        (qualityAssessment.received_rows_with_errors[dateIndex] || 0),
      valueErrorRows:
        accumulator.valueErrorRows +
        (qualityAssessment.received_rows_with_value_errors[dateIndex] || 0),
      aboveThreshold050Assets:
        accumulator.aboveThreshold050Assets + (achieved >= 0.5 ? 1 : 0),
      aboveThreshold075Assets:
        accumulator.aboveThreshold075Assets + (achieved >= 0.75 ? 1 : 0),
      aboveThreshold095Assets:
        accumulator.aboveThreshold095Assets + (achieved >= 0.95 ? 1 : 0),
      aboveThreshold100Assets:
        accumulator.aboveThreshold100Assets + (achieved >= 1 ? 1 : 0),
    };
  };

const summariseData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
): Array<Required<DataQualitySummary>> => {
  const aggregations = data.data.filter(
    (item) => item.asset_type !== "gateway" && item.asset_id !== undefined,
  );

  return data.times.map((time, timeIndex) => {
    const d = {
      ...aggregations.reduce(sumDataQualityForDate(timeIndex), {
        expectedRows: 0,
        receivedRows: 0,
        missingRows: 0,
        incompleteRows: 0,
        valueErrorRows: 0,
        errorRows: 0,
        assets: 0,
        aboveThreshold050Assets: 0,
        aboveThreshold075Assets: 0,
        aboveThreshold095Assets: 0,
        aboveThreshold100Assets: 0,
      }),
      date: dayjs(time),
    };
    d.missingRows =
      d.expectedRows -
      d.incompleteRows -
      d.errorRows -
      d.receivedRows -
      d.valueErrorRows;
    d.assets = aggregations.length;
    return d;
  });
};

export const SummaryDataQualityTimeSeriesAreaChart = (
  props: SummaryDataQualityTimeSeriesAreaChartProps,
): React.ReactElement => {
  const { data, ...rest } = props;
  const summary = data
    ? summariseData(data)
    : [
        {
          expectedRows: 0,
          receivedRows: 0,
          missingRows: 0,
          incompleteRows: 0,
          valueErrorRows: 0,
          errorRows: 0,
          assets: 0,
          aboveThreshold050Assets: 0,
          aboveThreshold075Assets: 0,
          aboveThreshold095Assets: 0,
          aboveThreshold100Assets: 0,
          date: new Date(),
        },
      ];

  return (
    <>
      <Col sm={12} lg={6}>
        <DataQualityTimeSeriesAreaChart
          missing={summary.map((d) => d.missingRows)}
          expected={summary.map((d) => d.expectedRows)}
          incomplete={summary.map((d) => d.incompleteRows)}
          errors={summary.map((d) => d.errorRows)}
          valueErrors={summary.map((d) => d.valueErrorRows)}
          complete={summary.map((d) => d.receivedRows)}
          times={summary.map((d) => d.date).map((t) => dayjs(t))}
          {...rest}
          loading={false}
        />
      </Col>
      <Col sm={12} lg={6}>
        <Card
          title={"Quality"}
          subtitle={"% of assets achieving % data quality"}
        >
          <div className="chart">
            <SimpleLineChart
              multipleData={[
                ["", summary.map((d) => [dayjs(d.date), 100]), "0", "red"],
                [
                  ">= 50%",
                  summary.map((d) => [
                    dayjs(d.date),
                    Math.round((d.aboveThreshold050Assets / d.assets) * 100),
                  ]),
                  "50",
                  "red-orange",
                ],
                [
                  ">= 75%",
                  summary.map((d) => [
                    dayjs(d.date),
                    Math.round((d.aboveThreshold075Assets / d.assets) * 100),
                  ]),
                  "75",
                  "yellow",
                ],
                [
                  ">= 95%",
                  summary.map((d) => [
                    dayjs(d.date),
                    Math.round((d.aboveThreshold095Assets / d.assets) * 100),
                  ]),
                  "95",
                  "orange-green",
                ],
                [
                  "= 100%",
                  summary.map((d) => [
                    dayjs(d.date),
                    Math.round((d.aboveThreshold100Assets / d.assets) * 100),
                  ]),
                  "100",
                  "green",
                ],
              ]}
              name={"% of assets"}
              type={"areaspline"}
              unit={"%"}
              yMax={100}
              yMin={0}
              legend={true}
              loading={false}
            />
          </div>
        </Card>
      </Col>
    </>
  );
};

export default SummaryDataQualityTimeSeriesAreaChart;
