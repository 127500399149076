import NewGetEntity, {
  type NewGetEntityResultProps,
} from "core/action/GetEntity";
import dayjs from "dayjs";
import {
  type JobDefinitionOut,
  jobStatusEnum,
  useGetExportJobListJobGet,
} from "kubb";
import { startCase } from "lodash-es";
import type React from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner } from "../../components/Error/ErrorBanner.tsx";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import ObjectDetailHeading from "../../components/Layout/ObjectDetailHeading";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import { Heading } from "../../components/Theme/heading";
import type { PageDefinition } from "../../components/navigation/breadcrumbs/Breadcrumbs";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import HighlightNullAndOldDates from "../../components/utils/HighlightNullAndOldDates";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../hooks/setPageTitle.tsx";
import EventList from "../event/EventList";
import ReportExecutionListContainer from "../report/executions/ReportExecutionListContainer";
import ChangeJobStateButton from "./ChangeJobState";
import ExecuteJobForm from "./ExecuteJobForm";
import JobCreateForm from "./JobCreateForm";

const jobToBreadcrumb = (job: JobDefinitionOut): PageDefinition[] => {
  return [
    {
      name: "Jobs",
      url: "/admin/job",
    },
    {
      name: `${job.name} (${job.job_id})`,
      url: `/admin/job/${job.job_id}`,
    },
  ];
};

export default function JobHomePage(props: {
  company_id: string;
  scheme_id?: string;
}): React.ReactElement {
  const { jobId } = useParams<{ jobId: string }>();

  if (jobId === undefined) {
    throw new Error("jobId must be specified.");
  }

  return (
    <NewGetEntity
      useQuery={useGetExportJobListJobGet}
      params={{
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        job_id: jobId,
      }}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Job Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={schemeObjectPageTitle("Jobs Error")}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Job Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={schemeObjectPageTitle("Jobs Loading")}
        />
      )}
      {...props}
    >
      {({ data, refresh }: NewGetEntityResultProps<JobDefinitionOut>) => (
        <OneCardPage
          breadcrumbs={data && jobToBreadcrumb(data)}
          headerTitle={"Job Detail"}
        >
          {data && (
            <>
              <ObjectDetailHeading
                lgBreakpoint={4}
                leftColumn={{
                  content: (
                    <ObjectPropertyItem title={"Job Name"} type={"heading"}>
                      {data.name}
                    </ObjectPropertyItem>
                  ),
                }}
                rightColumn={{
                  content: (
                    <>
                      <ObjectPropertyItem title={"Job ID"}>
                        <CopyToClipboard value={data.job_id} slice={8} />
                      </ObjectPropertyItem>
                      {data.object_type && (
                        <ObjectPropertyItem title={"Object Filter"}>
                          {startCase(data.object_type)}:{data.object_id}
                        </ObjectPropertyItem>
                      )}
                      <ObjectPropertyItem title={"Status"}>
                        <AssetRegisterStatusLabel
                          status={data.status || jobStatusEnum.active}
                          displayActive={true}
                        />
                      </ObjectPropertyItem>
                      <ObjectPropertyItem title={"Last Run"}>
                        <HighlightNullAndOldDates
                          ageHighlight={dayjs.duration(1, "day")}
                        >
                          {data.last_run}
                        </HighlightNullAndOldDates>
                      </ObjectPropertyItem>
                    </>
                  ),
                }}
              />
              <RoutedTabGroupInterface
                tabs={[
                  {
                    tabName: "Executions",
                    tabPath: "executions",
                    tabContent: (
                      <>
                        {data && data.status === jobStatusEnum.active ? (
                          <div>
                            <Heading>Active Job</Heading>
                            <span>
                              This job is currently active. It runs{" "}
                              {data.schedule?.frequency}.
                            </span>
                          </div>
                        ) : (
                          <div>
                            <Heading>Disabled job</Heading>
                            <span>
                              This job is currently disabled. It will not run.
                            </span>
                          </div>
                        )}
                        <div>
                          <ChangeJobStateButton job={data} refresh={refresh} />
                        </div>
                        {data && data.status === jobStatusEnum.active && (
                          <div>
                            <Heading>Run job now</Heading>
                            <ExecuteJobForm job={data} refresh={refresh} />
                          </div>
                        )}
                        <div>
                          <Heading>Previous Executions</Heading>
                          <ReportExecutionListContainer jobId={data.job_id} />
                        </div>
                      </>
                    ),
                  },
                  {
                    tabName: "Configuration",
                    tabPath: "configuration",
                    tabContent: (
                      <>
                        <JobCreateForm
                          existingJob={data}
                          locked={true}
                          company_id={data.company_id}
                          key={data.job_id}
                        />
                      </>
                    ),
                  },
                  {
                    tabName: "Events",
                    tabPath: "events",
                    tabContent: (
                      <>
                        {data && (
                          <EventList
                            jobId={jobId}
                            company_id={data?.company_id}
                          />
                        )}
                      </>
                    ),
                  },
                ]}
                topLevelPath={`/admin/job/${data.job_id}`}
              />
            </>
          )}
        </OneCardPage>
      )}
    </NewGetEntity>
  );

  /* TODO: fix tab switching */
}
