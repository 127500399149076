import type React from "react";
import { useParams } from "react-router-dom";
import { useGetBulkUpdate } from "../../hooks/getEntity.tsx";
import { useRequiredSelectedCompanyId } from "../../reducers/company";
import GenericUpdatesDisplay, {
  type GenericUpdateProps,
} from "./GenericUpdatesDisplay";

export default function GenericUpdatesPage<T>(
  props: GenericUpdateProps<T>,
): React.ReactElement {
  const company_id = useRequiredSelectedCompanyId();
  const { updateId } = useParams();
  if (!updateId) {
    throw new Error("SelectedUpdateId is required");
  }

  const display = useGetBulkUpdate<T>(updateId, company_id);

  return <GenericUpdatesDisplay {...display} {...props} />;
}
