import client from 'client'
import type { GetUserListUserGetQueryResponse, GetUserListUserGetQueryParams, GetUserListUserGet422 } from '../../types/GetUserListUserGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUserListUserGetQueryKey = (params?: GetUserListUserGetQueryParams) => [{ url: '/user' }, ...(params ? [params] : [])] as const

export type GetUserListUserGetQueryKey = ReturnType<typeof getUserListUserGetQueryKey>

/**
 * @description Gets a list of users for the specified company.
 * @summary Get User List
 * {@link /user}
 */
export async function getUserListUserGet(params?: GetUserListUserGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetUserListUserGetQueryResponse, ResponseErrorConfig<GetUserListUserGet422>, unknown>({
    method: 'GET',
    url: `/user`,
    params,
    ...requestConfig,
  })
  return res
}

export function getUserListUserGetQueryOptions(params?: GetUserListUserGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getUserListUserGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetUserListUserGetQueryResponse>,
    ResponseErrorConfig<GetUserListUserGet422>,
    ResponseConfig<GetUserListUserGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUserListUserGet(params, config)
    },
  })
}

/**
 * @description Gets a list of users for the specified company.
 * @summary Get User List
 * {@link /user}
 */
export function useGetUserListUserGet<
  TData = ResponseConfig<GetUserListUserGetQueryResponse>,
  TQueryData = ResponseConfig<GetUserListUserGetQueryResponse>,
  TQueryKey extends QueryKey = GetUserListUserGetQueryKey,
>(
  params?: GetUserListUserGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetUserListUserGetQueryResponse>, ResponseErrorConfig<GetUserListUserGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUserListUserGetQueryKey(params)

  const query = useQuery({
    ...(getUserListUserGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetUserListUserGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}