export const relayStateEnum = {
  ON: 'ON',
  OFF: 'OFF',
  SWITCHING_ON: 'SWITCHING_ON',
  SWITCHING_OFF: 'SWITCHING_OFF',
  SWITCHING_ERROR: 'SWITCHING_ERROR',
  UNKNOWN: 'UNKNOWN',
} as const

export type RelayStateEnum = (typeof relayStateEnum)[keyof typeof relayStateEnum]

export type RelayState = RelayStateEnum