import client from 'client'
import type {
  CreateNewAccountAccountPostMutationRequest,
  CreateNewAccountAccountPostMutationResponse,
  CreateNewAccountAccountPostQueryParams,
  CreateNewAccountAccountPost422,
} from '../../types/CreateNewAccountAccountPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewAccountAccountPostMutationKey = () => [{ url: '/account' }] as const

export type CreateNewAccountAccountPostMutationKey = ReturnType<typeof createNewAccountAccountPostMutationKey>

/**
 * @summary Create New Account
 * {@link /account}
 */
export async function createNewAccountAccountPost(
  data: CreateNewAccountAccountPostMutationRequest,
  params?: CreateNewAccountAccountPostQueryParams,
  config: Partial<RequestConfig<CreateNewAccountAccountPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewAccountAccountPostMutationResponse,
    ResponseErrorConfig<CreateNewAccountAccountPost422>,
    CreateNewAccountAccountPostMutationRequest
  >({ method: 'POST', url: `/account`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Account
 * {@link /account}
 */
export function useCreateNewAccountAccountPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewAccountAccountPostMutationResponse>,
      ResponseErrorConfig<CreateNewAccountAccountPost422>,
      { data: CreateNewAccountAccountPostMutationRequest; params?: CreateNewAccountAccountPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewAccountAccountPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewAccountAccountPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewAccountAccountPostMutationResponse>,
    ResponseErrorConfig<CreateNewAccountAccountPost422>,
    { data: CreateNewAccountAccountPostMutationRequest; params?: CreateNewAccountAccountPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewAccountAccountPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}