import type { DefaultError } from "@tanstack/query-core";
import type {
  UseMutationResult,
  UseQueryResult,
} from "@tanstack/react-query/src/types.ts";
import { isEqual } from "lodash-es";
import React, { useEffect } from "react";
import type { ResponseConfig } from "../client.ts";
import type { GetDataWrapperResult } from "../components/MutateWrapper.tsx";

export function useGetDataNew<TData>(
  useQuery: () => UseQueryResult<ResponseConfig<TData> | undefined, any>,
): GetDataWrapperResult<TData> {
  const result = useQuery();
  /* TODO: better error handling */
  return {
    loading: result.isLoading,
    data: result?.data?.data,
    error: result.error,
    refresh: result.refetch,
  };
}

export function useMutateGetData<
  D,
  TBody = unknown,
  TParams = unknown,
  TError = DefaultError,
>(
  fn: UseMutationResult<
    ResponseConfig<D>,
    TError,
    {
      data?: TBody;
      params?: TParams;
    }
  >,
  params: TBody,
): GetDataWrapperResult<D> {
  const [state, setState] = React.useState<GetDataWrapperResult<D>>({
    loading: true,
    refresh: () => null,
  });

  const [request, setRequest] = React.useState<any>();

  async function refresh() {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    const data = await fn.mutateAsync({
      data: params,
    });
    setState({ loading: false, data: data.data, refresh });
    /*TODO: error handling */
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: refresh will always change
  useEffect(() => {
    refresh();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: refresh will always change
  useEffect(() => {
    if (!isEqual(params, request)) {
      setRequest(params);
      refresh();
    }
  }, [params]);

  return state;
}

export default useGetDataNew;
