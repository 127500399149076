import {
  type AssetPosition,
  assetPositionActionTypesEnum,
  assetTypeEnum,
} from "kubb";
import type React from "react";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CreateActionButton from "../assetActions/CreateActionButton";
import GatewayConnectivityReport from "../assets/gateway/GatewayConnectivityReport";

export default function WirelessClusterGatewayList(props: {
  clusterPosition: AssetPosition;
}): React.ReactElement {
  const { clusterPosition } = props;

  return (
    <GatewayConnectivityReport
      company_id={clusterPosition.company_id}
      scheme_id={clusterPosition.scheme_id}
      parent_asset_position_id={clusterPosition.asset_position_id}
      clusterForAssignment={clusterPosition}
      additionalFunctionButtons={
        <DisplayIfUserType userTypes={"admin"}>
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={
              assetPositionActionTypesEnum.SYNC_CONFIGURATION_ALL_ASSETS
            }
            actionPayload={{ asset_type: assetTypeEnum.gateway }}
            actionName={"Sync Configuration all Gateways"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={
              assetPositionActionTypesEnum.SYNC_DEVICE_LIST_ALL_ASSETS
            }
            actionPayload={{ asset_type: assetTypeEnum.gateway }}
            actionName={"Sync Device List all Gateways"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={
              assetPositionActionTypesEnum.SYNC_CONFIGURATION_ALL_ASSETS
            }
            actionPayload={{ asset_type: assetTypeEnum.wireless_receiver }}
            actionName={"Sync Configuration all Receivers"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={
              assetPositionActionTypesEnum.SYNC_DEVICE_LIST_ALL_ASSETS
            }
            actionPayload={{ asset_type: assetTypeEnum.wireless_receiver }}
            actionName={"Sync Device List all Receivers"}
          />
        </DisplayIfUserType>
      }
    />
  );
}
