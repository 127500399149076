import PeriodPickerToggle from "components/Forms/PeriodPicker/PeriodPickerToggle";
import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { AssetType, GroupBy, Period } from "kubb";
import type { Company } from "kubb";
import { groupByEnum } from "kubb";
import { periodEnum } from "kubb";
import { isNil } from "lodash-es";
import type React from "react";
import { useState } from "react";
import AutoSubmit from "../../../../../components/Forms/AutoSubmit";
import { inclusiveDatePeriod } from "../../../../../components/Forms/DateTimePicker";
import GroupByRadioButtonGroupField from "../../../../../components/Forms/GroupByPicker/GroupByRadioButtonGroupField";
import Card, { FixHeightCardBody } from "../../../../../components/Theme/card";
import AggregatedStatsChart from "../../../../../components/charts/AggregatedStatsChart";
import TimeSeriesStatsApiWrapper, {
  type AggregatedTimeSeriesStatsParams,
} from "../../../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import {
  BillableAssets,
  NonBillableAssetTypes,
} from "../../../../../model/assetPosition";
import { useSelectedCompany } from "../../../../../reducers/company";
import {
  useLocationGroups,
  useSelectedScheme,
} from "../../../../../reducers/scheme";

function periodName(period: Period): string {
  switch (period) {
    case "DAILY":
      return "Daily";
    case "MONTHLY":
      return "Monthly";
    case "WEEKLY":
      return "Weekly";
    case "SEVEN_DAY":
      return "7 Day";
    case "ALL":
      return "All";
    default:
      return "ALL";
  }
}

const monthInclusiveDatePeriod = (length = 3): [Dayjs, Dayjs] => {
  const endDate: Dayjs = dayjs().startOf("day").subtract(1, "day");
  const startDate: Dayjs = dayjs(endDate)
    .subtract(length, "month")
    .startOf("month");
  return [startDate, endDate];
};

const periodLength = (period: Period): [Dayjs, Dayjs] => {
  switch (period) {
    case "MONTHLY":
      return monthInclusiveDatePeriod();
    case "SEVEN_DAY":
      return inclusiveDatePeriod(undefined, undefined, 56);
    default:
      return inclusiveDatePeriod();
  }
};

const DailyReadSuccessWidget = (): React.ReactElement => {
  const selectedCompany = useSelectedCompany() as Company;
  const selectedScheme = useSelectedScheme();
  const locationGroups = useLocationGroups();

  let assetTypes: AssetType[] = Object.values(NonBillableAssetTypes);
  assetTypes = assetTypes.concat(Object.values(BillableAssets));

  const [period, setPeriod] = useState<Period>(periodEnum.SEVEN_DAY);
  const [group_by, setGroupBy] = useState<GroupBy>(
    selectedScheme
      ? locationGroups
        ? groupByEnum.GROUP_ALL
        : groupByEnum.NONE
      : groupByEnum.SCHEME,
  );

  const [start_datetime, end_datetime] = periodLength(period);

  const params: AggregatedTimeSeriesStatsParams = {
    start_datetime,
    end_datetime,
    parameter: "Quality Assessment",
    aggregation: "received_rows_basic_complete",
    company_id: selectedCompany?.company_id || "NONE",
    scheme_id: selectedScheme?.scheme_id,
    group_by,
    asset_types: assetTypes,
    period: period,
  };

  const periodNameText = periodName(period);
  const schemeMode = !isNil(selectedScheme);

  return (
    <Card
      title={<>Billing Read Success ({periodNameText})</>}
      iconBlock={
        <Formik
          initialValues={{
            groupBy: group_by,
            period: period,
          }}
          onSubmit={(v) => {
            setGroupBy(v.groupBy);
            setPeriod(v.period);
          }}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <AutoSubmit />
              <div className={"flex justify-end gap-2"}>
                <div>
                  {(!schemeMode || locationGroups) && (
                    <GroupByRadioButtonGroupField
                      fieldName={"groupBy"}
                      noneLabel={schemeMode ? "SCHEME" : "COMPANY"}
                      includeLocationGroup={schemeMode}
                      includeScheme={!schemeMode}
                      size={"sm"}
                      label={{ margin: 0, size: "sm" }}
                    />
                  )}
                </div>
                <div>
                  <PeriodPickerToggle
                    fieldName={"period"}
                    size={"sm"}
                    label={{ margin: 0, size: "sm" }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    >
      <FixHeightCardBody height={96} overflowScroll={false}>
        <TimeSeriesStatsApiWrapper request={params}>
          {(chartProps) => (
            <AggregatedStatsChart
              {...chartProps}
              clickUrl={"/admin/report/row_data_quality"}
              groupBy={group_by}
              seriesName={"% of meter points with &gt; 1 read in period "}
              showCountAsPercentage={true}
              units={"%"}
            />
          )}
        </TimeSeriesStatsApiWrapper>
      </FixHeightCardBody>
    </Card>
  );
};

export default DailyReadSuccessWidget;
