import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, type FormikHelpers } from "formik";
import {
  type AllDataReportConfigurationInput,
  type AllDataReportFormatInput,
  type BillingReportConfigurationInput,
  type BillingReportFormatInput,
  type CumulativeDataConfigurationInput,
  type CumulativeDataReportFormatInput,
  type ElvacoX109ReportConfiguration,
  type ElvacoX109ReportFormatInput,
  type EmailConfiguration,
  type ExportTypes,
  type FTPConfiguration,
  type JobDefinitionIn,
  type JobDefinitionOut,
  type JobObjectTypesEnum,
  type LatestDataReportFormat,
  type OutputType,
  type OutputTypeEnum,
  type Schedule,
  exportTypesEnum,
  frequencyEnum,
  jobObjectTypesEnum,
  outputTypeEnum,
} from "kubb";
import { isNil, isString } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import AlertBanner from "../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { SelectComboField } from "../../components/Forms/SelectCombo/SelectCombo";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import SchemeSelectFormComponent from "../../components/Scheme/SchemeSelectFormComponent";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Button } from "../../components/Theme/button";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { useCreateNewJob } from "../../hooks/createEntity";
import { useRequiredSelectedCompanyId } from "../../reducers/company.tsx";
import DaySelect from "./Common/DaySelect";
import FrequencySelect from "./Common/FrequencySelect";
import FTPExportConfigurationSubForm, {
  FTPExportConfigurationSchema,
  FTPExportInitialValues,
} from "./ExportConfigurationForms/FTPExportConfiguration";
import AllDataReportConfigurationSubForm, {
  AllDataReportConfigurationInitialValues,
  AllDataReportConfigurationSubFormSchema,
} from "./FormatConfigurationForms/AllDataReportConfigurationForm";
import BillingConfigurationSubForm, {
  BillingConfigurationInitialValues,
  BillingConfigurationSubFormSchema,
} from "./FormatConfigurationForms/BillingConfigurationForm";
import X109ConfigurationSubForm, {
  X109ConfigurationInitialValues,
  X109ConfigurationSubFormSchema,
  X110ConfigurationInitialValues,
} from "./FormatConfigurationForms/X109ConfigurationForm";

interface Props {
  company_id: string;
  scheme_id?: string;
  asset_position_id?: string;
  locked?: boolean;
  existingJob?: JobDefinitionOut;
}

interface FormValues {
  name: string;
  format_type: OutputTypeEnum;
  export_type?: ExportTypes | "None";
  object_type?: JobObjectTypesEnum;
  object_id?: string;
  export?: FTPConfiguration | EmailConfiguration;
  format?: {
    configuration:
      | BillingReportConfigurationInput
      | AllDataReportConfigurationInput
      | ElvacoX109ReportConfiguration
      | CumulativeDataConfigurationInput;
    file_name?: string;
  };
  scheme_id?: string;
  schedule?: Schedule;
}

export default function JobCreateForm(props: Props): React.ReactElement {
  const { locked = false, existingJob } = { ...props };

  const create = useCreateNewJob(useRequiredSelectedCompanyId());

  const [createdJob, setCreatedJob] = useState<JobDefinitionOut | undefined>(
    undefined,
  );

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setCreatedJob(undefined);
    const job = convertFormToDefinition(values);
    create
      .update(job)
      .then(([data]) => {
        resetForm();
        setCreatedJob(data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formValuesToObjectTypeAndId = (
    values: FormValues,
  ): { objectType?: JobObjectTypesEnum; objectId?: string } => {
    if (props.asset_position_id) {
      return {
        objectId: props.asset_position_id,
        objectType: jobObjectTypesEnum.asset_position,
      };
    }
    if (values.scheme_id && values.scheme_id !== "undefined") {
      return {
        objectId: values.scheme_id,
        objectType: jobObjectTypesEnum.scheme,
      };
    }
    return {
      objectId: undefined,
      objectType: undefined,
    };
  };

  const convertFormToDefinition = (values: FormValues): JobDefinitionIn => {
    return {
      name: values.name,
      ...formValuesToObjectTypeAndId(values),
      export_configuration:
        values.export_type !== "None" && values.export
          ? {
              export_type: values.export_type as ExportTypes,
              configuration: values.export,
            }
          : undefined,
      report_format: {
        format: values.format_type as unknown as OutputTypeEnum,
        file_name: values.format?.file_name,
        configuration:
          (values.format?.configuration as
            | BillingReportConfigurationInput
            | AllDataReportConfigurationInput
            | ElvacoX109ReportConfiguration
            | CumulativeDataConfigurationInput) ||
          ({} as
            | BillingReportConfigurationInput
            | AllDataReportConfigurationInput
            | ElvacoX109ReportConfiguration
            | CumulativeDataConfigurationInput) /* hack to make typ check work */,
      } as
        | BillingReportFormatInput
        | ElvacoX109ReportFormatInput
        | AllDataReportFormatInput
        | LatestDataReportFormat
        | CumulativeDataReportFormatInput,
      company_id: props.company_id,
      schedule: values.schedule,
    };
  };

  const convertJobDefinitionToFormValues = (
    job: JobDefinitionOut,
  ): FormValues => {
    const { format, ...formatConfig } = job.report_format;
    const scheme_id =
      job.object_type === "scheme" ? job.object_id : "undefined";

    return {
      name: job.name,
      scheme_id,
      format_type: format as unknown as OutputType,
      export_type: job.export_configuration?.export_type || "None",
      object_type: job.object_type,
      object_id: job.object_id !== "" ? job.object_id : undefined,
      export: job.export_configuration?.configuration,
      format: formatConfig as {
        file_name?: string;
        configuration:
          | BillingReportConfigurationInput
          | AllDataReportConfigurationInput
          | ElvacoX109ReportConfiguration
          | CumulativeDataConfigurationInput;
      },
      schedule: job.schedule,
    };
  };

  const initialValues: FormValues = existingJob
    ? convertJobDefinitionToFormValues(existingJob)
    : {
        name: "",
        scheme_id: props.scheme_id,
        format_type: props.asset_position_id
          ? outputTypeEnum.ELVACO_X109
          : outputTypeEnum.CUMULATIVE_DATA,
        export_type: "None",
        object_type: props.asset_position_id
          ? jobObjectTypesEnum.asset_position
          : undefined,
        object_id: props.asset_position_id ? props.asset_position_id : "",
        export: props.asset_position_id ? FTPExportInitialValues : undefined,
        format: props.asset_position_id
          ? X109ConfigurationInitialValues
          : BillingConfigurationInitialValues(),
        schedule: { frequency: frequencyEnum.DAILY },
      };

  const defaultValuesForExport = (exportType: string) => {
    switch (exportType) {
      case "FTP":
        return FTPExportInitialValues;
      default:
        return {};
    }
  };

  const defaultValuesForReport = (reportType: string) => {
    switch (reportType) {
      case "ELVACO_X109":
        return X109ConfigurationInitialValues;
      case "ELVACO_X110":
        return X110ConfigurationInitialValues;
      case "CUMULATIVE_DATA":
        return BillingConfigurationInitialValues();
      case "ALL_DATA":
        return AllDataReportConfigurationInitialValues;
      default:
        return undefined;
    }
  };

  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: yupRequiredString,
          export: Yup.object().when("export_type", (export_type: string) => {
            switch (export_type) {
              case "FTP":
                return FTPExportConfigurationSchema;
              case "None":
                return Yup.object();
              default:
                throw new Error("export_type is not understood");
            }
          }),
          format: Yup.object().when("format_type", (format_type: string) => {
            switch (format_type) {
              case "ELVACO_X109":
                return X109ConfigurationSubFormSchema;
              case "ELVACO_X110":
                return X109ConfigurationSubFormSchema;
              case "CUMULATIVE_DATA":
                return BillingConfigurationSubFormSchema();
              case "LATEST":
                return Yup.object();
              case "ALL_DATA":
                return AllDataReportConfigurationSubFormSchema();
              default:
                throw new Error(`format_type ${format_type} is not understood`);
            }
          }),
          format_type: yupRequiredString,
          export_type: yupRequiredString,
        })}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({
          values,
          submitForm,
          isValid,
          isSubmitting,
          setFieldValue,
          validateForm,
        }): React.ReactElement => {
          return (
            <BlockSpinner loading={isSubmitting}>
              <>
                <h4>Basic</h4>

                <NewTextFormComponent
                  fieldName={"name"}
                  label={{ label: "Report Name" }}
                  disabled={locked}
                />

                {!props.asset_position_id && (
                  <SchemeSelectFormComponent
                    label={{ label: "Scheme Filter" }}
                    disabled={locked}
                    fieldName={"scheme_id"}
                  />
                )}
                <FrequencySelect
                  fieldName={"schedule.frequency"}
                  onChange={async (value) => {
                    if (!isNil(value) && isString(value)) {
                      if (value === "WEEKLY") {
                        await setFieldValue("schedule.dayOfWeek", "MONDAY");
                        return await setFieldValue(
                          "schedule.dayOfMonth",
                          undefined,
                        );
                      } else if (value === "MONTHLY") {
                        await setFieldValue("schedule.dayOfWeek", undefined);
                        return await setFieldValue("schedule.dayOfMonth", 2);
                      } else {
                        await setFieldValue("schedule.dayOfWeek", undefined);
                        return await setFieldValue(
                          "schedule.dayOfMonth",
                          undefined,
                        );
                      }
                    }
                    throw new Error("Schedule value is not valid");
                  }}
                  disabled={locked}
                  label={{ label: "Frequency" }}
                  clearable={false}
                />
                {values.schedule?.frequency === "WEEKLY" && (
                  <DaySelect
                    fieldName={"schedule.dayOfWeek"}
                    disabled={locked}
                    clearable={false}
                  />
                )}
                {values.schedule?.frequency === "MONTHLY" && (
                  <NewTextFormComponent
                    fieldName={"schedule.dayOfMonth"}
                    label={{ label: "Scheduled Day of Month" }}
                    disabled={locked}
                    type={"number"}
                  />
                )}
                <SelectComboField
                  fieldName={"format_type"}
                  onChange={async (event) => {
                    if (isString(event)) {
                      await setFieldValue(
                        "format",
                        defaultValuesForReport(event as unknown as string),
                      );
                      return await validateForm();
                    } else {
                      throw new Error("format_type must be a single string");
                    }
                  }}
                  label={{ label: "Format" }}
                  options={[
                    {
                      label: "Billing",
                      value: outputTypeEnum.CUMULATIVE_DATA,
                    },
                    { label: "Latest Data", value: outputTypeEnum.LATEST },
                    {
                      label: "Elvaco X109",
                      value: outputTypeEnum.ELVACO_X109,
                    },
                    {
                      label: "Elvaco X110",
                      value: outputTypeEnum.ELVACO_X110,
                    },
                    { label: "All data", value: outputTypeEnum.ALL_DATA },
                  ]}
                  disabled={locked}
                  clearable={false}
                />
                <SelectComboField
                  fieldName={"export_type"}
                  onChange={async (event) => {
                    if (!isNil(event) && isString(event)) {
                      await setFieldValue(
                        "export",
                        defaultValuesForExport(event),
                      );
                      return await validateForm();
                    }
                    throw new Error("export_type is not understood");
                  }}
                  label={{ label: "Destination" }}
                  options={[
                    { label: "None", value: "None" },
                    { label: "FTP", value: exportTypesEnum.FTP },
                  ]}
                  disabled={locked}
                  clearable={false}
                />
                {values.export_type === "FTP" && (
                  <FTPExportConfigurationSubForm
                    namespace={"export"}
                    disabled={locked}
                  />
                )}
                {values.format_type === outputTypeEnum.ELVACO_X109 && (
                  <X109ConfigurationSubForm
                    namespace={"format"}
                    disabled={locked}
                  />
                )}
                {values.format_type === outputTypeEnum.ELVACO_X110 && (
                  <X109ConfigurationSubForm
                    namespace={"format"}
                    disabled={locked}
                  />
                )}
                {values.format_type === outputTypeEnum.CUMULATIVE_DATA && (
                  <BillingConfigurationSubForm
                    namespace={"format.configuration"}
                    disabled={locked}
                  />
                )}
                {values.format_type === outputTypeEnum.ALL_DATA && (
                  <AllDataReportConfigurationSubForm
                    namespace={"format"}
                    disabled={locked}
                  />
                )}
                {!locked && (
                  <Button
                    type={"submit"}
                    color={"brandLight"}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid || locked}
                  >
                    Add Job
                  </Button>
                )}
              </>
            </BlockSpinner>
          );
        }}
      </Formik>
      <ErrorBanner error={create.error} />
      {createdJob && (
        <AlertBanner className={"bg-success my-2"}>
          <h4>
            <FontAwesomeIcon icon={faCheck} className={"mx-1"} />
            Job ...{createdJob.job_id.slice(-8)} was successfully created.
          </h4>
          <p>
            <CompanyNavLink
              to={{ pathname: `/admin/job/${createdJob.job_id}` }}
              className={"px-1 text-white"}
            >
              View job.
            </CompanyNavLink>
          </p>
        </AlertBanner>
      )}
    </>
  );
}
