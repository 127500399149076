import client from 'client'
import type {
  GetAlarmListEventAlarmGetQueryResponse,
  GetAlarmListEventAlarmGetQueryParams,
  GetAlarmListEventAlarmGet422,
} from '../../types/GetAlarmListEventAlarmGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAlarmListEventAlarmGetQueryKey = (params?: GetAlarmListEventAlarmGetQueryParams) =>
  [{ url: '/event/alarm' }, ...(params ? [params] : [])] as const

export type GetAlarmListEventAlarmGetQueryKey = ReturnType<typeof getAlarmListEventAlarmGetQueryKey>

/**
 * @summary Get Alarm List
 * {@link /event/alarm}
 */
export async function getAlarmListEventAlarmGet(
  params?: GetAlarmListEventAlarmGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetAlarmListEventAlarmGetQueryResponse, ResponseErrorConfig<GetAlarmListEventAlarmGet422>, unknown>({
    method: 'GET',
    url: `/event/alarm`,
    params,
    ...requestConfig,
  })
  return res
}

export function getAlarmListEventAlarmGetQueryOptions(
  params?: GetAlarmListEventAlarmGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAlarmListEventAlarmGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetAlarmListEventAlarmGetQueryResponse>,
    ResponseErrorConfig<GetAlarmListEventAlarmGet422>,
    ResponseConfig<GetAlarmListEventAlarmGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAlarmListEventAlarmGet(params, config)
    },
  })
}

/**
 * @summary Get Alarm List
 * {@link /event/alarm}
 */
export function useGetAlarmListEventAlarmGet<
  TData = ResponseConfig<GetAlarmListEventAlarmGetQueryResponse>,
  TQueryData = ResponseConfig<GetAlarmListEventAlarmGetQueryResponse>,
  TQueryKey extends QueryKey = GetAlarmListEventAlarmGetQueryKey,
>(
  params?: GetAlarmListEventAlarmGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAlarmListEventAlarmGetQueryResponse>,
        ResponseErrorConfig<GetAlarmListEventAlarmGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAlarmListEventAlarmGetQueryKey(params)

  const query = useQuery({
    ...(getAlarmListEventAlarmGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAlarmListEventAlarmGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}