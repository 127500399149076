import type { CompanyType } from "kubb";
import { isArray } from "lodash-es";
import type React from "react";
import useUserHasRoles from "../../../hooks/user/getCurrentUserRole";

interface Props {
  children: any;
  userTypes: string[] | string;
  companyType?: CompanyType | CompanyType[];
  ignoreViewerModeSwitch?: boolean;
}

export default function DisplayIfUserType(props: Props): React.ReactElement {
  const userTypes = isArray(props.userTypes)
    ? props.userTypes
    : singleUserTypeToList(props.userTypes);
  const companyTypes = isArray(props.companyType)
    ? props.companyType
    : props.companyType
      ? [props.companyType]
      : undefined;

  const display = useUserHasRoles(
    userTypes,
    companyTypes,
    props.ignoreViewerModeSwitch,
  );

  return <>{display && props.children}</>;
}

const singleUserTypeToList = (userType: string): Array<string> => {
  switch (userType) {
    case "superadmin":
      return ["superadmin"];
    case "admin":
      return ["admin", "superadmin"];
    case "editor":
      return ["superadmin", "admin", "editor"];
    case "viewer":
      return ["superadmin", "admin", "editor", "viewer"];
    default:
      return ["superadmin", "admin", "editor", "viewer"];
  }
};
