import SelectedScheme from "components/Scheme/SelectedScheme";
import { assetSortEnum, assetTypeEnum } from "kubb";
import type React from "react";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import { OneCardPage } from "../../../../components/utils/OneCardPage";
import { schemeObjectPageTitle } from "../../../../hooks/setPageTitle";
import AssetList from "../list/AssetList";
import GatewayConnectivityReport from "./GatewayConnectivityReport";
import { UnassignedGatewayConnectivityReportDataRow } from "./GatewayConnectivityReportDataRow";
import GatewayConnectivityReportHeaderRow from "./GatewayConnectivityReportHeaderRow";

export default function GatewayConnectivityReportPage(props: {
  assigned?: boolean;
}): React.ReactElement {
  const { assigned = true } = props;

  return (
    <OneCardPage
      headerTitle={assigned ? "Gateway Status" : "Unassigned Gateway Status"}
      breadcrumbs={[{ url: "/admin/report/gateway", name: "Gateway Status" }]}
      showSchemeInBreadcrumbs={assigned}
      setPageTitle={schemeObjectPageTitle(
        `Gateway Status${!assigned ? ": Unassigned" : ""}`,
      )}
    >
      <>
        {assigned ? (
          <CompanyNavLink to={{ pathname: "/admin/report/gateway/unassigned" }}>
            Switch to unassigned gateways
          </CompanyNavLink>
        ) : (
          <CompanyNavLink to={{ pathname: "/admin/report/gateway" }}>
            Switch to assigned gateways
          </CompanyNavLink>
        )}{" "}
        {assigned ? (
          <SelectedScheme>
            {(props) => <GatewayConnectivityReport {...props} />}
          </SelectedScheme>
        ) : (
          <SelectedScheme>
            {({ company_id }) => (
              <AssetList
                company_id={company_id}
                assetRow={UnassignedGatewayConnectivityReportDataRow}
                headerRow={GatewayConnectivityReportHeaderRow(false)(false)}
                params={{
                  asset_type: [assetTypeEnum.gateway],
                  asset_position_id: "NONE",
                  sort: assetSortEnum.last_contact,
                }}
              />
            )}
          </SelectedScheme>
        )}
      </>
    </OneCardPage>
  );
}
