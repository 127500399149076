import { ErrorBanner } from "components/Error/ErrorBanner";
import { Formik } from "formik";
import type {
  DeviceAssetOut,
  DeviceAssetUpdateIn,
  GatewayAssetUpdateIn,
} from "kubb";
import { type DeviceConfigurationOutput, mBusAddressingModeEnum } from "kubb";
import { isNumber } from "lodash-es";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import SuccessBanner from "../../../../../components/Banner/SuccessBanner";
import BlockSpinner from "../../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../../components/Theme/button";
import { useUpdateAsset } from "../../../../../hooks/updateEntity";
import { objectBlankStringsToUndefined } from "../../../../../utils/object";
import MBusConfigurationSubForm, {
  MBusConfigurationFormSchema,
} from "./MbusConfigurationForm";
import VirtualMeterConfigurationForm, {
  VirtualMeterConfigurationFormSchema,
} from "./VirtualMeterConfigurationForm.tsx";

export default function DeviceConfigurationTab(props: {
  asset: DeviceAssetOut;
}): React.ReactElement {
  const configuration: DeviceConfigurationOutput | undefined = props.asset
    .configuration as DeviceConfigurationOutput | undefined;

  const [updated, setUpdated] = useState(false);

  const { update, loading, error } = useUpdateAsset(
    props.asset as DeviceAssetUpdateIn | GatewayAssetUpdateIn,
  );

  const isVirtual = props.asset.virtual_asset;

  const initialValues: DeviceConfigurationOutput = {
    mbus: !isVirtual
      ? {
          primary_address: isNumber(configuration?.mbus?.primary_address)
            ? configuration?.mbus?.primary_address
            : undefined,
          addressing_mode:
            configuration?.mbus?.addressing_mode ||
            mBusAddressingModeEnum.secondary,
          encryption_key: configuration?.mbus?.encryption_key || "",
          baud_rate: configuration?.mbus?.baud_rate || 2400,
        }
      : null,
    virtual: isVirtual
      ? {
          field: configuration?.virtual?.field,
        }
      : null,
  };

  function submit(values: DeviceConfigurationOutput) {
    setUpdated(false);
    update({
      ...props.asset,
      configuration: {
        ...values,
        mbus: objectBlankStringsToUndefined(values.mbus),
      },
    }).then(() => setUpdated(true));
  }

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape(
        isVirtual
          ? {
              virtual: VirtualMeterConfigurationFormSchema,
            }
          : {
              mbus: MBusConfigurationFormSchema,
            },
      )}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ submitForm, isValid, values }): React.ReactElement => {
        return (
          <BlockSpinner loading={loading}>
            {isVirtual ? (
              <VirtualMeterConfigurationForm namespace={"virtual"} />
            ) : (
              <MBusConfigurationSubForm
                addressing_mode={values?.mbus?.addressing_mode}
                namespace={"mbus"}
              />
            )}
            <Button
              type={"submit"}
              color={"brandLight"}
              onClick={submitForm}
              disabled={loading || !isValid}
            >
              Update
            </Button>
            {updated && (
              <SuccessBanner>
                Configuration was updated successfully.
              </SuccessBanner>
            )}
            <ErrorBanner error={error} />
          </BlockSpinner>
        );
      }}
    </Formik>
  );
}
