import { type AggregatedReportRequest, assetTypeEnum, periodEnum } from "kubb";
import type React from "react";
import AggregationScatterChart from "../../../components/charts/AggregationsScatterChart";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import type { HeatNetworkReportParams } from "../heatNetworkAnalysis/model";
import VWARTReportView, {
  VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS,
} from "./VWARTReportView";

interface Props {
  company_id?: string;
  scheme_id?: string;
  query: HeatNetworkReportParams;
}

const VWARTReportContainer = (props: Props): React.ReactElement => {
  const [startDatetime, endDatetime] = [
    props.query.startDate,
    props.query.endDate,
  ];

  const params = (): AggregatedReportRequest => {
    if (props.company_id && props.scheme_id) {
      return {
        company_id: props.company_id,
        scheme_id: props.scheme_id,
        aggregations: [
          ...VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS.map((value) => {
            return {
              parameter: "Return Temperature",
              aggregation: value,
            };
          }),
          { parameter: "Volume", aggregation: "difference" },
        ],
        asset_types: [
          assetTypeEnum.heat_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ],
        start_time: startDatetime.toISOString(),
        end_time: endDatetime.toISOString(),
        period: periodEnum.ALL,
        use_case: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  return (
    <>
      {props.company_id && props.scheme_id && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data, loading }) => (
            <>
              <AggregationScatterChart
                data={data}
                name={"VWART"}
                xAggregation={"difference"}
                xParameter={"Volume"}
                yAggregation={"volume_weighted_average"}
                yParameter={"Return Temperature"}
                loading={loading}
                xLabel={"Volume"}
                xUnit={"m3"}
                yLabel={"VWART"}
                yUnit={"degC"}
                height={"400px"}
                company_id={props.company_id as string}
                scheme_id={props.scheme_id as string}
                start_time={startDatetime}
                end_time={endDatetime}
              />
              <VWARTReportView
                data={data}
                scheme_id={props.scheme_id}
                startDatetime={startDatetime}
                endDatetime={endDatetime}
              />
            </>
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default VWARTReportContainer;
