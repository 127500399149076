import dayjs, { type Dayjs } from "dayjs";
import type React from "react";
import MultipleChart from "../../../components/charts/MultipleChart";

import type { SingleAggregationTimeAssetData } from "../../../components/TimeSeries/model";

const TimeSeriesReportGraph = (props: {
  times: Array<Dayjs>;
  data: Array<SingleAggregationTimeAssetData>;
  start_time: Dayjs;
  end_time: Dayjs;
}): React.ReactElement => {
  return (
    <MultipleChart
      xaxis={props.times.map((d) => dayjs(d))}
      data={props.data}
      start_time={props.start_time}
      end_time={props.end_time}
    />
  );
};

export default TimeSeriesReportGraph;
