import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  type Cursor,
  alarmTypesEnum,
  useGetAlarmListEventAlarmGet,
} from "kubb";
import type { AssetAlarmEvent } from "kubb";
import type React from "react";
import ErrorOverlappingBanner from "../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import Card, { FixHeightCardBody } from "../../components/Theme/card";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import AlarmList from "./AlarmList";

const numberOfAlarms = (
  loading: boolean,
  error?: Error | null,
  list?: Array<AssetAlarmEvent>,
  cursor?: Cursor,
): string => {
  if (loading) {
    return "?";
  } else if (error) {
    return "-";
  } else if (cursor?.after) {
    return `${cursor.max_items}+`;
  } else {
    return `${list?.length}`;
  }
};

const AlarmWidget = (props: {
  company_id: string;
  scheme_id?: string;
}): React.ReactElement => {
  const filterAlarms = !useUserHasRoles("superadmin", [], false);
  const params = {
    ...props,
    alarmTypes: filterAlarms ? [alarmTypesEnum.GATEWAY_OFFLINE] : undefined,
  };

  const { data, error, isPending } = useGetAlarmListEventAlarmGet({
    ...params,
    max_items: 25,
  });

  return (
    <Card
      title={
        <>
          Open Alarms (
          {numberOfAlarms(
            isPending,
            error,
            data?.data,
            data?.headers["x-cursor"],
          )}
          ){" "}
        </>
      }
      py={"py-2"}
    >
      <FixHeightCardBody height={36}>
        <BlockSpinner loading={isPending}>
          <ErrorOverlappingBanner>
            <AlarmList
              params={{ open_alarms_only: true }}
              showHeader={false}
              dense={true}
              noDataRow={
                <div className="w-full">
                  <div className={"text-center"}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={"text-success my-2"}
                      size={"3x"}
                    />
                    <h4 className={"text-success"}>No open alarms</h4>
                  </div>
                </div>
              }
              showPagination={false}
              maxDisplay={5}
            />
            {data && data.data.length > 5 && (
              <CompanyNavLink
                to={{ pathname: "/admin/alarm", search: "?status=open" }}
                className={"text-sm"}
              >
                More
              </CompanyNavLink>
            )}
          </ErrorOverlappingBanner>
        </BlockSpinner>
      </FixHeightCardBody>
    </Card>
  );
};

export default AlarmWidget;
