import client from 'client'
import type {
  GetBulkUpdateListBulkGetQueryResponse,
  GetBulkUpdateListBulkGetQueryParams,
  GetBulkUpdateListBulkGet422,
} from '../../types/GetBulkUpdateListBulkGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getBulkUpdateListBulkGetQueryKey = (params?: GetBulkUpdateListBulkGetQueryParams) => [{ url: '/bulk' }, ...(params ? [params] : [])] as const

export type GetBulkUpdateListBulkGetQueryKey = ReturnType<typeof getBulkUpdateListBulkGetQueryKey>

/**
 * @summary Get Bulk Update List
 * {@link /bulk}
 */
export async function getBulkUpdateListBulkGet(params?: GetBulkUpdateListBulkGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetBulkUpdateListBulkGetQueryResponse, ResponseErrorConfig<GetBulkUpdateListBulkGet422>, unknown>({
    method: 'GET',
    url: `/bulk`,
    params,
    ...requestConfig,
  })
  return res
}

export function getBulkUpdateListBulkGetQueryOptions(
  params?: GetBulkUpdateListBulkGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getBulkUpdateListBulkGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetBulkUpdateListBulkGetQueryResponse>,
    ResponseErrorConfig<GetBulkUpdateListBulkGet422>,
    ResponseConfig<GetBulkUpdateListBulkGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getBulkUpdateListBulkGet(params, config)
    },
  })
}

/**
 * @summary Get Bulk Update List
 * {@link /bulk}
 */
export function useGetBulkUpdateListBulkGet<
  TData = ResponseConfig<GetBulkUpdateListBulkGetQueryResponse>,
  TQueryData = ResponseConfig<GetBulkUpdateListBulkGetQueryResponse>,
  TQueryKey extends QueryKey = GetBulkUpdateListBulkGetQueryKey,
>(
  params?: GetBulkUpdateListBulkGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetBulkUpdateListBulkGetQueryResponse>,
        ResponseErrorConfig<GetBulkUpdateListBulkGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getBulkUpdateListBulkGetQueryKey(params)

  const query = useQuery({
    ...(getBulkUpdateListBulkGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetBulkUpdateListBulkGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}