import client from 'client'
import type {
  UpdateLocationLocationLocationIdPutMutationRequest,
  UpdateLocationLocationLocationIdPutMutationResponse,
  UpdateLocationLocationLocationIdPutPathParams,
  UpdateLocationLocationLocationIdPutQueryParams,
  UpdateLocationLocationLocationIdPutHeaderParams,
  UpdateLocationLocationLocationIdPut422,
} from '../../types/UpdateLocationLocationLocationIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateLocationLocationLocationIdPutMutationKey = () => [{ url: '/location/{location_id}' }] as const

export type UpdateLocationLocationLocationIdPutMutationKey = ReturnType<typeof updateLocationLocationLocationIdPutMutationKey>

/**
 * @summary Update Location
 * {@link /location/:location_id}
 */
export async function updateLocationLocationLocationIdPut(
  location_id: UpdateLocationLocationLocationIdPutPathParams['location_id'],
  data: UpdateLocationLocationLocationIdPutMutationRequest,
  params?: UpdateLocationLocationLocationIdPutQueryParams,
  headers?: UpdateLocationLocationLocationIdPutHeaderParams,
  config: Partial<RequestConfig<UpdateLocationLocationLocationIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateLocationLocationLocationIdPutMutationResponse,
    ResponseErrorConfig<UpdateLocationLocationLocationIdPut422>,
    UpdateLocationLocationLocationIdPutMutationRequest
  >({ method: 'PUT', url: `/location/${location_id}`, params, data, headers: { ...headers, ...requestConfig.headers }, ...requestConfig })
  return res
}

/**
 * @summary Update Location
 * {@link /location/:location_id}
 */
export function useUpdateLocationLocationLocationIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateLocationLocationLocationIdPutMutationResponse>,
      ResponseErrorConfig<UpdateLocationLocationLocationIdPut422>,
      {
        location_id: UpdateLocationLocationLocationIdPutPathParams['location_id']
        data: UpdateLocationLocationLocationIdPutMutationRequest
        params?: UpdateLocationLocationLocationIdPutQueryParams
        headers?: UpdateLocationLocationLocationIdPutHeaderParams
      }
    >
    client?: Partial<RequestConfig<UpdateLocationLocationLocationIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateLocationLocationLocationIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateLocationLocationLocationIdPutMutationResponse>,
    ResponseErrorConfig<UpdateLocationLocationLocationIdPut422>,
    {
      location_id: UpdateLocationLocationLocationIdPutPathParams['location_id']
      data: UpdateLocationLocationLocationIdPutMutationRequest
      params?: UpdateLocationLocationLocationIdPutQueryParams
      headers?: UpdateLocationLocationLocationIdPutHeaderParams
    }
  >({
    mutationFn: async ({ location_id, data, params, headers }) => {
      return updateLocationLocationLocationIdPut(location_id, data, params, headers, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}