import client from 'client'
import type {
  UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest,
  UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationResponse,
  UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutPathParams,
  UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutQueryParams,
  UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut422,
} from '../../types/UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationKey = () =>
  [{ url: '/asset_position/{asset_position_id}/wireless_receivers' }] as const

export type UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationKey = ReturnType<
  typeof updateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationKey
>

/**
 * @summary Update Active Wireless Receivers
 * {@link /asset_position/:asset_position_id/wireless_receivers}
 */
export async function updateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut(
  asset_position_id: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutPathParams['asset_position_id'],
  data?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest,
  params?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutQueryParams,
  config: Partial<RequestConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest>> & {
    client?: typeof client
  } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationResponse,
    ResponseErrorConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut422>,
    UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest
  >({ method: 'PUT', url: `/asset_position/${asset_position_id}/wireless_receivers`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Update Active Wireless Receivers
 * {@link /asset_position/:asset_position_id/wireless_receivers}
 */
export function useUpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationResponse>,
      ResponseErrorConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut422>,
      {
        asset_position_id: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutPathParams['asset_position_id']
        data?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest
        params?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationResponse>,
    ResponseErrorConfig<UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut422>,
    {
      asset_position_id: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutPathParams['asset_position_id']
      data?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutMutationRequest
      params?: UpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPutQueryParams
    }
  >({
    mutationFn: async ({ asset_position_id, data, params }) => {
      return updateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut(asset_position_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}