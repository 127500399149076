import client from 'client'
import type {
  GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse,
  GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetPathParams,
  GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryParams,
  GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet422,
} from '../../types/GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey = (
  asset_id: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetPathParams['asset_id'],
  params?: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryParams,
) => [{ url: '/data/asset/:asset_id/time_series/quality', params: { asset_id: asset_id } }, ...(params ? [params] : [])] as const

export type GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey = ReturnType<
  typeof getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey
>

/**
 * @summary Get Asset Timeseries Quality
 * {@link /data/asset/:asset_id/time_series/quality}
 */
export async function getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet(
  asset_id: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetPathParams['asset_id'],
  params?: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse,
    ResponseErrorConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet422>,
    unknown
  >({ method: 'GET', url: `/data/asset/${asset_id}/time_series/quality`, params, ...requestConfig })
  return res
}

export function getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryOptions(
  asset_id: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetPathParams['asset_id'],
  params?: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey(asset_id, params)
  return queryOptions<
    ResponseConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>,
    ResponseErrorConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet422>,
    ResponseConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet(asset_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Timeseries Quality
 * {@link /data/asset/:asset_id/time_series/quality}
 */
export function useGetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet<
  TData = ResponseConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey,
>(
  asset_id: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetPathParams['asset_id'],
  params?: GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>,
        ResponseErrorConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryKey(asset_id, params)

  const query = useQuery({
    ...(getAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryOptions(asset_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}