import { timeFilterEnum } from "kubb";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../Forms/SelectCombo/SelectCombo";

const TimeFilterEnumOptions: Option[] = [
  { label: "All", value: timeFilterEnum.ALL },
  { label: "30 minute", value: timeFilterEnum.MINUTE_30 },
  { label: "Hourly", value: timeFilterEnum.HOURLY },
  { label: "Daily", value: timeFilterEnum.DAILY },
];

type TimeFilterEnumSelectProps = Omit<SelectComboFieldProps, "options">;

function TimeFilterEnumSelect(props: TimeFilterEnumSelectProps) {
  return <SelectComboField {...props} options={TimeFilterEnumOptions} />;
}

export default TimeFilterEnumSelect;
