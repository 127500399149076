import client from 'client'
import type {
  DeleteApiUserUserApiClientIdDeleteMutationResponse,
  DeleteApiUserUserApiClientIdDeletePathParams,
  DeleteApiUserUserApiClientIdDeleteQueryParams,
  DeleteApiUserUserApiClientIdDelete422,
} from '../../types/DeleteApiUserUserApiClientIdDelete.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const deleteApiUserUserApiClientIdDeleteMutationKey = () => [{ url: '/user/api/{client_id}' }] as const

export type DeleteApiUserUserApiClientIdDeleteMutationKey = ReturnType<typeof deleteApiUserUserApiClientIdDeleteMutationKey>

/**
 * @description Deletes an API access key.
 * @summary Delete Api User
 * {@link /user/api/:client_id}
 */
export async function deleteApiUserUserApiClientIdDelete(
  client_id: DeleteApiUserUserApiClientIdDeletePathParams['client_id'],
  params?: DeleteApiUserUserApiClientIdDeleteQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<DeleteApiUserUserApiClientIdDeleteMutationResponse, ResponseErrorConfig<DeleteApiUserUserApiClientIdDelete422>, unknown>({
    method: 'DELETE',
    url: `/user/api/${client_id}`,
    params,
    ...requestConfig,
  })
  return res
}

/**
 * @description Deletes an API access key.
 * @summary Delete Api User
 * {@link /user/api/:client_id}
 */
export function useDeleteApiUserUserApiClientIdDelete(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<DeleteApiUserUserApiClientIdDeleteMutationResponse>,
      ResponseErrorConfig<DeleteApiUserUserApiClientIdDelete422>,
      { client_id: DeleteApiUserUserApiClientIdDeletePathParams['client_id']; params?: DeleteApiUserUserApiClientIdDeleteQueryParams }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiUserUserApiClientIdDeleteMutationKey()

  return useMutation<
    ResponseConfig<DeleteApiUserUserApiClientIdDeleteMutationResponse>,
    ResponseErrorConfig<DeleteApiUserUserApiClientIdDelete422>,
    { client_id: DeleteApiUserUserApiClientIdDeletePathParams['client_id']; params?: DeleteApiUserUserApiClientIdDeleteQueryParams }
  >({
    mutationFn: async ({ client_id, params }) => {
      return deleteApiUserUserApiClientIdDelete(client_id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}