import type { UseQueryResult } from "@tanstack/react-query/src/types.ts";
import type { TypedBulkUpdateDetailed } from "components/BulkUpdate/GenericUpdatesDisplay.tsx";
import type { ResponseConfig } from "../client.ts";
import type { GetDataWrapperResult } from "../components/MutateWrapper.tsx";
import {
  type AssetPosition,
  type AssetRegisterItem,
  type BulkUpdateDetailed,
  type DeviceConfigurationMap,
  type GetStreamingConfigurationStreamingGet200,
  type Location,
  type ReportExecutionOut,
  type StreamingConfiguration,
  type WirelessSurveyOut,
  useGetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet,
  useGetAssetListAssetGet,
  useGetAssetPositionListAssetPositionGet,
  useGetAssetRegisterListAssetRegisterGet,
  useGetDetailedBulkUpdateBulkUpdateIdGet,
  useGetLocationListLocationGet,
  useGetReportJobListReportGet,
  useGetStreamingConfigurationStreamingGet,
  useGetWirelessSurveyListWirelessSurveyGet,
} from "../kubb";
import type { Asset } from "../utils/object.tsx";

function useGetEntityNew<TItem, TData = TItem[]>({
  useQuery,
  singletonApi,
}:
  | {
      useQuery: () => UseQueryResult<ResponseConfig<TData> | undefined, any>;
      singletonApi: false;
      manual?: boolean;
    }
  | {
      useQuery: () => UseQueryResult<ResponseConfig<TItem> | undefined, any>;
      singletonApi: true;
      manual?: boolean;
    }): GetDataWrapperResult<TItem> {
  const result = useQuery();
  /* TODO: better error handling */

  const data = (): TItem | undefined => {
    if (singletonApi) {
      return result.data?.data as TItem | undefined;
    } else {
      if (result.isLoading) {
        return undefined;
      }
      if (result.data?.data && (result.data.data as TItem[]).length === 1) {
        return (result.data.data as TItem[])[0];
      }
      return undefined;
    }
  };

  return {
    loading: result.isLoading,
    data: data(),
    error: result.error,
    refresh: result.refetch,
  };
}

export function useGetAssetPosition(
  asset_position_id: string,
  company_id: string,
): GetDataWrapperResult<AssetPosition> {
  return useGetEntityNew<AssetPosition>({
    useQuery: () =>
      useGetAssetPositionListAssetPositionGet({
        asset_position_id,
        company_id,
      }),
    singletonApi: false,
  });
}

export function useGetAssetRegisterItem(
  company_id: string,
  asset_id?: string,
  asset_position_id?: string,
  scheme_id?: string,
  enabled?: boolean,
): GetDataWrapperResult<AssetRegisterItem> {
  return useGetEntityNew<AssetRegisterItem>({
    useQuery: () =>
      useGetAssetRegisterListAssetRegisterGet(
        {
          asset_id,
          asset_position_id,
          company_id,
          scheme_id,
        },
        { query: { enabled } },
      ),
    singletonApi: false,
  });
}

export function useGetAsset(
  asset_id: string,
  company_id: string,
  enabled?: boolean,
): GetDataWrapperResult<Asset> {
  return useGetEntityNew<Asset>({
    useQuery: () =>
      useGetAssetListAssetGet(
        {
          asset_id,
          company_id,
        },
        {
          query: { enabled },
        },
      ),
    singletonApi: false,
  });
}

export function useGetLocation(
  location_id: string,
  company_id: string,
  scheme_id?: string,
): GetDataWrapperResult<Location> {
  return useGetEntityNew<Location>({
    useQuery: () =>
      useGetLocationListLocationGet({
        location_id,
        scheme_id,
        company_id,
      }),
    singletonApi: false,
  });
}

export function useGetWirelessSurvey(
  surveyId: string,
  company_id: string,
): GetDataWrapperResult<WirelessSurveyOut> {
  return useGetEntityNew<WirelessSurveyOut>({
    useQuery: () =>
      useGetWirelessSurveyListWirelessSurveyGet({
        survey_id: surveyId,
        company_id: company_id,
      }),
    singletonApi: false,
  });
}

export function useGetStreamingConfiguration(
  company_id: string,
): GetDataWrapperResult<StreamingConfiguration> {
  return useGetEntityNew<GetStreamingConfigurationStreamingGet200>({
    useQuery: () =>
      useGetStreamingConfigurationStreamingGet({
        company_id,
      }),
    singletonApi: true,
  });
}

export function useGetBulkUpdate<T>(
  update_id: string,
  company_id: string,
): GetDataWrapperResult<TypedBulkUpdateDetailed<T>> {
  return useGetEntityNew<BulkUpdateDetailed>({
    useQuery: () =>
      useGetDetailedBulkUpdateBulkUpdateIdGet(update_id, {
        company_id,
      }),
    singletonApi: true,
  }) as GetDataWrapperResult<TypedBulkUpdateDetailed<T>>;
}

export function useGetDetailedAssetConfiguration(
  asset_id: string,
  company_id?: string,
): GetDataWrapperResult<DeviceConfigurationMap> {
  return useGetEntityNew<DeviceConfigurationMap>({
    useQuery: () =>
      useGetAssetConfigurationDetailedAssetAssetIdConfigurationDetailedGet(
        asset_id,
        { company_id },
      ),
    singletonApi: true,
  });
}

export function useGetReportExecution(
  report_id: string,
  company_id: string,
  enabled?: boolean,
): GetDataWrapperResult<ReportExecutionOut> {
  return useGetEntityNew<ReportExecutionOut>({
    useQuery: () =>
      useGetReportJobListReportGet(
        {
          report_id,
          company_id,
        },
        { query: { enabled } },
      ),
    singletonApi: false,
  });
}
