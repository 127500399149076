import client from 'client'
import type {
  CreateNewApiUserUserApiPostMutationRequest,
  CreateNewApiUserUserApiPostMutationResponse,
  CreateNewApiUserUserApiPostQueryParams,
  CreateNewApiUserUserApiPost422,
} from '../../types/CreateNewApiUserUserApiPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewApiUserUserApiPostMutationKey = () => [{ url: '/user/api' }] as const

export type CreateNewApiUserUserApiPostMutationKey = ReturnType<typeof createNewApiUserUserApiPostMutationKey>

/**
 * @description Creates a new API access key.
 * @summary Create New Api User
 * {@link /user/api}
 */
export async function createNewApiUserUserApiPost(
  data: CreateNewApiUserUserApiPostMutationRequest,
  params?: CreateNewApiUserUserApiPostQueryParams,
  config: Partial<RequestConfig<CreateNewApiUserUserApiPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewApiUserUserApiPostMutationResponse,
    ResponseErrorConfig<CreateNewApiUserUserApiPost422>,
    CreateNewApiUserUserApiPostMutationRequest
  >({ method: 'POST', url: `/user/api`, params, data, ...requestConfig })
  return res
}

/**
 * @description Creates a new API access key.
 * @summary Create New Api User
 * {@link /user/api}
 */
export function useCreateNewApiUserUserApiPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewApiUserUserApiPostMutationResponse>,
      ResponseErrorConfig<CreateNewApiUserUserApiPost422>,
      { data: CreateNewApiUserUserApiPostMutationRequest; params?: CreateNewApiUserUserApiPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewApiUserUserApiPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewApiUserUserApiPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewApiUserUserApiPostMutationResponse>,
    ResponseErrorConfig<CreateNewApiUserUserApiPost422>,
    { data: CreateNewApiUserUserApiPostMutationRequest; params?: CreateNewApiUserUserApiPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewApiUserUserApiPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}