import clsx from "clsx";
import { useField } from "formik";
import type { AssetTypeEnum } from "kubb";
import { type Cursor, useGetAssetListAssetGet } from "kubb";
import { useState } from "react";
import ApiPaginatedTable from "../../../components/Pagination/NewApiPaginatedTable";
import { Button } from "../../../components/Theme/button";
import {
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/Theme/table";
import type { Asset } from "../../../utils/object.tsx";

function AssetSelectFormField(props: {
  name: string;
  assetType: AssetTypeEnum[];
  company_id: string;
  unassignedOnly?: boolean;
}) {
  const [field, _meta, { setValue }] = useField(props.name);

  const { unassignedOnly = true } = props;
  const [cursor, setCursor] = useState<Cursor>({ max_items: 10 });

  const { data, isPending, error } = useGetAssetListAssetGet({
    company_id: props.company_id,
    asset_type: props.assetType,
    asset_position_id: unassignedOnly ? "NONE" : undefined,
    ...cursor,
  });

  function onClick(asset_id: string) {
    setValue(asset_id);
  }

  return (
    <ApiPaginatedTable
      loading={isPending}
      error={error}
      data={{
        data: data?.data || [],
        cursor: data?.headers["x-cursor"] || { max_items: 10 },
        setCursor: (c) => {
          if (c) setCursor(c);
        },
      }}
      header={[
        <TableRow key={1}>
          <TableHeader>Serial Number</TableHeader>
          <TableHeader>Manufacturer</TableHeader>
          <TableHeader>&nbsp;</TableHeader>
        </TableRow>,
        3,
      ]}
      row={(asset: Asset, _) => (
        <TableRow
          key={asset.asset_id}
          className={clsx(
            field.value === asset.asset_id && "bg-brand-light-200",
          )}
        >
          <TableCell>{asset.serial_number}</TableCell>
          <TableCell>{asset.manufacturer}</TableCell>
          <TableCell>
            <Button
              color={"brandLight"}
              onClick={() => onClick(asset.asset_id)}
              size={"sm"}
            >
              Select
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default AssetSelectFormField;
