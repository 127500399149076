export const gatewayConfigurationStateEnum = {
  IN_SYNC: 'IN_SYNC',
  FACTORY_RESETTING: 'FACTORY_RESETTING',
  UPDATING_CONFIGURATION: 'UPDATING_CONFIGURATION',
  UPDATING_DEVICE_LIST: 'UPDATING_DEVICE_LIST',
  UPGRADING_FIRMWARE: 'UPGRADING_FIRMWARE',
  ERROR_UPDATING_CONFIGURATION: 'ERROR_UPDATING_CONFIGURATION',
  ERROR_UPDATING_DEVICE_LIST: 'ERROR_UPDATING_DEVICE_LIST',
  ERROR_UPGRADING_FIRMWARE: 'ERROR_UPGRADING_FIRMWARE',
  UNKNOWN: 'UNKNOWN',
  MBUS_SEARCH: 'MBUS_SEARCH',
} as const

export type GatewayConfigurationStateEnum = (typeof gatewayConfigurationStateEnum)[keyof typeof gatewayConfigurationStateEnum]

export type GatewayConfigurationState = GatewayConfigurationStateEnum