import { Form, Formik, type FormikHelpers } from "formik";
import type { ExpandedNote, Note } from "kubb";
import type { AssetPosition, Status } from "kubb";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import {
  yupRequiredString,
  yupString,
} from "../../components/Forms/yupValidators";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash-es";
import AlertBanner from "../../components/Banner/AlertBanner";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import { Badge } from "../../components/Theme/badge";
import { Button } from "../../components/Theme/button";
import { useCreateNote } from "../../hooks/createEntity.tsx";
import { useUpdateNote } from "../../hooks/updateEntity";

interface Props {
  assetPosition?: AssetPosition;
  onCreate?: () => void;
  onUpdate?: (note: Note) => void;
  note?: Note | ExpandedNote;
}

interface FormValues {
  title: string;
  content?: string;
  status?: Status;
}

export default function CreateNoteForm(props: Props): React.ReactElement {
  const { assetPosition, note } = props;

  const existing = !isNil(props.note);

  const update = note && useUpdateNote(note);
  const create = useCreateNote();
  const [editing, setEditing] = useState(false);

  const [initialValues, setInitialValues] = useState<Note | undefined>(
    props.note,
  );

  const [editSuccess, setEditSuccess] = useState(false);

  const viewMode = existing && !editing;

  function submit(
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(true);

    if (update) {
      setEditSuccess(false);
      update
        .update({ ...note, ...values })
        .then(() => {
          setInitialValues({ ...initialValues, ...values } as Note);
          props.onUpdate?.({ ...note, ...values } as Note);
          setEditing(false);
          setEditSuccess(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      if (assetPosition) {
        create
          .update({
            company_id: assetPosition.company_id,
            scheme_id: assetPosition.scheme_id,
            asset_position_id: assetPosition.asset_position_id,
            title: values.title,
            content: values.content,
          })
          .then((result) => {
            console.log(result);
            resetForm();
            props.onCreate?.();
          })
          .finally(() => setSubmitting(false));
      } else {
        throw new Error("Unable to create note. Requires an asset position.");
      }
    }
  }

  return (
    <Formik
      initialValues={
        {
          title: initialValues ? initialValues.title : "",
          content: initialValues ? initialValues.content : "",
          status: initialValues ? initialValues.status : undefined,
        } as FormValues
      }
      validationSchema={Yup.object({
        title: yupRequiredString,
        content: yupString,
      })}
      onSubmit={submit}
      onReset={() => {
        if (existing) {
          setEditing(false);
        }
      }}
      enableReinitialize={true}
    >
      {({
        isSubmitting,
        isValid,
        dirty,
        resetForm,
        submitForm,
        setFieldValue,
        values,
      }) => (
        <Form>
          {values.status === "DELETED" && (
            <Badge color={"danger"}>DELETED</Badge>
          )}
          <NewTextFormComponent
            fieldName={"title"}
            label={{ label: "Title" }}
            disabled={viewMode}
          />
          <NewTextFormComponent
            fieldName={"content"}
            type={"textarea"}
            label={{ label: "Detail" }}
            className={"h-60"}
          />
          {!props.note ? (
            <Button
              type="submit"
              color="brandLight"
              disabled={isSubmitting || !isValid}
            >
              Add
            </Button>
          ) : editing ? (
            <>
              <Button
                type="submit"
                color="brandLight"
                disabled={isSubmitting || !isValid || !dirty}
              >
                Update
              </Button>
              <Button type="reset" color="red" disabled={isSubmitting}>
                Cancel
              </Button>
            </>
          ) : (
            values.status !== "DELETED" && (
              <>
                <Button
                  type={"button"}
                  color={"brandLight"}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type={"button"}
                  color={"red"}
                  onClick={() => {
                    resetForm();
                    setFieldValue("status", "DELETED");
                    submitForm();
                  }}
                >
                  Delete
                </Button>
              </>
            )
          )}
          <ErrorBanner error={update ? update.error : create.error} />
          {editSuccess && (
            <AlertBanner className={"mt-1 bg-success"}>
              <FontAwesomeIcon icon={faCheck} fixedWidth />
              Note successfully updated.
            </AlertBanner>
          )}
        </Form>
      )}
    </Formik>
  );
}
