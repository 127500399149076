import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import NumberOfAssetPositionsDisplayWidget from "components/Common/NumberOfAssetPositionsDisplayWidget";
import PrivacyMode from "components/Text/PrivacyMode";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/Theme/table";
import UncontrolledTooltip from "components/Theme/tooltip";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import type { Scheme } from "kubb";
import type React from "react";
import { useContext } from "react";
import { NavLink } from "react-router";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { getUrlHash } from "../../../components/navigation/URL";
import { useSelectedCompany } from "../../../reducers/company";
import { usePrivacyMode } from "../../../reducers/privacy";
import { SchemeContext } from "../../../reducers/scheme";

const SchemesList = (): React.ReactElement => {
  const company = useSelectedCompany();
  const privacy = usePrivacyMode();

  const { state } = useContext(SchemeContext);
  const { selected, loading, schemes = [] } = state;

  const header = (
    <TableHead>
      <TableRow>
        <TableHeader>ID</TableHeader>
        <TableHeader>Name</TableHeader>
        <TableHeader>Location</TableHeader>
        <TableHeader>No. of Asset Positions</TableHeader>
        <TableHeader>No. of Locations</TableHeader>
        <TableHeader>&nbsp;</TableHeader>
      </TableRow>
    </TableHead>
  );

  const row = (value: Scheme, index: number): React.ReactElement => {
    const isSelected =
      value.scheme_id === selected?.scheme_id &&
      value.company_id === selected.company_id;
    return (
      <TableRow
        key={index}
        data-cy="schemes-list-item"
        className={clsx({
          "bg-brand-light-200": isSelected,
        })}
      >
        <TableCell data-cy="scheme-name">
          <PrivacyMode>{value.scheme_id}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="scheme-name">
          <PrivacyMode>{value.scheme_name}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="scheme-location">
          <PrivacyMode>{value.location}</PrivacyMode>
        </TableCell>
        <TableCell data-cy="scheme-devices">
          <NumberOfAssetPositionsDisplayWidget
            positions={value.number_of_asset_positions}
            index={index}
          />
        </TableCell>
        <TableCell data-cy="scheme-locations">
          {value.number_of_locations}
        </TableCell>
        <TableCell className="table-actions">
          {!isSelected && (
            <UncontrolledTooltip
              message={
                <>
                  Select <PrivacyMode>{value.scheme_name}</PrivacyMode>
                </>
              }
            >
              <NavLink
                className="table-action table-action-delete"
                to={{ hash: getUrlHash(company, value, privacy) }}
                id={`tooltip-select-company-${index}`}
              >
                <FontAwesomeIcon icon={faHandPointUp} />
              </NavLink>
            </UncontrolledTooltip>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BlockSpinner loading={loading}>
      <Table data-cy={"scheme-list"}>
        {header}
        {schemes.length > 0 ? (
          <TableBody>{schemes.map(row)}</TableBody>
        ) : (
          <TableBody data-cy={"no-data"}>
            <TableRow>
              <TableCell colSpan={3}>No schemes</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>

      {
        <div className={"flex gap-2"}>
          <DisplayIfUserType userTypes={"admin"}>
            <CompanyNavLink
              button={{
                color: "brandLight",
              }}
              to={{ pathname: "/admin/schemes/create" }}
            >
              Create New
            </CompanyNavLink>
          </DisplayIfUserType>
        </div>
      }
    </BlockSpinner>
  );
};

export default SchemesList;
