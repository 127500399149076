import type { AxiosError } from "axios";
import { Heading } from "components/Theme/heading";
import type React from "react";
import type { TypedError } from "../../model/error";
import AlertBanner from "../Banner/AlertBanner";
import { errorToTitleAndText } from "./errorMessages";

export const ErrorBanner = (props: {
  error?: Error | AxiosError | TypedError;
}): React.ReactElement => {
  const { error } = props;
  console.log(error);
  if (error) {
    const { text, title } = errorToTitleAndText(error);
    return (
      <AlertBanner className={"my-2 bg-red-700"}>
        <Heading>Error</Heading>
        {title && <b>{title}:</b>}
        {text}
      </AlertBanner>
    );
  } else {
    return <></>;
  }
};
