import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createSearchString } from "../../../components/navigation/SearchString";
import type { Asset } from "../../../utils/object.tsx";
import AssetDataSelectionForm, {
  type AssetTimeSeriesRequestParams,
} from "./meters/AssetDataSelectionForm";

interface Props extends AssetTimeSeriesRequestParams {
  device: Asset;
  showProjection?: boolean;
}

const AssetDataSelectorUrlQuerySetter = (props: Props): React.ReactElement => {
  const { showProjection = true } = props;
  const initialValues = (): AssetTimeSeriesRequestParams => {
    const { start_datetime, end_datetime, projection } = props;
    return { start_datetime, end_datetime, projection };
  };
  const navigate = useNavigate();
  const location = useLocation();

  const selectData = (values: AssetTimeSeriesRequestParams): void => {
    const { projection, start_datetime, end_datetime } = values;
    navigate({
      search: createSearchString({
        start_datetime: start_datetime?.format(),
        end_datetime: end_datetime?.format(),
        projection,
      }),
      hash: location.hash,
    });
  };

  return (
    <AssetDataSelectionForm
      {...initialValues()}
      device={props.device}
      loadData={selectData}
      showProjection={showProjection}
    />
  );
};

export default AssetDataSelectorUrlQuerySetter;
