import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FormikProps } from "formik";
import type { AssetPositionAssetLinkOut } from "kubb";
import type React from "react";
import { useRef } from "react";
import EditModal from "../../../../components/Forms/EditModal";
import { Button } from "../../../../components/Theme/button";
import type { MutateEntityWrapperResult } from "../../../../hooks/updateEntity";
import AssetPositionAssetLinkForm from "./AssetPositionAssetLinkForm";

function AssetPositionAssetUpdateModal(props: {
  link: AssetPositionAssetLinkOut;
  update: MutateEntityWrapperResult<
    AssetPositionAssetLinkOut,
    AssetPositionAssetLinkOut
  >;
  postUpdate?: () => void;
  buttonContent?: React.ReactNode;
  className?: string;
}) {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  const {
    buttonContent = (
      <span>
        <FontAwesomeIcon icon={faEdit} fixedWidth />
        Edit
      </span>
    ),
  } = props;

  const [setModalOpen, modal] = EditModal<
    AssetPositionAssetLinkOut,
    AssetPositionAssetLinkOut
  >({
    setModalFocus,
    values: props.link,
    children: (formikProps: FormikProps<AssetPositionAssetLinkOut>) => (
      <AssetPositionAssetLinkForm {...formikProps} />
    ),
    title: "Edit Link",
    update: props.update,
    postUpdate: props.postUpdate,
  });

  return (
    <>
      {modal}
      <Button
        onClick={setModalOpen}
        color={"brandLight"}
        className={props.className}
        size={"sm"}
        paddingY={1.5}
      >
        {buttonContent}
      </Button>
    </>
  );
}

export default AssetPositionAssetUpdateModal;
