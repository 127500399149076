import type React from "react";
import { useRef } from "react";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import EditModal from "../../components/Forms/EditModal";
import { useUpdateUserCompanyLink } from "../../hooks/updateEntity";
import type { CompanyUser } from "./users";

const EditUserTypeModel = (
  userCompany: CompanyUser,
  postUpdate?: () => void,
): [() => void, React.ReactElement] => {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  return EditModal({
    title: "Update User",
    values: userCompany,
    update: useUpdateUserCompanyLink(userCompany),
    postUpdate: postUpdate,
    size: "lg",
    children: () => (
      <div>
        <h6>Change User Type</h6>
        <ButtonGroupField
          fieldName={"user_type"}
          label={{ label: "User type" }}
          options={[
            { label: "admin", value: "admin" },
            { label: "editor", value: "editor" },
            { label: "viewer", value: "viewer" },
            { label: "none", value: "none" },
          ]}
        />
      </div>
    ),
    setModalFocus,
  });
};

export default EditUserTypeModel;
