import client from 'client'
import type {
  CreateBulkUpdateBulkPostMutationRequest,
  CreateBulkUpdateBulkPostMutationResponse,
  CreateBulkUpdateBulkPostQueryParams,
  CreateBulkUpdateBulkPost422,
} from '../../types/CreateBulkUpdateBulkPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createBulkUpdateBulkPostMutationKey = () => [{ url: '/bulk' }] as const

export type CreateBulkUpdateBulkPostMutationKey = ReturnType<typeof createBulkUpdateBulkPostMutationKey>

/**
 * @summary Create Bulk Update
 * {@link /bulk}
 */
export async function createBulkUpdateBulkPost(
  data?: CreateBulkUpdateBulkPostMutationRequest,
  params?: CreateBulkUpdateBulkPostQueryParams,
  config: Partial<RequestConfig<CreateBulkUpdateBulkPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateBulkUpdateBulkPostMutationResponse,
    ResponseErrorConfig<CreateBulkUpdateBulkPost422>,
    CreateBulkUpdateBulkPostMutationRequest
  >({ method: 'POST', url: `/bulk`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Bulk Update
 * {@link /bulk}
 */
export function useCreateBulkUpdateBulkPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateBulkUpdateBulkPostMutationResponse>,
      ResponseErrorConfig<CreateBulkUpdateBulkPost422>,
      { data?: CreateBulkUpdateBulkPostMutationRequest; params?: CreateBulkUpdateBulkPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateBulkUpdateBulkPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createBulkUpdateBulkPostMutationKey()

  return useMutation<
    ResponseConfig<CreateBulkUpdateBulkPostMutationResponse>,
    ResponseErrorConfig<CreateBulkUpdateBulkPost422>,
    { data?: CreateBulkUpdateBulkPostMutationRequest; params?: CreateBulkUpdateBulkPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createBulkUpdateBulkPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}