import type { Dayjs } from "dayjs";
import {
  type GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse,
  useGetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet,
} from "kubb";
import type React from "react";
import { useGetDataNew } from "../../../../hooks/getData";
import {
  type DeviceTimeSeriesData,
  convertTimesToDayjs,
  mapColumns,
} from "../../../../model/deviceTimeSeries";
import type { Asset } from "../../../../utils/object.tsx";
import type { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import type {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./model";

interface Props {
  device: Asset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesQualityGetter = (props: Props): React.ReactElement => {
  const { device, searchParams } = props;
  const { asset_id, company_id, scheme_id } = device;

  const { data, loading, error } =
    useGetDataNew<GetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGetQueryResponse>(
      () =>
        useGetAssetTimeseriesQualityDataAssetAssetIdTimeSeriesQualityGet(
          asset_id,
          {
            start_datetime: searchParams.start_datetime?.format(),
            end_datetime: searchParams.end_datetime?.format(),
            company_id,
          },
        ),
    );

  const timeseries: DeviceTimeSeriesData | undefined = data && {
    ...data,
    times: convertTimesToDayjs(data.times),
    errors: [],
  };

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(timeseries),
        times: timeseries.times as Dayjs[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
};

export default AssetTimeSeriesQualityGetter;
