import { Form, Formik } from "formik";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import type { WirelessSurveyOut } from "kubb";
import type { WirelessSurveySamplePointOutput } from "kubb";
import type { WirelessSurveyTarget } from "kubb";
import { useState } from "react";
import DataTable, {
  type ConditionalStyles,
  type TableColumn,
} from "react-data-table-component";
import tailwindConfig from "tailwind-config";
import resolveConfig from "tailwindcss/resolveConfig";
import AutoSubmit from "../../../components/Forms/AutoSubmit";
import { ButtonGroupField } from "../../../components/Forms/ButtonGroup";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";
import type {
  WirelessDataSurveyMap,
  WirelessDataSurveyMapItem,
  WirelessSurveyDataPoint,
} from "../../../model/assets/wirelessSurveyData";

const fullConfig = resolveConfig(tailwindConfig);

interface TableData {
  serial_number: string;
  data: WirelessDataSurveyMapItem;
  found: boolean;
  target?: WirelessSurveyTarget;
  sortOrder?: number;
}

function compareTableData(a: TableData, b: TableData): number {
  if ((a.sortOrder || 0) > (b.sortOrder || 0)) {
    return 1;
  }
  if ((a.sortOrder || 0) < (b.sortOrder || 0)) {
    return -1;
  }
  return 0;
}

function propsToTableData(
  surveyMap: WirelessDataSurveyMap,
  filter: string,
  serial_numberTargetsMap: { [serial_number: string]: WirelessSurveyTarget },
): TableData[] {
  const surveyMapCopy = new Map([...surveyMap.entries()]);
  let maxSortOrder = 0;
  if (filter === "targets" || filter === "all") {
    /* add targets that have not been received */
    const receivedSerials: string[] = [...surveyMap.entries()].map(
      (v) => v[1].serial_number,
    );

    for (const [serial, target] of Object.entries(serial_numberTargetsMap)) {
      if (receivedSerials.indexOf(serial) === -1) {
        surveyMapCopy.set(serial, {
          serial_number: serial,
          manufacturer: "",
          version: "",
          medium: "",
          dataCollectorDataMap: new Map(),
          dataCollectorSamplePointMap: new Map(),
        });
      }
      if (target.sort_order && target.sort_order > maxSortOrder) {
        maxSortOrder = target.sort_order;
      }
    }
  }
  const toSortNumber = (
    serial_number: string,
    target?: WirelessSurveyTarget,
  ): number => {
    return target?.sort_order
      ? target.sort_order
      : target !== undefined
        ? maxSortOrder + Number.parseInt(serial_number) / 100000000
        : maxSortOrder + 1 + Number.parseInt(serial_number) / 100000000;
  };

  return [...surveyMapCopy.entries()]
    .map((v) => {
      return {
        serial_number: v[0],
        data: v[1],
        found: isDataReceived(v[1].dataCollectorDataMap),
        target: serial_numberTargetsMap[v[1].serial_number],
        sortOrder: toSortNumber(
          v[1].serial_number,
          serial_numberTargetsMap[v[1].serial_number],
        ),
      } as TableData;
    })
    .sort(compareTableData)
    .filter((v) => {
      switch (filter) {
        case "received":
          return v.found;
        case "targets":
          return serial_numberTargetsMap[v.data.serial_number] !== undefined;
        default:
          return true;
      }
    });
}

function isDataReceived(data: Map<string, WirelessSurveyDataPoint[]>): boolean {
  return data.size !== 0;
}

function meanRssi(data: WirelessSurveyDataPoint[]) {
  return Math.round(
    data.reduce((p, c) => p + Number.parseInt(c.rssi), 0) / data.length,
  );
}

function samplePointRow(
  dataCollector: WirelessSurveyDataCollectorOut,
  samplePoint?: WirelessSurveySamplePointOutput,
) {
  return {
    name: (
      <div className={"container px-0"}>
        <div>{samplePoint?.sample_point_name || "-"}</div>
        <div>
          <small>
            ({dataCollector.data_collector_name} {dataCollector.serial_number})
          </small>
        </div>
      </div>
    ),
    cell: (row: TableData) => {
      const data =
        row.data.dataCollectorSamplePointMap.get(
          `${dataCollector.data_collector_id}-${samplePoint?.sample_point_name || "NONE"}`,
        ) || [];
      const rssi = meanRssi(data);
      return (
        <>
          {data?.length > 0 ? (
            <>
              {data.length}&nbsp;&nbsp;<small>({rssi})</small>
            </>
          ) : (
            <>-</>
          )}
        </>
      );
    },
  };
}

export default function WirelessSurveySummaryTable(props: {
  survey: WirelessSurveyOut;
  dataCollectors: WirelessSurveyDataCollectorOut[];
  wirelessDataSurveyMappedData: WirelessDataSurveyMap;
  targets?: WirelessSurveyTarget[];
}) {
  const haveTargets = props.targets && props.targets.length > 0;

  const [filter, setFilter] = useState<string>("all");

  const serial_numberTargetsMap: {
    [serial_number: string]: WirelessSurveyTarget;
  } =
    props.targets?.reduce((c, v) => {
      return { ...c, [v.serial_number]: v };
    }, {}) || {};

  const data: TableData[] = propsToTableData(
    props.wirelessDataSurveyMappedData,
    filter,
    serial_numberTargetsMap,
  );

  const columns: TableColumn<TableData>[] = [
    {
      name: "Target ID",
      compact: true,
      selector: (row) => row.sortOrder || 0,
      cell: (row) => row.target?.sort_order || "-",
      omit: !haveTargets,
      sortable: true,
    },
    {
      name: "Label",
      compact: true,
      selector: (row) => row.target?.label || "",
      omit: !haveTargets,
    },
    {
      name: "Serial",
      compact: true,
      selector: (row) => row.data.serial_number,
      sortable: true,
    },
    {
      name: "Manufacturer",
      compact: true,
      selector: (row) => row.data.manufacturer,
      sortable: true,
    },
    { name: "Medium", compact: true, selector: (row) => row.data.medium },
    { name: "Version", compact: true, selector: (row) => row.data.version },
    ...(props.dataCollectors || []).flatMap((dataCollector) => {
      return [
        ...(dataCollector.sample_points || []).map((samplePoint) =>
          samplePointRow(dataCollector, samplePoint),
        ),
        samplePointRow(dataCollector),
      ];
    }),
  ];
  const conditionalRowStyles: ConditionalStyles<TableData>[] = props.targets
    ? [
        {
          when: (row: TableData) =>
            serial_numberTargetsMap[row.data.serial_number] != null &&
            row.found,
          style: {
            color: fullConfig.theme.colors.gray["200"],
            backgroundColor: fullConfig.theme.colors.success,
          },
        },
        {
          when: (row: TableData) => !row.found,
          style: {
            color: fullConfig.theme.colors.gray["200"],
            backgroundColor: fullConfig.theme.colors.danger,
          },
        },
      ]
    : [];
  const dataCollectors = (props.dataCollectors || []).flatMap(
    (dataCollector) => {
      return [
        ...(dataCollector.sample_points || []).map(
          (samplePoint) =>
            `${dataCollector.data_collector_id}-${samplePoint.sample_point_name}`,
        ),
        `${dataCollector.data_collector_id}-NONE`,
      ];
    },
  );
  return (
    <>
      {haveTargets && (
        <Formik
          initialValues={{ targets: filter }}
          onSubmit={(values) => setFilter(values.targets)}
          enableReinitialize={true}
        >
          {() => (
            <>
              <AutoSubmit />
              <Form>
                <ButtonGroupField
                  fieldName={"targets"}
                  label={{ label: "Filter" }}
                  options={[
                    { value: "all", label: "Both" },
                    { value: "targets", label: "Only targets" },
                    { value: "received", label: "Only received" },
                  ]}
                />
              </Form>
            </>
          )}
        </Formik>
      )}
      <CSVExportButton
        download={() => {
          return Promise.resolve(
            data.map((v) => {
              return {
                target_label: v.target?.label,
                target_serial_number: v.target?.serial_number,
                target_sort_order: v.target?.sort_order || 0,
                serial_number: v.data.serial_number,
                manufacturer: v.data.manufacturer,
                medium: v.data.medium,
                version: v.data.version,
                ...Object.fromEntries(
                  dataCollectors.flatMap((key) => {
                    return [
                      [
                        `${key}-count`,
                        v.data.dataCollectorSamplePointMap.get(key)?.length ||
                          0,
                      ],
                      [
                        `${key}-rssi`,
                        meanRssi(
                          v.data.dataCollectorSamplePointMap.get(key) || [],
                        ) || "",
                      ],
                    ];
                  }),
                ),
              };
            }),
          );
        }}
        columnOrder={[
          "target_label",
          "target_serial_number",
          "target_sort_order",
          "serial_number",
          "manufacturer",
          "medium",
          "version",
        ]}
        filename={`wireless_survey_${props.survey.survey_name}_summary.csv`}
      />
      <DataTable
        columns={columns}
        data={data}
        dense={true}
        pagination={true}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[25, 50, 100, 250]}
        fixedHeader={true}
        fixedHeaderScrollHeight={"600px"}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
}
