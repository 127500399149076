import SelectedScheme from "components/Scheme/SelectedScheme";
import { OneCardPage } from "components/utils/OneCardPage";
import { assetRegisterSortEnum, statusEnum } from "kubb";
import AssetListFilterForm from "pages/assetRegister/assets/list/AssetListFilterForm";
import type React from "react";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { AssetRegisterListSearchParam } from "../assets/list/AssetListSearchParam.tsx";
import AssetRegisterList from "./AssetRegisterList";

const InnerAssetRegisterList = (props: any): React.ReactElement => {
  return (
    <SelectedScheme>
      {(schemeProps) => {
        return (
          <>
            <AssetListFilterForm
              includeNonCreateables={false}
              filterUnassignedField={"asset_id"}
              includeTextSearch={true}
              {...props}
            />
            <AssetRegisterList {...props} {...schemeProps} />
          </>
        );
      }}
    </SelectedScheme>
  );
};

const AssetPositionListPage = (): React.ReactElement => {
  return (
    <OneCardPage
      headerTitle={"Asset Register"}
      breadcrumbs={[{ url: "/admin/asset_register", name: "Asset Register" }]}
      setPageTitle={schemeObjectPageTitle("Asset Register")}
    >
      <AssetRegisterListSearchParam
        initialState={{
          asset_type: undefined,
          asset_id: undefined,
          text_search: undefined,
          sort: assetRegisterSortEnum["location.address"],
          status: [statusEnum.ACTIVE],
        }}
      >
        <InnerAssetRegisterList />
      </AssetRegisterListSearchParam>
    </OneCardPage>
  );
};

export default AssetPositionListPage;
