import client from 'client'
import type {
  UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest,
  UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse,
  UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams,
  UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams,
  UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams,
  UpdateAssetPositionAssetPositionAssetPositionIdPut422,
} from '../../types/UpdateAssetPositionAssetPositionAssetPositionIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateAssetPositionAssetPositionAssetPositionIdPutMutationKey = () => [{ url: '/asset_position/{asset_position_id}' }] as const

export type UpdateAssetPositionAssetPositionAssetPositionIdPutMutationKey = ReturnType<typeof updateAssetPositionAssetPositionAssetPositionIdPutMutationKey>

/**
 * @summary Update Asset Position
 * {@link /asset_position/:asset_position_id}
 */
export async function updateAssetPositionAssetPositionAssetPositionIdPut(
  asset_position_id: UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams['asset_position_id'],
  data: UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest,
  params?: UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams,
  headers?: UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams,
  config: Partial<RequestConfig<UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse,
    ResponseErrorConfig<UpdateAssetPositionAssetPositionAssetPositionIdPut422>,
    UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest
  >({ method: 'PUT', url: `/asset_position/${asset_position_id}`, params, data, headers: { ...headers, ...requestConfig.headers }, ...requestConfig })
  return res
}

/**
 * @summary Update Asset Position
 * {@link /asset_position/:asset_position_id}
 */
export function useUpdateAssetPositionAssetPositionAssetPositionIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse>,
      ResponseErrorConfig<UpdateAssetPositionAssetPositionAssetPositionIdPut422>,
      {
        asset_position_id: UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams['asset_position_id']
        data: UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest
        params?: UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams
        headers?: UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams
      }
    >
    client?: Partial<RequestConfig<UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateAssetPositionAssetPositionAssetPositionIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse>,
    ResponseErrorConfig<UpdateAssetPositionAssetPositionAssetPositionIdPut422>,
    {
      asset_position_id: UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams['asset_position_id']
      data: UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest
      params?: UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams
      headers?: UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams
    }
  >({
    mutationFn: async ({ asset_position_id, data, params, headers }) => {
      return updateAssetPositionAssetPositionAssetPositionIdPut(asset_position_id, data, params, headers, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}