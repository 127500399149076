import type React from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import GetAssetRegisterEntitiesCombination from "../../../core/action/GetAssetRegisterEntitiesCombination";
import { NotFoundErrorForEntity } from "../../../model/error";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";
import AssetDisplay from "./AssetDisplay";

const AssetHomepage = (): React.ReactElement => {
  const { asset_id } = useParams<{ asset_id: string }>();
  if (asset_id === undefined) {
    throw new Error("asset_id must be set");
  }
  const company_id = useSelectedCompany()?.company_id || "NONE";
  const scheme_id = useSelectedScheme()?.scheme_id;

  return (
    <GetAssetRegisterEntitiesCombination
      asset_id={asset_id}
      company_id={company_id}
      scheme_id={scheme_id}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Asset Detail"}
          breadcrumbs={[{ name: "Error" }]}
          setPageTitle={() => "Asset Error"}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Asset Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
          setPageTitle={() => "Asset Loading"}
        />
      )}
    >
      {({ asset, ...rest }) =>
        asset ? (
          <AssetDisplay {...rest} asset={asset} />
        ) : (
          <OneCardPage
            headerTitle={"Asset Detail"}
            breadcrumbs={[{ name: "Error" }]}
            setPageTitle={() => "Asset Error"}
          >
            <ErrorBanner error={NotFoundErrorForEntity("Asset", asset_id)} />
          </OneCardPage>
        )
      }
    </GetAssetRegisterEntitiesCombination>
  );
};

export default AssetHomepage;
