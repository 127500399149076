import type { Dayjs } from "dayjs";
import type { AssetRegisterAggregatedTimeSeriesResponse } from "kubb";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";

interface Props {
  scheme_id?: string;
  startDatetime: Dayjs;
  endDatetime: Dayjs;
  data?: AssetRegisterAggregatedTimeSeriesResponse;
}

function VwadtReportView(props: Props) {
  const { startDatetime, endDatetime } = props;

  return (
    <>
      {props.scheme_id && props.data && (
        <AllPeriodReportTable
          data={props.data}
          columnDefinitions={[
            {
              parameter: "Energy (Heating)/Volume",
              aggregation: "difference",
              name: "Energy:Volume Ratio",
            },
            {
              parameter: "Volume",
              aggregation: "difference",
              name: "Volume",
            },
          ]}
          startDatetime={startDatetime}
          endDatetime={endDatetime}
          downloadFilename={`${props.scheme_id}_vwadt_report`}
        />
      )}
    </>
  );
}

export default VwadtReportView;
