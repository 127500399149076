import { OneCardPage } from "components/utils/OneCardPage";
import dayjs from "dayjs";
import type { AggregatedReportRequest, AssetType } from "kubb";
import type { AggregationDefinition } from "kubb";
import type { CumulativeDataConfigurationInput } from "kubb";
import type React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { inclusiveDatePeriod } from "../../../components/Forms/DateTimePicker";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import { matchPeriod } from "../../../components/TimeSeries/model";
import {
  createSearchString,
  parseSearchString,
} from "../../../components/navigation/SearchString";
import { schemeObjectPageTitle } from "../../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../../reducers/company";
import { useSelectedSchemeId } from "../../../reducers/scheme";
import { BillingConfigurationInitialValues } from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import BillingReportSelectorForm, {
  type BillingReportRequestParams,
} from "./BillingReportSelectorForm";
import BillingReportView from "./BillingReportView";

const BillingReportPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const company_id = useRequiredSelectedCompanyId();
  const scheme_id = useSelectedSchemeId();

  const searchStringToParams = (): Required<BillingReportRequestParams> => {
    const [startDate, endDate] = inclusiveDatePeriod(undefined, undefined, 7);
    const searchParams = parseSearchString(location.search);
    const start_time = searchParams.start_time
      ? dayjs(searchParams.start_time as string)
      : startDate;
    const end_time = searchParams.start_time
      ? dayjs(searchParams.end_time as string)
      : dayjs(+endDate);
    const period = matchPeriod(searchParams.period as string);
    return {
      start_time,
      end_time,
      period,
      configuration: {
        ...BillingConfigurationInitialValues(false).configuration,
        ...(searchParams?.configuration as unknown as CumulativeDataConfigurationInput),
      },
    };
  };

  const selectData = (values: BillingReportRequestParams): void => {
    if (values.end_time) {
      const [start_time, end_time] = [
        values.start_time.format(),
        values.end_time.format(),
      ];
      navigate({
        search: createSearchString({
          start_time,
          end_time,
          period: values.period,
          configuration: values.configuration,
        }),
        hash: location.hash,
      });
    }
  };

  const { start_time, end_time, period, configuration } =
    searchStringToParams();
  const request: AggregatedReportRequest | undefined =
    company_id && scheme_id
      ? {
          company_id: company_id,
          scheme_id: scheme_id,
          asset_types: configuration.included_asset_types?.map((x) =>
            (x as string).toLowerCase(),
          ) as Array<unknown> as Array<AssetType>,
          aggregations: [
            "Energy (Heating)",
            "Energy (Cooling)",
            "Energy (Electrical Active Import)",
          ]
            .map((v): AggregationDefinition => {
              return {
                parameter: v,
                aggregation: "last",
                ...configuration.energy,
              };
            })
            .concat([
              {
                parameter: "Volume",
                aggregation: "last",
                ...configuration.volume,
              },
            ]),
          period,
          start_time: start_time.toISOString(),
          end_time: end_time.toISOString(),
          data_label: configuration.period_label as unknown as "left" | "right",
        }
      : undefined;
  console.log(request);
  const queryParams = searchStringToParams();
  return (
    <OneCardPage
      headerTitle={"Billing Data Report"}
      breadcrumbs={[
        { url: "/admin/report/billing/data", name: "Billing Data" },
      ]}
      setPageTitle={schemeObjectPageTitle("Billing Data")}
    >
      <RequireScheme>
        <BillingReportSelectorForm {...queryParams} action={selectData} />
        {request && (
          <AggregatedTimeSeriesApiWrapper request={request}>
            {({ data }) => (
              <>
                {data && scheme_id && (
                  <BillingReportView
                    scheme_id={scheme_id}
                    data={data}
                    query={queryParams}
                  />
                )}
              </>
            )}
          </AggregatedTimeSeriesApiWrapper>
        )}
      </RequireScheme>
    </OneCardPage>
  );
};

export default BillingReportPage;
