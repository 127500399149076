import type { FormikProps } from "formik";
import {
  type AssetPosition,
  type AssetPositionUpdateIn,
  type AssetType,
  type AssetTypeEnum,
  type DeviceAssetUpdateIn,
  type GatewayAssetUpdateIn,
  assetTypeEnum,
} from "kubb";
import { isNil } from "lodash-es";
import React, { useRef } from "react";
import AssetUseCaseSelect from "../../../components/Asset/AssetUseCaseSelect";
import EditModal, {
  type EditModalProps,
} from "../../../components/Forms/EditModal";
import type { Asset } from "../../../utils/object.tsx";
import AssetTypeSelect from "./AssetTypeSelect";

function InnerAssetTypeChangeForm<T>(
  props: {
    title: string;
    fieldName: keyof T;
    showUseCase: boolean;
  } & FormikProps<T>,
): React.ReactElement {
  const { title, fieldName, showUseCase, ...formikProps } = props;
  const { values, setFieldValue } = formikProps;
  React.useEffect(() => {
    if (
      showUseCase &&
      (
        [
          assetTypeEnum.heat_meter,
          assetTypeEnum.cooling_meter,
          assetTypeEnum.heating_and_cooling_meter,
        ] as AssetTypeEnum[]
      ).indexOf(values[fieldName] as unknown as AssetType) < 0
    ) {
      setFieldValue("use_case", undefined);
    }
  }, [values, fieldName, showUseCase, setFieldValue]);

  return (
    <>
      <h6>Change {title} Type</h6>
      <AssetTypeSelect fieldName={fieldName as string} />
      {showUseCase && (
        <AssetUseCaseSelect
          fieldName={"use_case"}
          includeNone={true}
          disabled={
            isNil(values[fieldName]) ||
            (
              [
                assetTypeEnum.heat_meter,
                assetTypeEnum.cooling_meter,
                assetTypeEnum.heating_and_cooling_meter,
              ] as AssetTypeEnum[]
            ).indexOf(values[fieldName] as unknown as AssetType) === -1
          }
        />
      )}
    </>
  );
}

export function AssetPositionTypeChangeForm({
  title,
  ...rest
}: Omit<
  EditModalProps<AssetPositionUpdateIn, AssetPosition>,
  "children" | "setModalFocus"
>) {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  return EditModal<AssetPositionUpdateIn, AssetPosition>({
    title: title,
    ...rest,
    children: (props) => (
      <InnerAssetTypeChangeForm
        title={title}
        showUseCase={true}
        fieldName={"asset_position_type"}
        {...props}
      />
    ),
    setModalFocus,
  });
}

export default function AssetTypeChangeForm({
  title,
  ...rest
}: Omit<
  EditModalProps<Asset, GatewayAssetUpdateIn | DeviceAssetUpdateIn>,
  "children" | "setModalFocus"
>) {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node?.focus();
  };

  return EditModal<Asset, GatewayAssetUpdateIn | DeviceAssetUpdateIn>({
    title: title,
    ...rest,
    children: (props) => (
      <InnerAssetTypeChangeForm
        title={title}
        showUseCase={false}
        fieldName={"asset_type"}
        {...props}
      />
    ),
    setModalFocus,
  });
}
