import client from 'client'
import type { GetSchemeListSchemeGetQueryResponse, GetSchemeListSchemeGetQueryParams, GetSchemeListSchemeGet422 } from '../../types/GetSchemeListSchemeGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSchemeListSchemeGetQueryKey = (params?: GetSchemeListSchemeGetQueryParams) => [{ url: '/scheme' }, ...(params ? [params] : [])] as const

export type GetSchemeListSchemeGetQueryKey = ReturnType<typeof getSchemeListSchemeGetQueryKey>

/**
 * @summary Get Scheme List
 * {@link /scheme}
 */
export async function getSchemeListSchemeGet(params?: GetSchemeListSchemeGetQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetSchemeListSchemeGetQueryResponse, ResponseErrorConfig<GetSchemeListSchemeGet422>, unknown>({
    method: 'GET',
    url: `/scheme`,
    params,
    ...requestConfig,
  })
  return res
}

export function getSchemeListSchemeGetQueryOptions(
  params?: GetSchemeListSchemeGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getSchemeListSchemeGetQueryKey(params)
  return queryOptions<
    ResponseConfig<GetSchemeListSchemeGetQueryResponse>,
    ResponseErrorConfig<GetSchemeListSchemeGet422>,
    ResponseConfig<GetSchemeListSchemeGetQueryResponse>,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSchemeListSchemeGet(params, config)
    },
  })
}

/**
 * @summary Get Scheme List
 * {@link /scheme}
 */
export function useGetSchemeListSchemeGet<
  TData = ResponseConfig<GetSchemeListSchemeGetQueryResponse>,
  TQueryData = ResponseConfig<GetSchemeListSchemeGetQueryResponse>,
  TQueryKey extends QueryKey = GetSchemeListSchemeGetQueryKey,
>(
  params?: GetSchemeListSchemeGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ResponseConfig<GetSchemeListSchemeGetQueryResponse>, ResponseErrorConfig<GetSchemeListSchemeGet422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSchemeListSchemeGetQueryKey(params)

  const query = useQuery({
    ...(getSchemeListSchemeGetQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetSchemeListSchemeGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}