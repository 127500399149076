import {
  type AssetPosition,
  type GetAssetListAssetGetQueryParams,
  getAssetListAssetGet,
  objectTypesEnum,
  useGetAssetListAssetGet,
} from "kubb";
import type React from "react";
import BulkUpdate from "../../../../components/BulkUpdate/BulkUpdate";
import NewGetListPaginatedTable from "../../../../components/Pagination/GetListPaginatedTable";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
// biome-ignore lint/style/useImportType: <explanation>
import { Asset } from "../../../../utils/object.tsx";
import AssetContainer, { type AssetChildProps } from "../AssetContainer";
import AssetListGenericRowsContent from "./AssetListGenericRowsContent";
import AssetListGenericRowsHeader from "./AssetListGenericRowsHeader";

interface Props {
  params: Omit<GetAssetListAssetGetQueryParams, "company_id" | "scheme_id">;
  showPosition?: boolean;
  assignAssetPosition?: AssetPosition;
  showDownload?: boolean;
  showPagination?: boolean;
  headerRow?: [React.ReactElement, number];
  assetRow?: (asset: Asset) => React.ReactElement;
  className?: string;
  company_id: string;
  scheme_id?: string;
  refresh?: () => any;
}

export default function AssetList(props: Props): React.ReactElement {
  const { company_id, scheme_id } = props;
  const headerRow = props.headerRow
    ? props.headerRow
    : AssetListGenericRowsHeader(
        props.showPosition,
        props.scheme_id === undefined,
      );

  const assetRow = props.assetRow
    ? props.assetRow
    : (asset: Asset): React.ReactElement => {
        return (
          <AssetContainer asset={asset} key={asset.asset_id}>
            {({ asset, refresh }: AssetChildProps) => (
              <AssetListGenericRowsContent
                asset={asset}
                assignAssetPosition={props.assignAssetPosition}
                showPosition={props.showPosition}
                showScheme={props.scheme_id === undefined}
                refreshList={props.refresh}
                refreshElement={refresh}
              />
            )}
          </AssetContainer>
        );
      };

  const params = {
    ...props.params,
    company_id,
    scheme_id,
  };
  return (
    <>
      <NewGetListPaginatedTable
        params={params}
        data-cy={"asset-list"}
        header={headerRow}
        row={assetRow}
        tableClassName={props.className}
        showPagination={props.showPagination}
        useQuery={useGetAssetListAssetGet}
        additionalFunctionButtons={
          <div className={"flex gap-2"}>
            <PaginatedApiToCSVExportButton
              params={params}
              useQuery={getAssetListAssetGet}
            />
            <BulkUpdate
              company_id={props.company_id}
              scheme_id={props.scheme_id}
              objectType={objectTypesEnum.Asset}
            />
            <CompanyNavLink
              to={{ pathname: "/admin/asset/updates" }}
              button={{
                color: "brandLight",
              }}
              className={"d-inline-block d-md-none"}
            >
              Previous Uploads
            </CompanyNavLink>
          </div>
        }
      />
    </>
  );
}
