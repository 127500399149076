import { cumulativeValueTimeSeriesFieldEnum } from "kubb";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../components/Forms/SelectCombo/SelectCombo";

function CumulativeDataSelect(props: Omit<SelectComboFieldProps, "options">) {
  const values: Array<Option> = [
    {
      value: cumulativeValueTimeSeriesFieldEnum["Energy (Cooling)"],
      label: "Energy (Cooling)",
    },
    {
      value: cumulativeValueTimeSeriesFieldEnum["Energy (Heating)"],
      label: "Energy (Heating)",
    },
    {
      value:
        cumulativeValueTimeSeriesFieldEnum["Energy (Electrical Active Import)"],
      label: "Energy (Electrical Active Import)",
    },
    {
      value:
        cumulativeValueTimeSeriesFieldEnum["Energy (Electrical Active Export)"],
      label: "Energy (Electrical Active Export)",
    },
    {
      value:
        cumulativeValueTimeSeriesFieldEnum[
          "Energy (Electrical Reactive Import)"
        ],
      label: "Energy (Electrical Reactive Import)",
    },
    {
      value:
        cumulativeValueTimeSeriesFieldEnum[
          "Energy (Electrical Reactive Export)"
        ],
      label: "Energy (Electrical Reactive Export)",
    },
    { value: cumulativeValueTimeSeriesFieldEnum.Volume, label: "Volume" },
  ];
  return <SelectComboField options={values} {...props} />;
}

export default CumulativeDataSelect;
