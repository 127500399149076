import { useCreateNewSimpleAssetRegister } from "hooks/createEntity";
import type { AssetPosition } from "kubb";
import type { SimpleAssetDefinition } from "kubb";
import type React from "react";
import { useState } from "react";
import AlertBanner from "../../../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import { Heading } from "../../../../components/Theme/heading";
import { removeMapBlanks } from "../../../../utils/object.tsx";
import SimpleAssetRegisterUploadPreview from "./SimpleAssetRegisterUploadPreview";
import { type ParsedAssetDefinition, UploadPage } from "./UploadPage";

interface Props {
  assetPosition: AssetPosition;
}

const SimpleAssetRegisterUploadContainer = (
  props: Props,
): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState<ParsedAssetDefinition[]>([]);
  const [state, setState] = useState<"upload" | "complete" | "error">("upload");

  const { assetPosition } = props;

  const create = useCreateNewSimpleAssetRegister(assetPosition);

  const handleNewFile = (
    definitions: Promise<ParsedAssetDefinition[]>,
  ): void => {
    setLoading(true);
    definitions
      .then((result) => {
        setRegister(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpload = (): void => {
    setLoading(true);
    const validRegister = register.filter((object: ParsedAssetDefinition) => {
      return !(object.errors && object.errors.length > 0);
    });

    create
      .update(
        validRegister.map(
          (o) =>
            removeMapBlanks(
              o.object as SimpleAssetDefinition,
            ) as SimpleAssetDefinition,
        ),
      )
      .then(() => {
        setState("complete");
      })
      .finally(() => setLoading(false));
  };

  const cancelUpload = (): void => {
    setRegister([]);
  };

  return (
    <>
      {state === "upload" && (
        <BlockSpinner loading={loading}>
          {register.length === 0 ? (
            <UploadPage handleFile={handleNewFile} />
          ) : (
            <>
              <SimpleAssetRegisterUploadPreview definitions={register} />
              <Button color={"brandLight"} onClick={handleUpload}>
                Upload
              </Button>
              <Button color={"red"} onClick={cancelUpload}>
                Cancel
              </Button>
            </>
          )}
        </BlockSpinner>
      )}
      {state === "complete" && (
        <AlertBanner className={"bg-green-700"}>
          <Heading>Complete</Heading>
          <p>Your upload was successfully processed</p>
        </AlertBanner>
      )}
      {state === "error" ||
        (create.error && <ErrorBanner error={create.error} />)}
    </>
  );
};

export default SimpleAssetRegisterUploadContainer;
