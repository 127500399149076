import dayjs, { type Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import type { WirelessSurveyDataCollectorOut } from "kubb";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import { Button } from "../../../components/Theme/button";

interface FormValues {
  sample_point_name: string;
  start_date_time: string;
  end_date_time?: string;
}

export interface WirelessSurveySamplePointFormSubmitValues {
  sample_point_name: string;
  start_date_time: Dayjs;
  end_date_time?: Dayjs;
}

function WirelessSurveySamplePointForm(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  refresh?: () => any;
  submit?: (values: WirelessSurveySamplePointFormSubmitValues) => Promise<any>;
  onCancel?: () => any;
  edit?: number;
}) {
  const timeFormat = "YYYY-MM-DD[T]HH:mm";

  const editPoint =
    props.edit !== undefined
      ? props.dataCollector.sample_points[props.edit]
      : undefined;
  const initialValues = {
    sample_point_name: editPoint ? editPoint.sample_point_name : "",
    start_date_time: (editPoint
      ? dayjs(editPoint.start_date_time)
      : dayjs().endOf("minute")
    ).format(timeFormat),
    end_date_time: editPoint?.end_date_time
      ? dayjs(editPoint.end_date_time).format(timeFormat)
      : undefined,
  } as FormValues;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        props.submit?.({
          ...values,
          start_date_time: dayjs(values.start_date_time, timeFormat),
          end_date_time: values.end_date_time
            ? dayjs(values.end_date_time, timeFormat)
            : undefined,
        });
      }}
      onReset={() => {
        props.onCancel?.();
      }}
    >
      {() => (
        <Form>
          <NewTextFormComponent
            fieldName={"sample_point_name"}
            label={{ label: "Sample Point Name" }}
          />
          <NewTextFormComponent
            fieldName={"start_date_time"}
            type={"datetime-local"}
            label={{ label: "Sample Point Start Date Time" }}
          />
          <NewTextFormComponent
            fieldName={"end_date_time"}
            type={"datetime-local"}
            label={{ label: "Sample Point End Date Time" }}
          />
          <div className={"flex gap-1"}>
            <Button color={"red"} type={"reset"}>
              Cancel
            </Button>
            <Button color={"green"} type={"submit"}>
              {editPoint ? "Edit" : "Add"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default WirelessSurveySamplePointForm;
