import type { WirelessSurveyOut } from "kubb";
import type React from "react";
import { StackedListTwoColumnItem } from "../../../components/Theme/stacked-list";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";

export default function WirelessSurveyListRowInner(props: {
  wirelessSurvey: WirelessSurveyOut;
}): React.ReactElement {
  const { wirelessSurvey } = props;

  return (
    <StackedListTwoColumnItem
      column1={
        <div className="min-w-0 flex-auto">
          <CompanyNavLink to={{ pathname: `view/${wirelessSurvey.survey_id}` }}>
            <h5 className={"mb-0"}>{wirelessSurvey.survey_name}</h5>
          </CompanyNavLink>
          <p className="text-sm mb-0 mt-1">{wirelessSurvey.survey_id}</p>
        </div>
      }
      column2={
        <div className={"w-full text-right"}>
          {formatDate(wirelessSurvey.created_at)}
        </div>
      }
      column1Size={"w-full sm:w-1/2"}
      column2Size={"w-0 sm:w-1/2"}
    />
  );
}
