import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Heading } from "components/Theme/heading";
import { type BulkUpdateExecutionStats, bulkUpdateStatusEnum } from "kubb";
/* TODO: styles from processbanner */
import type { BulkUpdatePreviewStats } from "kubb";
import type { BulkUpdateStatus } from "kubb";
import type React from "react";
import AlertBanner from "../Banner/AlertBanner";
import BulkUpdateProgressBar from "./BulkUpdateProgressBar";

interface Props {
  status: BulkUpdateStatus;
  previewStats: BulkUpdatePreviewStats;
  executeStats: BulkUpdateExecutionStats;
}

const UpdateProgressBanner = (props: Props): React.ReactElement => {
  const { status, executeStats, previewStats } = { ...props };

  const isPreviewGenerating =
    status === bulkUpdateStatusEnum.PREVIEW_GENERATING;
  const hasCompleted = status === bulkUpdateStatusEnum.EXECUTION_COMPLETED;

  const previewErrors =
    (previewStats.new_invalid || 0) + (previewStats.update_invalid || 0);
  const hasExecuteErrors = executeStats.failed !== 0;

  const heading = hasCompleted
    ? "Update Complete"
    : isPreviewGenerating
      ? "Preview Generating"
      : "Update In Progress";
  const errorText = hasExecuteErrors
    ? `${executeStats.failed} errors occurred during execution`
    : undefined;
  const previewErrorText =
    previewErrors !== 0
      ? `${previewErrors} lines were ignored because they were not valid when the update was created`
      : undefined;

  return (
    <>
      {status !== bulkUpdateStatusEnum.PREVIEW_CREATED && (
        <AlertBanner
          className={
            hasExecuteErrors
              ? "bg-red-700"
              : hasCompleted
                ? "bg-green-700"
                : "bg-gray-700"
          }
        >
          <Heading>{heading}</Heading>
          {errorText && <p>{errorText}</p>}
          {!hasCompleted && (
            <p>
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                aria-hidden="true"
              />
              <span className="px-2">
                {isPreviewGenerating
                  ? "Preview is generating."
                  : "Update is in progress."}
                Please wait.
              </span>
            </p>
          )}
          {hasCompleted && !hasExecuteErrors && (
            <p>The update has completed with no errors.</p>
          )}
          {previewErrorText && <p>{previewErrorText}</p>}
          {!isPreviewGenerating && (
            <BulkUpdateProgressBar executionStats={executeStats} />
          )}
        </AlertBanner>
      )}
    </>
  );
};

export default UpdateProgressBanner;
