import { Form, Formik, type FormikHelpers } from "formik";
import type { AssetPosition, AssetTypeEnum } from "kubb";
import type { DeviceAssetOut } from "kubb";
import type React from "react";
import { useState } from "react";
import * as Yup from "yup";
import AlertBanner from "../../../../components/Banner/AlertBanner";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import {
  yupRequiredString,
  yupString,
} from "../../../../components/Forms/yupValidators";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import { Button } from "../../../../components/Theme/button";
import { useCreateVirtualAsset } from "../../../../hooks/createEntity";
import { useSelectedCompany } from "../../../../reducers/company";
import AssetTypeSelect from "../AssetTypeSelect";

interface FormValues {
  assetType?: AssetTypeEnum;
  customerReference?: string;
  sourceField?: string;
}

interface PProps {
  parentAsset: DeviceAssetOut;
  parentAssetPosition: AssetPosition;
}

const VirtualAssetCreateForm = (props: PProps): React.ReactElement => {
  const { parentAssetPosition } = props;
  const company = useSelectedCompany();

  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const { update, error } = useCreateVirtualAsset(parentAssetPosition);

  const initialValue: FormValues = {
    customerReference: "",
    sourceField: "",
  };

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    setSuccess(undefined);
    if (values.assetType) {
      update({
        asset_type: values.assetType,
        asset_position_customer_reference: values.customerReference,
        asset_configuration: { virtual: { field: values.sourceField } },
        company_id: company?.company_id,
      })
        .then(() => {
          setSuccess(true);
          resetForm();
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      throw new Error("Form is invalid");
    }
  };

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValue}
      validationSchema={Yup.object().shape({
        assetType: yupRequiredString,
        customerReference: yupString,
      })}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting }): React.ReactElement => (
        <Form data-cy={"asset-position-create"}>
          <BlockSpinner loading={isSubmitting}>
            <h3 className="text-white mb-0">Add Virtual Asset</h3>
            <AssetTypeSelect fieldName={"assetType"} includeSelect={true} />
            <NewTextFormComponent
              fieldName={"customerReference"}
              label={{ label: "Customer Reference" }}
            />
            <NewTextFormComponent
              fieldName={"sourceField"}
              label={{ label: "Source Field" }}
            />
            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={isSubmitting || !isValid}
            >
              Add Virtual Asset
            </Button>
            {error && <ErrorBanner error={error} />}
            {success && (
              <AlertBanner className={"bg-success"}>
                Virtual Asset Successfully Created.
              </AlertBanner>
            )}
          </BlockSpinner>
        </Form>
      )}
    </Formik>
  );
};

export default VirtualAssetCreateForm;
