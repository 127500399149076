import clsx from "clsx";
import { Form, Formik, type FormikProps } from "formik";
import type {
  GetAssetListAssetGetQueryParams,
  GetAssetPositionListAssetPositionGetQueryParams,
  GetAssetRegisterListAssetRegisterGetQueryParams,
} from "kubb";
import { isUndefined } from "lodash-es";
import type React from "react";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AssetUseCaseSelect from "../../../../components/Asset/AssetUseCaseSelect";
import { ButtonGroupField } from "../../../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../../../components/Forms/NewTextFormComponent";
import LocationGroupSelect from "../../../../components/LocationGroup/LocationGroupSelect";
import { Button } from "../../../../components/Theme/button";
import { Col, Container } from "../../../../components/Theme/grid";
import {
  convertStringUndefined,
  createSearchString,
} from "../../../../components/navigation/SearchString";
import AssetTypeSelect from "../AssetTypeSelect";

type Props = {
  params: Omit<
    | GetAssetListAssetGetQueryParams
    | GetAssetPositionListAssetPositionGetQueryParams
    | GetAssetRegisterListAssetRegisterGetQueryParams,
    "company_id" | "scheme_id"
  >;
  includeNonCreateables?: boolean;
  filterUnassignedField?: string;
  includeTextSearch?: boolean;
  includeGroup?: boolean;
  includeUseCase?: boolean;
  includeStatus?: boolean;
};

function isAssetPositionParams(
  params:
    | GetAssetPositionListAssetPositionGetQueryParams
    | GetAssetListAssetGetQueryParams,
): params is GetAssetPositionListAssetPositionGetQueryParams {
  return (
    (params as GetAssetPositionListAssetPositionGetQueryParams).status !==
    undefined
  );
}

export default function AssetListFilterForm(props: Props): React.ReactElement {
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);

  const {
    filterUnassignedField = "asset_position_id",
    includeNonCreateables = true,
    includeTextSearch = false,
    includeGroup = true,
    includeUseCase = true,
    includeStatus = true,
  } = props;

  const setFiltersToRoute = useCallback(
    (values: any): void => {
      const newSearchString = createSearchString(
        convertStringUndefined({ ...values }),
      );

      if (
        location.search === ""
          ? newSearchString !== ""
          : `?${newSearchString}` !== location.search
      ) {
        navigate({
          ...location,
          search: newSearchString,
        });
      }
    },
    [navigate],
  );

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...props.params,
          asset_position_id: isUndefined(props.params.asset_position_id)
            ? "undefined"
            : props.params.asset_position_id,

          asset_id: isUndefined(props.params.asset_id)
            ? "undefined"
            : props.params.asset_id,
          status:
            isAssetPositionParams(props.params) &&
            props.params.status &&
            props.params.status.length >= 1
              ? props.params.status[0]
              : [],
        }}
        onSubmit={(x): void => {
          setFiltersToRoute(x);
        }}
      >
        {(): React.ReactElement => (
          <Form>
            <Container gap={3} className={"grid-"}>
              {includeTextSearch && (
                <Col sm={12} md={6}>
                  <NewTextFormComponent
                    label={{ label: "Search", size: "sm" }}
                    fieldName={"text_search"}
                  />
                </Col>
              )}
              <Col sm={12} md={6}>
                <AssetTypeSelect
                  fieldName={"asset_type"}
                  label={{ label: "Asset Type", size: "sm" }}
                  allowUnknown={true}
                  includeNonCreateables={includeNonCreateables}
                  isMulti={true}
                />
              </Col>
              {includeUseCase && (
                <Col sm={12} md={includeGroup && includeTextSearch ? 3 : 6}>
                  <AssetUseCaseSelect
                    fieldName={"use_case"}
                    label={{ label: "Use Case", size: "sm" }}
                    includeAll={false}
                    isMulti={true}
                    includeNone={true}
                  />
                </Col>
              )}
              {includeGroup && (
                <Col sm={12} md={includeUseCase && includeTextSearch ? 3 : 6}>
                  <LocationGroupSelect
                    fieldName={"group"}
                    label={{ label: "Group", size: "sm" }}
                  />
                </Col>
              )}
              <Col
                sm={12}
                md={includeStatus ? 2 : 4}
                className={clsx(
                  !includeUseCase && !includeGroup && "md:col-start-7",
                )}
              >
                <ButtonGroupField
                  fieldName={filterUnassignedField}
                  label={{ label: "Filter Unassigned", size: "sm" }}
                  options={[
                    { label: "Yes", value: "NONE" },
                    {
                      label: "No",
                      value: "undefined",
                    },
                  ]}
                  fullWidth={true}
                />
              </Col>
              {includeStatus && (
                <Col sm={12} md={2}>
                  <ButtonGroupField
                    fieldName={"status"}
                    label={{ label: "Status", size: "sm" }}
                    options={[
                      { label: "Active", value: "ACTIVE" },
                      {
                        label: "Deleted",
                        value: "DELETED",
                      },
                    ]}
                    fullWidth={true}
                  />
                </Col>
              )}
              <Col sm={12} md={2} className={"justify-end mt-auto"}>
                <Button type={"submit"} className={"w-full md:h-[38px]"}>
                  Filter
                </Button>
              </Col>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}
