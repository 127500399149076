import type React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrlHash } from "../../components/navigation/URL";
import { useSelectedCompany } from "../../reducers/company";
import { usePrivacyMode } from "../../reducers/privacy";
import { useProfile } from "../../reducers/profile";
import { useSelectedScheme } from "../../reducers/scheme";

export default function AdminLandingPage(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const userProfile = useProfile();

  const hash = getUrlHash(
    useSelectedCompany(),
    useSelectedScheme(),
    usePrivacyMode(),
  );
  const search = location.search;

  useEffect(() => {
    if (location.pathname === "/admin" || location.pathname === "/admin/") {
      if (userProfile?.user_type === "superadmin") {
        console.log("set company list");
        navigate({ pathname: "/admin/company", hash: hash, search: search });
      } else {
        console.log("set dashboard");
        navigate({ pathname: "dashboard", hash: hash, search: search });
      }
    }
  }, [navigate, hash, search, location, userProfile]);

  return <></>;
}
