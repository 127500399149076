import type { DefaultError } from "@tanstack/query-core";
import type { UseMutationResult } from "@tanstack/react-query/src/types.ts";
import type { AxiosResponse } from "axios";
import React from "react";
import type { ResponseConfig } from "../client.ts";
import type { AssetRegisterDataItem } from "../components/Table/AssetRegisterData.tsx";
import type { CompanyUser } from "../containers/users/users.tsx";
import {
  type AssetPosition,
  type AssetPositionAssetLinkOut,
  type DeviceAssetUpdateIn,
  type GatewayAssetUpdateIn,
  type JobDefinitionUpdate,
  type Location,
  type NoteUpdateIn,
  type StreamingConfiguration,
  type UpdateAssetAssetAssetIdPutMutationRequest,
  type UpdateAssetAssetAssetIdPutMutationResponse,
  type UpdateAssetAssetAssetIdPutPathParams,
  type UpdateAssetAssetAssetIdPutQueryParams,
  type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams,
  type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest,
  type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse,
  type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams,
  type UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams,
  type UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams,
  type UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest,
  type UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse,
  type UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams,
  type UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams,
  type UpdateLocationLocationLocationIdPutHeaderParams,
  type UpdateLocationLocationLocationIdPutMutationRequest,
  type UpdateLocationLocationLocationIdPutMutationResponse,
  type UpdateLocationLocationLocationIdPutPathParams,
  type UpdateLocationLocationLocationIdPutQueryParams,
  type UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest,
  type UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse,
  type UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams,
  type UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest,
  type UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse,
  type UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams,
  type UserCompanyLinkIn,
  type UserCompanyProperties,
  type WirelessSurveyDataCollectorOut,
  type WirelessSurveyDataCollectorUpdate,
  type WirelessSurveyIn,
  type WirelessSurveyOut,
  useCreateCompanyStreamingConfigurationStreamingPut,
  useCreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorDataCollectorIdPut,
  useUpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut,
  useUpdateAssetAssetAssetIdPut,
  useUpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut,
  useUpdateAssetPositionAssetPositionAssetPositionIdPut,
  useUpdateExportJobJobJobIdPut,
  useUpdateLocationLocationLocationIdPut,
  useUpdateNoteNoteNoteIdPut,
  useUpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut,
  useUpdateUserCompanyUserUserIdCompanyCompanyIdPut,
  useUpdateWirelessSurveyWirelessSurveySurveyIdPut,
} from "../kubb";

export interface MutateEntityWrapperResult<TData, TBody = TData> {
  loading: boolean;
  data?: TData;
  error?: Error;
  update: (d: TBody) => Promise<[TData, AxiosResponse["headers"]]>;
}

export function useMutateEntity<
  D,
  TBody = unknown,
  TParams = unknown,
  TVariables extends
    | { data: TBody }
    | { data?: TBody }
    | { data?: TBody; params?: TParams }
    | { data: TBody; params?: TParams } = {
    data: TBody;
    params?: TParams;
  },
  TError = DefaultError,
>(
  fn: UseMutationResult<ResponseConfig<D>, TError, TVariables>,
  path: Omit<TVariables, "data" | "params">,
  params?: TParams,
): MutateEntityWrapperResult<D, TBody> {
  async function update(d: TBody): Promise<[D, AxiosResponse["headers"]]> {
    setState((prev) => {
      return { ...prev, loading: true };
    });
    try {
      const r = await fn.mutateAsync({
        ...path,
        data: d,
        params,
      } as TVariables);
      setState((prev) => {
        return { ...prev, loading: false };
      });
      return [r.data, r.headers];
    } catch (e) {
      setState((prev) => {
        return { ...prev, loading: false, error: e as Error };
      });
      return Promise.reject(e);
    }

    /*TODO: error handling */
  }

  const [state, setState] = React.useState<MutateEntityWrapperResult<D, TBody>>(
    {
      loading: false,
      update: update,
    },
  );

  return state;
}

export function useUpdateAsset(
  asset: GatewayAssetUpdateIn | DeviceAssetUpdateIn,
): MutateEntityWrapperResult<null, GatewayAssetUpdateIn | DeviceAssetUpdateIn> {
  return useMutateEntity<
    UpdateAssetAssetAssetIdPutMutationResponse,
    UpdateAssetAssetAssetIdPutMutationRequest,
    UpdateAssetAssetAssetIdPutQueryParams,
    {
      asset_id: UpdateAssetAssetAssetIdPutPathParams["asset_id"];
      data?: UpdateAssetAssetAssetIdPutMutationRequest;
      params?: UpdateAssetAssetAssetIdPutQueryParams;
    }
  >(
    useUpdateAssetAssetAssetIdPut(),
    { asset_id: asset.asset_id },
    { company_id: asset.company_id },
  );
}

export function useUpdateAssetPosition(
  assetPosition: AssetPosition,
): MutateEntityWrapperResult<AssetPosition> {
  return useMutateEntity<
    UpdateAssetPositionAssetPositionAssetPositionIdPutMutationResponse,
    UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest,
    UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams,
    {
      asset_position_id: UpdateAssetPositionAssetPositionAssetPositionIdPutPathParams["asset_position_id"];
      data: UpdateAssetPositionAssetPositionAssetPositionIdPutMutationRequest;
      params?: UpdateAssetPositionAssetPositionAssetPositionIdPutQueryParams;
      headers?: UpdateAssetPositionAssetPositionAssetPositionIdPutHeaderParams;
    }
  >(
    useUpdateAssetPositionAssetPositionAssetPositionIdPut(),
    { asset_position_id: assetPosition.asset_position_id },
    { company_id: assetPosition.company_id },
  );
}

export function useUpdateLocation(
  location: Location,
): MutateEntityWrapperResult<Location> {
  return useMutateEntity<
    UpdateLocationLocationLocationIdPutMutationResponse,
    UpdateLocationLocationLocationIdPutMutationRequest,
    UpdateLocationLocationLocationIdPutQueryParams,
    {
      location_id: UpdateLocationLocationLocationIdPutPathParams["location_id"];
      data: UpdateLocationLocationLocationIdPutMutationRequest;
      params?: UpdateLocationLocationLocationIdPutQueryParams;
      headers?: UpdateLocationLocationLocationIdPutHeaderParams;
    }
  >(
    useUpdateLocationLocationLocationIdPut(),
    { location_id: location.location_id },
    { company_id: location.company_id },
  );
}

export function useUpdateStreamingConfiguration(
  configuration: StreamingConfiguration,
): MutateEntityWrapperResult<StreamingConfiguration> {
  return useMutateEntity(
    useCreateCompanyStreamingConfigurationStreamingPut({}),
    {},
  );
}

export function useUpdateJob(
  job: JobDefinitionUpdate,
): MutateEntityWrapperResult<JobDefinitionUpdate> {
  return useMutateEntity(useUpdateExportJobJobJobIdPut(), {
    job_id: job.job_id,
  });
}

export function useUpdateNote(
  note: NoteUpdateIn,
): MutateEntityWrapperResult<NoteUpdateIn> {
  return useMutateEntity(useUpdateNoteNoteNoteIdPut(), {
    note_id: note.note_id,
  });
}

export function useUpdateOwnProfile(
  userCompany: CompanyUser,
): MutateEntityWrapperResult<UserCompanyProperties, UserCompanyProperties> {
  return useMutateEntity<
    UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationResponse,
    UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest,
    null,
    {
      company_id: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutPathParams["company_id"];
      data?: UpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPutMutationRequest;
    }
  >(
    useUpdateOwnUserCompanyPropertiesUserProfileCompanyCompanyIdPropertiesPut(),
    { company_id: userCompany.company_id },
  );
}

export function useUpdateUserCompanyLink(
  userCompany: CompanyUser,
): MutateEntityWrapperResult<UserCompanyLinkIn, UserCompanyLinkIn> {
  return useMutateEntity<
    UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationResponse,
    UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest,
    null,
    {
      user_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams["user_id"];
      company_id: UpdateUserCompanyUserUserIdCompanyCompanyIdPutPathParams["company_id"];
      data: UpdateUserCompanyUserUserIdCompanyCompanyIdPutMutationRequest;
    }
  >(useUpdateUserCompanyUserUserIdCompanyCompanyIdPut(), {
    user_id: userCompany.user_id,
    company_id: userCompany.company_id,
  });
}

export function useUpdateAssetPositionAssetLink(
  link: AssetPositionAssetLinkOut,
): MutateEntityWrapperResult<
  AssetPositionAssetLinkOut,
  AssetPositionAssetLinkOut
> {
  return useMutateEntity<
    UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationResponse,
    UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest,
    UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams,
    {
      link_id: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutPathParams["link_id"];
      data: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutMutationRequest;
      params?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutQueryParams;
      headers?: UpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPutHeaderParams;
    }
  >(
    useUpdateAssetPositionAssetLinkAssetPositionLinkLinkIdPut(),
    { link_id: link.link_id },
    { company_id: link.company_id },
  );
}

export function useUpdateActiveWirelessReceiverIdList(
  assetPosition: AssetPosition | AssetRegisterDataItem,
  company_id: string,
): MutateEntityWrapperResult<string[]> {
  return useMutateEntity(
    useUpdateActiveWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversPut(),
    { asset_position_id: assetPosition.asset_position_id },
    { company_id },
  );
}

export function useUpdateWirelessSurveyDataCollector(
  dataCollector: WirelessSurveyDataCollectorOut,
): MutateEntityWrapperResult<WirelessSurveyDataCollectorUpdate> {
  return useMutateEntity(
    useCreateWirelessSurveyDataCollectorWirelessSurveySurveyIdDataCollectorDataCollectorIdPut(),
    {
      survey_id: dataCollector.survey_id,
      data_collector_id: dataCollector.data_collector_id,
    },
  );
}

export function useUpdateWirelessSurvey(
  survey: WirelessSurveyOut,
): MutateEntityWrapperResult<WirelessSurveyIn> {
  return useMutateEntity(
    useUpdateWirelessSurveyWirelessSurveySurveyIdPut(),
    { survey_id: survey.survey_id },
    { company_id: survey.company_id },
  );
}
