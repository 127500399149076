import type { TariffInstanceIn } from "kubb";
import type { TariffOut } from "kubb";
import React from "react";
import { useCreateNewTariffInstance } from "../../hooks/createEntity";
import TariffInstanceListDisplay from "./TariffInstanceListDisplay";
import {
  type TariffServiceDescription,
  type TariffServiceDescriptionMap,
  getTariffServices,
} from "./model";

interface TariffInstanceWithCompany extends TariffInstanceIn {
  company_id: string;
}

function TariffInstanceList(props: { tariff: TariffOut }) {
  const create = useCreateNewTariffInstance(props.tariff);

  const tariffInstances = props.tariff.instances || [];

  const [services, setServices] = React.useState<TariffServiceDescriptionMap>(
    getTariffServices(tariffInstances),
  );
  const addNewService = (
    newService: TariffServiceDescription,
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (newService.service_name in services) {
        reject("Service already exists.");
      } else {
        setServices({ ...services, [newService.service_name]: newService });
      }
      resolve(null);
    });
  };

  return (
    <TariffInstanceListDisplay
      tariffInstances={tariffInstances}
      services={services}
      addNewService={addNewService}
      addNewInstance={create.update}
    />
  );
}

export default TariffInstanceList;
