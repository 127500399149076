import dayjs from "dayjs";
import type { DeviceAssetOut } from "kubb";
import type { InputOutputModuleStateInput } from "kubb";
import type React from "react";
import { Heading } from "../../../../components/Theme/heading";
import { Table, TableCell, TableRow } from "../../../../components/Theme/table";
import HighlightNullAndOldDates from "../../../../components/utils/HighlightNullAndOldDates";
import RelayStateLabel from "./RelayStateLabel";
import SwitchRelayStateButtons from "./SwitchRelayStateButtons";

const InputOutputModuleRelayStateTab = (props: {
  asset: DeviceAssetOut;
  refresh?: () => void;
}): React.ReactElement => {
  const state: InputOutputModuleStateInput = props.asset
    .state as InputOutputModuleStateInput;
  return (
    <>
      <Heading>Relay State</Heading>
      <Table>
        <TableRow>
          <TableCell>Current State</TableCell>
          <TableCell>
            <RelayStateLabel state={state.relay_state} />
            <HighlightNullAndOldDates
              ageHighlight={dayjs.duration(3, "days")}
              unknownText={"Date of status unknown."}
            >
              {state.last_update}
            </HighlightNullAndOldDates>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Switch State</TableCell>
          <TableCell>
            <SwitchRelayStateButtons
              asset={props.asset}
              refresh={props.refresh}
            />
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};

export default InputOutputModuleRelayStateTab;
