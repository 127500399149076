import {
  type DeleteApiUserUserApiClientIdDeleteMutationResponse,
  type DeleteApiUserUserApiClientIdDeletePathParams,
  type DeleteApiUserUserApiClientIdDeleteQueryParams,
  useDeleteApiUserUserApiClientIdDelete,
} from "../kubb";
import {
  type MutateEntityWrapperResult,
  useMutateEntity,
} from "./updateEntity.tsx";

export function useDeleteUserCompanyLink(
  client_id: string,
  company_id: string,
): MutateEntityWrapperResult<any> {
  return useMutateEntity<
    DeleteApiUserUserApiClientIdDeleteMutationResponse,
    null,
    DeleteApiUserUserApiClientIdDeleteQueryParams,
    {
      client_id: DeleteApiUserUserApiClientIdDeletePathParams["client_id"];
      params?: DeleteApiUserUserApiClientIdDeleteQueryParams;
    }
  >(
    useDeleteApiUserUserApiClientIdDelete(),
    { client_id: client_id },
    { company_id: company_id },
  );
}
