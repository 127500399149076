import useUserHasRoles from "hooks/user/getCurrentUserRole";
import type React from "react";
import * as Yup from "yup";
import NewTextFormComponent from "../../../../../components/Forms/NewTextFormComponent";

export const VirtualMeterConfigurationFormSchema = Yup.object().shape({
  field: Yup.string(),
});

export default function VirtualMeterConfigurationForm({
  namespace,
}: {
  namespace?: string;
}): React.ReactElement {
  const allowEditing = useUserHasRoles("admin");

  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <>
      <h4>Virtual Meter Configuration</h4>

      <NewTextFormComponent
        fieldName={withNamespace("field")}
        label={{ label: "Source Field" }}
        type={"string"}
        disabled={!allowEditing}
      />
    </>
  );
}
