import client from 'client'
import type {
  GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse,
  GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetPathParams,
  GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryParams,
  GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet422,
} from '../../types/GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey = (
  asset_id: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetPathParams['asset_id'],
  params?: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryParams,
) => [{ url: '/data/asset/:asset_id/time_series', params: { asset_id: asset_id } }, ...(params ? [params] : [])] as const

export type GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey = ReturnType<typeof getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey>

/**
 * @summary Get Asset Timeseries
 * {@link /data/asset/:asset_id/time_series}
 */
export async function getAssetTimeseriesDataAssetAssetIdTimeSeriesGet(
  asset_id: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetPathParams['asset_id'],
  params?: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse,
    ResponseErrorConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet422>,
    unknown
  >({ method: 'GET', url: `/data/asset/${asset_id}/time_series`, params, ...requestConfig })
  return res
}

export function getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryOptions(
  asset_id: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetPathParams['asset_id'],
  params?: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey(asset_id, params)
  return queryOptions<
    ResponseConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse>,
    ResponseErrorConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet422>,
    ResponseConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetTimeseriesDataAssetAssetIdTimeSeriesGet(asset_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Timeseries
 * {@link /data/asset/:asset_id/time_series}
 */
export function useGetAssetTimeseriesDataAssetAssetIdTimeSeriesGet<
  TData = ResponseConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey,
>(
  asset_id: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetPathParams['asset_id'],
  params?: GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryResponse>,
        ResponseErrorConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryKey(asset_id, params)

  const query = useQuery({
    ...(getAssetTimeseriesDataAssetAssetIdTimeSeriesGetQueryOptions(asset_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetTimeseriesDataAssetAssetIdTimeSeriesGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}