import type { Event } from "kubb";
import { useGetEventListEventGet } from "kubb";
import type React from "react";
import NewGetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { TableHeader, TableRow } from "../../components/Theme/table";
import EventListFilterForm, {
  useGetParsedEventListFilterParams,
} from "./EventListFilterForm";
import EventListRow from "./EventListRow";
import type { EventListProps } from "./model";

export default function EventList(props: EventListProps): React.ReactElement {
  const { maxDisplay = 100 } = props;

  const { params } = useGetParsedEventListFilterParams();

  const header = (
    <TableRow>
      <TableHeader>Time</TableHeader>
      <TableHeader>Type</TableHeader>
      <TableHeader>Message</TableHeader>
      <TableHeader>User</TableHeader>
      <TableHeader>&nbsp;</TableHeader>
    </TableRow>
  );

  const row = props.row
    ? props.row
    : (value: Event): React.ReactElement => (
        <EventListRow
          key={value.event_id}
          value={value}
          eventListProps={props}
        />
      );

  return (
    <>
      <NewGetListPaginatedTable
        params={{
          company_id: props.company_id,
          scheme_id: props.scheme_id,
          asset_id: props.asset_id,
          asset_position_id: props.asset_position_id,
          location_id: props.location_id,
          jobId: props.jobId,
          eventTypes: params.eventTypes,
          startDatetime: params.startDatetime?.format(),
          endDatetime: params.endDatetime?.format(),
        }}
        tableClassName={"table-sm"}
        cursor={{ max_items: maxDisplay, direction: "desc" }}
        header={[header, 4]}
        row={row}
        useQuery={useGetEventListEventGet}
        noDataRow={props.noDataRow}
        filterForm={(props) => <EventListFilterForm {...props} />}
      />
    </>
  );
}
