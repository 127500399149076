import client from 'client'
import type {
  GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse,
  GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetPathParams,
  GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryParams,
  GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet422,
} from '../../types/GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey = (
  asset_position_id: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryParams,
) =>
  [
    { url: '/asset_position/:asset_position_id/wireless_receivers/history', params: { asset_position_id: asset_position_id } },
    ...(params ? [params] : []),
  ] as const

export type GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey = ReturnType<
  typeof getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey
>

/**
 * @summary Get Asset Position Wireless Receiver History
 * {@link /asset_position/:asset_position_id/wireless_receivers/history}
 */
export async function getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet(
  asset_position_id: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse,
    ResponseErrorConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet422>,
    unknown
  >({ method: 'GET', url: `/asset_position/${asset_position_id}/wireless_receivers/history`, params, ...requestConfig })
  return res
}

export function getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryOptions(
  asset_position_id: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey(asset_position_id, params)
  return queryOptions<
    ResponseConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>,
    ResponseErrorConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet422>,
    ResponseConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_position_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet(asset_position_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Position Wireless Receiver History
 * {@link /asset_position/:asset_position_id/wireless_receivers/history}
 */
export function useGetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet<
  TData = ResponseConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey,
>(
  asset_position_id: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryResponse>,
        ResponseErrorConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryKey(asset_position_id, params)

  const query = useQuery({
    ...(getAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGetQueryOptions(
      asset_position_id,
      params,
      config,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetPositionWirelessReceiverHistoryAssetPositionAssetPositionIdWirelessReceiversHistoryGet422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}