import inclusiveDatePeriod from "components/Forms/DateTimePicker";
import type { Dayjs } from "dayjs";
import {
  type AggregatedStatsRequest,
  type GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest,
  type GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse,
  type GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams,
  useGetTimeSeriesReportStatsReportTimeSeriesStatsPost,
} from "kubb";
import type { AggregatedTimeSeriesStatsItem } from "kubb";
import type React from "react";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import type { GetDataWrapperResult } from "../../../components/MutateWrapper.tsx";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { useMutateGetData } from "../../../hooks/getData";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";

export type AggregatedStatsResponse = {
  [group: string]: {
    [use_case: string]: { [datetime: string]: AggregatedTimeSeriesStatsItem };
  };
};

export interface AggregatedTimeSeriesStatsParams
  extends Omit<AggregatedStatsRequest, "start_datetime" | "end_datetime"> {
  start_datetime?: Dayjs;
  end_datetime?: Dayjs;
}

export default function TimeSeriesStatsApiWrapper(props: {
  request: AggregatedTimeSeriesStatsParams;
  children: (
    props: GetDataWrapperResult<AggregatedStatsResponse>,
  ) => React.ReactNode;
}): React.ReactElement {
  const company_id = useSelectedCompany()?.company_id as string;
  const scheme_id = useSelectedScheme()?.scheme_id;
  const { request } = props;
  const [actualStartDatetime, actualEndDatetime] = inclusiveDatePeriod(
    request.start_datetime,
    request.end_datetime,
  );

  const { data, loading, error, refresh } = useMutateGetData<
    GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationResponse,
    GetTimeSeriesReportStatsReportTimeSeriesStatsPostMutationRequest,
    GetTimeSeriesReportStatsReportTimeSeriesStatsPostQueryParams
  >(useGetTimeSeriesReportStatsReportTimeSeriesStatsPost({}), {
    company_id: company_id,
    scheme_id: scheme_id,
    ...request,
    start_datetime: actualStartDatetime.toISOString(),
    end_datetime: actualEndDatetime.toISOString(),
  });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({
            error: error || undefined,
            data,
            loading,
            refresh,
          })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
}
