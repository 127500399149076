import client from 'client'
import type {
  GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse,
  GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetPathParams,
  GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryParams,
  GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet422,
} from '../../types/GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey = (
  asset_id: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetPathParams['asset_id'],
  params?: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryParams,
) => [{ url: '/data/asset/:asset_id/time_series/rf', params: { asset_id: asset_id } }, ...(params ? [params] : [])] as const

export type GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey = ReturnType<typeof getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey>

/**
 * @summary Get Asset Timeseries Rf Data
 * {@link /data/asset/:asset_id/time_series/rf}
 */
export async function getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet(
  asset_id: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetPathParams['asset_id'],
  params?: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse,
    ResponseErrorConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet422>,
    unknown
  >({ method: 'GET', url: `/data/asset/${asset_id}/time_series/rf`, params, ...requestConfig })
  return res
}

export function getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryOptions(
  asset_id: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetPathParams['asset_id'],
  params?: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey(asset_id, params)
  return queryOptions<
    ResponseConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse>,
    ResponseErrorConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet422>,
    ResponseConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet(asset_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Timeseries Rf Data
 * {@link /data/asset/:asset_id/time_series/rf}
 */
export function useGetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet<
  TData = ResponseConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey,
>(
  asset_id: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetPathParams['asset_id'],
  params?: GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryResponse>,
        ResponseErrorConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryKey(asset_id, params)

  const query = useQuery({
    ...(getAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGetQueryOptions(asset_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetTimeseriesRfDataDataAssetAssetIdTimeSeriesRfGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}