import { Form, Formik, type FormikHelpers } from "formik";
import { type CompanyRole, companyRoleEnum } from "kubb";
import type React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import {
  yupMatchPassword,
  yupPassword,
  yupRequiredString,
} from "../../components/Forms/yupValidators";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Button } from "../../components/Theme/button";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { useCreateNewCompanyUser } from "../../hooks/createEntity";
import { companyObjectPageTitle } from "../../hooks/setPageTitle";
import { useRequiredSelectedCompanyId } from "../../reducers/company";

interface FormValues {
  email_address: string;
  user_type: CompanyRole;
  password: string;
  confirmPassword: string;
}

const CreateUserForm = (): React.ReactElement => {
  const { update, error } = useCreateNewCompanyUser();
  const navigate = useNavigate();

  const company_id = useRequiredSelectedCompanyId();

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ): void => {
    setSubmitting(true);
    update({
      company_id: company_id,
      email_address: values.email_address,
      fullname: values.email_address,
      password: values.password,
      user_type: values.user_type,
      /* removing the confirm password*/
    })
      .then(() => {
        resetForm();
        navigate({ pathname: "/admin/users" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    email_address: "",
    user_type: companyRoleEnum.viewer,
    password: "",
    confirmPassword: "",
  };
  return (
    <OneCardPage
      headerTitle={"Create New User"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users/create", name: "Create" },
      ]}
      setPageTitle={companyObjectPageTitle("New User")}
      showSchemeInBreadcrumbs={false}
    >
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email_address: yupRequiredString.email(),
          user_type: yupRequiredString,
          password: yupPassword(),
          confirmPassword: yupMatchPassword(),
        })}
        isInitialValid={false}
      >
        {({ isValid, isSubmitting }): React.ReactElement => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <h3 className="text-white mb-0">Add new user</h3>

              <NewTextFormComponent
                fieldName={"email_address"}
                label={{ label: "Email Address" }}
                type={"email"}
              />
              <ButtonGroupField
                fieldName={"user_type"}
                label={{ label: "User type" }}
                options={[
                  { label: "admin", value: "admin" },
                  { label: "editor", value: "editor" },
                  { label: "viewer", value: "viewer" },
                ]}
              />
              <NewTextFormComponent
                fieldName={"password"}
                label={{ label: "Users Password" }}
                type={"password"}
              />
              <NewTextFormComponent
                fieldName={"confirmPassword"}
                label={{ label: "Confirm Password" }}
                type={"password"}
              />
              <Button
                type={"submit"}
                color={"brandLight"}
                disabled={isSubmitting || !isValid}
              >
                Add User
              </Button>
              <ErrorBanner error={error} />
            </Form>
          </BlockSpinner>
        )}
      </Formik>
    </OneCardPage>
  );
};

export default CreateUserForm;
