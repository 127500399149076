import client from 'client'
import type {
  HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest,
  HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationResponse,
  HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostPathParams,
  HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostQueryParams,
  HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost422,
} from '../../types/HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const handleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationKey = () =>
  [{ url: '/asset_register/{asset_position_id}/virtual' }] as const

export type HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationKey = ReturnType<
  typeof handleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationKey
>

/**
 * @summary Handle Virtual Asset Position Creation
 * {@link /asset_register/:asset_position_id/virtual}
 */
export async function handleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost(
  asset_position_id: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostPathParams['asset_position_id'],
  data: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest,
  params?: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostQueryParams,
  config: Partial<RequestConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationResponse,
    ResponseErrorConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost422>,
    HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest
  >({ method: 'POST', url: `/asset_register/${asset_position_id}/virtual`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Handle Virtual Asset Position Creation
 * {@link /asset_register/:asset_position_id/virtual}
 */
export function useHandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationResponse>,
      ResponseErrorConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost422>,
      {
        asset_position_id: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostPathParams['asset_position_id']
        data: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest
        params?: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostQueryParams
      }
    >
    client?: Partial<RequestConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? handleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationKey()

  return useMutation<
    ResponseConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationResponse>,
    ResponseErrorConfig<HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost422>,
    {
      asset_position_id: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostPathParams['asset_position_id']
      data: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostMutationRequest
      params?: HandleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPostQueryParams
    }
  >({
    mutationFn: async ({ asset_position_id, data, params }) => {
      return handleVirtualAssetPositionCreationAssetRegisterAssetPositionIdVirtualPost(asset_position_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}