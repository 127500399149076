import {
  type RequestConfig,
  type ResponseConfig,
  type ResponseErrorConfig,
  axiosInstance,
  client,
} from "@kubb/plugin-client/clients/axios";
import Qs from "qs";
import { getApiUrl } from "./Config.tsx";
import { getAccessJWT } from "./actions/helpers/auth.tsx";

axiosInstance.interceptors.request.use(async (config) => {
  config.baseURL = getApiUrl();
  config.headers.Authorization = `Bearer ${await getAccessJWT()}`;
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, { arrayFormat: "repeat" });
  };
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  if (response.headers["x-cursor"])
    response.headers["x-cursor"] = JSON.parse(response.headers["x-cursor"]);
  if (response.headers["x-etags"])
    response.headers["x-etags"] = JSON.parse(response.headers["x-etags"]);
  return response;
});

export type { ResponseConfig, ResponseErrorConfig, RequestConfig };

export { axiosInstance };

export default client;
