import type { GatewayAssetOut } from "kubb";
import type React from "react";

export default function GatewayListContent(props: {
  asset: GatewayAssetOut;
}): React.ReactElement {
  if (props.asset.asset_type !== "gateway") {
    throw new Error("Unsupported asset type");
  }

  return (
    <>
      {props.asset.sim_card_iccid && (
        <>Sim Iccid: {props.asset.sim_card_iccid}</>
      )}
    </>
  );
}
