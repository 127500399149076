import { Button } from "components/Theme/button";
import { Form, Formik, type FormikHelpers } from "formik";
import type { AssetPosition } from "kubb";
import * as yup from "yup";
import { ErrorBanner } from "../../../../components/Error/ErrorBanner.tsx";
import { useCreateNewAssetPositionLink } from "../../../../hooks/createEntity";
import AssetSelectFormField from "../../assets/AssetSelectFormField";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";

interface FormValues {
  asset_id?: string;
  install_date?: string;
  uninstall_date?: string;
}

export default function AssetPositionAssetLinkCreateForm(props: {
  assetPosition: AssetPosition;
  refresh?: () => any;
}) {
  const create = useCreateNewAssetPositionLink();
  const initialValues: FormValues = {};

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    helpers.setSubmitting(true);
    if (values.asset_id) {
      create
        .update({
          company_id: assetPosition.company_id,
          install_date: values.install_date,
          uninstall_date: values.uninstall_date,
          cancelled: false,
          asset_id: values.asset_id,
          asset_position_id: assetPosition.asset_position_id,
        })
        .then(() => {
          helpers.resetForm();
          props.refresh?.();
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    } else {
      throw new Error("asset_id must be specified to create a link");
    }
  };
  const { assetPosition } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        asset_id: yup.string().required(),
      })}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form>
            <h4>Select Asset</h4>
            <AssetSelectFormField
              name={"asset_id"}
              assetType={[assetPosition.asset_position_type]}
              company_id={assetPosition.company_id}
            />
            <h4>Dates</h4>
            <AssetPositionAssetLinkDateSelect newLink={true} />

            <Button
              type={"submit"}
              color={"brandLight"}
              disabled={!isValid || isSubmitting}
            >
              Assign
            </Button>
            {create.error && <ErrorBanner error={create.error} />}
          </Form>
        );
      }}
    </Formik>
  );
}
