import { periodEnum } from "kubb";
import type React from "react";
import {
  ButtonGroupField,
  type ButtonGroupFieldProps,
  type Option,
} from "../ButtonGroup";

interface CommonProps extends Omit<ButtonGroupFieldProps, "options"> {
  includeWeekly?: boolean;
}

export default function PeriodPickerToggle(
  props: CommonProps,
): React.ReactElement {
  const { includeWeekly = false, ...rest } = props;

  const options: Option[] = [
    { label: "D", value: periodEnum.DAILY },
    includeWeekly && { label: "W", value: periodEnum.WEEKLY },
    { label: "7D", value: periodEnum.SEVEN_DAY },
    { label: "M", value: periodEnum.MONTHLY },
  ].filter((v) => v !== false);

  return <ButtonGroupField {...rest} options={options} />;
}
