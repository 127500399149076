import client from 'client'
import type {
  UpdateExportJobJobJobIdPutMutationRequest,
  UpdateExportJobJobJobIdPutMutationResponse,
  UpdateExportJobJobJobIdPutPathParams,
  UpdateExportJobJobJobIdPutQueryParams,
  UpdateExportJobJobJobIdPut422,
} from '../../types/UpdateExportJobJobJobIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateExportJobJobJobIdPutMutationKey = () => [{ url: '/job/{job_id}' }] as const

export type UpdateExportJobJobJobIdPutMutationKey = ReturnType<typeof updateExportJobJobJobIdPutMutationKey>

/**
 * @summary Update Export Job
 * {@link /job/:job_id}
 */
export async function updateExportJobJobJobIdPut(
  job_id: UpdateExportJobJobJobIdPutPathParams['job_id'],
  data: UpdateExportJobJobJobIdPutMutationRequest,
  params?: UpdateExportJobJobJobIdPutQueryParams,
  config: Partial<RequestConfig<UpdateExportJobJobJobIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateExportJobJobJobIdPutMutationResponse,
    ResponseErrorConfig<UpdateExportJobJobJobIdPut422>,
    UpdateExportJobJobJobIdPutMutationRequest
  >({ method: 'PUT', url: `/job/${job_id}`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Update Export Job
 * {@link /job/:job_id}
 */
export function useUpdateExportJobJobJobIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateExportJobJobJobIdPutMutationResponse>,
      ResponseErrorConfig<UpdateExportJobJobJobIdPut422>,
      {
        job_id: UpdateExportJobJobJobIdPutPathParams['job_id']
        data: UpdateExportJobJobJobIdPutMutationRequest
        params?: UpdateExportJobJobJobIdPutQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateExportJobJobJobIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateExportJobJobJobIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateExportJobJobJobIdPutMutationResponse>,
    ResponseErrorConfig<UpdateExportJobJobJobIdPut422>,
    { job_id: UpdateExportJobJobJobIdPutPathParams['job_id']; data: UpdateExportJobJobJobIdPutMutationRequest; params?: UpdateExportJobJobJobIdPutQueryParams }
  >({
    mutationFn: async ({ job_id, data, params }) => {
      return updateExportJobJobJobIdPut(job_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}