import { isUndefined } from "lodash-es";
import type React from "react";
import type { Asset } from "utils/object";
import CompanyNavLink from "../navigation/CompanyNavLink";

interface Props {
  asset?: Asset;
  asset_id?: string;
  full?: boolean;
  placeholder?: boolean;
  text?: string;
}

const AssetIdLink = (props: Props): React.ReactElement => {
  const { asset, asset_id, placeholder } = props;
  const actualasset_id = asset ? asset.asset_id : asset_id;

  const usePlaceholder = isUndefined(actualasset_id) && placeholder;

  if (actualasset_id) {
    if (actualasset_id !== "NONE") {
      const displayId = props.full
        ? actualasset_id
        : `...${actualasset_id.slice(24)}`;
      return (
        <CompanyNavLink to={{ pathname: `/admin/asset/${actualasset_id}` }}>
          {props.text || displayId}
        </CompanyNavLink>
      );
    } else {
      return <>-</>;
    }
  } else if (usePlaceholder) {
    return <CompanyNavLink to={{ pathname: "" }}>...</CompanyNavLink>;
  }
  throw new Error("One of asset or asset_id must be set");
};

export default AssetIdLink;
