import { useGetCompanyListCompanyGet } from "kubb";
import type React from "react";
import { useContext, useEffect } from "react";
import PageSpinner from "../../components/Spinners/PageSpinner";
import { CompanyContext } from "../../reducers/company";
import CompanySelectInit from "./CompanySelectInit";

export default function CompanyListInit(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const { state, dispatch } = useContext(CompanyContext);
  const { stale } = state;

  const { data, isLoading, error, refetch } = useGetCompanyListCompanyGet(
    {
      max_items: 250,
    },
    {
      query: {
        staleTime: 5 * 60 * 1000,
      },
    },
  );

  useEffect(() => {
    if (data !== undefined) {
      dispatch({ type: "LOADING_COMPANIES_SUCCESS", payload: data.data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (isLoading && !stale) {
      dispatch({ type: "LOADING_COMPANIES_STARTED" });
    }
  }, [isLoading, stale, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_COMPANIES_ERROR", payload: error });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (stale) {
      refetch();
    }
  }, [stale, refetch]);

  return (
    <>
      <PageSpinner spin={isLoading}>
        {state.companies && (
          <CompanySelectInit>{props.children}</CompanySelectInit>
        )}
      </PageSpinner>
    </>
  );
}
