import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import UserTypeLabel from "components/user/UserTypeLabel";
import { Form, Formik, type FormikHelpers } from "formik";
import type { UserCompanyLinkIn, UserCompanyProperties } from "kubb";
import { statusEnum } from "kubb";
import type React from "react";
import { useState } from "react";
import EditButton from "../../components/Buttons/EditButton";
import UpdateButton from "../../components/Buttons/UpdateButton.tsx";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { ButtonGroupField } from "../../components/Forms/ButtonGroup";
import PrivacyMode from "../../components/Text/PrivacyMode";
import { TableCell, TableRow } from "../../components/Theme/table";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { useDeleteUserCompanyLink } from "../../hooks/deleteEntity.tsx";
import {
  useUpdateOwnProfile,
  useUpdateUserCompanyLink,
} from "../../hooks/updateEntity";
import EditUserTypeModel from "../users/EditUserTypeModel";
import type { CompanyUser } from "../users/users";

interface Props {
  userCompany: CompanyUser;
  showcompany_id?: boolean;
  showUserDetails?: boolean;
  profileAction?: boolean;
}

const UserCompanyListItemForm = (props: Props): React.ReactElement => {
  const {
    showcompany_id = false,
    showUserDetails = false,
    profileAction = true,
  } = props;

  const [error, setError] = useState<Error | undefined>(undefined);
  const [user, setUser] = useState<CompanyUser>(props.userCompany);
  const deleteFn = useDeleteUserCompanyLink(user.full_name, user.company_id);

  const update = profileAction
    ? useUpdateOwnProfile(props.userCompany)
    : useUpdateUserCompanyLink(props.userCompany);

  const initialValues: UserCompanyProperties = {
    receives_alerts: user.properties?.receives_alerts,
  };

  const postDelete = () => {
    setUser((s) => {
      return { ...s, status: statusEnum.DELETED };
    });
  };

  type profileFn = (d: UserCompanyProperties) => Promise<any>;
  type companyFn = (d: UserCompanyLinkIn) => Promise<any>;

  function updateIsCompany(fn: profileFn | companyFn): fn is profileFn {
    return profileAction;
  }

  const submit = (
    values: UserCompanyProperties,
    {
      setSubmitting,
      resetForm,
      setStatus,
    }: FormikHelpers<UserCompanyProperties>,
  ) => {
    setSubmitting(true);
    setStatus("submitting");
    setError(undefined);

    (updateIsCompany(update.update)
      ? update.update(values)
      : update.update({
          user_type: user.user_type,
          properties: values,
        })
    )
      .then(() => {
        setStatus("success");
      })
      .catch((err: Error) => {
        resetForm();
        setStatus("error");
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [openEditModal, editModal] = EditUserTypeModel(
    user,
    // (updatedCompany: CompanyUser) => {
    //   setUser(updatedCompany);
    // },
  );

  return (
    <TableRow data-cy={"list-item"} key={user.user_id}>
      {editModal}
      {showcompany_id && (
        <TableCell data-cy={"user-company"}>{user.company_id}</TableCell>
      )}
      {showUserDetails && (
        <>
          <TableCell data-cy="user-fullname">
            <PrivacyMode>{user.full_name}</PrivacyMode>
          </TableCell>
          <TableCell data-cy="user-email_address">
            <PrivacyMode>{user.email_address}</PrivacyMode>
          </TableCell>
        </>
      )}
      <TableCell data-cy={"user-type"}>
        <UserTypeLabel user={user} />
      </TableCell>
      <TableCell data-cy={"user-alerts"}>
        {!user.api_user && (
          <Formik initialValues={initialValues} onSubmit={submit}>
            {({ submitForm }): React.ReactElement => (
              <Form>
                <ButtonGroupField
                  fieldName={"receives_alerts"}
                  label={{ margin: 0, size: "sm" }}
                  options={[
                    { label: "On", value: true },
                    { label: "Off", value: false },
                  ]}
                  onChange={(): void => {
                    submitForm();
                  }}
                  size={"sm"}
                />
                {error && <ErrorBanner error={error} />}
              </Form>
            )}
          </Formik>
        )}
      </TableCell>
      <TableCell>
        {!user.api_user && (
          <DisplayIfUserType userTypes={"admin"}>
            <EditButton onClick={openEditModal} />
          </DisplayIfUserType>
        )}
        {user.api_user && user.status !== statusEnum.DELETED && (
          <UpdateButton
            icon={faTrashAlt}
            color={"red"}
            update={deleteFn}
            updatedEntity={null}
            postUpdate={postDelete}
            errorMessageBody={<b>Failed to delete user</b>}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserCompanyListItemForm;
