import dayjs from "dayjs";
import {
  type AssetTypeEnum,
  type AssetUseCase,
  type ReportView,
  reportViewEnum,
} from "kubb";
import { type GroupBy, assetTypeEnum, assetUseCaseEnum } from "kubb";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { removeMapBlanks } from "../../../utils/object";
import { parse_to_enum, singularParam } from "../../Search/SearchParamPage.tsx";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../../navigation/SearchString";
import { type NewTimeSeriesRequestParams, matchPeriod } from "../model";

interface TimeSeriesQueryUrlWrapperProps {
  defaultValues: NewTimeSeriesRequestParams;
  children: (
    params: NewTimeSeriesRequestParams,
    onChange: (v: NewTimeSeriesRequestParams) => void,
  ) => React.ReactNode;
}

function TimeSeriesQueryUrlWrapper(props: TimeSeriesQueryUrlWrapperProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [init, setInit] = useState<boolean>(false);

  const searchParams = parseSearchString(location.search);
  const currentParams: Partial<NewTimeSeriesRequestParams> = {
    start_date: searchParams.start_date
      ? dayjs(searchParams.start_date as string)
      : undefined,
    end_date: searchParams.end_date
      ? dayjs(searchParams.end_date as string)
      : undefined,
    parameter: searchParams.parameter
      ? (searchParams.parameter as string)
      : undefined,
    aggregation: searchParams.aggregation
      ? (searchParams.aggregation as string)
      : undefined,
    group: searchParams.group ? (searchParams.group as string) : undefined,
    view_by: singularParam(
      parse_to_enum(searchParams, reportViewEnum, "view_by"),
    ) as ReportView | undefined,
    group_by: searchParams.group_by
      ? (searchParams.group_by as GroupBy)
      : undefined,
    period: searchParams.period
      ? matchPeriod(searchParams.period as string)
      : undefined,
    asset_type: parse_to_enum(searchParams, assetTypeEnum, "asset_type") as
      | AssetTypeEnum[]
      | undefined,
    use_case: parse_to_enum(searchParams, assetUseCaseEnum, "use_case") as
      | AssetUseCase[]
      | undefined,
  };

  const setLocationWithParams = (values: NewTimeSeriesRequestParams): void => {
    const [startDatetime, endDatetime] = [values.start_date, values.end_date];
    const newSearchString = createSearchString(
      convertStringUndefined({
        ...values,
        startDatetime,
        endDatetime,
      }),
    );
    if (
      location.search === ""
        ? newSearchString !== ""
        : `?${newSearchString}` !== location.search
    ) {
      navigate({
        ...location,
        search: newSearchString,
      });
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: shouldnt change, we only want to run once
  useEffect(() => {
    setLocationWithParams({
      ...removeMapBlanks(currentParams),
      ...props.defaultValues,
    });
    setInit(true);
  }, []);

  return (
    <>
      {init &&
        props.children(
          { ...props.defaultValues, ...removeMapBlanks(currentParams) },
          setLocationWithParams,
        )}
    </>
  );
}

export default TimeSeriesQueryUrlWrapper;
