import client from 'client'
import type {
  UpdateNoteNoteNoteIdPutMutationRequest,
  UpdateNoteNoteNoteIdPutMutationResponse,
  UpdateNoteNoteNoteIdPutPathParams,
  UpdateNoteNoteNoteIdPutQueryParams,
  UpdateNoteNoteNoteIdPut422,
} from '../../types/UpdateNoteNoteNoteIdPut.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const updateNoteNoteNoteIdPutMutationKey = () => [{ url: '/note/{note_id}' }] as const

export type UpdateNoteNoteNoteIdPutMutationKey = ReturnType<typeof updateNoteNoteNoteIdPutMutationKey>

/**
 * @summary Update Note
 * {@link /note/:note_id}
 */
export async function updateNoteNoteNoteIdPut(
  note_id: UpdateNoteNoteNoteIdPutPathParams['note_id'],
  data: UpdateNoteNoteNoteIdPutMutationRequest,
  params?: UpdateNoteNoteNoteIdPutQueryParams,
  config: Partial<RequestConfig<UpdateNoteNoteNoteIdPutMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UpdateNoteNoteNoteIdPutMutationResponse, ResponseErrorConfig<UpdateNoteNoteNoteIdPut422>, UpdateNoteNoteNoteIdPutMutationRequest>({
    method: 'PUT',
    url: `/note/${note_id}`,
    params,
    data,
    ...requestConfig,
  })
  return res
}

/**
 * @summary Update Note
 * {@link /note/:note_id}
 */
export function useUpdateNoteNoteNoteIdPut(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<UpdateNoteNoteNoteIdPutMutationResponse>,
      ResponseErrorConfig<UpdateNoteNoteNoteIdPut422>,
      { note_id: UpdateNoteNoteNoteIdPutPathParams['note_id']; data: UpdateNoteNoteNoteIdPutMutationRequest; params?: UpdateNoteNoteNoteIdPutQueryParams }
    >
    client?: Partial<RequestConfig<UpdateNoteNoteNoteIdPutMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateNoteNoteNoteIdPutMutationKey()

  return useMutation<
    ResponseConfig<UpdateNoteNoteNoteIdPutMutationResponse>,
    ResponseErrorConfig<UpdateNoteNoteNoteIdPut422>,
    { note_id: UpdateNoteNoteNoteIdPutPathParams['note_id']; data: UpdateNoteNoteNoteIdPutMutationRequest; params?: UpdateNoteNoteNoteIdPutQueryParams }
  >({
    mutationFn: async ({ note_id, data, params }) => {
      return updateNoteNoteNoteIdPut(note_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}