import client from 'client'
import type {
  CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest,
  CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse,
  CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams,
  CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams,
  CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost422,
} from '../../types/CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationKey = () => [{ url: '/asset_register/simple/{asset_position_id}' }] as const

export type CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationKey = ReturnType<
  typeof createSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationKey
>

/**
 * @summary Create Simple Asset Register
 * {@link /asset_register/simple/:asset_position_id}
 */
export async function createSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost(
  asset_position_id: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams['asset_position_id'],
  data?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest,
  params?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams,
  config: Partial<RequestConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse,
    ResponseErrorConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost422>,
    CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest
  >({ method: 'POST', url: `/asset_register/simple/${asset_position_id}`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create Simple Asset Register
 * {@link /asset_register/simple/:asset_position_id}
 */
export function useCreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse>,
      ResponseErrorConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost422>,
      {
        asset_position_id: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams['asset_position_id']
        data?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest
        params?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationKey()

  return useMutation<
    ResponseConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationResponse>,
    ResponseErrorConfig<CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost422>,
    {
      asset_position_id: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostPathParams['asset_position_id']
      data?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostMutationRequest
      params?: CreateSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPostQueryParams
    }
  >({
    mutationFn: async ({ asset_position_id, data, params }) => {
      return createSimpleAssetRegisterAssetRegisterSimpleAssetPositionIdPost(asset_position_id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}