import client from 'client'
import type {
  GetAssetFilesAssetAssetIdFilesGetQueryResponse,
  GetAssetFilesAssetAssetIdFilesGetPathParams,
  GetAssetFilesAssetAssetIdFilesGetQueryParams,
  GetAssetFilesAssetAssetIdFilesGet422,
} from '../../types/GetAssetFilesAssetAssetIdFilesGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetFilesAssetAssetIdFilesGetQueryKey = (
  asset_id: GetAssetFilesAssetAssetIdFilesGetPathParams['asset_id'],
  params?: GetAssetFilesAssetAssetIdFilesGetQueryParams,
) => [{ url: '/asset/:asset_id/files', params: { asset_id: asset_id } }, ...(params ? [params] : [])] as const

export type GetAssetFilesAssetAssetIdFilesGetQueryKey = ReturnType<typeof getAssetFilesAssetAssetIdFilesGetQueryKey>

/**
 * @summary Get Asset Files
 * {@link /asset/:asset_id/files}
 */
export async function getAssetFilesAssetAssetIdFilesGet(
  asset_id: GetAssetFilesAssetAssetIdFilesGetPathParams['asset_id'],
  params?: GetAssetFilesAssetAssetIdFilesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetAssetFilesAssetAssetIdFilesGetQueryResponse, ResponseErrorConfig<GetAssetFilesAssetAssetIdFilesGet422>, unknown>({
    method: 'GET',
    url: `/asset/${asset_id}/files`,
    params,
    ...requestConfig,
  })
  return res
}

export function getAssetFilesAssetAssetIdFilesGetQueryOptions(
  asset_id: GetAssetFilesAssetAssetIdFilesGetPathParams['asset_id'],
  params?: GetAssetFilesAssetAssetIdFilesGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetFilesAssetAssetIdFilesGetQueryKey(asset_id, params)
  return queryOptions<
    ResponseConfig<GetAssetFilesAssetAssetIdFilesGetQueryResponse>,
    ResponseErrorConfig<GetAssetFilesAssetAssetIdFilesGet422>,
    ResponseConfig<GetAssetFilesAssetAssetIdFilesGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetFilesAssetAssetIdFilesGet(asset_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Files
 * {@link /asset/:asset_id/files}
 */
export function useGetAssetFilesAssetAssetIdFilesGet<
  TData = ResponseConfig<GetAssetFilesAssetAssetIdFilesGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetFilesAssetAssetIdFilesGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetFilesAssetAssetIdFilesGetQueryKey,
>(
  asset_id: GetAssetFilesAssetAssetIdFilesGetPathParams['asset_id'],
  params?: GetAssetFilesAssetAssetIdFilesGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetFilesAssetAssetIdFilesGetQueryResponse>,
        ResponseErrorConfig<GetAssetFilesAssetAssetIdFilesGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAssetFilesAssetAssetIdFilesGetQueryKey(asset_id, params)

  const query = useQuery({
    ...(getAssetFilesAssetAssetIdFilesGetQueryOptions(asset_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetFilesAssetAssetIdFilesGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}