import type { AssetPosition } from "kubb";
import type { ExpandedNote } from "kubb";
import type { Note } from "kubb";
import type React from "react";
import { useState } from "react";
import { Dialog, DialogBody, DialogTitle } from "../../components/Theme/dialog";
import CreateNoteForm from "./CreateNoteForm";
import NoteHistoryAccordian from "./NoteHistoryAccordian";

interface Props {
  assetPosition?: AssetPosition;
  onCreate?: () => void;
  onUpdate?: (note: Note) => void;
  children: (props: {
    openModal: () => void;
    isOpen: boolean;
  }) => React.ReactElement;
  note?: Note | ExpandedNote;
  creating?: boolean;
  editing?: boolean;
}

function title(editing = false, creating = false): string {
  if (editing) {
    return "Edit Note";
  }
  if (creating) {
    return "Add New Note";
  }
  return "Note";
}

export default function NoteModal(props: Props): React.ReactElement {
  const [open, setOpen] = useState(false);
  const [refreshed, setRefreshed] = useState(new Date());

  function onCreate(): void {
    props.onCreate?.();
    setOpen(false);
  }

  function onUpdate(note: Note): void {
    setRefreshed(new Date());
    props.onUpdate?.(note);
  }

  const { children, editing = false, creating = false, ...rest } = props;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>{title(editing, creating)}</DialogTitle>
        <DialogBody>
          <CreateNoteForm {...rest} onCreate={onCreate} onUpdate={onUpdate} />
          {props.note && (
            <NoteHistoryAccordian note={props.note} lastUpdate={refreshed} />
          )}
        </DialogBody>
      </Dialog>
      {children({
        isOpen: open,
        openModal: () => setOpen(true),
      })}
    </>
  );
}
