import client from 'client'
import type {
  CreateNewCompanyRegisterPostMutationRequest,
  CreateNewCompanyRegisterPostMutationResponse,
  CreateNewCompanyRegisterPostQueryParams,
  CreateNewCompanyRegisterPost422,
} from '../../types/CreateNewCompanyRegisterPost.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import type { RequestConfig, ResponseConfig, ResponseErrorConfig } from 'client'
import { useMutation } from '@tanstack/react-query'

export const createNewCompanyRegisterPostMutationKey = () => [{ url: '/register' }] as const

export type CreateNewCompanyRegisterPostMutationKey = ReturnType<typeof createNewCompanyRegisterPostMutationKey>

/**
 * @summary Create New Company
 * {@link /register}
 */
export async function createNewCompanyRegisterPost(
  data: CreateNewCompanyRegisterPostMutationRequest,
  params?: CreateNewCompanyRegisterPostQueryParams,
  config: Partial<RequestConfig<CreateNewCompanyRegisterPostMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateNewCompanyRegisterPostMutationResponse,
    ResponseErrorConfig<CreateNewCompanyRegisterPost422>,
    CreateNewCompanyRegisterPostMutationRequest
  >({ method: 'POST', url: `/register`, params, data, ...requestConfig })
  return res
}

/**
 * @summary Create New Company
 * {@link /register}
 */
export function useCreateNewCompanyRegisterPost(
  options: {
    mutation?: UseMutationOptions<
      ResponseConfig<CreateNewCompanyRegisterPostMutationResponse>,
      ResponseErrorConfig<CreateNewCompanyRegisterPost422>,
      { data: CreateNewCompanyRegisterPostMutationRequest; params?: CreateNewCompanyRegisterPostQueryParams }
    >
    client?: Partial<RequestConfig<CreateNewCompanyRegisterPostMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNewCompanyRegisterPostMutationKey()

  return useMutation<
    ResponseConfig<CreateNewCompanyRegisterPostMutationResponse>,
    ResponseErrorConfig<CreateNewCompanyRegisterPost422>,
    { data: CreateNewCompanyRegisterPostMutationRequest; params?: CreateNewCompanyRegisterPostQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createNewCompanyRegisterPost(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}