import { labellingCasesEnum } from "kubb";
import type React from "react";
import {
  type Option,
  SelectComboField,
  type SelectComboFieldProps,
} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function HeaderLabelCaseSelect(
  props: Omit<SelectComboFieldProps, "options">,
): React.ReactElement {
  const options: Option[] = [
    { label: "Title Case", value: labellingCasesEnum.TITLE_CASE },
    { label: "snake_case", value: labellingCasesEnum.SNAKE_CASE },
    { label: "camelCase", value: labellingCasesEnum.CAMEL_CASE },
  ];

  return <SelectComboField options={options} {...props} />;
}
