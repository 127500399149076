import client from 'client'
import type {
  GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse,
  GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetPathParams,
  GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryParams,
  GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet422,
} from '../../types/GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey = (
  survey_id: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetPathParams['survey_id'],
  params?: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryParams,
) => [{ url: '/wireless_survey/:survey_id/data_collector', params: { survey_id: survey_id } }, ...(params ? [params] : [])] as const

export type GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey = ReturnType<
  typeof getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey
>

/**
 * @summary Get Wireless Survey Sample Points
 * {@link /wireless_survey/:survey_id/data_collector}
 */
export async function getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet(
  survey_id: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetPathParams['survey_id'],
  params?: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse,
    ResponseErrorConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet422>,
    unknown
  >({ method: 'GET', url: `/wireless_survey/${survey_id}/data_collector`, params, ...requestConfig })
  return res
}

export function getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryOptions(
  survey_id: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetPathParams['survey_id'],
  params?: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey(survey_id, params)
  return queryOptions<
    ResponseConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse>,
    ResponseErrorConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet422>,
    ResponseConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!survey_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet(survey_id, params, config)
    },
  })
}

/**
 * @summary Get Wireless Survey Sample Points
 * {@link /wireless_survey/:survey_id/data_collector}
 */
export function useGetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet<
  TData = ResponseConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse>,
  TQueryData = ResponseConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse>,
  TQueryKey extends QueryKey = GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey,
>(
  survey_id: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetPathParams['survey_id'],
  params?: GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryResponse>,
        ResponseErrorConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryKey(survey_id, params)

  const query = useQuery({
    ...(getWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGetQueryOptions(survey_id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}