import type { ResponseConfig } from "@kubb/plugin-client/clients/axios.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import {
  type AssetPosition,
  type AssetFile as GatewayFile,
  type GetAssetFilesAssetAssetIdFilesGetQueryParams,
  type GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  type IntegrationFile,
  useGetIntegrationFilesAssetPositionAssetPositionIdFilesGet,
} from "kubb";
import { useGetAssetFilesAssetAssetIdFilesGet } from "kubb";
import { isNil } from "lodash-es";
import type React from "react";
import type { RequireExactlyOne } from "type-fest";
import type { Asset } from "utils/object";
import NewGetListPaginatedTable from "../../../../../components/Pagination/GetListPaginatedTable";
import { TableHeader, TableRow } from "../../../../../components/Theme/table";
import GatewayFileListFilterForm, {
  useGetParsedGatewayFileListFilterParams,
} from "./GatewayFileListFilterForm";
import GatewayFileListRow from "./GatewayFileListRow";

type Props = RequireExactlyOne<
  {
    asset?: Asset;
    assetPosition?: AssetPosition;
  },
  "asset" | "assetPosition"
>;

function getVariables(
  asset?: Asset,
  assetPosition?: AssetPosition,
): {
  company_id: string;
  query: (
    variables:
      | GetAssetFilesAssetAssetIdFilesGetQueryParams
      | GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
  ) => UseQueryResult<
    ResponseConfig<(IntegrationFile | GatewayFile)[]> | undefined,
    any
  >;
} {
  if (!isNil(asset)) {
    return {
      company_id: asset.company_id,
      query: (params: GetAssetFilesAssetAssetIdFilesGetQueryParams) =>
        useGetAssetFilesAssetAssetIdFilesGet(asset.asset_id, params),
    };
  } else {
    return {
      company_id: (assetPosition as AssetPosition).company_id,
      query: (
        params: GetIntegrationFilesAssetPositionAssetPositionIdFilesGetQueryParams,
      ) =>
        useGetIntegrationFilesAssetPositionAssetPositionIdFilesGet(
          (assetPosition as AssetPosition).asset_position_id,
          params,
        ),
    };
  }
}

const GatewayFilelist = ({
  asset,
  assetPosition,
}: Props): React.ReactElement => {
  const { params } = useGetParsedGatewayFileListFilterParams();
  const header = (
    <TableRow>
      <TableHeader>&nbsp;</TableHeader>
      <TableHeader>Filename</TableHeader>
      <TableHeader>Date received</TableHeader>
      <TableHeader>File size</TableHeader>
      <TableHeader>Status</TableHeader>
      <TableHeader>&nbsp;</TableHeader>
    </TableRow>
  );
  const row = (value: GatewayFile): React.ReactElement => (
    <GatewayFileListRow
      file={value}
      key={value.file_id}
      index={value.file_id}
    />
  );

  const { company_id, query } = getVariables(asset, assetPosition);

  return (
    <>
      <GatewayFileListFilterForm />
      <NewGetListPaginatedTable
        params={{
          company_id,
          start_datetime: params.startDatetime?.format(),
          end_datetime: params.endDatetime?.format(),
        }}
        useQuery={query}
        cursor={{ max_items: 50, direction: "desc" }}
        header={[header, 5]}
        row={row}
      />
    </>
  );
};

export default GatewayFilelist;
