import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import type { WirelessSurveyOut } from "kubb";
import type { WirelessSurveyTarget } from "kubb";
import { useState } from "react";
import * as yup from "yup";
import BulkUpdateCSVDropZone from "../../../components/BulkUpdate/BulkUpdateCSVDropZone";
import UpdateButton from "../../../components/Buttons/UpdateButton";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import { useUpdateWirelessSurvey } from "../../../hooks/updateEntity.tsx";
import {
  type UploadDefinition,
  convertCSVToDefinitions,
} from "../assetPositions/simpleAssetRegisterUpload/GenericUploadPage";
import UploadPreviewTable from "../assetPositions/simpleAssetRegisterUpload/UploadPreviewTable";

type ParsedTargetDefinition = UploadDefinition<WirelessSurveyTarget>;

const importSchema = yup.object().shape({
  serial_number: yup.number().integer().positive().required(),
  label: yup.string(),
  sortOrder: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : null))
    .nullable(true)
    .integer(),
});

function WirelessSurveyTargetTab(props: {
  survey: WirelessSurveyOut;
  refresh?: () => any;
}) {
  const [newTargets, setNewTargets] = useState<
    ParsedTargetDefinition[] | undefined
  >(undefined);

  const currentNumberOfTargets =
    props.survey.configuration?.targets?.length || 0;

  function processFile(data: Promise<ParsedTargetDefinition[]>) {
    data.then((values) => {
      setNewTargets(
        values.map((v) => {
          return {
            ...v,
            original: {
              ...v.original,
              serial_number: !Number.isNaN(v.original.serial_number)
                ? String(Number.parseInt(v.original?.serial_number)).padStart(
                    8,
                    "0",
                  )
                : v.original.serial_number,
            },
            object: {
              ...v.object,
              serial_number: String(v.object?.serial_number).padStart(8, "0"),
            },
          };
        }),
      );
    });
  }

  async function postUpload() {
    setNewTargets(undefined);
    props.refresh?.();
  }

  const update = useUpdateWirelessSurvey(props.survey);

  return (
    <>
      <p>This survey currently has {currentNumberOfTargets} targets</p>
      <p>
        You can change the targets for the survey by uploading a new CSV file.
        Once uploaded, you will be shown a preview before you save the changes.
      </p>
      <p>
        Your csv file must contain the columns:
        <ul>
          <li>
            <b>serial_number</b> - The 8 digit secondary address for the device
            you want to find
          </li>
        </ul>
      </p>
      <p>
        Your csv file may contain the columns:
        <ul>
          <li>
            <b>label</b> - A description you want to give to the device
          </li>
          <li>
            <b>sort_order</b> - An integer to set the default sort order of
            targets
          </li>
        </ul>
      </p>
      <p>
        <CompanyNavLink
          to={{ pathname: "/examples/wireless_survey_targets.csv" }}
          onlyExternal={true}
        >
          Click to download an example file.
        </CompanyNavLink>
      </p>

      <BulkUpdateCSVDropZone
        buttonLabel={"Upload new target CSV"}
        parseComplete={(d) => {
          processFile(convertCSVToDefinitions(d, importSchema));
        }}
      />
      <UpdateButton
        text={"Save new targets"}
        updatedEntity={{
          ...props.survey,
          configuration: {
            targets: newTargets
              ?.filter((v) => (v.errors || []).length === 0)
              .map((v) => v.object) as WirelessSurveyTarget[],
          },
        }}
        update={update}
        icon={faSave}
        color={"brandLight"}
        disabled={newTargets === undefined}
        postUpdate={postUpload}
      />
      <UpdateButton
        text={"Delete current targets"}
        update={update}
        updatedEntity={{ ...props.survey, configuration: { targets: [] } }}
        icon={faTrash}
        color={"red"}
        disabled={currentNumberOfTargets === 0}
        postUpdate={props.refresh}
      />
      {newTargets && (
        <UploadPreviewTable
          definitions={newTargets}
          requiredHeaders={["serial_number"]}
          optionalHeaders={["label", "sort_order"]}
          uploadedEntityName={"target"}
        />
      )}
    </>
  );
}

export default WirelessSurveyTargetTab;
