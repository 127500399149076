import type {
  WirelessSurveyDataCollectorOut,
  WirelessSurveyDataCollectorUpdate,
} from "kubb";
import { useState } from "react";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "../../../components/Theme/button";
import {
  Dialog,
  DialogBody,
  DialogTitle,
} from "../../../components/Theme/dialog";
import type { MutateEntityWrapperResult } from "../../../hooks/updateEntity";
import WirelessSurveySamplePointForm, {
  type WirelessSurveySamplePointFormSubmitValues,
} from "./WirelessSurveySamplePointForm";

function WirelessSurveySamplePointAddModal(props: {
  dataCollector: WirelessSurveyDataCollectorOut;
  refresh?: () => any;
  update: MutateEntityWrapperResult<WirelessSurveyDataCollectorUpdate>;
  disabled?: boolean;
  edit?: number;
}) {
  const { update } = props;

  const editMode = props.edit !== undefined;

  const [open, setOpen] = useState<boolean>(false);
  function submit(v: WirelessSurveySamplePointFormSubmitValues): Promise<any> {
    const newValue = editMode
      ? {
          ...props.dataCollector,
          sample_points: [
            ...props.dataCollector.sample_points.filter(
              (_v, j) => j !== props.edit,
            ),
            {
              ...v,
              start_date_time: v.start_date_time.toISOString(),
              end_date_time: v.end_date_time?.toISOString(),
            },
          ],
        }
      : {
          ...props.dataCollector,
          sample_points: [
            ...props.dataCollector.sample_points,
            {
              ...v,
              start_date_time: v.start_date_time.toISOString(),
              end_date_time: v.end_date_time?.toISOString(),
            },
          ],
        };
    return update.update(newValue).finally(() => {
      setOpen(false);
      props.refresh?.();
    });
  }

  return (
    <>
      <Dialog open={open} onClose={() => {}}>
        <DialogTitle>{editMode ? "Edit" : "Add"} Sample Point</DialogTitle>
        <DialogBody>
          <BlockSpinner loading={update.loading}>
            <WirelessSurveySamplePointForm
              dataCollector={props.dataCollector}
              edit={props.edit}
              refresh={() => {
                props.refresh?.();
                setOpen(false);
              }}
              onCancel={() => setOpen(false)}
              submit={submit}
            />
          </BlockSpinner>
        </DialogBody>
      </Dialog>
      <Button
        color={"green"}
        size={"sm"}
        disabled={props.disabled}
        onClick={() => setOpen(true)}
      >
        {editMode ? "Edit" : "Add"}
      </Button>
    </>
  );
}

export default WirelessSurveySamplePointAddModal;
