import dayjs from "dayjs";
import type {
  AssetRegisterItem,
  GatewayAssetOut,
  GatewaySettingsTypeEnum,
  GatewayState,
} from "kubb";
import { gatewaySettingsTypeEnum } from "kubb";
import { DenseAssetRegisterListItem } from "pages/assetRegister/AssetRegisterListPage/AssetRegisterListItem";
import type React from "react";
import CompanyNavLink from "../../../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates from "../../../../components/utils/HighlightNullAndOldDates";
import { formatDataCollectionFrequency } from "../../../../model/assets/deviceData";
import type { Asset } from "../../../../utils/object.tsx";
import GatewayStateLabel from "./GatewayStateLabel";

const GatewayRows = (gateway: GatewayAssetOut): React.ReactElement => {
  return (
    <>
      <td data-cy={"gateway-asset-id"}>
        <CompanyNavLink to={{ pathname: `/admin/asset/${gateway?.asset_id}` }}>
          ...{gateway?.asset_id.slice(-8)}
        </CompanyNavLink>
      </td>
      <td data-cy={"gateway-serial-number"}>{gateway?.serial_number}</td>
      <td data-cy={"gateway-last-contact"}>
        <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
          {gateway?.last_contact}
        </HighlightNullAndOldDates>
      </td>
      <td data-cy={"gateway-expected-connectivity"}>
        {formatDataCollectionFrequency(
          gateway?.configuration?.collection_frequency,
        )}
      </td>
      <td data-cy={"gateway-model"}>{gateway?.model}</td>
      <td data-cy={"gateway-ip-address"}>{gateway?.ip_address}</td>
      <td data-cy={"gateway-manufacturer"}>{gateway?.manufacturer}</td>
      <td data-cy={"gateway-mac-address"}>{gateway?.mac_address}</td>
      <td data-cy={"gateway-firmware-version"}>{gateway?.firmware_version}</td>
    </>
  );
};

function ReportDetail(props: {
  title: string;
  value?: string;
}): React.ReactElement {
  return (
    <p className={"ml-2 text-nowrap text-gray-500 text-xs"}>
      <span className={"font-bold"}>{props.title}</span>:{" "}
      <span className={"text-muted"}>{props.value || "Unknown"}</span>
    </p>
  );
}

function gatewayStatusColumn(gateway?: GatewayAssetOut): React.ReactElement {
  if (!gateway) {
    return <>No gateway</>;
  }
  return (
    <div className={"min-w-0 flex-auto"}>
      <p data-cy={"gateway-last-contact"}>
        <HighlightNullAndOldDates ageHighlight={dayjs.duration(3, "days")}>
          {gateway?.last_contact}
        </HighlightNullAndOldDates>
      </p>
      <p>
        {gateway && (
          <GatewayStateLabel
            size={"sm"}
            short={true}
            state={(gateway.state as GatewayState)?.configuration_state}
          />
        )}
      </p>
    </div>
  );
}

function configSwitch(config: GatewaySettingsTypeEnum): string {
  switch (config) {
    case "ELVACO_CMI_WIRELESS_ONLY":
      return "CMi Wireless";
    case "ELVACO_SLOW":
      return "Slow";
    case "ELVACO_AGGRESSIVE":
      return "Aggressive";
    default:
      return "Default";
  }
}
function gatewayDetailsColumn(
  gateway?: GatewayAssetOut,
  actions?: React.ReactNode,
): React.ReactElement {
  if (!gateway) {
    return <></>;
  }
  return (
    <>
      <div className={"hidden lg:flex flex-wrap gap-0 flex-grow"}>
        <div
          className={"ml-2 text-gray-500 text-xs"}
          data-cy={"gateway-expected-connectivity"}
        >
          <b>Target</b>:{" "}
          {formatDataCollectionFrequency(
            gateway?.configuration?.collection_frequency,
            true,
          )}
        </div>
        <ReportDetail
          title={"Model"}
          value={`${gateway.manufacturer}/${gateway.model}`}
        />
        <ReportDetail title={"Firmware"} value={gateway.firmware_version} />
        <ReportDetail title={"SIM"} value={gateway.sim_card_iccid} />
        <ReportDetail title={"IP"} value={gateway.ip_address} />
        <ReportDetail
          title={"Config"}
          value={configSwitch(
            gateway.configuration.manufacturer_specific_settings
              ?.mbus_settings || gatewaySettingsTypeEnum.ELVACO_DEFAULT,
          )}
        />
      </div>
      {actions && <div className={"flex justify-self-end px-2"}>{actions}</div>}
    </>
  );
}

export default function GatewayConnectivityReportDataRow(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  actionButtons?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
}): React.ReactElement {
  const { assetRegisterItem, showScheme = true } = props;
  return (
    <DenseAssetRegisterListItem
      assetRegisterItem={assetRegisterItem}
      showScheme={showScheme}
      showType={false}
      column2={({ assetRegisterItem }) => {
        return (
          <>
            {gatewayStatusColumn(
              assetRegisterItem.asset as GatewayAssetOut | undefined,
            )}
          </>
        );
      }}
      column3={({ assetRegisterItem }) => {
        return gatewayDetailsColumn(
          assetRegisterItem.asset as GatewayAssetOut | undefined,
          props.actionButtons?.({ assetRegisterItem }),
        );
      }}
    />
  );
}

export const UnassignedGatewayConnectivityReportDataRow = (value: Asset) => {
  return <tr>{GatewayRows(value as GatewayAssetOut)}</tr>;
};
