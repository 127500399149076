import client from 'client'
import type {
  GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse,
  GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetPathParams,
  GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryParams,
  GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet422,
} from '../../types/GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig, ResponseErrorConfig, ResponseConfig } from 'client'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey = (
  asset_position_id: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryParams,
) => [{ url: '/asset_position/:asset_position_id/wireless_receivers', params: { asset_position_id: asset_position_id } }, ...(params ? [params] : [])] as const

export type GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey = ReturnType<
  typeof getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey
>

/**
 * @summary Get Asset Position Wireless Receivers
 * {@link /asset_position/:asset_position_id/wireless_receivers}
 */
export async function getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet(
  asset_position_id: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse,
    ResponseErrorConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet422>,
    unknown
  >({ method: 'GET', url: `/asset_position/${asset_position_id}/wireless_receivers`, params, ...requestConfig })
  return res
}

export function getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryOptions(
  asset_position_id: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey(asset_position_id, params)
  return queryOptions<
    ResponseConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse>,
    ResponseErrorConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet422>,
    ResponseConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse>,
    typeof queryKey
  >({
    enabled: !!asset_position_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet(asset_position_id, params, config)
    },
  })
}

/**
 * @summary Get Asset Position Wireless Receivers
 * {@link /asset_position/:asset_position_id/wireless_receivers}
 */
export function useGetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet<
  TData = ResponseConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse>,
  TQueryData = ResponseConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse>,
  TQueryKey extends QueryKey = GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey,
>(
  asset_position_id: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetPathParams['asset_position_id'],
  params?: GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ResponseConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryResponse>,
        ResponseErrorConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryKey(asset_position_id, params)

  const query = useQuery({
    ...(getAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGetQueryOptions(
      asset_position_id,
      params,
      config,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetAssetPositionWirelessReceiversAssetPositionAssetPositionIdWirelessReceiversGet422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}