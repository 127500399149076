import { assetTypeEnum } from "../../kubb";
import {
  type OptionsList,
  SelectComboField,
  type SelectComboFieldProps,
  stringListToOptions,
} from "../Forms/SelectCombo/SelectCombo";

export interface AssetTypeSelectComboProps
  extends Omit<SelectComboFieldProps, "options"> {
  includeNonCreateables?: boolean;
  includeWirelessCluster?: boolean;
  allowUnknown?: boolean;
}

export const meterOptions = [
  assetTypeEnum.cooling_meter,
  assetTypeEnum.electricity_meter,
  assetTypeEnum.gas_meter,
  assetTypeEnum.heat_meter,
  assetTypeEnum.heating_and_cooling_meter,
  assetTypeEnum.water_meter,
];

export function getOptionsList(
  includeNonCreateables = false,
  includeWirelessCluster = false,
  allowUnknown = false,
): OptionsList {
  const infrastructureOptions = [
    "gateway",
    "wireless_receiver",
    includeWirelessCluster ? "wireless_cluster" : undefined,
    includeNonCreateables ? "sim_card" : undefined,
  ];

  const otherOptions = [
    "room_sensor",
    "co2_room_sensor",
    "input_output_module",
    allowUnknown ? "unknown" : undefined,
  ];

  return [
    { label: "Meters", options: stringListToOptions(meterOptions) },
    {
      label: "Infrastructure",
      options: stringListToOptions(infrastructureOptions),
    },
    { label: "Other", options: stringListToOptions(otherOptions) },
  ];
}

function AssetTypeSelectCombo(props: AssetTypeSelectComboProps) {
  const {
    includeNonCreateables = false,
    includeWirelessCluster = false,
    allowUnknown = false,
    ...rest
  } = props;
  return (
    <SelectComboField
      {...rest}
      options={getOptionsList(
        includeNonCreateables,
        includeWirelessCluster,
        allowUnknown,
      )}
    />
  );
}

export default AssetTypeSelectCombo;
