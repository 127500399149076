import * as Sentry from "@sentry/react";
import type { WirelessSurveyOut } from "kubb";
import { useGetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet } from "kubb";
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getWebSocketURL } from "../../../actions/helpers/auth";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import {
  type WirelessDataSurveyMap,
  type WirelessSurveyDataPoint,
  groupWirelessSurveyDataByDataCollector,
  handleReceivingNewStreamingSurveyDataRowMessage,
  parseWebsocketMessage,
} from "../../../model/assets/wirelessSurveyData";
import WirelessSurveyDataCollectorsTab from "./WirelessSurveyDataCollectorsTab";
import WirelessSurveyLiveData from "./WirelessSurveyLiveData";
import WirelessSurveySamplePointTab from "./WirelessSurveySamplePointTab";
import WirelessSurveySummaryTable from "./WirelessSurveySummaryTable";
import WirelessSurveyTargetTab from "./WirelessSurveyTargetTab";

function WirelessSurveyTabs(props: {
  survey: WirelessSurveyOut;
  refreshSurvey?: () => any;
}) {
  const { survey } = props;

  const { data, isPending, refetch } =
    useGetWirelessSurveySamplePointsWirelessSurveySurveyIdDataCollectorGet(
      survey.survey_id,
    );

  const [messageHistory, setMessageHistory] = useState<
    WirelessSurveyDataPoint[]
  >([]);
  const [messageMap, setMessageMap] = useState<WirelessDataSurveyMap>(
    new Map(),
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    getWebSocketURL,
    {
      onClose: (e) => {
        console.log(
          `Socket closed. code=${e.code} message=${e.reason} clean=${e.wasClean}`,
        );
        Sentry.captureException(
          `Socket closed. code=${e.code} message=${e.reason} clean=${e.wasClean}`,
        );
      },
      onError: (e) => {
        Sentry.captureException(e);
      },
    },
  );

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsed = parseWebsocketMessage(lastMessage.data);
        setMessageHistory((prevState) =>
          handleReceivingNewStreamingSurveyDataRowMessage(parsed, prevState),
        );
        setMessageMap((prevState) =>
          groupWirelessSurveyDataByDataCollector(
            parsed,
            prevState,
            new Map((data?.data || []).map((v) => [v.data_collector_id, v])),
          ),
        );
      } catch (e) {
        console.log(lastMessage.data);
        throw e;
      }
    }
  }, [lastMessage, data?.data]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(
        JSON.stringify({
          action: "GetSurveyData",
          company_id: props.survey.company_id,
          survey_id: props.survey.survey_id,
        }),
      );
    }
  }, [
    readyState,
    sendMessage,
    props.survey.company_id,
    props.survey.survey_id,
  ]);

  return (
    <>
      {readyState !== ReadyState.OPEN && (
        <>Connection: {ReadyState[readyState]}</>
      )}
      <RoutedTabGroupInterface
        tabs={[
          {
            tabName: "Data Collectors",
            tabPath: "data_collectors",
            tabContent: (
              <WirelessSurveyDataCollectorsTab
                survey={survey}
                dataCollectors={data?.data}
                loading={isPending}
                refresh={refetch}
              />
            ),
          },
          {
            tabName: "Sample Points",
            tabPath: "sample_points",
            tabContent: (
              <WirelessSurveySamplePointTab
                dataCollectors={data?.data}
                loading={isPending}
                refresh={refetch}
              />
            ),
          },
          {
            tabName: `Live Data (${messageHistory.length})`,
            tabPath: "live_data",
            tabContent: (
              <WirelessSurveyLiveData
                survey={props.survey}
                dataCollectors={data?.data || []}
                data={messageHistory}
              />
            ),
          },
          {
            tabName: "Targets",
            tabPath: "targets",
            tabContent: (
              <WirelessSurveyTargetTab
                survey={props.survey}
                refresh={props.refreshSurvey}
              />
            ),
          },
          {
            tabName: "Summary",
            tabPath: "summary",
            tabContent: (
              <WirelessSurveySummaryTable
                survey={props.survey}
                dataCollectors={data?.data || []}
                wirelessDataSurveyMappedData={messageMap}
                targets={props.survey.configuration?.targets}
              />
            ),
          },
        ]}
        topLevelPath={`/admin/asset_register/wireless_survey/view/${survey.survey_id}`}
      />
    </>
  );
}

export default WirelessSurveyTabs;
