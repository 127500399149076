import dayjs from "dayjs";
import {
  type AssetPosition,
  type Note,
  getNoteListNoteGet,
  noteOutputEnumEnum,
} from "kubb";
import type { ExpandedNote } from "kubb";
import { useGetNoteListNoteGet } from "kubb";
import { isNil } from "lodash-es";
import type React from "react";
import { useState } from "react";
import { useSelectedCompany } from "reducers/company";
import { useSelectedScheme } from "reducers/scheme";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import NewGetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { StackedListTwoColumnItem } from "../../components/Theme/stacked-list";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import PaginatedApiToCSVExportButton from "../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import CreateNoteModalButton from "./CreateNoteModalButton";
import NoteModal from "./NoteModal";

interface Props {
  assetPosition?: AssetPosition;
}

function NoteListRow(
  note: Note | ExpandedNote,
  update: (updatedItem: Note) => void,
): React.ReactElement {
  const expanded = !isNil((note as ExpandedNote).location_id);

  return (
    <StackedListTwoColumnItem
      key={note.note_id}
      column1={
        <div className={""}>
          <NoteModal note={note} onUpdate={update}>
            {({ openModal }) => {
              return (
                <h4
                  className={"mb-0"}
                  onClick={openModal}
                  onKeyDown={openModal}
                >
                  {note.title}
                  <AssetRegisterStatusLabel
                    status={note.status}
                    className={"ml-2"}
                  />
                </h4>
              );
            }}
          </NoteModal>
          {expanded && (
            <>
              <small className={"pr-2"}>
                <CompanyNavLink
                  to={{
                    pathname: `/admin/location/${
                      (note as ExpandedNote).location_id
                    }`,
                  }}
                  external={true}
                >
                  {(note as ExpandedNote).address}
                </CompanyNavLink>
              </small>
              <small className={"pr-2"}>
                Position:{" "}
                <CompanyNavLink
                  to={{
                    pathname: `/admin/asset_position/${note.asset_position_id}`,
                  }}
                  external={true}
                >
                  {(note as ExpandedNote).customer_reference ||
                    `...${note.asset_position_id.slice(26)}`}
                </CompanyNavLink>
              </small>
            </>
          )}
        </div>
      }
      column2={
        <span className={"text-xs text-gray-600 italic"}>
          {dayjs(note.created_at).format("LLL")} by {note.creator.user_name}
        </span>
      }
    />
  );
}

export default function NoteList(props: Props): React.ReactElement {
  const { assetPosition } = props;

  const selectedCompany = useSelectedCompany();
  const selectedScheme = useSelectedScheme();

  const [lastChange, setLastChange] = useState(new Date());

  const params = {
    company_id: selectedCompany?.company_id,
    scheme_id: selectedScheme?.scheme_id,
    asset_position_id: assetPosition?.asset_position_id,
    output: assetPosition
      ? noteOutputEnumEnum.NOTE
      : noteOutputEnumEnum.EXPANDED,
  };

  const filename: string = assetPosition
    ? `notes_export_${assetPosition.company_id}_${assetPosition.scheme_id}_${assetPosition.asset_position_id}`
    : selectedScheme
      ? `notes_export_${selectedScheme.company_id}_${selectedScheme.scheme_id}`
      : `notes_export_${selectedCompany?.company_id}`;

  return (
    <>
      {assetPosition && (
        <CreateNoteModalButton
          assetPosition={assetPosition}
          onCreate={() => setLastChange(new Date())}
        />
      )}

      <NewGetListPaginatedTable
        params={params}
        cursor={{ max_items: 25 }}
        data-cy={"note-list"}
        row={NoteListRow}
        header={[null, 4]}
        useQuery={useGetNoteListNoteGet}
        additionalFunctionButtons={
          <PaginatedApiToCSVExportButton
            params={params}
            useQuery={getNoteListNoteGet}
            columnOrder={[
              "title",
              "content",
              "company_id",
              "scheme_id",
              "location_id",
              "address",
              "asset_position_id",
              "customerReference",
              "createdAt",
              "creator.userId",
              "creator.userName",
              "noteId",
              "status",
            ]}
            filename={filename}
          />
        }
      />
    </>
  );
}
