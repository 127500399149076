import type { ResponseConfig } from "@kubb/plugin-client/clients/axios.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import type { Dayjs } from "dayjs";
import type { Cursor } from "kubb";
import type React from "react";
import { useEffect, useState } from "react";
import NewApiPaginatedList from "./NewApiPaginatedList";

export default function GetListPaginatedList<
  TItem,
  TQuery,
  TData = TItem[],
>(props: {
  row: (item: TItem, index: number, refresh?: () => void) => React.ReactElement;
  header?: React.ReactElement;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  cursor?: Cursor;
  noDataRow?: React.ReactElement;
  showPagination?: boolean;
  additionalFunctionButtons?: React.ReactElement;
  lastChange?: Dayjs;
  params: TQuery;
  useQuery: (
    variables: TQuery,
  ) => UseQueryResult<ResponseConfig<TData> | undefined, any>;
}): React.ReactElement {
  const [cursor, setCursor] = useState<Cursor>(
    props.cursor || { max_items: 25 },
  );
  const { useQuery, params, ...rest } = props;
  const { data, isPending, error, refetch } = useQuery({
    ...params,
    ...cursor,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: want to trigger a refresh if last change updates
  useEffect(() => {
    refetch();
  }, [props.lastChange]);
  return (
    <NewApiPaginatedList
      data={{
        data: (data?.data as Array<TItem>) || [],
        cursor: data?.headers?.["x-cursor"],
        setCursor: (cursor) => {
          if (cursor) {
            setCursor(cursor);
          }
        },
      }}
      error={error ? (error as unknown as Error) : null}
      loading={isPending}
      refresh={refetch}
      {...rest}
    />
  );
}
